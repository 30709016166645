import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import { unregister } from './registerServiceWorker';
import Root from './containers/Root';
import configureStore from './redux/store.js';
import * as Sentry from '@sentry/browser';

const store = configureStore();

if(process.env.REACT_APP_STAGE !== 'local_dev')
	Sentry.init({
		dsn: "https://2384c40ae254437facc7df55af45f479@sentry.io/1726160",
		release: 'RW6.03.04b',
		environment: process.env.REACT_APP_STAGE
	});

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
//registerServiceWorker();
unregister();
