import React from 'react';
import Spinner from "../components/Spinner";
import { navigateTo } from '../../../actions/navigator';
import axios from 'axios';
// import { updateJobNotificationUrl } from "../../../utils/AuthUtils"

const JobNotification = props => {

    const onNotificationClick = (id, notificationId) => {
        const { dispatch } = props;
        props.updateNotificationStatus(notificationId);
        dispatch(navigateTo({ path: ['jobs', id] }));
    }

    const onScroll = event => {
        var element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // props.setMoreNotificationsLoading(true)
            props.fetchJobNotifications(props.jobNotifPage + 1);
        }
      };

    return (
        <div className="drop-down active job-notif notification-container" style={{overflowX:"hidden", overflowY: "hidden"}}>
			<div className="col-12 your-job-title text-center">
				<span className="job-title">Your Job Alerts</span>
			</div>
			<div className="col-12 pl-0 pr-0 pb-1">
				<ul className="padding0 scroll" style={{ listStyleType: 'none' }} onScroll={props.moreJobNotificationsLoading ? onScroll : null}>
                {props.isLoading ? 
                <li style={{ padding: '10px 2px', minHeight: '2em'}} key="loading-indicator">
                <Spinner />
                </li> :  null}
                {!props.isLoading && props.notifications && props.notifications.length === 0 ? <li className="text-center" style={{ padding: '10px 2px', minHeight: '2em'}} key="loading-indicator">
                <span className="job-title mt-4">No more notifications</span>
                </li> : null}
                {props.notifications && props.notifications.length ? props.notifications.map((job,i) => {
                    const daySummary = props.calculateDaySummary(job.days)
                    return (
                        <a key={i} onClick={() => onNotificationClick(job.job_id, job.my_notification_id)} className="job-redirect">
                        <li style={{ borderBottom: '1px solid #ddd', padding: '10px 10px', minHeight: '2em'}} className="cursorPointer">
							<div className="row">
                                    <div className="col-2">
                                        <img width="24px" className="img-round ml-2" src={job.avatar_url} alt="" />
                                    </div>
                                <div className="col-6 pr-0 pl-0 line-height">
                                    <span className="job-title">{job.company_name}</span>
                                
                                    <span className="job-desc">{job.title}</span>
                                </div>
                                <div className="col-4 pl-0 pr-0 line-height center">
                                    <span className="job-desc" style={{fontSize: "12px"}}>{(daySummary.days === 0 && daySummary.weeks == 0) ? <div className="mt-2" style={{color: "red"}}>Last Day</div> : null} {daySummary.weeks !== 0 ? daySummary.weeks : null} {daySummary.weeks !== 0 ? daySummary.weeks > 1 ? "Weeks" : "Week" : null} {daySummary.days !== 0 ? daySummary.days : null} {daySummary.days !== 0 ? daySummary.days > 1 ? "Days" : "Day" : null} {(daySummary.days === 0 && daySummary.weeks == 0) ? "" : <br/>}
                                    {(daySummary.days === 0 && daySummary.weeks == 0) ? "" : "to Apply"}</span>
                                </div>
					        </div>
						<div className="cf clearfix"></div>
					</li>
                    </a>
                    )}) : null}
				</ul>
				<div className="col-12 text-center">
                    <a onClick={() => props.dispatch(navigateTo({ path: ['jobs'] }))} className="job-view-all-link">View All</a>
				</div>
				</div>
		    </div>
    )
}


export default JobNotification;