import { base_url } from "./ConstantUrl";

export function constructAddResourceUrl() {
  return base_url + '/api/resources'
}

export function constructFetchResourceUrl(id) {
  return base_url + '/api/resources/' + id
}

export function constructFetchDomainGroupedDataUrl() {
  return base_url + '/api/domain_grouped_resource_catalog'
}


export function constructFetchFilteredResources() {
  return base_url + '/api/fetch_filtered_resources'
}

export function constructFetchResourcesList() {
  return base_url + '/api/fetch_resources_list'
}

export function constructFetchResourceGroupsList() {
  return base_url + '/api/fetch_resource_groups_list'
}


export function constructAddResourceGroupUrl() {
  return base_url + '/api/resource_groups';
}

export function constructResourceGroupBImageUrl() {
  return base_url + '/api/my_profile/create_resource_group_upload';
}

export function deleteResourceGroupBImageUrl(id) {
  return base_url + '/api/delete_resource_group_banner/' + id;
}

export function constructAddQuestUrl() {
  return base_url + '/api/quests';
}

export function constructFetchQuestUrl(id) {
  return base_url + '/api/quests/' + id;
}

export function constructQuestBImageUrl() {
  return base_url + '/api/my_profile/create_quest_upload';
}

export function deleteQuestBImageUrl(id) {
  return base_url + `/api/delete_quest_file/` + id;
}

export function constructFetchResourceGroupUrl(id) {
  return base_url + '/api/resource_groups/' + id;
}

export function constructResourceGroupFileUrl() {
  return base_url + '/api/upload_resource_group_file';
}

export function constructResourceGroupFileDeleteUrl(id) {
  return base_url + `/api/delete_resource_group_file?id=${id}`;
}

export function constructResourceFileUrl() {
  return base_url + '/api/resource_query_files'
}

export function constructFilterResourceGroups() {
  return base_url + '/api/filter_resource_groups'
}

export function constructFetchQuestDetailUrl(id) {
  return base_url + '/api/quests/' + id
}

export function constructFetchQuestsUrl() {
  return base_url + '/api/fetch_filtered_quests'
}

export function constructWebResourceUrl() {
  return base_url + '/api/webresources'
}

export function constructEnrollMyQuestUrl() {
  return base_url + '/api/my_profile/my_quests'
}

export function constructEnrollMyInterventionsUrl() {
  return base_url + '/api/get_my_learnings'
}

export function constructPendingTaskByInterventionUrl() {
  return base_url + '/api/pending_tasks_by_intervention'
}

export function constructEnrollMyResourceGroupUrl() {
  return base_url + '/api/my_profile/my_resource_groups'
}

export function constructFetchMyResourceGroupUrl(id) {
  return base_url + `/api/my_profile/my_resource_groups/${id}`
}

export function constructFetchMyQuestUrl(id) {
  return base_url + `/api/my_profile/my_quests/${id}`
}

export function constructAssignmentUrl(id = false) {
  return id ? base_url + `/api/assignments/${id}` : base_url + `/api/assignments`
}

export function constructMyAssignmentUrl(id = false) {
  return id ? base_url + `/api/my_profile/my_assignments/${id}` : base_url + `/api/my_profile/my_assignments`;
}

export function constructMyAssignmentFileUrl() {
  return base_url + '/api/my_profile/create_assignment_upload';
}

export function constructMyAssignmentFileDeleteUrl(assignment_id, file_id) {
  return base_url + `/api/delete_assignment_file/${file_id}?assignment_id=${assignment_id}`;
}

export function myAssignmentSubmitUrl() {
  return base_url + '/api/my_profile/my_assignment_submit';
}

export function myAssignmentEvaluateUrl () {
  return base_url + '/api/update_assignment_evaluation';
}

export function updateAssignmentEvalStatusUrl() {
  return base_url + '/api/final_activity_evaluation ';
}

export function updateAssignmentStatusUrl() {
  return base_url + '/api/my_profile/update_my_assignment_status';
}

export function fetchFilteredAssignmentList() {
  return base_url + `/api/fetch_filtered_assignment_lists`
}

export function fetchCreatedAssignmentsUrl() {
  return base_url + '/api/get_created_assignments'
}

export function fetchCreatedResourceUrl() {
  return `${base_url}/api/get_created_resources`
}

export function fetchCreatedResourceGroupUrl() {
  return `${base_url}/api/get_created_resource_groups`
}

export function fetchInterventionRatingResponseUrl() {
  return `${base_url}/api/feedback`;
}

export function fetchMentorProjectsEnrollUrl() {
  return `${base_url}/api/list_mentor_project_enrollments`
}

export function fetchMentorQuizEnrollUrl() {
  return `${base_url}/api/list_mentor_quiz_enrollments`
}

export function fetchMentorAssignmentEnrollUrl() {
  return `${base_url}/api/list_mentor_assignment_enrollments`
}

export function fetchMentorEngageEventUrl() {
  return `${base_url}/api/get_mentor_events`
}

export function constructAssignmentApplicantsUrl() {
  return base_url + '/api/my_profile/assignment_applicants';
}

export function constructAssignmentEnrollmentSplitUrl() {
  return base_url + "/api/assignment_status_split"
}

export function constructAssignmentOrgSplitUrl() {
  return base_url + '/api/my_profile/assignment_applicants_org_split';
}

export function constructAssignmentFilterByBatch() {
  return base_url + '/api/get_assignment_batch_tags_list';
}

export function generatePresignedUrl() {
  return base_url + '/api/my_profile/create_presigned_url';
}

export function constructMyInterventionUrl() {
  return base_url + '/api/get_my_learnings'
}

export function constructMyResumeFileUrl() {
  return base_url + '/api/my_profile/resume_upload';
}
export function constructMyResumeFileUpdateUrl() {
  return base_url + '/api/my_profile/create_resume_upload';
}

export function skipResourceUnit() {
  return base_url + '/api/skip_resource_group_unit';
}