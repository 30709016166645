import React from 'react';
import Link from './Link';
import { checkNested } from '../../../utils/CommonUtils';
import { Waypoint } from 'react-waypoint';
import Spinner from '../components/Spinner';
import { fetchMoreNotifications } from '../../../actions/authed';

const MyNotifications = props => {
	const loadMoreNotifications = () => {
		const { isFetchingNotification, nextPageNotification, type } = props;
		if (!isFetchingNotification && nextPageNotification > 1) {
			props.dispatch(fetchMoreNotifications(type));
		}
	}

	const renderNotifItem = item => {
		let vRoute = { path: [''] };
		if (item.post_id) {
			vRoute = { path: ['posts', item.post_id] }
		}
		else if (item.my_project_id) {
			vRoute = { path: ['myProjects', item.my_project_id] }
		}
		else if (item.resource_group_id) {
			vRoute = {
				path: ['resourceGroups', item.resource_group_id]
			}
		}
		else if (item.quest_id) {
			vRoute = {
				path: ['quests', item.quest_id]
			}
		}
		else if (item.project_id) {
			vRoute = { path: ['projects', item.project_id] }
		}
		else if (item.club_id && (item.activity_key === "act-club-subscribe" || item.activity_key === "act-club-subscribed" || item.activity_key === "act-club-invited-student" || item.activity_key === "act-club-invited-institute-info")) {
			if (props.currentUser.profile.profile_type === 'STUDENT' || props.currentUser.profile.profile_type === 'ADVISOR')
				vRoute = { path: ['clubs', item.club_id] }
			else
				vRoute = { path: ['clubDashboard', item.club_id] }
		}
		else if (item.job_id) {
			vRoute = { path: ['jobs', item.job_id] }
		} else if (item.my_quiz_id) {
			vRoute = { path: ['quiz', item.quiz_id] }
		} else if (item.my_question_bank_id) {
			vRoute = { path: ['quizByte', item.my_question_bank_id],
			query:  {question_bank_id: item.question_bank_id}}
		} else if (item.my_assignment_id) {
			vRoute = { path: ['myactivity', item.my_assignment_id] }
		}else if (item.event_id) {
			vRoute = { path: ['events', item.event_id] }
		}
		return (
			<li key={`${item.id}`}
				style={{
					borderBottom: '1px solid #ddd', padding: '10px 2px',
					minHeight: '2em'
				}}
				className="cursorPointer">
				<div className="row">
					<div className="col-1"><Link
						style={{ textDecoration: 'none', color: 'inherit' }}
						dispatch={props.dispatch}
						route={vRoute} >
						<div className="col-1">
							{checkNested(item, 'performer_profile', 'avatar_url') ?
								<img width="24px" className="img-round" src={item.performer_profile.avatar_url} alt="" />
								: <div style={{ minWidth: 24 }}></div>}
						</div>
					</Link>
					</div>
					<div className="col-10" style={{ marginLeft: 12 }}>
						{item.board_id ?
							<a href={'/workbench/boards/' + item.board_id} className="notification-overflow">{item.activity_type}</a>
							: <Link
								style={{ textDecoration: 'none', color: 'inherit' }}
								dispatch={props.dispatch}
								route={vRoute} >
								<span className="notification-overflow"> {item.activity_type} </span>
							</Link>
						}
					</div>
				</div>
				<div className="cf clearfix"></div>
			</li>
		);
	}

	const renderNotifications = () => {
		const { notifications } = props;
		var notifCards = notifications.map((item) => {
			return renderNotifItem(item);
		});
		return notifCards;
		// const { notifications } = props;
		// // Filter notifications based on activity_key
        // const questionBankNotifications = notifications.filter(item =>
        //     item.activity_key && item.activity_key.includes('act-myquestionbank')
        // );
        // const otherNotifications = notifications.filter(item =>
        //     !item.activity_key || !item.activity_key.includes('act-myquestionbank')
        // );
        // // Function to check if a notification is within the specified criteria
        // const isWithinCriteria = (notification) => {
        //     const activityMatch = notification.activity_type && notification.activity_type.match(/(\w{3} \d{1,2}, \d{4} \d{1,2}:\d{2})/);
        //     if (!activityMatch) return false;
    
        //     const currentDate = new Date();
        //     const activityDate = new Date(activityMatch[0]);
    
        //     if (activityDate.getFullYear() < currentDate.getFullYear()) {
        //         return true;
        //     } else if (activityDate.getFullYear() === currentDate.getFullYear()) {
        //         if (activityDate.getMonth() < currentDate.getMonth()) {
        //             return true;
        //         } else if (activityDate.getMonth() === currentDate.getMonth()) {
        //             if (activityDate.getDate() < currentDate.getDate()) {
        //                 return true;
        //             }else if (activityDate.getDate() === currentDate.getDate()) {
        //                 // Check if time is before or equal to current time
        //                 // return activityDate <= currentDate;
        //                 return true;
        //             }
        //         }
        //     }
        //     return false;
        // };
    
        // // Filter and map question bank notifications
        // const filteredQuestionBankNotifications = questionBankNotifications.filter(isWithinCriteria).map(renderNotifItem);
    
        // // Map other notifications
        // const otherNotifCards = otherNotifications.map(renderNotifItem);
    
        // // Combine and return all notifications
        // return [...filteredQuestionBankNotifications, ...otherNotifCards];
		
	}

	const renderWaypoint = () => {
		return (
			<Waypoint
				onEnter={loadMoreNotifications}
			/>
		);
	}

	const { notifications, isFetchingNotification, hasMorenotification, isMobile } = props;
	if (isMobile) {
		return (
			<div className="drop-down-mobile active notification-container">
				<div>
					<ul className="padding0" style={{ listStyleType: 'none' }}>
						{renderNotifications()}
					</ul>
					<div>
						{renderWaypoint()}
						{isFetchingNotification && hasMorenotification ? <li style={{ listStyleType: 'none' }} key="loading-indicator">
							<div style={notifications.length ? { "marginTop": "-10px" } : {}}>
								<Spinner />
							</div>
						</li> : null}
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<div className="drop-down active notification-container">
				<div className="col-12" style={{ paddingLeft: 0 }}>
					<ul className="padding0" style={{ listStyleType: 'none' }}>
						{renderNotifications()}
					</ul>
					<div>
						{renderWaypoint()}
						{isFetchingNotification && hasMorenotification ? <li style={{ listStyleType: 'none' }} key="loading-indicator">
							<div style={notifications.length ? { "marginTop": "-10px" } : {}}>
								<Spinner />
							</div>
						</li> : null}
					</div>
				</div>
			</div>
		)
	}
}

export default MyNotifications;