import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ClickOutsideHandler from "../../../components/commonComponent/clickOutsideHandler";
import { drawerStructure } from "../../../../../constants/DrawerSchema";
import { navigateTo } from "../../../../../actions/navigator";
import "./style.css";
import { changeDrawerState } from "../../../../../actions/drawer";
import { getAdminAccessLevelUrl } from "../../../../../utils/AdminUtils";
import { fetchDataAuth } from "../../../../../actions/commonActions";
import axios from "axios"
const fetchDrawerStructure = (profileType) => {
  if (profileType) {
    return `${`${profileType}_LOGIN`}`;
  } else {
    return "DEFAULT";
  }
};

const DrawerComponent = (props) => {
  const { dispatch, drawerState, currentUser, isMobile, height,adminAccessLevel } = props;
  const [showChildItem, setShowChildItem] = useState({ name: "", show: false });
  let profileType = currentUser && currentUser.profile.profile_type;
  let college_admin = currentUser && currentUser.profile.college_admin;
  useEffect(() => {
    if (currentUser && currentUser.profile && currentUser.profile.profile_type=="ADVISOR"  && !adminAccessLevel) {
      axios
        .get(getAdminAccessLevelUrl(), {
          headers: { Authorization: localStorage.getItem("access_token") },
        })
        .then((res) => {
           dispatch({
                type: "MERGE_IN_COMMON_REDUCER",
                data: res.data,
                key :"adminAccessLevel",
              });
        })
        .catch((res) => {
          console.log("Error");
        });
    }
  }, []);
  let drawer = drawerStructure(
    fetchDrawerStructure(profileType),
    isMobile,
    college_admin,
    props.adminAccessLevel
  );
  let currentPath = props.path.join("/");
  let partialDrawerCondition = !drawerState && profileType;
  let drawerItems = drawer.map((item) => {
    let renderItem =
      item.child &&
      item.child.map((child) => (
        <div
          key={`child_item_${child.name}`}
          className={`CustomDrawer-MainItem ${
            currentPath === child.path ? "Active" : ""
          } ${
            showChildItem.name === item.name ? "ChildItemActive" : "ChildItem"
          }`}
          onClick={() =>
            handleNavigation(child.name, child.navigateTo, child.child)
          }
        >
          <div className="CustomDrawer-IconDiv">
            <img
              src={child.drawerIcon}
              alt={`drawer_icon`}
              className="CustomDrawer-ItemIcon"
            />
          </div>
          <span className="ChildName">{child.display_name}</span>
        </div>
      ));
    return (
      <div
        className={`CustomDrawer-DrawerItemDiv ${
          showChildItem.show && showChildItem.name === item.name ? "Open" : ""
        }`}
        key={`drawer_item_${item.name}`}
      >
        <div
          onClick={() =>
            handleNavigation(
              item.name,
              item.navigateTo,
              item.child,
              partialDrawerCondition
            )
          }
          className={`CustomDrawer-MainItem ${
            currentPath === item.path ? "Active" : ""
          } ${partialDrawerCondition ? "PartialDrawer" : ""} ${
            item.childPaths && item.childPaths.includes(currentPath)
              ? "SelectedParent"
              : ""
          }`}
        >
          <div
            className={`CustomDrawer-IconDiv ${
              partialDrawerCondition ? "PartialIcon" : ""
            }`}
          >
            <img
              src={item.drawerIcon}
              alt={`drawer_icon`}
              className="CustomDrawer-ItemIcon"
              style={item.name === "ninja" ? { height: 40, width: 40 } : {}}
            />
          </div>
          <div
            className={`CustomDrawer-DrawerItemNameDiv ${
              partialDrawerCondition ? "PartialDrawerName" : ""
            }`}
            style={
              item.childPaths && item.childPaths.includes(currentPath)
                ? { color: "var(--spot-yellow)" }
                : {}
            }
          >
            {item.display_name}{" "}
            {item.child && !partialDrawerCondition ? (
              <i
                className={`fa fa-chevron-${
                  showChildItem.show && showChildItem.name === item.name
                    ? "up"
                    : "down"
                } DrawerItemToggleIcon`}
              ></i>
            ) : null}
          </div>
        </div>
        <div
          className={`CustomDrawer-ChildItemDiv ${
            showChildItem.name === item.name ? "Open" : ""
          }`}
        >
          {renderItem}
        </div>
      </div>
    );
  });

  const handleNavigation = (name, navigate, child) => {
    if (navigate) {
      setShowChildItem({ name: "", show: false });
      dispatch(navigateTo({ path: navigate }));
    } else {
      if (child) {
        if (partialDrawerCondition) {
          dispatch(changeDrawerState(!drawerState));
          setShowChildItem({ name: name, show: true });
        } else if (showChildItem.show && showChildItem.name === name)
          setShowChildItem({ name: "", show: false });
        else setShowChildItem({ name: name, show: true });
      }
    }
  };

  const handleDrawerToggle = () => {
    setShowChildItem({ name: "", show: false });
    dispatch(changeDrawerState(!drawerState));
  };

  return (
    <ClickOutsideHandler
      handleClickOutside={() => setShowChildItem({ name: "", show: false })}
    >
      <div
        className={`CustomDrawer-MainDiv ${profileType ? "Login" : ""} ${
          isMobile ? "Mobile" : ""
        } ${drawerState ? "Open" : ""}`}
        style={
          partialDrawerCondition
            ? {}
            : {
                maxHeight: `${
                  isMobile
                    ? `${window.innerHeight}px - ${
                        profileType ? "55px" : "70px"
                      }`
                    : profileType
                    ? `calc(${height}px - 54px)`
                    : `calc(${height}px - 70px)`
                }`,
              }
        }
      >
        {profileType ? (
          <div
            className=""
            key={`drawer_item_arrow_toggle`}
            onClick={() => handleDrawerToggle()}
          >
            <div
              className={`CustomDrawer-MainItem LoginState ${
                drawerState ? "Open" : ""
              }`}
            >
              <i
                className={`fa fa-angle-double-${
                  drawerState ? "left" : "right"
                }`}
              ></i>
            </div>
          </div>
        ) : null}
        {profileType && drawerState ? (
          <div className="CustomDrawer-InfoSection">
            <img
              className="Image"
              alt="profile_image"
              src={currentUser.profile.avatar_url}
            />
            <h4 className="UserName">
              {currentUser.profile.profile_type === "COMPANY" ||
              currentUser.profile.profile_type === "INSTITUTION"
                ? currentUser.profile.full_name
                : currentUser.profile.first_name}
            </h4>
          </div>
        ) : null}
        <div
          className={`CustomDrawer-ListItemDiv ${
            profileType ? (drawerState ? "Open" : "LoginState") : ""
          }`}
        >
          {drawerItems}
        </div>
      </div>
    </ClickOutsideHandler>
  );
};

const mapStateToProps = (state) => {
  const {
    environment,
    navigator,
    portfolio,
    drawerState,
    currentUser,
    commonReducer,
  } = state;
  const { height, isMobile, width } = environment;
  const { path } = navigator.route;
  const { adminAccessLevel } = commonReducer;
  return {
    currentUser,
    path,
    height,
    environment,
    isMobile,
    width,
    drawerState,
    portfolio,
    adminAccessLevel,
  };
};
export default connect(mapStateToProps)(DrawerComponent);
