const tokenExpiryWorker = () => {
	onmessage = (event) => {
        let expiryTime = event.data;
        let remaining_time = parseInt(expiryTime) - Math.round(new Date().getTime() / 1000)
        if (remaining_time < 120 ) {
            postMessage({ tokenExpired: true })
        } else {
            setTimeout(() => {
                postMessage({ tokenExpired: true })
            }, (remaining_time - 120) * 1000)
        }
	}
}

let code = tokenExpiryWorker.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

export default worker_script;