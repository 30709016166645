import { base_url } from "./ConstantUrl"

export function constructFetchPortfolioArticleUrl() {
  return base_url + '/api/my_portfolio/articles';
}

export function constructFetchPortfolioReadingUrl() {
  return base_url + '/api/my_portfolio/readings';
}

export function constructFetchPortfolioCurricularUrl() {
  return base_url + '/api/my_portfolio/cocurriculars';
}

export function constructFetchPortfolioSemesterUrl() {
  return base_url + '/api/my_portfolio/semesters';
}
export function constructFetchPortfolioEducationUrl() {
  return base_url + '/api/my_portfolio/educations';
}

export function constructFetchPortfolioSocialClubUrl() {
  return base_url + '/api/my_portfolio/social_clubs';
}

export function constructFetchPortfolioInternshipUrl() {
  return base_url + '/api/my_portfolio/internships';
}

export function constructFetchPortfolioExtProjectUrl() {
  return base_url + '/api/my_portfolio/external_projects';
}

export function constructFetchPortfolioExtEventUrl() {
  return base_url + '/api/my_portfolio/external_events';
}

export function constructFetchPortfolioExtCoursesUrl() {
  return base_url + '/api/my_portfolio/external_courses';
}

export function constructFetchPortfolioMyEventUrl() {
  return base_url + '/api/my_profile/my_events';
}
export function constructFetchPortfolioMyClubUrl() {
  return base_url + '/api/my_profile/my_clubs';
}

export function constructFetchReadingISBNUrl(isbn) {
  return base_url + '/api/get_book/readings/' + isbn;
}

export function constructFetchSemesterUserUrl(my_org_id) {
  return base_url + '/api/academics/fetch_stream?my_org_id=' + my_org_id;
}


//Edit Portfolio Urls

export function constructEditPortfolioArticleUrl(id) {
  return base_url + '/api/my_portfolio/articles/' + id;
}

export function constructEditPortfolioReadingUrl(id) {
  return base_url + '/api/my_portfolio/readings/' + id;
}

export function constructEditPortfolioCurricularUrl(id) {
  return base_url + '/api/my_portfolio/cocurriculars';
}

export function constructEditPortfolioSemesterUrl(id) {
  return base_url + '/api/my_portfolio/subjects/' + id;
}
export function constructEditPortfolioEducationUrl(id) {
  return base_url + '/api/my_portfolio/educations/' + id;
}

export function constructEditPortfolioSocialClubUrl(id) {
  return base_url + '/api/my_portfolio/social_clubs/' + id;
}

export function constructEditPortfolioInternshipUrl(id) {
  return base_url + '/api/my_portfolio/internships/' + id;
}

export function constructEditPortfolioExtProjectUrl(id) {
  return base_url + '/api/my_portfolio/external_projects/' + id;
}

export function constructEditPortfolioExtEventUrl(id) {
  return base_url + '/api/my_portfolio/external_events/' + id;
}

export function constructEditPortfolioExtCoursesUrl(id) {
  return base_url + '/api/my_portfolio/external_courses/' + id;
}

export function constructFetchJobRoles() {
  return base_url + '/api/my_portfolio/fetch_job_roles'
}

export function constructFetchLifeSkillsRole() {
  return base_url + '/api/my_portfolio/fetch_life_skill_roles'
}

export function constructAddJobRolesUrl() {
  return base_url + '/api/my_portfolio/create_job_roles'
}

export function constructAddPortfolioSubjectsUrl() {
  return base_url + '/api/my_portfolio/subjects'
}

export function updatePortfolio(id) {
  return base_url + '/api/my_portfolio/portfolios/' + id;
}

// company portfolio
export function constructCompanyClubsUrl(page, count) {
  return base_url + `/api/show_org_clubs?page=${page}&count=${count}`
}


export function constructCompanyJobsUrl(page, count) {
  return base_url + `/api/show_org_jobs?page=${page}&count=${count}`
}
export function constructCompanyJobsUrlMobile(page, count) {
  return base_url + `/api/v2/show_org_jobs?page=${page}&count=${count}`
}
export function constructCompanyEventsUrl(page, count) {
  return base_url + `/api/show_org_events?page=${page}&count=${count}`
}
export function constructCompanyMentorsUrl() {
  return base_url + '/api/org_profiles?profile_types[]=ADVISOR'
}
export function constructCompanyPartnerJobUrl(page, count) {
  return base_url + `/api/show_partner_jobs?page=${page}&count=${count}`
}
export function constructCompanyPublicJobsUrl(id) {
  return base_url + '/api/show_org_jobs?org_id=' + id + '&status=PUBLIC'
}
export function constructCompanyMentorsPublicUrl(orgId) {
  return base_url + `/api/org_profiles?org_id=${orgId}&profile_types[]=ADVISOR`
}
export function constructCompanyEventsPublicUrl(orgId) {
  return base_url + `/api/show_org_events?org_id=${orgId}&status=PUBLIC`
}

export function constructInstituteMentorList(type) {
  return base_url + `/api/list_org_advisor_entities_counts?type=${type}`;
}

export function constructCompanyPublicClubsUrl(id) {
  return base_url + '/api/show_org_clubs?org_id=' + id + '&status=PUBLIC'
}
export function constructFetchMentorClubsUrl() {
  return base_url + '/api/show_mentor_clubs';
}
export function constructFetchMentorResourcesUrl() {
  return base_url + '/api/fetch_mentor_resources';
}
export function constructFetchMentorResourceGroupsUrl() {
  return base_url + '/api/fetch_mentor_resource_groups';
}
export function constructFetchMentorQuestsUrl() {
  return base_url + '/api/fetch_mentor_quests';
}
export function fetchCreatedEntitiesCountUrl() {
  return base_url + '/api/get_created_entities_counts';
}

export function fetchMentorEnrollEntitiesCountUrl() {
  return base_url + '/api/get_mentors_my_entities_counts'
}

export function fetchMentorMentoringEntitiesCountUrl() {
  return base_url + '/api/get_mentors_mentoring_entities_counts'
}

export function fetchMentorEngageEntitiesCountUrl() {
  return base_url + '/api/get_mentors_my_engage_entities_counts'
}

export function publicPortfoliocopyUrl(profile_id) {
  return window.location.origin + `/portfolio/${profile_id}`
}
export function constructFetchJobLifeSkillRoles() {
  return base_url + '/api/my_portfolio/fetch_life_skill_roles'
}
export function constructJobRoleListUrl() {
  return base_url + '/api/my_portfolio/list_job_roles';
}
export function constructChangeJobRolesUrl() {
  return base_url + '/api/my_portfolio/update_my_job_roles';
}
export function constructUpdateFinalProjectStatusUrl() {
  return base_url + '/api/admin/evaluate_my_project';
}

export function constructXceleronCoursesListUrl() {
  return base_url + '/api/my_portfolio/get_lms_details';
}
export function constructTrainingInstituteStudentJobsUrl(page, count) {
  return base_url + `/api/show_training_student_jobs?page=${page}&count=${count}`
}
export function constructTrainingInstituteStudentEventsUrl(page, count) {
  return base_url + `/api/show_training_student_events?page=${page}&count=${count}`
}
export function constructPortfolioPdfUrl(profileId) {
  return base_url + `/api/generate_portfolio_pdf/${profileId}`
}

export function constructPendingTasksUrl() {
  return base_url + '/api/pending_tasks'
}

export function constructPendingTasksByInterventionsUrl(type, id) {
  return base_url + `/api/pending_tasks_by_intervention?type=${type}&id=${id}`
}