import React from 'react';
import { connect } from 'react-redux';
import Actions from '../../../../../../redux/actions.js';
import MyNotifications from '../../../../components/Notification.js';
import NavDropdown from 'react-bootstrap/NavDropdown';
import axios from 'axios';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { resetUnviewed, fetchJobNotificationsUrl, updateJobNotificationUrl } from './../../../../../../utils/AuthUtils'
import { navigateTo } from '../../../../../../actions/navigator';
import { fetchNotifications, fetchMoreNotifications } from '../../../../../../actions/authed';
import './style.css';
import { changeDrawerState } from '../../../../../../actions/drawer.js';
import ClickOutsideHandler from '../../../../components/commonComponent/clickOutsideHandler.js';
import JobNotification from "../../../../components/JobNotification";

class LoggedInLinks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			profileMenuOpen: true,
			showForm: false,
			showJobNotif: false,
			showTaggedForm: false,
			outsideOnly: true,
			jobNotifications : [],
			jobNotificationsLoading: true,
			moreJobNotificationsLoading: true,
			jobUnviewedCount: null,
			jobNotifPage: 1
		}
	}

	componentDidMount() {
		this.fetchJobNotificationsCount()
	}

	logout() {
		const { dispatch } = this.props; 
      	dispatch({
        	type: "DELETE_KEY_COMMON_REDUCER",
        	key: "adminAccessLevel",
      	});
    	this.props.dispatch(changeDrawerState(false));
		this.props.dispatch(Actions.logout());
		this.props.dispatch({ type: "IS_LOADING" });
		this.props.dispatch({ type: 'HIDE_ADD_JOBS_MODAL' });
	}

	handleClick(pathValue) {
		const { dispatch } = this.props;
		dispatch(navigateTo({ path: [pathValue] }));
	}

	_handleCancelClick = () => {
		this.setState({ showForm: false, showJobNotif: false, showTaggedForm: false, jobNotifications: [], jobNotificationsLoading: true, moreJobNotificationsLoading: true, jobNotifPage: 1 })
	}

	_handleShowNotif() {
		const show = this.state.showForm;
		if (!show) {
			this.props.dispatch(fetchNotifications());
		}
		this.setState({ showTaggedForm: false, showForm: !show })
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		axios.post(resetUnviewed())
			.then((_response) => {
			})
			.catch((_error) => {
			});
	}


	fetchJobNotificationsCount = () => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(fetchJobNotificationsUrl(), {})
			.then((response) => {
				if(response?.data?.data){
					this.setState({
						jobUnviewedCount: response?.data?.unviewed_count
					});
				}
			})
			.catch(() => {
			})
	}

	fetchJobNotifications = (page) => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(fetchJobNotificationsUrl(page), {})
			.then((response) => {
				if(response?.data?.data){
					if(this.state.jobNotifications && this.state.jobNotifications.length){
						this.setState(prevState => ({
							jobNotifications: [...prevState.jobNotifications, ...response.data.data],
							jobNotifPage: page,
							moreJobNotificationsLoading: response.data.data.length !== 20 ? false : true
						  }));
					} else {
						this.setState({
							jobNotifications: response?.data?.data,
							jobNotificationsLoading: false
						})
					}
				}
			})
			.catch((error) => {
				console.log(error)
				this.setState({
					jobNotificationsLoading: false,
				})
			})
	}

	updateNotificationStatus = (notificationId) => {
		// const { dispatch } = this.props;
		let params = {
            "my_notification_id": notificationId
        }
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		axios.post(updateJobNotificationUrl(), params)
		.then((response) => {
			this.setState({
				jobUnviewedCount: response.data.unviewed_count
			})
		})
		.catch((error) => {
			console.log(error)
		});
	}

	setMoreNotificationsLoading = (val) => {
		this.setState({
			moreJobNotificationsLoading: val
		})
	}


	handleOpenJobNotifications = () => {
		const { showJobNotif } = this.state;
		this.setState({ showJobNotif: !showJobNotif });
		this.fetchJobNotifications()
	}

	loadMoreNotifications() {
		const { isFetchingNotification, nextPageNotification } = this.props;
		if (!isFetchingNotification && nextPageNotification > 1) {
			this.props.dispatch(fetchMoreNotifications());
		}
	}


	calculateDaySummary = (d) => {
		var weeks = Math.floor(d/7);
		var days = Math.floor(d%7)
		return { weeks: weeks, days: days}
	}

	render() {
		const { currentUser, isFetchingNotification, hasMorenotification, nextPageNotification, isMobile, signUpFlowActive, dispatch } = this.props;
		const { jobUnviewedCount, jobNotifPage, moreJobNotificationsLoading, jobNotifications } = this.state;

		return (
			<div className="row">
				{currentUser.profile && !currentUser.profile.active || currentUser?.profile?.profile_type !== 'STUDENT' ?
					null
					:
					<div key='1' onClick={() => this.setState({ jobNotifications: [], jobNotificationsLoading: true, moreJobNotificationsLoading: true}, () => this.handleOpenJobNotifications()) }
						className={` navBarListItem notification-link col-3 offset-3 ${signUpFlowActive ? 'hideLoggenIn' : this.state.showJobNotif ? 'active' : ''}`}
						style={currentUser.profile ? null : { backgroundColor: 'var(--clr-white)' }}>
						<center style={{ position: 'relative', top: '4px' }}>
							{currentUser.profile ? <i className="fas fa-briefcase headerImgs" aria-hidden="true"></i> : null}
							{jobUnviewedCount ?
								<span className="headerBadge bg-success">{jobUnviewedCount}</span>
							 : null}
						</center>
						{
							!this.state.showJobNotif ? null :
								<ClickOutsideHandler handleClickOutside={() => this._handleCancelClick()}>
									<JobNotification
									notifications={jobNotifications} isLoading={this.state.jobNotificationsLoading}
									dispatch={dispatch} updateNotificationStatus={this.updateNotificationStatus}
									calculateDaySummary={this.calculateDaySummary} jobNotifPage={jobNotifPage} fetchJobNotifications={this.fetchJobNotifications}
									moreJobNotificationsLoading={moreJobNotificationsLoading} setMoreNotificationsLoading={this.setMoreNotificationsLoading} />
								</ClickOutsideHandler>
						}
					</div>}

					{currentUser.profile && !currentUser.profile.active ?
						null
						:
						<div key='2' onClick={this._handleShowNotif.bind(this)}
							className={`navBarListItem notification-link col-3 ${currentUser?.profile?.profile_type !== "STUDENT" ? "offset-6": null}  ${signUpFlowActive ? 'hideLoggenIn' : this.state.showForm ? 'active' : ''}`}
							style={currentUser.profile ? null : { backgroundColor: 'var(--clr-white)' }}>
							<center style={{ position: 'relative', top: '4px' }}>
								{currentUser.profile ? <i className="fa fa-bell headerImgs" aria-hidden="true"></i> : null}
								{currentUser.profile ? currentUser?.profile?.unviewed_count ?
									<span className="headerBadge">{currentUser?.profile?.unviewed_count}</span>
									:
									null : null}
							</center>
							{
								!this.state.showForm ? null :
									<ClickOutsideHandler handleClickOutside={() => this._handleCancelClick()}>
										<MyNotifications
											dispatch={this.props.dispatch}
											notifications={this.props.notifications}
											hasMorenotification={hasMorenotification}
											nextPageNotification={nextPageNotification}
											isFetchingNotification={isFetchingNotification}
											isMobile={isMobile}
											type={"notifications"}
											currentUser={this.props.currentUser}
										/>
									</ClickOutsideHandler>
							}
						</div>}
					
				<div className={"col-3 col-sm-3 float-right"} style={{ padding: 0, color: '#666' }}>
					<center style={{ marginTop: '5px' }}>
						<NavDropdown title={<img src={currentUser.profile ? currentUser.profile.avatar_url : null}
							className="rounded-circle" style={{ height: '28px', width: '28px' }} alt="" />} id="nav-dropdown">
							{currentUser.profile && currentUser.profile.active && !signUpFlowActive ?
								<DropdownItem eventKey="4.1" onClick={this.handleClick.bind(this, 'myprofile')}>Profile Settings</DropdownItem> : null}
							{currentUser.profile && currentUser.profile.active && !signUpFlowActive ?
								<DropdownItem eventKey="4.2" onClick={this.handleClick.bind(this, 'Aboutus')}>About Us</DropdownItem> : null}
							{currentUser.profile && currentUser.profile.active && !signUpFlowActive ?
								<DropdownItem eventKey="4.3" onClick={this.handleClick.bind(this, 'FAQ')}>FAQ</DropdownItem> : null}
							{currentUser.profile && currentUser.profile.active && !signUpFlowActive ?
								<DropdownItem eventKey="4.3" onClick={this.handleClick.bind(this, 'Privacy')}>Privacy Policy</DropdownItem>
								: null}
							<DropdownItem eventKey="4.4" onClick={this.logout.bind(this)}>Logout</DropdownItem>
						</NavDropdown>
					</center>
				</div>
			</div>)
	}
}

const mapStateToProps = (state) => {
	const { ntfMaster, environment } = state;
	const { notifications, isFetchingNotification, nextPageNotification, hasMorenotification } = ntfMaster;
	const { isMobile, signUpFlowActive } = environment;
	return {
		currentUser: state.currentUser,
		notifications,
		isFetchingNotification,
		nextPageNotification,
		hasMorenotification,
		signUpFlowActive,
		isMobile
	}
}

export default connect(mapStateToProps)(LoggedInLinks);