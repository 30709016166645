import { base_url } from "./ConstantUrl"

export function constructFetchFieldCount() {
	return base_url + "/api/fetch_counters"
}

export function constructFetchAcademicData() {
	return base_url + "/api/fetch_histogram_academic"
}
export function constructFetchBranchData() {
	return base_url + "/api/split_based_on_field"
}

export function constructOrgPublishJob() {
	return base_url + "/api/show_skills_dashboard_jobs"
}

export function constructSkillDashboardEvents() {
	return base_url + "/api/show_skills_dashboard_events"
}

export function constructSkillDashboardClubs() {
	return base_url + "/api/show_skills_dashboard_clubs"
}

export function constructFetchDegreeData() {
	return base_url + "/api/split_by_degree"
}

export function constructFetchTopSkills() {
	return base_url + "/api/fetch_top_skills"
}

export function constructFetchShortlistUrl() {
	return base_url + "/api/shortlist_portfolios"
}

export function constructFetchShortlistNextPageUrl(start) {
	return base_url + "/api/shortlisted_table?from=" + start
}

export function constructFetchExcelUrl() {
	return base_url + "/api/generate_xlsx"
}

export function constructGenerateMailNotification(job_id, job_type) {
	return base_url + `/api/generate_mail_notification?job_id=${job_id}&type=${job_type}`
}

export function constructGenerateMailNotificationClubs(club_id) {
	return base_url + `/api/generate_mail_notification?club_id=${club_id}&type=Clubs`
}

export function constructGenerateMailNotificationEvents(event_id) {
	return base_url + `/api/generate_mail_notification?event_id=${event_id}&type=Events`
}

export function constructFetchGraphRange() {
	return base_url + "/api/get_score_range"
}

export function constructJobEnrollmentByCollegeUrl() {
	return base_url + '/api/job_applications'
}

export function constructJobApplicantsDetailsUrl() {
	return base_url + '/api/job_applicants'
}

export function constructJobApplicantsDetailsMobileUrl() {
	return base_url + '/api/v2/job_applicants'
}

export function constructJobActivityLogUrl() {
	return base_url + '/api/v2/profile/job_applications_log';
}

export function constructOrgJobApplicationStatsUrl(id) {
	return base_url + '/api/v2/show_org_job_application_stats?job_id=' + id;
}

export function constructJobInvitedApplicantsUrl() {
	return base_url + '/api/job_invited_applications'
}

export function constructModifyJobApplicantsStatus() {
	return base_url + '/api/modify_job_applicants';
}

export function constructUpdateJobOpenPositionsUrl() {
	return base_url + '/api/v2/update_job_open_positions';
}

export function constructProjectTeamStatsUrl() {
	return base_url + '/api/enrollment_listing'
}

export function constructProjectEnrollmentCountUrl() {
	return base_url + "/api/project_status_counter"
}

export function constructProjectEnrollmentSplitUrl() {
	return base_url + "/api/project_status_split"
}

export function constructEventEnrollmentCountUrl() {
	return base_url + "/api/event_enrollments"
}

export function constructEventApplicantsDetailsUrl() {
	return base_url + "/api/event_applicants"
}

export function constructEventTotalCountUrl() {
	return base_url + "/api/event_enrollment_count"
}

export function constructCreateEventUrl() {
	return base_url + "/api/events"
}

export function constructFetchNewClubUrl() {
	return base_url + `/api/clubs`;
}

export function constructOrgAvatarUpdateUrl(id) {
	return base_url + `/api/orgs/${id}`
}

export function constructPostsTimelineUrl() {
	return base_url + '/api/posts_histogram'
}

export function constructFetchYearUrl() {
	return base_url + '/api/split_by_batch'
}

export function constuctEnrollmentBranchUrl() {
	return base_url + '/api/split_by_branch'
}

export function constructFetchEnrollmentDashboardUrl() {
	return base_url + '/api/enrollment_dashboard'
}

export function constructInstituteProjectCountUrl() {
	return base_url + '/api/project_status_counter'
}

export function constructInstituteProjectSplitUrl() {
	return base_url + '/api/project_status_split'
}

export function constructInstituteProjectDashboardUrl() {
	return base_url + '/api/enrollment_listing'
}

export function constructInstituteProjectListUrl() {
	return base_url + '/api/institute_student_project_list'
}

export function constructInstitutePaginationUrl(page) {
	return base_url + '/api/enrollment_listing?page=' + page
}

export function constructEventSummaryData() {
	return base_url + '/api/show_event_summary';
}

export function constructJobSummaryData() {
	return base_url + '/api/show_job_summary';
}

export function constructProjectApplicantsUrl() {
	return base_url + '/api/my_profile/project_applicants';
}

export function constructProjectOrgSplitUrl() {
	return base_url + '/api/my_profile/project_applicants_org_split';
}

export function countSummaryUrl() {
	return base_url + '/api/get_my_entity_counts';
}

export function InstituteMentorUrl(type) {
	return base_url + `/api/get_org_entity_count?type=${type}`;
}

export function sendReminderJob(){
	return base_url + `/api/admin/send_remainder`;
}