import React from 'react';
import SkillComponent from '../detailsPageCommon/skills';

const ratingSummary = props => {
    return(
        <div className={`row margin0 ${props.mainDivClass ? props.mainDivClass : ''}`} style={props.mainDivStyles ? props.mainDivStyles : {}}>
            {props.listData ? <SkillComponent customClass={props.listDataClass ? props.listDataClass : 'col-8'} styles={{borderBottom: 'none'}} nameStyles={props.isMobile ? { fontSize: 13, padding: 4 } : {}} skills={props.listData} /> : null}
            <div className={`${props.ratingContentClass ? props.ratingContentClass : 'col-4'} row margin0`} style={props.ratingContentDiv ? props.ratingContentDiv : {}}>
                <div className="col-4 padding0"><p className="RatingContent-centerAlign font15 padding0" style={{color: '#424242', ...props.reviewTextStyle}}>Rating & Reviews</p></div>
                <div className="col-4 padding0">
                    <p className="RatingContent-centerAlign RatingSummary-rateIcon" >{props.rating} <i className="fas fa-star RatingComponent-StarStyle font18" ></i></p>
                    <p className="RatingContent-centerAlign RatingSummary-rateIcon" >{props.number_of_reviews} <i className="fas fa-comments font18" style={{color: '#424242'}}></i></p>
                </div>
                <div className="col-4 padding0"><p className="RatingContent-centerAlign font15 padding0 cursorPointer" style={{color: 'var(--clr-button-orange)'}} onClick={props.ratingHandler}>{props.canRate ? `Rate Now` : `View`}</p></div>
            </div>
        </div>
    )
}

export default ratingSummary;