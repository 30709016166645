import {base_url} from "./ConstantUrl"

export function constructRecommendedEventsUrl() {
  return base_url+'/api/recommended/events/';
}
export function constructRecommendedJobsUrl() {
  return base_url+'/api/recommended/jobs?type=Job_Fulltime';
}

export function constructRecommendedReadingsUrl() {
  return base_url+'/api/recommended/readings/';
}
export function constructRecommendedClubsUrl() {
  return base_url+'/api/recommended/clubs/';
}

export function constructRecommendedArticlesUrl() {
  return base_url+'/api/recommended/articles/';
}