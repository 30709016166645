import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "../../components/Link";
import imglogo from "../../../../assets/main-logo.png";
import imglogoMobile from "../../../../assets/xceler_logo.jpeg";
import { navigateTo } from "../../../../actions/navigator";
import { initError } from "../../../../actions/error";
import { changeDrawerState } from "../../../../actions/drawer";

class LoggedOutMainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessages: {},
      verify_status: "",
      invalidDetails: false,
      firstname: "",
      lastname: "",
      dob: null,
      formattedDob: null,
      mobile: null,
      signupEmail: "",
      signupPassword: "",
      institute: "",
      instituteCity: "",
      instituteState: "",
      degree: "",
      specialization: "",
      instituteOption: {},
      degreeOption: {},
      specializationOption: {},
      openLogin: true
    };
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.dispatch(navigateTo({ path: [] }));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(initError());
  }

  handleNavigation = event => {
    const { dispatch } = this.props;
    if (event.target.innerHTML === "MENTOR")
      dispatch(navigateTo({ path: ["MentorHome"] }));
    else if (event.target.innerHTML === "NINJA")
      dispatch(navigateTo({ path: ["Home"] }));
    else if (event.target.innerHTML === "COMPANY")
      dispatch(navigateTo({ path: ["CompanyHome"] }));
    else if (event.target.innerHTML === "INSTITUTE")
      dispatch(navigateTo({ path: ["InstituteHome"] }));
  };

  handleDrawer = () => {
    const { dispatch } = this.props;
    var value = !this.props.drawerState;
    dispatch(changeDrawerState(value));
  };

  render() {
    const {
      dispatch,
      path,
      drawerState,
      environment,
      hideMenuBar,
      hideMenuBarInsta
    } = this.props;
    return (
      <div
        className={
          hideMenuBarInsta ? "displayNone" : "loggedOutMenu col-12 row margin0"
        }
      >
        {hideMenuBar ? null : (
          <div className="col-md-3 col-sm-4 col-6 logo" style={{ height: 70 }}>
            <span className="hamBurger">
              <i
                className="fa fa-bars"
                style={
                  drawerState ? { color: "var(--spot-yellow)" } : null
                }
                onClick={this.handleDrawer}
                aria-hidden="true"
              ></i>
            </span>
            {!environment.isMobile ? (
              <Link
                className="navbarBrandLoggedOut"
                dispatch={dispatch}
                route={{ path: ["Home"] }}
                title="Home"
              >
                {path[0] === "myGyanVihar" ? (
                  <img
                    src={
                      "https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/partner_logos/sgvu_logo.jpg"
                    }
                    style={{
                      height: 60,
                      width: "auto",
                      position: "absolute",
                      top: 5
                    }}
                    alt="sgvu_logo"
                  />
                ) : (
                  <img
                    src={imglogo}
                    style={{
                      height: 18,
                      width: "auto",
                      position: "absolute",
                      top: 26
                    }}
                    alt="xcelerator_logo"
                  />
                )}
              </Link>
            ) : (
              <span onClick={this.handleDrawer}>
                {path[0] === "myGyanVihar" ? (
                  <img
                    src={
                      "https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/partner_logos/thumb_sgvu_logo.jpg"
                    }
                    width="40px"
                    height="40px"
                    style={{ position: "relative", top: 14 }}
                    alt=""
                  />
                ) : (
                  <img
                    src={imglogoMobile}
                    width="40px"
                    height="40px"
                    style={{ position: "relative", top: 14 }}
                    alt=""
                  />
                )}
              </span>
            )}
          </div>
        )}
        {hideMenuBar ? null : (
          <div className="offset-md-1 col-md-5 col-sm-6 hidden-xs row">
            <button
              className={
                path[0] === "Home" || path[0] === ""
                  ? "col-sm-3 nav active"
                  : "col-sm-3 nav"
              }
              onClick={event => this.handleNavigation(event)}
            >
              NINJA
            </button>
            <button
              className={
                path[0] === "MentorHome"
                  ? "col-sm-3 nav active"
                  : "col-sm-3 nav"
              }
              onClick={event => this.handleNavigation(event)}
            >
              MENTOR
            </button>
            <button
              className={
                path[0] === "InstituteHome"
                  ? "col-sm-3 nav active"
                  : "col-sm-3 nav"
              }
              onClick={event => this.handleNavigation(event)}
            >
              INSTITUTE
            </button>
            <button
              className={
                path[0] === "CompanyHome"
                  ? "col-sm-3 nav active"
                  : "col-sm-3 nav"
              }
              onClick={event => this.handleNavigation(event)}
            >
              COMPANY
            </button>
          </div>
        )}
        {hideMenuBar ? null : (
          <div
            className="col-md-3 col-sm-2 col-6 loggedOutBtnMobile"
            style={
              drawerState && environment.isMobile ? { display: "none" } : null
            }
          >
            <button
              className="login"
              onClick={() =>
                dispatch({
                  type: "MERGE_IN_COMMON_REDUCER",
                  key: "loginPopupConfig",
                  data: { showModal: true, isLogin: true }
                })
              }
            >
              LOGIN
            </button>
            <button
              className="signUp"
              onClick={() =>
                dispatch({
                  type: "MERGE_IN_COMMON_REDUCER",
                  key: "loginPopupConfig",
                  data: { showModal: true, isLogin: false }
                })
              }
            >
              SIGN UP
            </button>
          </div>
        )}
        {hideMenuBarInsta ? null : hideMenuBar ? (
          <img
            src={imglogo}
            style={
              environment.isMobile
                ? {
                    width: "87%",
                    position: "relative",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }
                : {}
            }
            alt="xcelerator_logo"
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { environment, navigator, drawerState, commonReducer } = state;
  const { path } = navigator.route;
  const { hideMenuBar, hideMenuBarInsta } = commonReducer;
  return {
    currentUser: state.currentUser,
    path,
    hideMenuBarInsta,
    drawerState,
    environment,
    error_msg: state.error.error_msg,
    hideMenuBar
  };
};

export default connect(mapStateToProps)(LoggedOutMainMenu);
