import React from 'react';
import { connect } from 'react-redux';
import ModalComponent from '../commonComponent/modalComponent';

const PopUpModal = props => {
	const { popUpConfig } = props;

	const changeModalState = () => {
		const { dispatch } = props;
		dispatch({ type: "CLOSE_POPUP_MODAL" });
	}

	return (
			<ModalComponent show={popUpConfig.show} modalClass='IwantTobeModal' onHide={changeModalState}>
			<div className="container-fluid">
				{popUpConfig.showClose ?
					<div className="col-12 padding0" style={{ height: '15px' }}>
						<i className="IWantClose fa fa-times float-right" onClick={changeModalState} />
					</div>
					: null}
				<div className="col-12">
					<h3 className="heading" style={{ marginTop: 0 }}>{popUpConfig.heading}</h3>
					<p style={{ marginBottom: '18px' }} className="text" dangerouslySetInnerHTML={{ __html: popUpConfig.message }}></p>
				</div>
			</div>
			</ModalComponent>
	)
}

const mapStateToProps = state => {
	const { popUpConfig } = state;
	return {
		popUpConfig
	}
}

export default connect(mapStateToProps)(PopUpModal);