import React, { useEffect, useRef } from "react";
import { base_url } from "./ConstantUrl";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import axios from "axios";

dayjs.extend(isBetween);

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export function buildHeaders() {
  const authToken = localStorage.getItem("access_token");

  return { ...defaultHeaders, Authorization: authToken };
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function httpGet(url) {
  return axios
    .get(url, {
      headers: buildHeaders(),
    })
    .then(checkStatus);
}

export function initCap(str) {
  if (!str) {
    return null;
  }
  /* eslint-disable-next-line */
  String.prototype.initCap = function () {
    return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
      return m.toUpperCase();
    });
  };
  return str.initCap();
}

export function getTwitterId(url) {
  if (url.search("/") >= 0) {
    var urlAfterSplit = url.split("/");
    return urlAfterSplit[urlAfterSplit.length - 1];
  }
  return null;
}

export function checkNested(obj) {
  var args = Array.prototype.slice.call(arguments, 1);
  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

export function constructDomainUrl() {
  return base_url + `/api/skills?type=DOMAIN`;
}

export function constructSkillUrl() {
  return base_url + `/api/skills?type=TECHNICAL`;
}

export function RemoveUsersFromBatch(title) {
  return base_url + `/api/admin/unenroll_college_batch_by_profile_ids`;
}

export function fetchAllJobsByTitle(title) {
  return base_url + `/api/admin/fetch_all_jobs?title=${title}`;
}

export function getSubscription() {
  return base_url + `/api/admin/get_subscription`;
}
export function fetchAllEventsByTitle(title) {
  return base_url + `/api/admin/fetch_all_events?title=${title}`;
}

export function fetchAllClubsByTitle(title) {
  return base_url + `/api/admin/fetch_all_clubs?title=${title}`;
}

export function constructSkillsByTypeUrl(type) {
  return base_url + `/api/skills?type=${type}`;
}

export function constructFeaturedEventsFetchUrl(page, count) {
  return base_url + `/api/events?show=featured&page=${page}&count=${count}`;
}

export function constructEventDetailUrl(id) {
  return base_url + `/api/events/${id}`;
}

export function constructEventDashboardDetailUrl(id) {
  return base_url + `/api/show_event_dashboard/${id}`;
}

export function constructEventDetailAvatarUrl(id) {
  return base_url + `/api/event_update_avatar/${id}`;
}

export function constructEventImageUploadUrl() {
  return base_url + `/api/event_image_uploads`;
}

export function constructClubDetailUrl(id) {
  return base_url + `/api/clubs/${id}`;
}

export function constructClubDashboardDetailUrl(id) {
  return base_url + `/api/show_club_dashboard/${id}`;
}

export function constructFeaturedJobsFetchUrl(page, count) {
  return (
    base_url + `/api/list/jobs?type=Job_Fulltime&page=${page}&count=${count}`
  );
}
export function fetchJobsFetchUrl(page,count,filter = false,min,max,org_ids,days,search=false,title) {
  if (!filter) {
    if (search) {
      return (
        base_url + `/api/list/jobs_v1?type=Job_Fulltime&page=${page}&count=${count}&title=${title}`
      );
    } else {
      return (
        base_url + `/api/list/jobs_v1?type=Job_Fulltime&page=${page}&count=${count}`
      );
    }
  } else {
    return (
      base_url + `/api/list/jobs_v1?type=Job_Fulltime&page=${page}&count=${count}&min=${min}&max=${max}&${org_ids.map((n, index) => `org_ids[]=${n}`).join('&')}&days=${days}`
    );
  }
}


export function constructFeaturedInternshipsFetchUrl(page, count) {
  return (
    base_url + `/api/list/jobs?type=Internship&page=${page}&count=${count}`
  );
}

export function constructClubsFetchUrl() {
  return base_url + "/api/clubs";
}

export function constructBooksFetchUrl() {
  return base_url + "/api/readings";
}

export function constructArticlesFetchUrl() {
  return base_url + "/api/articles";
}

// truncate
export function truncate(text, value) {
  if (text && text.length > value) return text.slice(0, value) + "...";
  else return text;
}
// Payment
export function createPaymentApi() {
  return base_url + "/api/v2/multiple_payments";
}

export function createPaymentConfirmationUrl() {
  return base_url + "/api/v2/payment_confirmation";
}
export function removeStudentFromBatchUrl() {
  return base_url + "/api/admin/unenroll_college_batch";
}
export function recommendedListJobRolesUrl() {
  return base_url + "/api/my_portfolio/recommended_list_job_roles";
}


export function createPaymentPageUrl(id = false) {
  if (id) return base_url + "/api/payment_pages/" + id;
  else return base_url + "/api/payment_pages";
}

export function editPaymentPageUrl(id) {
  return base_url + "/api/payment_pages/" + id + "/edit";
}

export function generatePaymentUrl() {
  return base_url + "/api/create_payment";
}

export function downloadPaymentInvoice(payment_id) {
  return base_url + `/api/download_invoice?payment_id=${payment_id}`;
}
// Payment

export function constructTestimonialUrl(typeOfUser) {
  return base_url + "/api/testimonials?type=" + typeOfUser;
}

export function constructJobRoleUrl() {
  return base_url + "/api/my_portfolio/job_roles";
}

export function constructOrgUrl(id) {
  return base_url + `/api/orgs/${id}`;
}

export function constructInstituteOrgUrl(id) {
  return base_url + `/api/institute_orgs/${id}`;
}

export function fetchPlatformUpdatesUrl() {
  return base_url + "/api/platform_updates";
}

export function constructNewClubDeleteUrl(id) {
  return base_url + `/api/clubs/${id}`;
}

export function constructClubDetailUpdateUrl(id) {
  return base_url + `/api/clubs/${id}`;
}

export function publicClubcopyUrl(profile_id) {
  return window.location.origin + `/clubs/${profile_id}`;
}

export function constructLookupTypesUrl(type) {
  return base_url + `/api/lookups?type=` + type;
}

export function constructXetResultUrl(dob, invoiceId) {
  return (
    base_url +
    `/api/platform_info?type=XET2020&sub_type=Jan&dob=${dob}&invoice_id=${invoiceId}`
  );
}

export function getPlatformInfoUrl(type, sub_type) {
  return (
    base_url + `/api/get_platform_info_data?type=${type}&sub_type=${sub_type}`
  );
}

export function constructLookupSubTypesUrl(sub_type) {
  return base_url + `/api/lookups?sub_type=` + sub_type;
}

export function constructLookupSubTypeSearchUrl(input, type) {
	return base_url + `/api/lookups/get_country_code?type=${type}&name=${input}`
}

export function constructLookupSubTypeSearchByCountryCodeUrl(input, type) {
	return base_url + `/api/lookups/get_country_code?type=${type}&country_code=${input}`
}

export function getDateDropdown(yearStart, yearEnd, showEmpty = false) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateOptions = [],
    monthsOptions = [],
    yearOptions = [];
  if (showEmpty) {
    dateOptions.push(
      <option value="" key="Select">
        DD
      </option>
    );
    yearOptions.push(
      <option value="" key="Select">
        YYYY
      </option>
    );
    monthsOptions.push(
      <option value="" key="Select">
        MM
      </option>
    );
  }
  for (var i = 1; i <= 31; i++) {
    dateOptions.push(
      <option value={i} key={i}>
        {i}
      </option>
    );
  }
  for (i = yearEnd; i >= yearStart; i--) {
    yearOptions.push(
      <option value={i} key={i}>
        {i}
      </option>
    );
  }
  for (i in monthNames) {
    monthsOptions.push(
      <option value={parseInt(i) + 1} key={i}>
        {monthNames[i]}
      </option>
    );
  }
  return { dateOptions, monthsOptions, yearOptions };
}

export function getTimeDropDown() {
  var hours = [],
    minutes = [];
  for (let i = 0; i < 24; i++) {
    hours.push(
      <option key={i} value={i}>
        {i < 10 ? `0${i} hrs` : `${i} hrs`}
      </option>
    );
  }
  for (let i = 0; i < 60; i++) {
    minutes.push(
      <option key={i} value={i}>
        {i < 10 ? `0${i} mins` : `${i} mins`}
      </option>
    );
  }
  return { hours, minutes };
}

export function createMomentDate(date, month, year) {
  date = `${year}-${month}-${date}`;
  date = dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  return date;
}

export function validDateRange(date, start, end) {
  var validRange = dayjs(date).isBetween(start, end);
  return validRange;
}

export function handleAuthClick(setPath = false, pathname = false) {
  if (setPath) {
    sessionStorage.setItem(
      "redirectPath",
      pathname ? pathname : window.location.pathname
    );
  } else {
    sessionStorage.removeItem("redirectPath");
  }
}

export function isEditorEmpty(content) {
  let pattern = /^<p><\/p>\n$/;
  return pattern.test(content);
}

export function richContentPrefixAppend(content) {
  if (content) {
    if (content.startsWith("<p")) return content;
    else return "<p></p>" + content;
  } else return content;
}

export function unauthorizedForbiddenConfig() {
  return {
    imgUrl:
      "https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/default_images/unathorised.jpg",
    errHeader: "Access Denied",
    errMsg:
      "Sorry about that, but you don't have permission to access this page. Please contact us if this was not supposed to happen.",
    btnText: "Back to Homepage",
    btnUrl: "/dashboard",
  };
}

export function pageNotFoundConfig() {
  return {
    imgUrl:
      "https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/default_images/page_not_found.jpg",
    errHeader: "Page Not Found",
    errMsg:
      "The page you are looking for doesn't exist. Please make sure you have entered the correct URL address.  If you have, perhaps the content has been taken down.",
    btnText: "Back to Homepage",
    btnUrl: "/dashboard",
  };
}

export function unprocessableEntityConfig() {
  return {
    imgUrl:
      "https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/default_images/error.jpg",
    errHeader: "Something seems to be broken",
    errMsg:
      "Sorry about that, look's like we are facing some issue in processing your request. Please try again in some time. If the problem persists, feel free to contact us.",
    btnText: "Back to Homepage",
    btnUrl: `/dashboard`,
  };
}

export function internalServerErrorConfig() {
  return {
    imgUrl:
      "https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/default_images/error.jpg",
    errHeader: "Internal Server Error",
    errMsg:
      "We are very sorry. It seems there is some issue with our server. Don't worry, we are currently looking into the issue. Please try again in some time. Please contact us if the problem persists.",
    btnText: "Retry",
    btnUrl: `${window.location.pathname}`,
  };
}

export const getPaginationTotal = (pagination) =>
  pagination && pagination.length
    ? Math.max(...pagination.map((item) => item.page))
    : 0;

export const createErrorList = (errorMessagesObject) => {
  const errorMessages = [];
  Object.keys(errorMessagesObject).forEach((error) => {
    if (errorMessagesObject[error] !== "")
      errorMessages.push(<li key={error}>{errorMessagesObject[error]}</li>);
  });
  return (
    <div
      className="signupErrorMsg invalidDetails col-12"
      style={{ textAlign: "left" }}
    >
      <h6>
        The following issue(s) need to be fixed before you can submit the
        resource:
      </h6>
      <ul className="col-12">{errorMessages}</ul>
    </div>
  );
};

export const restartGuide = (intro) => {
  intro.start();
};

export const parseQueryString = (query_string) => {
  if (!query_string) return {};

  query_string = query_string.substring(1);
  let query_arr = query_string.split("&");
  let query = {};
  query_arr.forEach((item) => {
    let [key, value] = item.split("=");
    if (key && value) query = { ...query, [key]: value.split("%20").join(" ") };
  });
  return query;
};

/* Function to obtain Moment Date in the format 'YYYY-MM-DD' */
export const getMomentDate = (date) => {
  if (date) {
    return dayjs(date, "YYYY-MM-DD");
  } else {
    return dayjs();
  }
};

export const getMomentMonthYear = (date) => {
  if (date) {
    return dayjs(date, "YYYY-MM");
  } else {
    return dayjs();
  }
};

/* Function to obtain Year, Month and Date as an object */
export const getMomentDateObject = (date) => {
  const momentDate = getMomentDate(date);
  return {
    year: momentDate.format("YYYY"),
    month: momentDate.format("MM"),
    date: momentDate.format("DD"),
  };
};

/* Function that returns date as string. Have also taken care of the condition where the date is invalid */
export const getMomentDateString = (dateString, name, type, value) => {
  let { year, month, date } = getMomentDateObject(dateString);

  if (year === "Invalid date") {
    const dateArr = dateString.split("-");
    year = dateArr[0];
  }

  if (month === "Invalid date") {
    const dateArr = dateString.split("-");
    month = dateArr[1];
  }

  if (date === "Invalid date") {
    const dateArr = dateString.split("-");
    date = dateArr[2];
  }

  switch (name) {
    case `${type}_year`: {
      year = value;
      break;
    }
    case `${type}_month`: {
      month = dayjs(value, "M").format("MM");
      break;
    }
    case `${type}_date`: {
      date = ("0" + value).slice(-2);
      break;
    }
    default: {
      date = value;
    }
  }
  return year + "-" + month + "-" + date;
};

export const getMomentMonthYearObject = (date) => {
  const momentDate = getMomentDate(date);
  return {
    year: momentDate.format("YYYY"),
    month: momentDate.format("MM"),
  };
};

export const getMomentMonthYearString = (dateString, name, type, value) => {
  let { year, month } = getMomentMonthYearObject(dateString);

  if (year === "Invalid date") {
    const dateArr = dateString.split("-");
    year = dateArr[0];
  }

  if (month === "Invalid date") {
    const dateArr = dateString.split("-");
    month = dateArr[1];
  }

  switch (name) {
    case `${type}_year`: {
      year = value;
      break;
    }
    case `${type}_month`: {
      month = value;
      if (month < 9) {
        month = "0" + month;
      }
      break;
    }
    default: {
      year = value;
    }
  }
  return year + month;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const createUrlSlug = (name) => {
  return name.split(" ").join("-");
};


export const concatenateData = (val1, val2) =>{
  return val1+"("+val2+")"
}


export const tinyMCEditorApiKey = 'fpxv55gjepbk04pjujg4uon43yidwctkbjmk0tdlz6lbfcx9'
// export const tinyMCEditorApiKey = '0q09a1q7scp0erx7oxajrp1xpoe94u7nybzfy5d2pd4igg6r'
// export const tinyMCEditorApiKey = '6badks3qj3nztv05o0yxdxujhc9atugvo3ozt9msef9iwyxu'


export function acceptTermsUrl() {
  return base_url + `/api/update_terms_nd_cond`
  
}

export function checkRgUNitUrl(unit_type, unit_id) {
	return base_url + `/api/admin/check_resource_group_units?unit_type=${unit_type}&unit_id=${unit_id}`;
}

export function constructTitleUrl(title) {
  return base_url + `/api/get_created_resources_by_title?title=${title}`;
}

