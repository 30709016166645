import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTimeout, clearTimeout } from 'timers';
var time;

class SnackBar extends Component {
	componentDidMount() {
		const { on, timeout, dispatch } = this.props;
		time = setTimeout(() => {
			if (on)
				dispatch({ type: 'SNACK_BAR_OFF' });
		}, timeout)
	}

	componentDidUpdate(prevProps) {
		const { on, timeout, dispatch } = this.props;
		time = setTimeout(() => {
			if (on)
				dispatch({ type: 'SNACK_BAR_OFF' });
		}, timeout)
		if (prevProps.on !== this.props.on && !this.props.on)
			clearTimeout(time);
	}

	render() {
		const { on, message } = this.props;
		let style = null;
		if (on && message) {
			style = {
				transform: 'translate(-50%,0px)',
				visibility: 'visible'
			}
		}
		return (
			<div className="snackBar" style={style}>
				<p className="snackBarText" dangerouslySetInnerHTML={{ __html: message }}></p>
			</div>
		)
	}

}

const mapStateToProps = (state) => {
	const { snackBarState } = state;
	const { on, message, timeout } = snackBarState;
	return {
		on,
		message,
		timeout
	}
}

export default connect(mapStateToProps)(SnackBar);