import * as types from '../constants/ActionTypes';
import merge from 'lodash/merge'; //for entities
import { cardMapping } from '../constants/PortfolioSchema';

const Reducers = {};
const initialRoute = { path: ['projects'] };
const initialNav = { route: initialRoute };
const initialErr = { error_msg: '', login_error_msg: '' };

Reducers.error = function error(state = initialErr, action) {
	switch (action.type) {
		case types.SET_ERROR:
			return Object.assign({}, state, {
				error_msg: action.error_msg,
			});
		case types.SET_LOGIN_ERROR:
			return Object.assign({}, state, {
				login_error_msg: action.error_msg,
			});
		default:
			return state;
	}
};

// contains the current route details.
Reducers.navigator = function navigator(state = initialNav, action) {
	switch (action.type) {
		case types.CHANGE_PATH:
			return { ...state, route: action.route };
		default:
			return state;
	}
};

const initialEnv = {
	isMobile: false,
	height: null,
	width: null,
	browser: {
		browserName: null,
	},
	signUpFlowActive: false,
};
// Gets initialised when the platform starts.
// contains the details of height,width, brower name, etc.
Reducers.environment = function environment(state = initialEnv, action) {
	switch (action.type) {
		case types.CHANGE_IS_MOBILE:
			return Object.assign({}, state, {
				isMobile: action.isMobile,
			});

		case types.CHANGE_WIDTH_AND_HEIGHT:
			return Object.assign({}, state, {
				height: action.height,
				width: action.width,
			});

		case 'DETECT_USER_BROWSER':
			return Object.assign({}, state, {
				browser: action.browserObject,
			});
		case 'SIGN_UP_FLOW_ACTIVE':
			return Object.assign({}, state, {
				signUpFlowActive: true,
			});
		case 'SIGN_UP_FLOW_OFF':
			return Object.assign({}, state, {
				signUpFlowActive: false,
			});

		default:
			return state;
	}
};

//It is one of the most important part of the project.
// It contains the present user's profile details.
Reducers.currentUser = function currentUser(state = false, action) {
  switch (action.type) {
    case "LOG_IN":
      return action.user;
    case "DELETE_CURRENT_USER":
      return false;
    case "CURRENT_USER":
      return action.user;
    case "UPDATE_CURRENT_USER":
      return action.data;
    case "UPDATE_CURRENT_USER_ORG":
      return {
        ...state,
        profile: {
          ...state.profile,
          org: action.data,
        },
      };
    case "UPDATE_CURRENT_USER_PROFILE":
      var profile = state.profile;
      profile = Object.assign({}, profile, action.data);
      return { ...state, profile: profile };
    case "NOT_LOGGED_IN":
      return state;
    case "NEW_TOPIC_INTEREST_ADDED":
      profile = Object.assign({}, state.profile, {
        topic_interests: [...state.profile.topic_interests, action.data],
      });
      return Object.assign({}, state, { profile: profile });
    case "PORTFOLIO_JOB_ROLE_UPDATE":
      let job_roles = action.data.map((item) => {
        return {
          job_role: {
            type: item.type,
            name: item.name,
            id: item.id,
            description: null,
            active: item.active,
          },
          portfolio_id: action.portfolio_id,
          user_id: state.id,
        };
      });
      return {
        ...state,
        portfolio_job_roles: job_roles,
      };
    default:
      return state;
  }
};

//entities
const initialEntityState = {
	projects: {},
	users: {},
	profiles: {},
	posts: {},
	displaygroups: {},
	feeds: {},
	notifications: {},
};

Reducers.entities = function entities(state = initialEntityState, action) {
	switch (action.type) {
		case 'DELETE_ALL_PROJECTS_DATA':
			return Object.assign({}, state, { projects: false });
		case action.entities:
			return merge({}, state, action.entities);
		default:
			return state;
	}
};

//profile Reducer
const initialProfilesState = {
	isFetching: false,
	items: [],
	hasMore: true,
	pagination: false,
};

// TO DO: add merge
Reducers.profileMaster = function profileMaster(state = initialProfilesState, action) {
	switch (action.type) {
		case types.REQUEST_PROFILES:
			return Object.assign({}, state, { isFetching: true });
		case types.RECEIVE_PROFILES:
			return Object.assign({}, state, {
				isFetching: false,
				items: action.profiles,
				pagination: action.pagination,
			});
		default:
			return state;
	}
};

const initialNotifState = {
	isFetchingNotification: false,
	notifications: [],
	tagged_notifications: [],
	hasMorenotification: true,
	nextPageNotification: 1,
	nextPageTaggedNotification: 1,
	hasMoreTaggednotification: true,
};
// This reducer manages all the features of the notifications.
Reducers.ntfMaster = function ntfMaster(state = initialNotifState, action) {
	switch (action.type) {
		case types.REQUEST_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: true,
				isFetchingTaggedNotification: false,
			});
		case types.REQUEST_MORE_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: true,
				isFetchingTaggedNotification: false,
			});
		case types.RECEIVE_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: false,
				isFetchingTaggedNotification: false,
				nextPageNotification: 2,
				hasMorenotification: action.notifications.length > 0,
				notifications: [...action.notifications], //...state.items,
			});
		case types.RECEIVE_MORE_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: false,
				isFetchingTaggedNotification: false,
				nextPageNotification: state.nextPageNotification + 1,
				hasMorenotification: action.notifications.length > 0,
				notifications: state.notifications.concat([...action.notifications]), //...state.items,
			});
		case types.REQUEST_TAGGED_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: false,
				isFetchingTaggedNotification: true,
			});
		case types.REQUEST_MORE_TAGGED_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: false,
				isFetchingTaggedNotification: true,
			});
		case types.RECEIVE_TAGGED_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: false,
				isFetchingTaggedNotification: false,
				nextPageTaggedNotification: 2,
				hasMoreTaggednotification: action.tagged_notifications.length > 0,
				tagged_notifications: [...action.tagged_notifications], //...state.items,
			});
		case types.RECEIVE_MORE_TAGGED_NOTIFICATIONS:
			return Object.assign({}, state, {
				isFetchingNotification: false,
				isFetchingTaggedNotification: false,
				nextPageTaggedNotification: state.nextPageTaggedNotification + 1,
				hasMoreTaggednotification: action.tagged_notifications.length > 0,
				tagged_notifications: state.tagged_notifications.concat([...action.tagged_notifications]), //...state.items,
			});
		default:
			return state;
	}
};

// This is one the most important reducer for a student profile.
// It contains all the details of the student's progress like projects, events, jobs, etc he/she has completed.
Reducers.portfolio = function portfolio(state = false, action) {
	switch (action.type) {
		case types.FETCH_PORTFOLIO:
			return action.portfolio;
		case types.UPDATE_PORTFOLIO:
			return Object.assign({}, state, action.portfolio);
		case 'UPDATE_PORTFOLIO_JOB_ROLES':
			return Object.assign({}, state, action.data);
		case 'REFRESH_PORTFOLIO':
			return action.portfolio;
		case types.FAILED_TO_FETCH_PORTFOLIO:
			return state;
		case 'DELETE_PORTFOLIO':
			return false;
		default:
			return state;
	}
};

// Public portfolio is the minified version of student's portfolio.
// It contains the data which is public. It is used for viewing the portfolio of a particular student.
Reducers.publicPortfolio = function publicPortfolio(state = false, action) {
	switch (action.type) {
		case 'FETCH_PUBLIC_PORTFOLIO':
			return action.portfolio;
		case 'FAILED_FETCH_PUBLIC_PORTFOLIO':
			return Object.assign({}, state, action.portfolio);
		case 'DELETE_PUBLIC_PORTFOLIO':
			return false;
		default:
			return state;
	}
};

const initialRecommendationState = {
	projects: false,
	events: false,
	articles: false,
	courses: false,
	internships: false,
	jobs: false,
	clubs: false,
	readings: false,
	cocurriculars: false,
};

// this reducer contains the recommendations for all the entities like projects, events, articles, etc.
Reducers.recommendations = function recommendations(state = initialRecommendationState, action) {
	switch (action.type) {
		case types.RECEIVE_RECOMMENDED_EVENTS:
			return Object.assign({}, state, { events: action.data });
		case types.RECEIVE_RECOMMENDED_JOBS:
			return Object.assign({}, state, { jobs: action.data });
		case types.RECEIVE_RECOMMENDED_READINGS:
			return Object.assign({}, state, { readings: action.data });
		case types.RECEIVE_RECOMMENDED_CLUBS:
			return Object.assign({}, state, { clubs: action.data });
		case types.RECEIVE_RECOMMENDED_ARTICLES:
			return Object.assign({}, state, { articles: action.data });
		case 'DELETE_RECOMMENDED_PROJECTS':
			return Object.assign({}, state, { projects: false });
		case 'DELETE_RECOMMENDED_EVENTS':
			return Object.assign({}, state, { events: false });
		case 'DELETE_RECOMMENDED_JOBS':
			return Object.assign({}, state, { jobs: false });
		case 'DELETE_RECOMMENDED_INTERNSHIPS':
			return Object.assign({}, state, { internships: false });
		case 'DELETE_RECOMMENDED_READINGS':
			return Object.assign({}, state, { readings: false });
		case 'DELETE_RECOMMENDED_CLUBS':
			return Object.assign({}, state, { clubs: false });
		case 'DELETE_RECOMMENDED_ARTICLES':
			return Object.assign({}, state, { articles: false });
		default:
			return state;
	}
};

const initialEvents = {
	allEvents: false,
	featuredEvents: false,
};
// This reducer contains the list of featured events.
// It is used for events catalog and student's dashboard.
Reducers.events = function events(state = initialEvents, action) {
	switch (action.type) {
		case 'FETCH_ALL_EVENTS':
			return Object.assign({}, state, { allEvents: action.data });
		case 'FETCH_FEATURED_EVENTS':
			return Object.assign({}, state, { featuredEvents: action.data });
		case 'DELETE_FEATURED_EVENTS':
			return Object.assign({}, state, { featuredEvents: false });
		default:
			return state;
	}
};
// this reducer contains the list of books for books catalog.
Reducers.books = function books(state = false, action) {
	switch (action.type) {
		case 'FETCH_BOOKS':
			return action.books;
		case 'DELETE_BOOKS':
			return false;
		default:
			return state;
	}
};

// this reducer contains the list of articles for articles catalog.

Reducers.articles = function articles(state = false, action) {
	switch (action.type) {
		case 'FETCH_ARTICLES':
			return action.articles;
		case 'DELETE_ARTICLES':
			return false;
		default:
			return state;
	}
};

// this reducer contains the list of clubs for clubs catalog.
Reducers.clubs = function clubs(state = false, action) {
	switch (action.type) {
		case 'FETCH_CLUBS':
			return Object.assign({}, state, {
				data: action.data,
			});
		case 'DELETE_CLUBS':
			return false;
		default:
			return state;
	}
};

// this reducer contains the drawer state. It is used globally to open or close the drawer.
Reducers.drawerState = function drawerState(state = false, action) {
	switch (action.type) {
		case 'CHANGE_DRAWER':
			return action.value;
		default:
			return state;
	}
};

const initialDetailsPageActive = {
	events: false,
	jobs: false,
	projects: false,
	loading: false,
	clubs: false,
};
// This reducer contains the data for any detail page like project, event, job, etc.
// It gets reset when the detail page unmounts.
Reducers.currentActive = function currentActive(state = initialDetailsPageActive, action) {
	switch (action.type) {
		case 'RECIEVE_JOB_DETAIL':
			return Object.assign({}, state, {
				jobs: action.data,
			});
		case 'DELETE_JOB_DETAIL':
			return Object.assign({}, state, {
				jobs: action.data,
			});
		case 'RECIEVE_EVENT_DETAIL':
			return Object.assign({}, state, {
				events: action.data,
			});
		case 'RECIEVE_PROJECT_DETAIL':
			return Object.assign({}, state, {
				projects: action.data.projects,
			});
		case 'RECEIVE_MY_PROJECT_DETAIL':
			return Object.assign({}, state, {
				projects: action.data.data,
			});
		case 'UPDATE_MY_PROJECT_DETAILS':
			return {
				...state,
				projects: action.data,
			};
		case 'DETAIL_PAGE_LOADING':
			return Object.assign({}, state, {
				loading: true,
			});
		case 'DETAIL_PAGE_NOT_LOADING':
			return Object.assign({}, state, {
				loading: false,
			});
		case 'PROJECT_TASK_COMPLETED':
			return {
				...state,
				projects: action.data.data,
			};
		case 'DELETE_PROJECT_DATA':
			return Object.assign({}, state, { projects: false });
		case 'DELETE_CURRENT_ACTIVE':
			return initialDetailsPageActive;
		case 'RECIEVE_CLUB_DETAIL':
			return Object.assign({}, state, {
				clubs: action.data.data,
			});
		case 'DELETE_CLUB_DETAIL':
			return Object.assign({}, state, {
				clubs: action.data,
			});
		case 'DELETE_EVENT_DETAIL':
			return Object.assign({}, state, {
				events: action.data,
			});
		default:
			return state;
	}
};

const loginModalInitial = {
	messageModal: {
		error: false,
		show: false,
		ineligible: false,
	},
	selectedJobs: false,
	profileType: 'student',
};
Reducers.loginModal = function loginModal(state = loginModalInitial, action) {
	switch (action.type) {
		case 'SHOW_KUDOS_MODAL':
			return Object.assign({}, state, {
				messageModal: { error: false, show: true },
			});
		case 'ERROR_KUDOS_MODAL':
			return Object.assign({}, state, {
				messageModal: { error: true, show: true },
			});
		case 'INELIGIBLE_KUDOS_MODAL':
			return Object.assign({}, state, {
				messageModal: { ineligible: true, show: true },
			});
		case 'HIDE_KUDOS_MODAL':
			return Object.assign({}, state, {
				messageModal: { error: false, show: false, ineligible: false },
			});
		default:
			return state;
	}
};

// This a loading state reducer. It is used for api loading state.
// It also has a feature of multiple load which can be used handling multiple apis.
Reducers.isLoading = function isLoading(
	state = { currentUser: false, apply: false, component: false, load: 0 },
	action
) {
	switch (action.type) {
		case 'IS_LOADING':
			return Object.assign({}, state, {
				currentUser: true,
			});
		case 'NOT_LOADING':
			return Object.assign({}, state, {
				currentUser: false,
			});
		case 'APPLY_IS_LOADING':
			return Object.assign({}, state, {
				apply: true,
			});
		case 'APPLY_NOT_LOADING':
			return Object.assign({}, state, {
				apply: false,
			});
		case 'COMPONENT_IS_LOADING':
			return Object.assign({}, state, {
				component: true,
			});
		case 'COMPONENT_NOT_LOADING':
			return Object.assign({}, state, {
				component: false,
			});
		case 'MULTIPLE_LOAD':
			return Object.assign({}, state, {
				load: action.load,
			});
		case 'REDUCE_LOAD':
			if (state.load > 0)
				return Object.assign({}, state, {
					load: state.load - 1,
				});
			else return state;
		default:
			return state;
	}
};

const initialModalAddState = {
	social_clubs: {
		status: false,
		data: null,
		cardName: null,
	},
	external_projects: {
		status: false,
		data: null,
		cardName: null,
	},
	education: {
		status: false,
		data: null,
		cardName: null,
	},
	external_courses: {
		status: false,
		data: null,
		cardName: null,
	},
	internships: {
		status: false,
		data: null,
		cardName: null,
	},
	external_events: {
		status: false,
		data: null,
		cardName: null,
	},
	readings: {
		status: false,
		data: null,
		cardName: null,
	},
	cocurriculars: {
		status: false,
		data: null,
		cardName: null,
	},
	jobs: {
		status: false,
		data: null,
		cardName: null,
	},
	semester: {
		status: false,
		data: null,
		cardName: null,
	},
	certificate: {
		status: false,
		data: null,
		cardName: null,
	},
	openAddCard: false,
	selectedCard: false,
};

//addModal state which makes the property true based on their respective card names
Reducers.addModal = function addModal(state = initialModalAddState, action) {
	switch (action.type) {
		case types.RECEIVE_ADD_MODAL_DATA:
			return {
				...state,
				openAddCard: action.openAddCard,
				selectedCard: action.cardName,
				[cardMapping[action.cardName]]: {
					...state[cardMapping[action.cardName]],
					data: action.data,
					cardName: action.cardName,
				},
			};
		case types.UPDATE_SELECTED_ADD_CARD_STATUS:
			return {
				...state,
				[cardMapping[action.data.cardName]]: {
					...state[cardMapping[action.data.cardName]],
					status: action.data.status,
				},
			};
		case types.RESET_ADD_CARD_MODAL_DATA:
			return initialModalAddState;

		default:
			return state;
	}
};

// contains fetured jobs for job's catalog and student dashboard.
Reducers.jobs = function jobs(state = false, action) {
	switch (action.type) {
		case 'ALL_JOBS_FETCH':
			return Object.assign({}, state, { allJobs: action.data });
		case 'FEATURED_JOBS_FETCH':
			return Object.assign({}, state, { featuredJobs: action.data });
		case 'DELETE_FEATURED_JOBS':
			return Object.assign({}, state, { featuredJobs: false });

		default:
			return state;
	}
};

// contains fetured internships for internship's catalog and student dashboard.
Reducers.internships = function internships(state = false, action) {
	switch (action.type) {
		case 'ALL_INTERNSHIPS_FETCH':
			return Object.assign({}, state, { allInternships: action.data });
		case 'FEATURED_INTERNSHIPS_FETCH':
			return Object.assign({}, state, { featuredInternships: action.data });
		case 'DELETE_FEATURED_INTERNSHIPS':
			return Object.assign({}, state, { featuredInternships: false });
		default:
			return state;
	}
};

Reducers.updateProfile = function updateProfile(state = '', action) {
	switch (action.type) {
		case types.PROFILE_UPDATED:
			return (state = 'updated');
		case types.PROFILE_NOT_UPDATED:
			return (state = 'failed');
		case 'RESET_PROFILE_UPDATE':
			return null;
		default:
			return state;
	}
};

// this reducer contains the list of testimonials for the home page.
Reducers.testimonials = function testimonials(state = false, action) {
	switch (action.type) {
		case 'FETCHED_TESTIMONIALS':
			return action.data;
		default:
			return state;
	}
};

Reducers.myPlansModal = function myPlansModal(state = { isMyPlanOpen: false, addJobsOpen: false }, action) {
	switch (action.type) {
		case 'HIDE_MY_PLANS':
			return Object.assign({}, state, {
				isMyPlanOpen: false,
			});
		case 'SHOW_ADD_JOBS_MODAL':
			return Object.assign({}, state, {
				addJobsOpen: true,
			});
		case 'HIDE_ADD_JOBS_MODAL':
			return Object.assign({}, state, {
				addJobsOpen: false,
			});
		default:
			return state;
	}
};

// Contains the list families and their job roles.
// It is used for select job roles page.
Reducers.jobRoles = function jobRoles(state = false, action) {
	switch (action.type) {
		case 'FETCHED_JOB_ROLES':
			return action.data;
		default:
			return state;
	}
};

// Contains the recommended job roles.
// Used for select job roles page.
Reducers.recommendedJobRoles = function recommendedJobRoles(state = false, action) {
	switch (action.type) {
		case 'FETCHED_RECOMMENDED_JOB_ROLES':
			return action.data;
		case 'RESET_RECOMMENDED_JOB_ROLES':
			return false;
		default:
			return state;
	}
};

Reducers.getJobList = function getJobList(state = false, action) {
	switch (action.type) {
		case 'FETCHED_JOB_LIST':
			return action.data;
		default:
			return state;
	}
};

Reducers.countStudentData = function countStudentData(state = false, action) {
	switch (action.type) {
		case 'FETCH_FIELD_COUNT':
			return action.data;
		case 'DELETE_GRAPH_COUNT_DATA':
			return false;
		default:
			return state;
	}
};

Reducers.shortlistedStudents = function shortlistedStudents(state = false, action) {
	switch (action.type) {
		case 'FETCHED_SHORTLISTED_DATA':
			return action.data;
		case 'DELETE_SHORTLIST':
			return false;
		default:
			return state;
	}
};

Reducers.jobsAppliedByInstitute = function jobsAppliedByInstitute(state = false, action) {
	switch (action.type) {
		case 'FETCHED_JOBS_APPLIED_BY_INSTITUTE':
			return action.data;
		case 'DELETE_JOBS_APPLIED_BY_INSTITUTE':
			return false;
		default:
			return state;
	}
};

// contains the list job applicants for a particular job.
// used in  job's dashboard.
Reducers.jobApplicants = function jobApplicants(state = { data: false, pagination: false }, action) {
	switch (action.type) {
		case 'FETCHED_JOB_ENROLLED_DETAILS':
			return {
				data: action.data.data ? action.data.data : action.data,
				pagination: action.pagination ? action.pagination : action.data.pagination,
			};
		case 'DELETE_JOB_ENROLLED_DETAILS':
			return { data: false, pagination: false };
		default:
			return state;
	}
};

// contains the list of students who are shortlisted by company/institute through skills dashboard.
Reducers.jobInvitedApplicants = function jobInvitedApplicants(state = { data: false, pagination: false }, action) {
	switch (action.type) {
		case 'FETCHED_JOB_INVITED_STUDENT_DETAILS':
			return {
				data: action.data,
				pagination: action.pagination,
				count: action.count,
			};
		case 'DELETE_JOB_INVITED_STUDENT_DETAILS':
			return { data: false, pagination: false, count: false };
		default:
			return state;
	}
};

Reducers.eventApplicants = function eventApplicants(state = { data: false, pagination: false }, action) {
	switch (action.type) {
		case 'FETCHED_EVENT_ENROLLED_DETAILS':
			return { data: action.data, pagination: action.pagination };
		case 'DELETE_EVENT_ENROLLED_DETAILS':
			return { data: false, pagination: false };
		default:
			return state;
	}
};

Reducers.projectTeamData = function projectTeamData(state = { data: false, pagination: false }, action) {
	switch (action.type) {
		case 'FETCHED_PROJECT_TEAM_DATA':
			return { data: action.data, pagination: action.pagination };
		case 'DELETE_PROJECT_TEAM_DATA':
			return false;
		default:
			return state;
	}
};

// contains the list of company specific jobs.
Reducers.companyJobs = function companyJobs(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_COMPANY_JOBS':
			return action.data;
		case 'DELETE_COMPANY_JOBS':
			return action.data;
		default:
			return state;
	}
};

// contains the list of company specific events.
Reducers.companyEvents = function companyEvents(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_COMPANY_EVENTS':
			return action.data;
		case 'DELETE_COMPANY_EVENTS':
			return action.data;
		default:
			return state;
	}
};

// contains the list of company specific mentors.
Reducers.companyMentors = function companyMentors(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_COMPANY_MENTORS':
			return action.data;
		case 'DELETE_COMPANY_MENTORS':
			return action.data;
		default:
			return state;
	}
};

const initialProjectCountState = {
	count: false,
	split: false,
};
Reducers.projectEnrollmentData = function projectEnrollmentData(state = initialProjectCountState, action) {
	switch (action.type) {
		case 'RECEIVE_PROJECT_ENROLLMENT_COUNT':
			return Object.assign({}, state, { count: action.data });
		case 'RECEIVE_PROJECT_ENROLLMENT_SPLIT':
			return Object.assign({}, state, { split: action.data });
		case 'DELETE_PROJECT_ENROLLMENT_COUNT_SPLIT':
			return initialProjectCountState;
		default:
			return state;
	}
};

Reducers.eventEnrolledByInstitute = function eventEnrolledByInstitute(state = false, action) {
	switch (action.type) {
		case 'FETCHED_EVENT_ENROLLMENT_BY_INSTITUTE':
			return action.data;
		case 'DELETE_EVENT_ENROLLMENT_BY_INSTITUTE':
			return false;
		default:
			return state;
	}
};

Reducers.totalEventCount = function totalEventCount(state = false, action) {
	switch (action.type) {
		case 'FETCHED_TOTAL_EVENT_COUNT':
			return action.data;
		case 'DELETE_TOTAL_EVENT_COUNT':
			return false;
		default:
			return state;
	}
};

Reducers.org = function org(state = false, action) {
	switch (action.type) {
		case 'ORG_FETCHED':
			return action.data;
		case 'DELETE_ORG_PUBLiC':
			return false;
		default:
			return state;
	}
};

Reducers.projectGroups = function projectGroups(state = false, action) {
	switch (action.type) {
		case 'FETCHED_ALL_PROJECT_GROUPS':
			return action.data;
		default:
			return state;
	}
};

Reducers.isCreateEvent = function isCreateEvent(state = false, action) {
	switch (action.type) {
		case 'CREATE_EVENT_FORM_OPEN':
			return action.data;
		case 'CREATE_EVENT_FORM_CLOSE':
			return action.data;
		default:
			return state;
	}
};

Reducers.companyPublicEventsData = function companyPublicEventsData(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_COMPANY_PUBLIC_EVENT_DATA':
			return action.data;
		case 'DELETE_COMPANY_PUBLIC_EVENT_DATA':
			return false;
		default:
			return state;
	}
};

// contains company specific jobs. used in jobs catalog for a particular company.
Reducers.companyPublicJobs = function companyPublicJobs(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_COMPANY_PUBLIC_JOB_DATA':
			return action.data;
		case 'DELETE_COMPANY_PUBLIC_JOB_DATA':
			return false;
		default:
			return state;
	}
};

Reducers.clubStudent = function clubStudent(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_CLUB_STUDENTS_MEMBER_DATA':
			return action.data;
		case 'DELETE_CLUB_STUDENTS_MEMBER_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.clubAllStudents = function clubAllStudents(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_CLUB_ALL_MEMBER_DATA':
			return action.data;
		case 'DELETE_CLUB_ALL_MEMBER_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.clubJoinResponse = function clubJoinResponse(state = false, action) {
	switch (action.type) {
		case 'UPDATE_CLUB_JOIN_RESPONSE':
			return action.data;
		case 'DELETE_CLUB_JOIN_RESPONSE':
			return action.data;
		default:
			return state;
	}
};

Reducers.clubStudentCommittee = function clubStudentCommittee(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_CLUB_STUDENTS_COMMITTEE_DATA':
			return action.data;
		case 'DELETE_CLUB_STUDENTS_COMMITTEE_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.clubJoinReq = function clubJoinReq(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_CLUB_JOIN_REQUEST_DATA':
			return action.data;
		case 'DELETE_CLUB_JOIN_REQUEST_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.clubInvite = function clubInvite(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_CLUB_INVITE_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.eventInvite = function eventInvite(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_EVENT_INVITE_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.companyPublicMentorsData = function companyPublicMentorsData(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_COMPANY_PUBLIC_MENTOR_DATA':
			return action.data;
		case 'DELETE_COMPANY_PUBLIC_MENTOR_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.requiredSkills = function requiredSkills(state = false, action) {
	switch (action.type) {
		case 'FETCHED_JOB_REQUIRED_SKILLS':
			return action.data;
		default:
			return state;
	}
};

Reducers.postCategoryId = function postCategoryId(state = false, action) {
	switch (action.type) {
		case 'SAVE_POST_CATEGORY_IDS':
			return action.data;
		default:
			return state;
	}
};

Reducers.isCreateJob = function isCreateJob(state = false, action) {
	switch (action.type) {
		case 'CREATE_JOB_FORM_OPEN':
			return action.data;
		case 'CREATE_JOB_FORM_CLOSE':
			return action.data;
		default:
			return state;
	}
};

Reducers.isCreateClub = function isCreateClub(state = false, action) {
	switch (action.type) {
		case 'CREATE_CLUB_FORM_OPEN':
			return action.data;
		case 'CREATE_CLUB_FORM_CLOSE':
			return action.data;
		default:
			return state;
	}
};

const initialFilterState = {
	project: { name: false, project_ids: [] },
	job: { name: false, org_ids: [] },
	load: 0,
	applicants_status: false,
};
Reducers.filteredData = function filteredData(state = initialFilterState, action) {
	switch (action.type) {
		case 'FILTERED_PROJECTS':
			return Object.assign({}, state, {
				project: { name: action.name, project_ids: action.project_ids },
				load: action.loadCount,
			});
		case 'FILTERED_JOBS':
			return Object.assign({}, state, {
				job: { name: action.name, org_ids: action.org_ids },
				load: action.loadCount,
			});
		case 'FILTERED_APPLICANTS_STATUS':
			return Object.assign({}, state, action.data);
		case 'REDUCE_FILTER_LOAD':
			if (state.load > 0) return Object.assign({}, state, { load: state.load - 1 });
			else return state;
		case 'RESET_FILTERS':
			return Object.assign({}, initialFilterState, { load: action.loadCount });
		default:
			return state;
	}
};

const initialGraphState = {
	academic: false,
	branch: false,
	xceler: false,
	education: false,
	experience: false,
	exposure: false,
};
Reducers.graphData = function graphData(state = initialGraphState, action) {
	switch (action.type) {
		case 'ACADEMIC_GRAPH_DATA':
			return Object.assign({}, state, { academic: action.data });
		case 'BRANCH_GRAPH_DATA':
			return Object.assign({}, state, { branch: action.data });
		case 'DELETE_GRAPH_DATA':
			return initialGraphState;
		default:
			return state;
	}
};

Reducers.graphFilters = function graphFilters(state = false, action) {
	switch (action.type) {
		case 'FETCH_FILTER_SET':
			return action.data;
		case 'DELETE_FILTER_SET':
			return false;
		default:
			return state;
	}
};

const initialTopSkillsList = {
	xceler: false,
	education: false,
	experience: false,
	exposure: false,
};
Reducers.topSkillsList = function topSkillsList(state = initialTopSkillsList, action) {
	switch (action.type) {
		case 'FETCH_OVERALL_SKILLS':
			return Object.assign({}, state, { xceler: action.data });
		case 'FETCH_EDUCATION_SKILLS':
			return Object.assign({}, state, { education: action.data });
		case 'FETCH_EXPERIENCE_SKILLS':
			return Object.assign({}, state, { experience: action.data });
		case 'FETCH_EXPOSURE_SKILLS':
			return Object.assign({}, state, { exposure: action.data });
		default:
			return state;
	}
};

Reducers.academicFilters = function academicFilters(state = false, action) {
	switch (action.type) {
		case 'ACADEMIC_GRAPH_FILTERS':
			return action.data;
		case 'RESET_ACADEMIC_FILTER':
			return false;
		default:
			return state;
	}
};

Reducers.maxGraphRange = function maxGraphRange(state = false, action) {
	switch (action.type) {
		case 'MAX_GRAPH_RANGE':
			return action.data;
		default:
			return state;
	}
};

Reducers.totalStudentCount = function totalStudentCount(state = false, action) {
	switch (action.type) {
		case 'TOTAL_STUDENT_COUNT':
			return action.data;
		case 'DELETE_TOTAL_COUNT':
			return false;
		default:
			return state;
	}
};
Reducers.finalStudentCount = function finalStudentCount(state = false, action) {
	switch (action.type) {
		case 'FETCHED_FINAL_COUNT':
			return action.data;
		default:
			return state;
	}
};

Reducers.isSavingShortlist = function isSavingShortlist(state = false, action) {
	switch (action.type) {
		case 'SAVE_NEW_SHORTLIST':
			return true;
		case 'SAVED_SHORTLIST':
			return false;
		default:
			return state;
	}
};

Reducers.skillsDashboardFilterProp = function skillsDashboardFilterProp(state = skillsDashboardFilterData, action) {
	switch (action.type) {
		case 'FETCH_YEAR_FILTER_DATA':
			return Object.assign({}, state, { year: action.data });
		case 'FETCH_PLACED_FILTER_DATA':
			return Object.assign({}, state, { placed: action.data });
		case 'FETCH_BACKLOGS_FILTER_DATA':
			return Object.assign({}, state, { backlogs: action.data });
		case 'FETCH_BRANCH_FILTER_DATA':
			return Object.assign({}, state, { branch: action.data });
		case 'FETCH_ACADEMIC_SCORE_FILTER_DATA':
			return Object.assign({}, state, { academicScore: action.data });
		default:
			return state;
	}
};

Reducers.skillsXcelerFilters = function skillsXcelerFilters(state = false, action) {
	switch (action.type) {
		case 'FETCH_SKILLS_XCELER_FILTER':
			return action.data;
		case 'DELETE_SKILLS_XCELER_FILTER':
			return false;
		default:
			return state;
	}
};

const filterStudentCountState = {
	academic: false,
};
Reducers.filterStudentCount = function filterStudentCount(state = filterStudentCountState, action) {
	switch (action.type) {
		case 'FETCH_ACADEMIC_FILTER_COUNT':
			return Object.assign({}, state, { academic: action.data });
		default:
			return state;
	}
};

const initialsnackBarState = {
	on: false,
	save: false,
	publish: false,
	discard: false,
	message: false,
	timeout: 5000,
};

Reducers.snackBarState = function snackBarState(state = initialsnackBarState, action) {
	switch (action.type) {
		case 'SNACK_BAR_ON':
			return action.data;
		case 'SNACK_BAR_OFF':
			return Object.assign({}, state, { on: false });
		case 'SNACK_BAR_SAVE_OFF':
			return Object.assign({}, state, { save: false });
		case 'SNACK_BAR_PUBLISH_OFF':
			return Object.assign({}, state, { publish: false });
		case 'SNACK_BAR_DISCARD_OFF':
			return Object.assign({}, state, { discard: false });
		case 'RESET_SNACK_BAR':
			return initialsnackBarState;
		default:
			return state;
	}
};

const initialToastState = {
	active: false,
	toastMsg: null,
	toastHeading: null,
	toastType: null,
	timeout: 3000,
};

Reducers.toastState = function toastState(state = initialToastState, action) {
	switch (action.type) {
		case 'TOAST_ACTIVE':
			return action.data;
		case 'TOAST_INACTIVE':
			return Object.assign({}, state, { active: false });
		case 'RESET_TOAST':
			return initialToastState;
		default:
			return state;
	}
};

Reducers.instituteSplitData = function instituteSplitData(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_SPLIT':
			return action.data;
		case 'DELETE_INSTITUTE_SPLIT':
			return false;
		default:
			return state;
	}
};

Reducers.orgStudent = function orgStudent(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_STUDENTS':
			return action.data;
		case 'DELETE_INSTITUTE_SPLIT':
			return false;
		default:
			return state;
	}
};
Reducers.degreeSplitData = function degreeSplitData(state = false, action) {
	switch (action.type) {
		case 'FETCH_DEGREE_SPLIT':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteMentors = function instituteMentors(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_MENTORS':
			return action.data;
		default:
			return state;
	}
};
Reducers.instituteDegreeFilters = function instituteDegreeFilters(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_DEGREE_FILTERS':
			return action.data;
		case 'DELETE_INSTITUTE_DEGREE_FILTERS':
			return false;
		default:
			return state;
	}
};

Reducers.instituteJobs = function instituteJobs(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_JOBS':
			return action.data;
		case 'DELETE_INSTITUTE_JOB_PROP':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteEvents = function instituteEvents(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_EVENTS':
			return action.data;
		case 'DELETE_INSTITUTE_EVENT_PROP':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteClubs = function instituteClubs(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_CLUBS':
			return action.data;
		case 'DELETE_INSTITUTE_CLUB_PROP':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteOrg = function instituteOrg(state = false, action) {
	switch (action.type) {
		case 'INSTITUTE_FETCHED':
			return action.data;
		default:
			return state;
	}
};

Reducers.platformUpdates = function platformUpdates(state = false, action) {
	switch (action.type) {
		case 'FETCHED_PLATFORM_UPDATES':
			return action.data;
		default:
			return state;
	}
};

Reducers.actionItems = function actionItems(state = false, action) {
	switch (action.type) {
		case 'FETCHED_ACTION_ITEMS':
			return action.data;
		default:
			return state;
	}
};

Reducers.postsTimeline = function postsTimeline(state = false, action) {
	switch (action.type) {
		case 'FETCHED_POSTS_TIMELINE':
			return action.data;
		default:
			return state;
	}
};

Reducers.yearGraphData = function yearGraphData(state = false, action) {
	switch (action.type) {
		case 'YEAR_GRAPH_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.enrollmentBranchData = function enrollmentBranchData(state = false, action) {
	switch (action.type) {
		case 'ENROLLMENT_BRANCH_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.enrollmentDashboardData = function enrollmentDashboardData(state = false, action) {
	switch (action.type) {
		case 'FETCH_ENROLLMENT_DASHBOARD_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.enrollmentFilters = function enrollmentFilters(state = false, action) {
	switch (action.type) {
		case 'SET_ENROLLMENT_FILTERS':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteProjectCount = function instituteProjectCount(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_PROJECT_COUNT':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteProjectSplit = function instituteProjectSplit(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_PROJECT_SPLIT':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteProjectTable = function instituteProjectTable(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_PROJECT_TABLE':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteProjects = function instituteProjects(state = false, action) {
	switch (action.type) {
		case 'FETCH_INSTITUTE_PROJECTS':
			return action.data;
		default:
			return state;
	}
};

Reducers.instituteProjectFilter = function instituteProjectFilter(state = [], action) {
	switch (action.type) {
		case 'INSTITUTE_PROJECT_FILTERS':
			return action.data;
		default:
			return state;
	}
};

Reducers.tokenExpiryTime = function tokenExpiryTime(state = false, action) {
	switch (action.type) {
		case 'TOKEN_EXPIRY_TIME':
			return action.access_expiry;
		default:
			return state;
	}
};

const initialTokenExpiryCondition = {
	tokenExpired: false,
	callFetchRefresh: false,
};

Reducers.tokenExpiredCondition = function tokenExpiredCondition(state = initialTokenExpiryCondition, action) {
	switch (action.type) {
		case 'TOKEN_EXPIRED_CONDITION':
			return {
				tokenExpired: action.data.tokenExpired,
				callFetchRefresh: action.data.callFetchRefresh,
			};
		default:
			return state;
	}
};

const initialProfileCreateState = {
	institute: false,
	company: false,
	degree: false,
	designation: false,
	specialization: false,
	state: false,
};
Reducers.profileCreationData = function profileCreationData(state = initialProfileCreateState, action) {
	switch (action.type) {
		case 'FETCH_DEGREE_DATA':
			return Object.assign({}, state, { degree: action.data });
		case 'FETCH_INSTITUTE_DATA':
			return Object.assign({}, state, { institute: action.data });
		case 'FETCH_COMPANY_DATA':
			return Object.assign({}, state, { company: action.data });
		case 'FETCH_DESIGNATION_DATA':
			return Object.assign({}, state, { designation: action.data });
		case 'FETCH_SPECIALIZATION_DATA':
			return Object.assign({}, state, { specialization: action.data });
		case 'FETCH_STATES_DATA':
			return Object.assign({}, state, { state: action.data });
		default:
			return state;
	}
};

Reducers.cityList = function cityList(state = false, action) {
	switch (action.type) {
		case 'FETCH_CITY_DATA':
			return Object.assign({}, state, { city: action.data });
		default:
			return state;
	}
};

Reducers.jobRoleLifeSkillsList = function jobRoleLifeSkillsList(state = false, action) {
	switch (action.type) {
		case 'FETCHED_JOB_LIFE_SKILLS':
			return action.data;
		default:
			return state;
	}
};

Reducers.jobSummaryData = function jobSummaryData(state = false, action) {
	switch (action.type) {
		case 'FETCH_JOB_SUMMARY_DATA':
			return action.data;
		default:
			return state;
	}
};

Reducers.eventSummaryData = function eventSummaryData(state = false, action) {
	switch (action.type) {
		case 'FETCH_EVENT_SUMMARY_DATA':
			return action.data;
		default:
			return state;
	}
};

const skillsDashboardFilterData = {
	year: [],
	placed: false,
	backlogs: true,
	branch: [],
	academicScore: {
		to: 100,
		from: 0,
	},
};

const initialPopUpConfig = {
	message: false,
	show: false,
	heading: false,
};
Reducers.popUpConfig = function popUpConfig(state = initialPopUpConfig, action) {
	switch (action.type) {
		case 'SET_POPUP_MODAL':
			return Object.assign({}, state, action.data);
		case 'CLOSE_POPUP_MODAL':
			return initialPopUpConfig;
		default:
			return state;
	}
};
/*
Common Reducer is one of the most important reducers of the project. 
It creates dynamic reducers based on the keys and values passed. 

  */
Reducers.commonReducer = function commonReducer(state = {}, action) {
	switch (action.type) {
		case 'MERGE_IN_COMMON_REDUCER':
			return Object.assign({}, state, { [action.key]: action.data });
		// Creating a reducer based on the key and assigning the value.
		case 'DELETE_KEY_COMMON_REDUCER':
			var obj = state;
			delete obj[action.key];
			return obj;
		// Deleting the common reducer's key.
		default:
			return state;
	}
};

// CREATING NEW REDUCER FOR POST
Reducers.postsData = function postsData(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_POST_DATA':
			return Object.assign({}, state, action.data);
		case 'ADD_NEW_POST':
			return {
				data: [action.data].concat(state.data),
				pagination: state.pagination,
			};
		case 'LOAD_MORE_POST_DATA':
			return {
				data: state.data.concat(action.data.data),
				pagination: action.data.pagination,
			};
		case 'DELETE_POST_BY_ID':
			var stateData = state.data.filter((item) => item.id !== action.data);
			return {
				data: stateData,
				pagination: state.pagination,
			};

		case 'LIKE_POST_BY_ID':
			var index = state.data.findIndex((item) => item.id === action.postId);
			return {
				data: [
					...state.data.slice(0, index),
					{
						...state.data[index],
						like_count: state.data[index].like_count + 1,
						post_actions: state.data[index].post_actions.concat([action.data]),
					},
					...state.data.slice(index + 1),
				],
				pagination: state.pagination,
			};
		case 'ADD_NEW_POST_COMMENT':
			index = state.data.findIndex((item) => item.id === action.postId);
			return {
				data: [
					...state.data.slice(0, index),
					{
						...state.data[index],
						comment_count: state.data[index].comment_count + 1,
						comments: state.data[index].comments.concat([action.data]),
					},
					...state.data.slice(index + 1),
				],
				pagination: state.pagination,
			};
		case 'LIKE_POST_COMMENT_BY_ID':
			var postIndex = state.data.findIndex((item) => item.id === action.postId);
			var commentIndex = state.data[postIndex].comments.findIndex((item) => item.id === action.commentId);
			return {
				data: [
					...state.data.slice(0, postIndex),
					{
						...state.data[postIndex],
						comments: [
							...state.data[postIndex].comments.slice(0, commentIndex),
							{
								...state.data[postIndex].comments[commentIndex],
								like_count: state.data[postIndex].comments[commentIndex].like_count + 1,
								post_actions: state.data[postIndex].comments[commentIndex].post_actions.concat([
									action.data,
								]),
							},
							...state.data[postIndex].comments.slice(commentIndex + 1),
						],
					},
					...state.data.slice(postIndex + 1),
				],
				pagination: state.pagination,
			};
		case 'DELETE_POST_COMMENT_BY_ID':
			postIndex = state.data.findIndex((item) => item.id === action.postId);
			var commentData = state.data[postIndex].comments.filter((item) => item.id !== action.commentId);
			return {
				data: [
					...state.data.slice(0, postIndex),
					{
						...state.data[postIndex],
						comments: commentData,
					},
					...state.data.slice(postIndex + 1),
				],
				pagination: state.pagination,
			};
		case 'DELETE_POST_DATA':
			return false;
		default:
			return state;
	}
};

const initialPostLoadState = {
	isFetchingPosts: false,
	hasMorePosts: true,
	nextPagePosts: 1,
};
Reducers.postLoadState = function postLoadState(state = initialPostLoadState, action) {
	switch (action.type) {
		case 'POST_IS_FETCHING':
			return {
				...state,
				isFetchingPosts: action.data,
			};
		case 'LOAD_MORE_POST_STATE':
			return {
				isFetchingPosts: action.data.isFetchingPosts,
				hasMorePosts: action.data.hasMorePosts,
				nextPagePosts: action.data.nextPagePosts,
			};
		case 'RESET_LOAD_MORE_STATE':
			return {
				isFetchingPosts: false,
				hasMorePosts: true,
				nextPagePosts: 1,
			};
		default:
			return state;
	}
};

Reducers.postDetailData = function postDetailData(state = false, action) {
	switch (action.type) {
		case 'RECEIVE_POST_DETAIL_DATA':
			return Object.assign({}, state, action.data);
		case 'DELETE_POST_DETAIL_BY_ID':
			return false;
		case 'LIKE_POST_DETAIL_BY_ID':
			return {
				...state,
				like_count: state.like_count + 1,
				post_actions: state.post_actions.concat([action.data]),
			};
		case 'ADD_NEW_POST_DETAIL_COMMENT':
			return {
				...state,
				comment_count: state.comment_count + 1,
				comments: state.comments.concat([action.data]),
			};
		case 'LIKE_POST_DETAIL_COMMENT_BY_ID':
			var commentIndex = state.comments.findIndex((item) => item.id === action.commentId);
			return {
				...state,
				comments: [
					...state.comments.slice(0, commentIndex),
					{
						...state.comments[commentIndex],
						like_count: state.comments[commentIndex].like_count + 1,
						post_actions: state.comments[commentIndex].post_actions.concat([action.data]),
					},
					...state.comments.slice(commentIndex + 1),
				],
			};
		case 'DELETE_POST_DETAIL_COMMENT_BY_ID':
			var commentData = state.comments.filter((item) => item.id !== action.commentId);
			return {
				...state,
				comments: commentData,
			};
		case 'DELETE_POST_DETAIL_DATA':
			return false;
		default:
			return state;
	}
};

const initialPaymentData = {
	modal: false,
	entity_id: false,
	entity_name: false,
	entity_type: false,
	entity_amount: 0,
	loading: false,
	payment_order: null,
	payment_status: null,
	payment_id: null,
};
Reducers.paymentData = function paymentData(state = initialPaymentData, action) {
	switch (action.type) {
		case types.ACTIVE_PAYMENT_MODAL:
			return Object.assign({}, state, action.data);
		case types.PAYMENT_URL_LOADING:
			return { ...state, loading: true };
		case types.ADD_PAYMENT_RESPONSE:
			return {
				...state,
				loading: false,
				payment_order: action.data,
				modal: true,
			};
		case types.CONFIRM_PAYMENT:
			return {
				...state,
				payment_status: action.data.status,
				payment_id: action.data.id,
			};
		case types.CLOSE_PAYMENT_MODAL:
			return { ...state, modal: false };
		case types.RESET_PAYMENT_MODAL:
			return initialPaymentData;
		default:
			return state;
	}
};

// CREATING NEW REDUCER FOR POST --- END
Reducers.quizDetail = function quizDetail(state = false, action) {
	switch (action.type) {
		case 'FETCH_QUIZ_DATA':
			return action.data;
		case 'MERGE_QUESTION_IN_QUIZ':
			let questions = state.quiz_questions.map((item) =>
				item.sequence === action.data.sequence ? action.data : item
			);
			return { ...state, quiz_questions: questions };
		case 'MERGE_MULTIPLE_QUESTIONS':
			let ques1 = action.data[0],
				ques2 = action.data[1];
			questions = state.quiz_questions.map((item) => {
				if (item.sequence === ques1.sequence) return ques1;
				else if (item.sequence === ques2.sequence) return ques2;
				else return item;
			});
			return { ...state, quiz_questions: questions };
		case 'DELETE_QUESTION_FROM_QUIZ':
			questions = state.quiz_questions.filter((item) => item.id !== action.item_id);
			return { ...state, quiz_questions: questions };
		case 'ADD_QUESTION_TO_QUIZ':
			questions = state.quiz_questions;
			questions.push(action.data);
			return { ...state, quiz_questions: questions };
		case 'UPDATE_QUIZ_ANSWER':
			questions = state.quiz.quiz_questions.map((item) => (item.id === action.data.id ? action.data : item));
			return { ...state, quiz: { ...state.quiz, quiz_questions: questions } };
		case 'DELETE_QUIZ_DETAIL':
			return false;

			
		default:
			return state;
	}
};

// CREATING NEW REDUCER FOR POST --- END
Reducers.quizBankDetail = function quizBankDetail(state = false, action) {
	switch (action.type) {
		case 'FETCH_QUIZ_DATA':
			return action.data;
		case 'MERGE_QUESTION_IN_QUIZ_BANK':
			let questions = state.question_bank_questions.map((item) =>
				item.sequence === action.data.sequence ? action.data : item
			);
			return { ...state, question_bank_questions: questions };
		case 'MERGE_MULTIPLE_QUESTIONS':
			let ques1 = action.data[0],
				ques2 = action.data[1];
			questions = state.question_bank_questions.map((item) => {
				if (item.sequence === ques1.sequence) return ques1;
				else if (item.sequence === ques2.sequence) return ques2;
				else return item;
			});
			return { ...state, question_bank_questions: questions };
		case 'DELETE_QUESTION_FROM_QUIZ_BANK':
			questions = state.question_bank_questions.filter((item) => item.id !== action.item_id);
			return { ...state, question_bank_questions: questions };
		case 'ADD_QUESTION_TO_QUIZ_BANK':
			questions = state.question_bank_questions;
			questions.push(action.data);
			return { ...state, question_bank_questions: questions };
		case 'UPDATE_QUIZ_BANK_ANSWER':
			questions = state.question_bank.question_bank_questions.map((item) => (item.id === action.data.id ? action.data : item));
			return { ...state, question_bank: { ...state.question_bank, question_bank_questions: questions } };
		case 'DELETE_QUIZ_BANK_DETAIL':
			return false;
		default:
			return state;
	}
};

// PROJECT CREATION REDUCER STARTS
Reducers.projectCreate = function projectCreate(state = false, action) {
	switch (action.type) {
		case 'FETCH_PROJECT_CREATION_DATA':
			return action.data;
		case 'UPDATE_PROJECT_CREATION_DATA':
			return action.data;
		case 'ADD_ACTIVITY_IN_PROJECT':
			return { ...state, activities: state.activities.concat([action.data]) };
		case 'UPDATE_ACTIVITY_IN_PROJECT':
			return {
				...state,
				activities: state.activities.map((item) => (item.id === action.data.id ? action.data : item)).sort((a,b)=> a.seq -b.seq)
			};
		case 'DELETE_ACTIVITY_IN_PROJECT':
			return { ...state, activities: action.data };
		case 'DELETE_PROJECT_CREATION_DATA':
			return false;
		default:
			return state;
	}
};
// PROJECT CREATION REDUCER ENDS

// MY ASSIGNMENT  REDUCER
Reducers.myAssignment = function myAssignment(state = false, action) {
	switch (action.type) {
		case 'FETCH_MY_ASSIGNMENT':
			return action.data;
		case 'UPDATE_ASSIGNMENT_SUBMISSION':
			return { ...state, status: 'Submitted', submissions: [action.data] };
		default:
			return state;
	}
};
// MY ASSIGNMENT  REDUCER

// RATING REDUCER
Reducers.ratingIntervention = function ratingIntervention(state = false, action) {
	switch (action.type) {
		case 'FETCH_RATING_INTERVENTION':
			return action.data;
		case 'UPDATE_RATING_INTERVENTION':
			let index = state.responses.findIndex((item) => item.profile_id === action.data.profile_id);
			return {
				...state,
				my_feedback: { ...action.data },
				responses: [
					...state.responses.slice(0, index),
					{ ...action.data },
					...state.responses.slice(index + 1),
				],
			};
		default:
			return state;
	}
};
// RATING REDUCER

const initialEmailAvailability = {
	msg: '',
};

Reducers.emailAvailability = function emailAvailability(state = initialEmailAvailability, action) {
	switch (action.type) {
		case types.FETCH_EMAIL_AVAILABILITY:
			return { ...state, msg: action.msg };
		default:
			return state;
	}
};

const initialPhoneAvailability = {
	msg: '',
};

Reducers.phoneAvailability = function phoneAvailability(state = initialPhoneAvailability, action) {
	switch (action.type) {
		case types.FETCH_PHONE_AVAILABILITY:
			return { ...state, msg: action.msg };
		default:
			return state;
	}
};

const intitialInstaPost = {
	instaData: null,
	maxPage: 0,
};

Reducers.instaReducer = function instaReducer(state = intitialInstaPost, action) {
	switch (action.type) {
		case 'INSTA_POST':
			return {
				...state,
				instaData: action.data.data,
				maxPage: action.data.pagination_map.max_page,
			};

		case 'INSTA_DATA_MORE':
			return {
				...state,
				instaData: [...state.instaData, ...action.data.data],
			};

		default:
			return state;
	}
};
const dataValue = {
	data: null,
};
Reducers.storeCurrentPath = function storeCurrentPath(state = dataValue, action) {
	switch (action.type) {
		case 'CURRENT_PATH':
			return action.data;
			break;
		default:
			return state;
	}
};

Reducers.getSearchProps = function getSearchProps(state = dataValue, action) {
	switch (action.type) {
		case 'SEARCH_PROPS':
			return action.data;
			break;
		default:
			return state;
	}
};

Reducers.getCoAuthor = function getCoAuthor(state = dataValue, action) {
	switch (action.type) {
		case 'CO_AUTHOR':
			return action.data;
			break;
		default:
			return state;
	}
};

Reducers.adminReducers = function adminReducers(state = dataValue, action) {
	switch (action.type) {
		case 'CREATE_SUBSCRIPTION':
			return action.data;
		case 'CREATE_ADMIN_ACCESS':
			return action.data;
		case 'CREATE_INTERVENTION_ACCESS':
			return action.data;

		case 'UPDATE_INTERVENTION_AUTHOR':
			return action.data;
		default:
			return state;
	}
};

Reducers.getBatchScreen = function getCoAuthor(state = dataValue, action) {
  switch (action.type) {
    case "BATCH_SCREEN":
      return action.data;
      break;
    default:
      return state;
  }
};

Reducers.accountActionUserUpdate = function accountActionUserUpdate(state = false, action) {
  switch(action.type) {
    case 'USER_UPDATION':
      return true;
    case 'DELETE_USER_UPDATION':
      return false;
    default:
      return state;
  }
}

export default Reducers;
