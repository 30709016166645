import * as types from "../constants/ActionTypes";
import {
  constructNotificationsUrl,
  constructTaggedNotificationsUrl
} from "../utils/AuthUtils";
import { httpGet } from "../utils/CommonUtils";
import { errorHandler } from "./errorHandler";
import axios from "axios";

export function fetchTaggedNotifications(type) {
  return dispatch => {
    dispatch(requestTaggedNotifications());
    return httpGet(constructTaggedNotificationsUrl(type))
      .then(data => {
        dispatch(receiveTaggedNotifications(data.data.data));
      })
      .catch(error => {
        errorHandler(error, dispatch);
      });
  };
}

export function fetchNotifications() {
  return dispatch => {
    dispatch(requestNotifications());
    return httpGet(constructNotificationsUrl())
      .then(data => {
        dispatch(receiveNotifications(data.data.data));
      })
      .catch(error => {
        errorHandler(error, dispatch);
      });
  };
}

export function fetchMoreNotifications(type) {
  if (type === "act-post-tagged") {
    return (dispatch, getState) => {
      const state = getState();
      const nextPageNotification = state.ntfMaster.nextPageTaggedNotification;
      let url =
        constructNotificationsUrl(type) + `&page=${nextPageNotification}`;
      dispatch(requestMoreTaggedNotifications());
      return httpGet(url)
        .then(data => {
          dispatch(receiveMoreTaggedNotifications(data.data.data));
        })
        .catch(error => {
          errorHandler(error, dispatch);
        });
    };
  } else {
    return (dispatch, getState) => {
      const state = getState();
      const nextPageNotification = state.ntfMaster.nextPageNotification;
      let url = constructNotificationsUrl() + `?page=${nextPageNotification}`;
      dispatch(requestMoreNotifications());
      return httpGet(url)
        .then(data => {
          dispatch(receiveMoreNotifications(data.data.data));
        })
        .catch(error => {
          errorHandler(error, dispatch);
        });
    };
  }
}

export function receiveNotifications(notifications) {
  return {
    type: types.RECEIVE_NOTIFICATIONS,
    notifications
  };
}

export function receiveMoreNotifications(notifications) {
  return {
    type: types.RECEIVE_MORE_NOTIFICATIONS,
    notifications
  };
}

export function requestTaggedNotifications() {
  return {
    type: types.REQUEST_TAGGED_NOTIFICATIONS
  };
}
export function requestMoreTaggedNotifications() {
  return {
    type: types.REQUEST_MORE_TAGGED_NOTIFICATIONS
  };
}
export function receiveTaggedNotifications(tagged_notifications) {
  return {
    type: types.RECEIVE_TAGGED_NOTIFICATIONS,
    tagged_notifications
  };
}

export function receiveMoreTaggedNotifications(tagged_notifications) {
  return {
    type: types.RECEIVE_MORE_TAGGED_NOTIFICATIONS,
    tagged_notifications
  };
}

export function requestNotifications() {
  return {
    type: types.REQUEST_NOTIFICATIONS
  };
}
export function requestMoreNotifications() {
  return {
    type: types.REQUEST_MORE_NOTIFICATIONS
  };
}

export function fetchPhoneAvailability(url, field) {
  return dispatch => {
    axios
      .get(url, {
        headers: { Authorization: localStorage.access_token }
      })
      .then(response => {
        dispatch({ type: types.FETCH_PHONE_AVAILABILITY, msg: "" });
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_PHONE_AVAILABILITY,
          msg: `${field} already in use`
        });
      });
  };
}

export function fetchEmailAvailability(url, field) {
  return dispatch => {
    axios
      .get(url, {
        headers: { Authorization: localStorage.access_token }
      })
      .then(response => {
        dispatch({ type: types.FETCH_EMAIL_AVAILABILITY, msg: "" });
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_EMAIL_AVAILABILITY,
          msg: `${field} already in use`
        });
      });
  };
}
