import React from "react"
import { navigateTo } from '../../../../../src/actions/navigator';


const TermsofUse=props=>{

    const navigate=()=> {
        const { dispatch } = props;
        dispatch(navigateTo({ path: ['Guidelines'] }))
    }
    
    return (
        <div className="col-12 copyrightParentDiv">
            <div className="container-fluid bannerImageCopyright" style={{height: "80px"}}>
                <h2 className="copyrightText"><strong>TERMS OF USE</strong></h2>
            </div>
            <div className="offset-1 col-10 copyrightContentDiv" style={{ fontSize: '14px' }}>
                <h5><strong>1. General Overview </strong></h5>
                <p>1.1 This Platform for usage on your electronic device has been provided to you by <strong>HED Experts Private Limited</strong>, an Indian limited liability company incorporated under the Companies Act, 2013, and having its registered offices at <strong>#11, Commissariat Road, Ashok Nagar, Bengaluru - 560025</strong> (“<strong>Company</strong>”). Throughout the Platform, the terms “we”, “us” and “our” refer to the Company. This Terms of Use is applicable to all persons who access, use, purchase any product or services from the Company or its subsidiaries or affiliates or its brands such as Xcelerator Ninja, Xcelerator (collectively, the “<strong>Services</strong>”) through our mobile applications, website, mobile sites and other online channels and through other interactions and communications with the Company or its agents or affiliates (collectively, the “<strong>Platform</strong>”). This Terms of Use is applicable to all users of the Services from any Platform. </p>
                <p>1.2 The Terms of Use, Privacy Policy, Community Guidelines, Branding Guidelines, Copyright Policy, Law Enforcement Data Request Guidelines and any other applicable polices of the Company (the “<strong>Policies</strong>”), as updated from time to time, govern your access and use of our Platform and Services.</p>
                <p>1.3 This Terms of Use is a legally binding contract between you and the Company regarding the terms of access and use of our Platform and Services. You may request a .pdf copy of this Terms of Use by emailing us at <a href="mailto:sensei@xceler.ninja">sensei@xceler.ninja</a>.</p>
                <p>1.4 Please read the Terms of Use carefully. By downloading, installing, or otherwise accessing or using our Platform or Services (whether in whole or in part), you agree that you have read, understood, and agree to be bound by the Terms of Use. By agreeing to these Terms of Use, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use our Services. If you do not agree with the Terms of Use or any other Policies, please do not access or use any of the Platforms or Services.</p>
                <p>1.5 Upon your consenting to the Terms of Use and the Policies of the Company (which are incorporated by reference into this Terms of Use), the Company hereby grants you a limited, personal, worldwide, revocable, non-assignable and non-exclusive license to use our Services. This license is for the sole purpose of enabling you to use the Services in the manner expressly permitted by these Terms of Use and the Policies.</p>

                <h5><strong>2. Access to the Services</strong></h5>
                <p>2.1 It is your responsibility to ensure your equipment (computer, laptop, netbook, tablet or other mobile device) meets all the necessary technical specifications and is compatible with our Services to enable your access and use of the Services.</p>
                <p>2.2 You must ensure that the details provided by you are accurate and treat such information as confidential. Based on the information provided by you to us, you may be provided with a login identification (such as a username and password or any other identifier). You are solely responsible for all activities that occur under your login identification and must notify us immediately of any unauthorised use or other security breach relating to your login identification.</p>
                <p>2.3 We reserve the right to block, disable or delete any login identification, at any time, if in our opinion, you have failed to comply with any of the provisions of the Terms of Use or any other Policies, or if any details you provide for the purposes of registering as a user of the Services are, in our view, incorrect, false or a misrepresentation.</p>
                <p>2.4 You consent to any collection, use or disclosure of personal information or personally identifiable data required to provide you with access to the Services or to deliver the Services to you, as per the Privacy Policy of the Company.</p>
                <p>2.5 You agree that we may collect and use technical data and related information, including but not limited to technical information about your device, system and application software, and peripherals, that is gathered periodically, to facilitate the provision of software updates, product support, and other services to you (if any). We may use this information as per the Privacy Policy of the Company, including to improve our products or services or technologies.</p>
                <p>2.6 The operation of our Services includes periodic delivery to you of employment related updates, career opportunities or other information that may be of interest to you based on your stated preferences or your location.</p>
                <p>2.7 Any new features, tools, products or services which are added to the Services shall also be subject to the Terms of Use. You can review the most current version of the Terms of Use at any time on this page. We reserve the right to update, change or replace any part of these Terms of Use by posting updates and/or changes to the Services. It is your responsibility to check the Services periodically for changes. Your continued use of or access to the Services following the posting of any changes constitutes your acceptance of those changes.</p>
                <p>2.8 You agree that we may use our Platform, emails and messages to provide you with important notices. If the contact information you provide is not up to date, you may miss out on these notices. You agree that we may provide notices to you in the following ways: (1) a banner notice on the Platform and/ or the Services, or (2) an email sent to an address you have provided, or (3) through other means including mobile number. You agree to keep your contact information up to date.</p>

                <h5><strong>3. Usage of Services</strong></h5>
                <p>3.1 Any and all content displayed on, or transmitted via, the Services provided to you is on a ‘as is’ basis and your access to and use of the Services and any content therein is at your own risk. You may access the content solely for your information and personal use. You shall not copy, reproduce, distribute, transmit, broadcast, display, sell, license or otherwise exploit any content on any Platform for any other purposes.</p>
                <p>3.2 You shall not reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Services, any updates, or any part thereof.</p>
                <p>3.3 You understand that when using the Services, you will be exposed to content from a variety of sources, and that the Company is not responsible for the accuracy, usefulness, safety or intellectual property rights of, or relating to, such content on the Platform. The Company may, but is not obligated to, monitor or control the content posted on the Platform and/ or via the Services. Any use or reliance on any content posted on the Platform and/ or via the Services or obtained by you through in any other manner is at your own risk. You further understand and acknowledge that you may be exposed to content which you may deem inaccurate, offensive, or objectionable; and you agree to waive and hereby do waive, any legal or equitable rights or remedies you have or may have against the Company with respect thereto. To the extent permitted by applicable law, you agree to indemnify and hold harmless the Company, its owners, officers, directors and employees to the fullest extent regarding all matters relating to your use of the Services and any content posted by you by access to, or use of the Platform and/ or the Services.</p>
                <p>3.4 Usage of our Services should not replace your good judgment and common sense. In any of any concerns and queries, please immediately contact us at <a href="mailto:sensei@xceler.ninja">sensei@xceler.ninja</a>, along with the necessary details. Additionally, please read and comply with all terms and conditions that accompany these Services.</p>
                <p>3.5 We reserve the right to limit our Services to any person, geographic region or jurisdiction. We may also exercise this right on a case-by-case basis. We reserve the right to limit the quantity of any Services that we offer. All descriptions of the Services or their pricing are subject to change at any time without notice, at our sole discretion. We reserve the right to modify, discontinue, suspend or cancel any Service at any time.</p>
                <p>3.6 The minimum age for a user to be registered for the Services is the age of contractual consent under applicable law or 18 years, whichever is lower. We do not knowingly collect any information from any person under the age of 18. If you are below the age of 18, please contact us at <a href="mailto:sensei@xceler.ninja">sensei@xceler.ninja</a>. If you are aware of a user under the age of 18 using our Services, please contact us at <a href="mailto:sensei@xceler.ninja">sensei@xceler.ninja</a>.</p>
                <p>3.7 We do not warrant that the quality of any products, services, information, or other material purchased, accessed, obtained or used by you in relation to the Platform or Services will meet your expectations, or that any issues or errors in the Services will be corrected, or corrected to your satisfaction.</p>
                <p>3.8 In the event of a loss, theft or malfunctioning of your device, you are required to promptly inform us of the same, in order for us to disable the Services accessible through such device. You understand and accept that the reporting of such an event is solely your responsibility, contingent to which, we may enable you to access our Services as was previously available to you with the same terms and conditions as was previously applicable. However, we do not warrant the replacement or refund of our Services, on account of the loss, theft, malfunctioning etc. of your device, through which our Services were previously being accessed by you. </p>

                <h5><strong>4. Payment for Services</strong></h5>
                <p>4.1 You'll honour your payment obligations and you are okay with us storing your payment information. You acknowledge and accept that we do not guarantee refunds.</p>
                <p>4.2 If you purchase any of our paid Services, you agree to pay us the applicable fees and taxes. Failure to pay these fees may result in the termination of your subscription. Further, your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).</p>
                <p>4.3 You authorize us to store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your service (e.g. subscriptions) and to facilitate easy payment for new services.</p>
                <p>4.4 The pricing for the Services, wherever applicable, is subject to change without notice.</p>
                <p>4.5 You must pay us for applicable fees and taxes unless you cancel the paid Services, in which case you agree to still pay these fees through the end of the applicable subscription period. For information related to generation of invoice, refund policy, cancelling or changing of your paid Services, kindly contact our customer support.</p>

                <h5><strong>5. Cancellation / Refund Policy</strong></h5>
                <p>5.1 You acknowledge and accept that we do not offer refunds on purchases made for services purchased through our platform.</p>
                <p>5.2 You acknowledge and accept that your purchase of services offered through our platform is non-transferrable.</p>

                <h5><strong>6. Intellectual Property</strong></h5>
                <p>6.1 All rights, title and interest in and to the Platform and Services are and will remain the exclusive property of the Company. The Services are protected by copyright, trademark and other Indian laws. Nothing in these Terms of Use or any Policies gives you a right to use the name or any of the trademarks, logos, domain names, distinctive brand features or any other intellectual property rights of the Company for any purpose whatsoever. All the data and information arising out of, and in relation to the access and use of the Platform and the Services shall be the property of the Company; and the Company shall be vested with all rights, title and interests thereto. The Company shall have the right to use such data and information in the manner it deems fit, subject however, to the Privacy Policy.</p>
                <p>6.2 You own all of the content, feedback, and personal information you provide to us, but you also grant us a non-exclusive license to it. While we may edit and make formatting changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression. We take no responsibility and assume no liability for any such information posted by you or any third-party.</p>
                <p>6.3 You agree to only provide content or information that does not violate the law nor anyone's rights (e.g., without violating any intellectual property rights or breaching a contract). You also agree that your profile information will be truthful.  The Company may be required by law to remove certain information or content.</p>
                <p>6.4 You acknowledge and accept that your usage our Services is limited solely to your personal and non-commercial use. The usage of our Services does not implicitly or expressly transfer any ownership interest to you in the content, and specifically excludes, without limitation, any commercial or promotional use rights in such content. Furthermore, you are prohibited from republishing, retransmitting and reproducing any images accessed through our Services for any other use or purpose without our prior written consent.</p>

                <h5><strong>7. Prohibited Uses</strong></h5>
                <p>7.1 In addition to other restrictions and prohibitions as set forth in the Terms of Use and any restrictions and prohibitions under applicable laws, you are prohibited from using the Service for:</p>
                <ul type="none">
                    <li>(a) any unlawful purpose;</li>
                    <li>(b) soliciting others to perform or participate in any unlawful acts;</li>
                    <li>(c) violating any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
                    <li>(d) infringing upon or violate our intellectual property rights including, without limitation, using the word “HED-X”, “Xceler” (and its derivatives such as “Xcelerator”, “Xceleron”) or our logos in any business name, email, or URL except as provided in the Brand Guidelines; or the intellectual property rights of others;</li>
                    <li>(e) harassing, abusing, insulting, harming, defaming, slandering, disparaging, intimidating, or discriminating any person (irrespective of whether or not such a person is a user of the Services or otherwise) based on gender, sexual orientation, religion, caste, ethnicity, race, age, national origin, disability, or on any other account;</li>
                    <li>(f) submitting any false or misleading information including but not limited to creating a false identity, misrepresenting your current or previous positions and qualifications etc;</li>
                    <li>(g) uploading or transmitting viruses or any other type of malicious data or any code that will or may be used in any way that will affect the functionality or operation of the Service or of any Platform or any other related websites or applications;</li>
                    <li>(h) collecting or tracking the personal information of others;</li>
                    <li>(i) any spamming, phishing, web crawling or spidering or scraping;</li>
                    <li>(j) any obscene or immoral purpose; or</li>
                    <li>(k) interfering with or circumventing the security features of the Service or any related applications.</li>
                    <li>(l) Deep-link to our Services for any purpose other than to promote your profile or a group on our Services, without the Company’s consent;</li>
                    <li>(m) Violate the Company’s Policies or the <span onClick={()=>navigate()} className="termsOfUseLink">Community Guidelines.</span></li>
                </ul>
                <p>7.2 We reserve the right to discontinue, suspend or terminate your use of the Services for violating any of the prohibited uses or restricted uses.</p>

                <h5><strong>8. Disclaimer of Warranties; Limitation of Liability</strong></h5>
                <p>8.1 We do not guarantee, represent or warrant in any manner that your use of our Services will be uninterrupted, timely, secure or error-free.</p>
                <p>8.2 We do not warrant in any manner that the results that may be obtained from the use of the Services will be accurate or reliable or effective in nature. You understand and accept that the reliance on the content obtained from the Services or the output from the Services is solely at your own judgement and risk, and you shall not hold the Company liable for the same in any manner whatsoever.</p>
                <p>8.3 You agree that, from time to time, we may suspend, modify, restrict or remove our Services for uncertain or indefinite periods of time or cancel the Services at any time, without notice to you.</p>
                <p>8.4 You expressly agree that your use of, or inability to use, the Services is at your sole risk. The Services delivered to you are (except as expressly stated by us) provided on an 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                <p>8.5 You acknowledge that third-party links on our Platform or Services may direct you to third-party sites or pages or applications that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy of such sites or pages or applications, and we do not warrant and will not have any liability or responsibility for any third-party materials or websites or pages or applications, or for any other data, information, products or services of any third-parties.</p>
                <p>8.6 We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools. Any use by you of optional tools offered through the Platform is entirely at your own risk and discretion; and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</p>
                <p>8.7 In no case shall the Company, our subsidiaries or affiliates, directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Services or any products or services procured using the Services, or for any other claim related in any manner to your use of the Services, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Services or any content (or product) posted, transmitted, or otherwise made available via the Services or on the Platform, even if advised of their possibility.</p>
                <p>8.8 No reseller, agent, or employee of the Company is authorized to make any modification, extension, or addition to the warranty extended by the Company to you with respect to any of our products or Services.</p>

                <h5><strong>9. Indemnification</strong></h5>
                <p>9.1 You agree to indemnify, defend and hold harmless the Company and our subsidiaries and affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, from any losses, injuries, claims, demands, proceedings, penalties, interests, costs and expenses, including attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Use or the Policies of the Company or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                <p>9.2 The indemnification rights of the Company shall be in addition to any rights that may be available to the Company under applicable law (including injunctions and specific performance reliefs), none of which shall be affected in any manner whatsoever.</p>

                <h5><strong>10. Severability</strong></h5>
                <p>10.1 In the event that any provision of these Terms of Use is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Use. However, such determination shall not affect the validity and enforceability of any other remaining provisions of these Terms of Use.</p>

                <h5><strong>11. Termination</strong></h5>
                <p>11.1 Your obligations and liabilities incurred prior to the termination date shall survive the termination of this Terms of Use for all purposes.</p>
                <p>11.2 These Terms of Use are effective unless and until terminated by either you or us. You may terminate these Terms of Use at any time by notifying us that you no longer wish to use our Services, or when you cease using our Services.</p>
                <p>11.3 If, in our sole judgement, you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Use or/and the Policies, we may terminate this Terms of Use at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or we, accordingly, may deny you access to our Services (or any part thereof).</p>

                <h5><strong>12. Entire agreement</strong></h5>
                <p>12.1 The failure of us to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision.</p>
                <p>12.2 These Terms of Use and any Policies and any other policies or operating rules posted by us on our Platform or in respect of the use of the Services constitutes the entire agreement and understanding between you and us and governs your use of Platform and/ or our Services. This will supersede any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use).</p>
                <p>12.3 Any ambiguities in the interpretation of these Terms of Use and the Policies shall not be construed against the drafting party.</p>

                <h5><strong>13. Governing law</strong></h5>
                <p>13.1 These Terms of Use and the Policies which govern the use of the Platform and the Services shall be governed by and construed in accordance with the laws of India. Any disputes or claims arising in relation to the Terms of Use and the Policies and any of the Services shall be subject to the exclusive jurisdiction of the courts in Bangalore, Karnataka, India.</p>

                <h5><strong>14. Contact Information</strong></h5>
                <p>14.1 Questions about the Terms of Use should be sent to us via email at <a href="mailto:sensei@xceler.ninja">sensei@xceler.ninja</a> or by speed Post at the below mentioned address.</p>
                <p>#11, Commissariat Road,<br />
                    Opp. Ashok Nagar Police Station,<br />
                    Bengaluru - 560025</p>
            </div>
            
        </div>
    )
}

export default TermsofUse