// FIREBASE CONFIG
import firebase from "firebase/app";
import "firebase/auth";
const config = process.env.REACT_APP_STAGE === 'production' ? {
	apiKey: "AIzaSyBGHBVeJh4p7lMbhQocL_Q6TF5Q29irbq4",
	authDomain: "xcelerator-ninja.firebaseapp.com",
	databaseURL: "https://xcelerator-ninja.firebaseio.com",
	projectId: "xcelerator-ninja",
	storageBucket: "xcelerator-ninja.appspot.com",
	messagingSenderId: "538252590079",
	appId: "1:538252590079:web:62f68e4ebfc2ac5a7c85ca"
} : {
 apiKey: 'AIzaSyASzvEhTdht6tNT7H03H1TeTEZDgA1j5yc',
 authDomain: 'xceleratorqa.firebaseapp.com',
 databaseURL: 'https://xceleratorqa.firebaseio.com',
 projectId: 'xceleratorqa',
 storageBucket: 'xceleratorqa.appspot.com',
 messagingSenderId: '1018128376473',
 appId: '1:1018128376473:web:18d18f24f1c25ef70ece08'
};

firebase.initializeApp(config);
export default firebase;