const getUrl = () => {
  if (process.env.REACT_APP_STAGE === "testing") return "https://api.xn.de";
  else if (process.env.REACT_APP_STAGE === "production")
    return "https://api.xcelerator.ninja";
  else if (process.env.REACT_APP_STAGE === "dev")
    return "https://api-dev.xcelerator.ninja";
  else if (process.env.REACT_APP_STAGE === "production_testing")
    return "http://localhost_api";
  else return "http://localhost:4000";
};

export const base_url = getUrl();
