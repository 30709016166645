import React, { useState, useEffect, useRef } from 'react';
import ModalComponent from '../../../components/commonComponent/modalComponent';
import './style.css'
import { emailAddressValidator, validateString, internationalPhoneNumberMobileValidator } from '../../../validations/validate';
import firebase from '../../../../../firebase';
import Actions from '../../../../../redux/actions';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import Async from 'react-select/async';
import { constructLookupSubTypeSearchUrl } from '../../../../../utils/CommonUtils';
import axios from 'axios';

const customStyles = {
	menu: (provided) => ({
	  ...provided,
	  width: 380,
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: 12
	})
}  

const RenderTooltip = props => (
	<OverlayTrigger placement='right'
		overlay={<Tooltip placement="right" className="loginTooltip">
			<span className="font12">{props.text}</span>
		</Tooltip>}>
		<i className="fas fa-exclamation-circle toggleShowIcon" style={{ cursor: 'pointer', color: '#e03202', ...props.customIconStyle}}></i>
	</OverlayTrigger>
)

const LoginPopup = props => {
	const { dispatch, environment } = props;
	const [email, setEmail] = useState(''); // User's email address - input by user or fetched from firebase for social auth
	const [password, setPassword] = useState(''); // Password input by the user
	const [showPassword, setShowPassword] = useState(false); // Display password entered by user
	const [phoneAuth, setPhoneAuth] = useState(false); // Display password entered by user
	const [phoneNumber, setPhoneNumber] = useState(''); // Display password entered by user
	const [countryCode, setCountryCode] = useState({name: 'India (+91)', desc: '+91'});
	const [selectCodeActive, setSelectCodeActive] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState(''); // Re enter password during account creation
	const [forgotPassword, setForgotPassword] = useState(false); // Display password reset popup when this is true
	const [confirmationResult, setConfirmationResult] = useState(null);
	const [verificationCodeCondition, setVerificationCodeCondition] = useState(false);
	const [inputOTP, setInputOTP] = useState(['','','','','','']);
	const [verifiedOTPLength, setVerifiedOTPLength] = useState(false);
	const [recoveryLinkSuccess, setRecoveryLinkSuccess] = useState(false); // True when password reset link has been sent to email
	const [fbLinkPassword, setFbLinkPassword] = useState(false); // Display popup to link fb account with email when this is true
	const [pendingCred, setPendingCred] = useState(null); // Fb credentials to link to email account
	const [showLoader, setShowLoader] = useState(false); // Display loader
	const [errorMsg, setErrorMsg] = useState({}); // Set and display error messages for user inputs

	const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);

	/**
	 * Validate user input for signup and login
	 * @param {string} type Signup/login
	 * 
	 * @public
	 */
	const validateForm = (type) => {
		let isValid = true, isFieldValid, errors = {};
		isFieldValid = emailAddressValidator(email);
		isValid = isValid && isFieldValid;
		errors.email = isFieldValid ? '' : 'Enter a valid email address.'

		isFieldValid = validateString(password);
		isValid = isValid && isFieldValid;
		errors.password = isFieldValid ? '' : 'Please enter your password.'

		if (type === 'signup') {
			isFieldValid = validateString(password) && password.length >= 8;
			isValid = isValid && isFieldValid;
			errors.password = isFieldValid ? '' : 'Please enter a password with atleast 8 characters.'

			isFieldValid = confirmPassword === password;
			isValid = isValid && isFieldValid;
			errors.confirmPassword = isFieldValid ? '' : 'Your password and confirmation password do not match.'
		}
		setErrorMsg(errors);
		return isValid
	}

	const validatePhoneForm = () => {
		let isValid = true, isFieldValid, errors = {};
		isFieldValid = internationalPhoneNumberMobileValidator(phoneNumber);
		isValid = isValid && isFieldValid;
		errors.phoneNumber = isFieldValid ? '' : 'Enter a valid phone number.'

		setErrorMsg(errors);
		return isValid
	}

	/**
	 * Validate user input and login the user to their account through firebase. Show relevant error messages in case login fails 
	 * @param {object} event 
	 * @public
	 */
	const handleLogin = (event) => {
		event.preventDefault();
		let isValid = validateForm('login');
		if (isValid) {
			setShowLoader(true)
			firebase.auth().signInWithEmailAndPassword(email, password)
				.then((response) => {
					localStorage.setItem('access_token', response.user.ma);
					localStorage.setItem('refresh_token', response.user.refreshToken);
					localStorage.setItem('access_expiry', Math.round(response.user.b.c / 1000));
					dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: Math.round(response.user.b.c / 1000) })
					dispatch(Actions.login(environment.isMobile))
				})
				.catch((error) => {
					let errorText = {};
					switch (error.code) {
						case 'auth/wrong-password':
							errorText.password = error.message;
							break;
						case 'auth/user-not-found':
							errorText.email = 'This username does not exist in our records. Please check your username or proceed to signup';
							break;
						case 'auth/user-disabled':
						case 'auth/invalid-email':
							errorText.email = error.message;
							break;
						default:
							break;
					}
					setErrorMsg(errorText);
					setShowLoader(false);
				});
		}
	}

	/**
	 * Validate user input and create their account on firebase. Show relevant error messages in case login fails 
	 * @param {object} event 
	 * @public
	 */
	const handleSignup = (event) => {
		event.preventDefault();
		let isValid = validateForm('signup');
		if (isValid) {
			setShowLoader(true);
			if (sessionStorage.getItem('redirectPath') !== null) {
				sessionStorage.removeItem('redirectPath')
			}
			firebase.auth()
				.createUserWithEmailAndPassword(email, password)
				.then((response) => {
					localStorage.setItem('access_token', response.user.ma);
					localStorage.setItem('refresh_token', response.user.refreshToken);
					localStorage.setItem('access_expiry', Math.round(response.user.b.c / 1000));
					dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: Math.round(response.user.b.c / 1000) })
					dispatch(Actions.login(environment.isMobile))
				})
				.catch((error) => {
					let errorText = {};
					switch (error.code) {
						case 'auth/email-already-in-use':
							errorText.email = error.message;
							break;
						case 'auth/weak-password':
							errorText.password = error.message;
							break;
						default:
							break;
					}
					setErrorMsg(errorText);
					setShowLoader(false);
				});
		}
	}

	/**
	 * Sign in to the user account with google auth. Display snackbar for errors 
	 * When user signs in through google for the first time, all other credentials are removed
	 * @param {object} linkWithCred Credentials to link with the user account after sign in 
	 * @public
	 */
	const handleGoogleSignin = (linkWithCred = false) => {
		setShowLoader(true);
		let provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().signInWithPopup(provider).then(async (response) => {
			if (linkWithCred) {
				try {
					await firebase.auth().currentUser.linkWithCredential(linkWithCred)
				} catch (error) {
					Sentry.captureException(error.toString());
				}
			}
			localStorage.setItem('access_token', response.user.ma);
			localStorage.setItem('refresh_token', response.user.refreshToken);
			localStorage.setItem('access_expiry', Math.round(response.user.b.c / 1000));
			dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: Math.round(response.user.b.c / 1000) })
			dispatch(Actions.login(environment.isMobile))
		})
			.catch(error => {
				switch (error.code) {
					case 'auth/popup-closed-by-user':
					case 'auth/popup-blocked':
						dispatch(Actions.snackBarOn(error.message, 5000))
						break;
					default:
						break;
				}
				setShowLoader(false);
			})
	}

	/**
	 * Sign in to the user account with facebook auth. Display snackbar for errors. 
	 * Handle flow for linking fb account credentials when the user has previously signed in with the same email through different providers.
	 * @param {object} linkWithCred Credentials to link with the user account after sign in 
	 * @public
	 */
	const handleFacebookSignin = () => {
		setShowLoader(true);
		var provider = new firebase.auth.FacebookAuthProvider();
		firebase.auth().signInWithPopup(provider).then(response => {
			localStorage.setItem('access_token', response.user.ma);
			localStorage.setItem('refresh_token', response.user.refreshToken);
			localStorage.setItem('access_expiry', Math.round(response.user.b.c / 1000));
			dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: Math.round(response.user.b.c / 1000) })
			dispatch(Actions.login(environment.isMobile))
		}).catch(error => {
			switch (error.code) {
				case 'auth/account-exists-with-different-credential':
					let fbCred = error.credential;
					let email = error.email;
					firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
						if (methods[0] === 'password') {
							setEmail(email)
							setFbLinkPassword(true)
							setPendingCred(fbCred)
						}
						else if (methods[0] === 'google.com') {
							handleGoogleSignin(fbCred);
						}
					});
					break;
				case 'auth/popup-closed-by-user':
				case 'auth/popup-blocked':
					dispatch(Actions.snackBarOn(error.message, 5000))
					break;
				default:
					break;
			}
			setShowLoader(false);
		});
	}

	const handleEmailChange = event => {
		setEmail(event.target.value ? event.target.value.trim() : '')
		setErrorMsg(prevData => ({ ...prevData, email: false, forgotPassword: false }))
	}

	const handlePasswordChange = event => {
		setPassword(event.target.value)
		setErrorMsg(prevData => ({ ...prevData, password: false }))
	}

	const handleConfirmPasswordChange = event => {
		setConfirmPassword(event.target.value);
		setErrorMsg(prevData => ({ ...prevData, confirmPassword: false }))
	}

	const handlePhoneNumberChange = event => {
		setPhoneNumber(event.target.value);
		setErrorMsg(prevData => ({ ...prevData, phoneNumber: false}));
	}

	/**
	 * Send reset email to the user's email address when they click on forgot password
	 * @public
	 */
	const handleForgotPassword = () => {
		var actionCodeSettings = {
			url: window.location.origin
		};
		firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
		.then(_response => {
			setRecoveryLinkSuccess(true);
		}).catch(error => {
			let errorText = {};
			switch (error.code) {
				case 'auth/invalid-email':
					errorText.forgotPassword = error.message;
					break;
				case 'auth/user-not-found':
					errorText.forgotPassword = 'This email address does not exist in our records. Please check your email address to proceed with password reset procedure';
					break;
				default:
					errorText.forgotPassword = 'Something went wrong while processing your request. Please try to reset password after some time.'
					break;
			}
			setErrorMsg(errorText)
		})
	}
	
	/**
	 * Link user's account with fb credentials when they sign in with facebook after signing in by email.
	 * @public
	 */
	const handleFbLink = () => {
		setShowLoader(true)
		firebase.auth().signInWithEmailAndPassword(email, password)
			.then(async (response) => {
				try {
					await firebase.auth().currentUser.linkWithCredential(pendingCred)
				} catch (error) {
					Sentry.captureException(error.toString());
				}
				localStorage.setItem('access_token', response.user.ma);
				localStorage.setItem('refresh_token', response.user.refreshToken);
				localStorage.setItem('access_expiry', Math.round(response.user.b.c / 1000));
				dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: Math.round(response.user.b.c / 1000) })
				dispatch(Actions.login(environment.isMobile))
			})
			.catch((error) => {
				let errorText = {};
				switch (error.code) {
					case 'auth/wrong-password':
						errorText.password = error.message;
						break;
					case 'auth/user-not-found':
						errorText.email = 'This username does not exist in our records. Please check your username or proceed to signup';
						break;
					case 'auth/user-disabled':
					case 'auth/invalid-email':
						errorText.email = error.message;
						break;
					default:
						break;
				}
				setErrorMsg(errorText);
				setShowLoader(false);
			});
	}

	const handleAuthWithPhone = () => {
		let valid = validatePhoneForm()
		if (valid) {
			setShowLoader(true)
			if (!window.applicationVerifier)
				window.applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
					size: 'invisible',
					callback: _response => {
						// reCAPTCHA solved, allow signInWithPhoneNumber.
						// onSignInSubmit();
					}
				});
			let phone = countryCode.desc + phoneNumber;
			firebase.auth().signInWithPhoneNumber(phone, window.applicationVerifier)
			.then(confirmationResult => {
				setShowLoader(false)
				setConfirmationResult(confirmationResult)
				setVerificationCodeCondition(true);
			})
			.catch(function(error) {
				let errorText = {};
				switch (error.code) {
					case 'auth/invalid-phone-number':
						errorText.phoneNumber = 'Please enter a valid phone number';
						break;
						default:
							break;
				}
				setErrorMsg(errorText);
				setShowLoader(false)
			});
		}
	}

	const confirmCodeHandler = () => {
		setShowLoader(true);
		let verificationCode = Object.values(inputOTP).join('');
		confirmationResult.confirm(verificationCode).then(response => {
			setShowLoader(false);
			localStorage.setItem('access_token', response.user.ma);
			localStorage.setItem('refresh_token', response.user.refreshToken);
			localStorage.setItem('access_expiry', Math.round(response.user.b.c / 1000));
			dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: Math.round(response.user.b.c / 1000) })
			dispatch(Actions.login(environment.isMobile))
			window.applicationVerifier = null;
		}).catch(error => {
			let errorText = {};
			switch (error.code) {
				case 'auth/invalid-verification-code':
					errorText.verificationCode = 'The OTP entered by you does not match the one sent to your phone number. Please enter the valid OTP.';
					break;
				case 'auth/missing-verification-code':
					errorText.verificationCode = error.message;
					break;
				default:
					break;
			}
			setErrorMsg(errorText);
			setShowLoader(false);
		})
	}

	const handleEnterOtp = (index, event) => {
		if (event && event.target) {
			event.persist();
		}
		if(isNaN(Number(event.target.value))){
			return true;
		}
		let currentOTP = inputOTP.concat();
        currentOTP[index] = event.target.value;
		setInputOTP(currentOTP)
		setErrorMsg(prevData => ({ ...prevData, errorMsg: { ...prevData.errorMsg, verificationCode: ''}}))
        if(event.target.value !== '') {
            if (index === 0)
                inputRef2.current.focus()
            else if (index === 1)
                inputRef3.current.focus()
            else if (index === 2)
                inputRef4.current.focus()
            else if (index === 3)
                inputRef5.current.focus()
            else if (index === 4)
                inputRef6.current.focus()
        }
	}

	const handleResendOTP = () => {
		handleAuthWithPhone();
	}

	const handleOtpKeyPress = (index, event) => {
		if (event && event.target) {
			event.persist();
		}
		if ((event.keyCode === 8 || event.keyCode === 43) && inputOTP[index] === '') {
            if (index === 1) {
                inputRef1.current.focus();
            } else if (index === 2) {
                inputRef2.current.focus();
            } else if (index === 3) {
                inputRef3.current.focus();
            } else if (index === 4) {
                inputRef4.current.focus();
            } else if (index === 5) {
                inputRef5.current.focus();
            }
            if (index > 0) {
              let currentOTP = inputOTP.concat();
              currentOTP[index] = '';
              setInputOTP(currentOTP);
            }
        } else if (event.keyCode === 39) {
			if (index < 5) {
				if (index === 0)
					inputRef2.current.focus()
				else if (index === 1)
					inputRef3.current.focus()
				else if (index === 2)
					inputRef4.current.focus()
				else if (index === 3)
					inputRef5.current.focus()
				else if (index === 4)
					inputRef6.current.focus()
			}
		} else if (event.keyCode === 37) {
			if (index > 0) {
				if (index === 1) {
					inputRef1.current.focus();
				} else if (index === 2) {
					inputRef2.current.focus();
				} else if (index === 3) {
					inputRef3.current.focus();
				} else if (index === 4) {
					inputRef4.current.focus();
				} else if (index === 5) {
					inputRef5.current.focus();
				}
			}
		}
		setVerifiedOTPLength(inputOTP.join('').length === 6)
	}

	const refCallback = textInputRef => node => {
		textInputRef.current = node;
    };
		
	/**
	 * Fetches datagroup which match the search string
	 * @param {string} input Search string to fetch data
	 *
	 * @public
	 */
	const handleCountryCodeData = async (input) => {
		if (!input)
			return Promise.resolve({
				options: []
			});
		const response = await axios
			.get(constructLookupSubTypeSearchUrl(input, 'COUNTRY_CODE'));
		return response.data.data;
	}

	const handleDropDownChange = (value) => {
		setCountryCode(value);
		setSelectCodeActive(false);
	}

	/**
	 * Return to login popup from password change or link facebook account popup.
	 * @public
	 */
	const handleReturnToLogin = () => {
		setForgotPassword(false);
		setRecoveryLinkSuccess(false);
		setPhoneAuth(false);
		setPhoneNumber('')
		setVerificationCodeCondition(false);
		setInputOTP(['','','','','','']);
		setVerifiedOTPLength(false);
		setCountryCode({name: 'India (+91)', desc: '+91'});
		setErrorMsg({});
		window.applicationVerifier = null
	}

	/**
	 * Clear data when user toggles signup/login.
	 * @public
	 */
	useEffect(() => {
		setEmail('');
		setPassword('');
		setShowPassword(false);
		setConfirmPassword('');
		setForgotPassword(false);
		setRecoveryLinkSuccess(false);
		setErrorMsg({});
	}, [props.isLogin, props.showModal])
	return <ModalComponent show={props.showModal} modalClass="LoginPopup">
			<div className={`container-fluid LoginCard ${props.showModal && !props.isLogin ? 'is-flipped' : ''} ${forgotPassword || fbLinkPassword ? 'forgotPasswordCard' : phoneAuth ? 'phoneAuthCard' : ''}`}>
				{/* Login */}
				{!forgotPassword && !fbLinkPassword && !phoneAuth ? <div className="LoginContent signIn">
					{showLoader ? <div className="loaderDiv">
						<i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
					</div> : null}
					<form onSubmit={handleLogin} className='col-12 padding0'>
						<div className='col-12 padding0' style={{ textAlign: 'right' }}>
							<i className='fa fa-times' onClick={() => props.changePopupState(!props.isLogin, false)} />
						</div>
						<div className="col-12 padding0 inputDiv col-sm-10 offset-sm-1" style={{ marginTop: 10 }}>
							<h5 className="headingText">Welcome Back, Please Login</h5>
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1'>
							<i className="fa fa-user-circle inputLogo" />
							<input type='text' className='loginInput' style={errorMsg.email ? { border: '1px solid #e03202' } : {}} placeholder="Email" onChange={(event) => handleEmailChange(event)} value={email} />
							{errorMsg.email ? <RenderTooltip text={errorMsg.email} /> : null}
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1'>
							<i className="fa fa-key inputLogo" />
							<input id='passwordField' type={showPassword ? 'text' : 'password'} style={errorMsg.password ? { border: '1px solid #e03202' } : {}} className='loginInput' placeholder="Password" onChange={(event) => handlePasswordChange(event)} value={password} autoComplete="off" />
							{errorMsg.password ? <RenderTooltip text={errorMsg.password} /> : null}
							<i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} toggleShowIcon`} title={showPassword ? 'Hide Password' : 'Show Password'} onClick={() => setShowPassword(prevState => !prevState)} />
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1 text-center'>
							<input type="submit" value="LOGIN" className='loginBtn' />
						</div>
					</form>
					<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1' style={{display: 'flex', justifyContent: 'space-between'}}>
						<button className='forgotPassword' onClick={() => setForgotPassword(true)}>Forgot Password?</button>
						<button className='forgotPassword' onClick={() => setPhoneAuth(true)}>Login with Phone Number</button>
					</div>
					<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1'>
						Are you a new user ? <button className='linkBtn' onClick={() => props.changePopupState(!props.isLogin, true)}>Sign Up</button>
					</div>
					<div className='col-12 col-sm-10 offset-sm-1 padding0 formOrSocial'>OR</div>
					<div className='col-12 col-sm-10 offset-sm-1 padding0 socialLogin text-center'>
						<div>
							<button className='fbLogin' onClick={() => handleFacebookSignin()}>
								<img className="fbIcon" alt='fb icon' src="https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/icons/facebook_logo.png" />
								<span>Continue with Facebook</span>
							</button>
						</div>
						<div>
							<button className='googleLogin' onClick={() => handleGoogleSignin()}>
								<i className="fab fa-google" />
								<span>Continue with Google</span>
							</button>
						</div>
					</div>
				</div> : null}
				{/* Login */}

				{/* Sign Up */}
				{!forgotPassword && !fbLinkPassword && !phoneAuth ? <div className="LoginContent register">
					{showLoader ? <div className="loaderDiv">
						<i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
					</div> : null}
					<form onSubmit={handleSignup} className='col-12 padding0'>
						<div className='col-12 padding0' style={{ textAlign: 'right' }}>
							<i className='fa fa-times' onClick={() => props.changePopupState(!props.isLogin, false)} />
						</div>
						<div className="col-12 padding0 inputDiv col-sm-10 offset-sm-1" style={{ marginTop: 10 }}>
							<h5 className="headingText">Create Account</h5>
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1'>
							<i className="fa fa-user-circle inputLogo" />
							<input type='text' className='loginInput' placeholder="Email" style={errorMsg.email ? { border: '1px solid #e03202' } : {}} onChange={(event) => handleEmailChange(event)} value={email} />
							{errorMsg.email ? <RenderTooltip text={errorMsg.email} /> : null}
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1'>
							<i className="fa fa-key inputLogo" />
							<input type='password' className='loginInput' placeholder="Password" style={errorMsg.password ? { border: '1px solid #e03202' } : {}} onChange={(event) => handlePasswordChange(event)} value={password} autoComplete="off" />
							{errorMsg.password ? <RenderTooltip text={errorMsg.password} /> : null}
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1'>
							<i className="fa fa-key inputLogo" />
							<input type='password' className='loginInput' placeholder="Confirm Password" style={errorMsg.confirmPassword ? { border: '1px solid #e03202' } : {}} onChange={(event) => handleConfirmPasswordChange(event)} value={confirmPassword} autoComplete="off" />
							{errorMsg.confirmPassword ? <RenderTooltip text={errorMsg.confirmPassword} /> : null}
						</div>
						<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1 text-center'>
							<input type="submit" value="SIGN UP" className='loginBtn' />
						</div>
					</form>

					<div className='col-12 padding0 inputDiv col-sm-10 offset-sm-1' style={{display: 'flex', flexDirection: 'column'}}>
						<button className='forgotPassword' onClick={() => {props.changePopupState(!props.isLogin, true); setPhoneAuth(true)}} style={{textAlign: 'left', marginBottom: 10}}>Signup with Phone Number</button>
						<span>Already have an account ? <button className='linkBtn' onClick={() => props.changePopupState(!props.isLogin, true)}>Login</button></span>
					</div>
					<div className='col-12 col-sm-10 offset-sm-1 padding0 formOrSocial'>OR</div>
					<div className='col-12 col-sm-10 offset-sm-1 padding0 socialLogin text-center'>
						<div>
							<button className='fbLogin' onClick={() => handleFacebookSignin()}>
								<img className="fbIcon" alt='fb icon' src="https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/icons/facebook_logo.png" />
								<span>Continue with Facebook</span>
							</button>
						</div>
						<div>
							<button className='googleLogin' onClick={() => handleGoogleSignin()}>
								<i className="fab fa-google" />
								<span>Continue with Google</span>
							</button>
						</div>
					</div>
				</div> : null}
				{/* Sign Up */}

				{/* Forgot Password */}
				{forgotPassword ? <div className="LoginContent forgotLoginPassword">
					<div className="mainDiv">
						{recoveryLinkSuccess ?
							<div>
								<p className="loginHeading">Forgot Password?</p>
								<p>We have sent a recovery link to your email address:</p>
								<h4>{email}</h4>
								<div className="returnLoginDiv"><span onClick={() => handleReturnToLogin()}>Return to login</span></div>
							</div>
							: <div>
								<h5 className="loginHeading">Forgot Password?</h5>
								<p className="infoText">We'll send a password recovery link to your email.</p>
								<div className="forgotInputDiv">
									<input type='text' className='emailInput' style={errorMsg.forgotPassword ? { border: '1px solid #e03202' } : {}} placeholder="Enter email" onChange={(event) => handleEmailChange(event)} value={email} />
									{errorMsg.forgotPassword ? <RenderTooltip text={errorMsg.forgotPassword} /> : null}
								</div>
								<button className="recoveryBtn" onClick={() => handleForgotPassword()}>Send recovery link</button>
								<div className="returnLoginDiv">
									<span onClick={() => handleReturnToLogin()}>Return to login</span>
								</div>
							</div>}
					</div>
				</div> : null}
				{/* Forgot Password */}

				{/* Fb Link Account */}
				{fbLinkPassword ? <div className="LoginContent forgotLoginPassword">
					{showLoader ? <div className="loaderDiv">
						<i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
					</div> : null}
					<div className="mainDiv">
						<div>
							<h4 className="loginHeading">Link your account</h4>
							<p>We noticed that you have signed up using email. Please enter your password to link your accounts</p>
							<h5>{email}</h5>
							<div className='col-12 padding0 inputDiv'>
								<i className="fa fa-key inputLogo" />
								<input id='passwordField' type={showPassword ? 'text' : 'password'} style={errorMsg.password ? { border: '1px solid #e03202' } : {}} className='loginInput' placeholder="Password" onChange={(event) => handlePasswordChange(event)} value={password} autoComplete="off" />
								{errorMsg.password ? <RenderTooltip text={errorMsg.password} /> : null}
								<i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} toggleShowIcon`} title={showPassword ? 'Hide Password' : 'Show Password'} onClick={() => setShowPassword(prevState => !prevState)} />
							</div>
							<button className="recoveryBtn" onClick={handleFbLink}>Login</button>

						</div>
					</div>
				</div> : null}
				{/* Fb Link Account */}

				{/* Phone Number Auth */}
				{phoneAuth ? <div className="LoginContent forgotLoginPassword" style={{paddingTop: 14}}>
					{showLoader ? <div className="loaderDiv">
						<i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
					</div> : null}
					<div className="mainDiv">
						<div>
							<div className='padding0' style={{ textAlign: 'right', marginBottom: 7 }}>
								<i className='fa fa-times' onClick={() => {setPhoneAuth(false); props.changePopupState(!props.isLogin, false)}} />
							</div>
							<h5 className="loginHeading">{verificationCodeCondition ? 'Verify Your Account' : 'Authenticate with Phone Number' }</h5>
							<p>{verificationCodeCondition ? `Please enter the OTP sent to ${countryCode.desc}-${phoneNumber} to verify your account`: 'Please enter your valid phone number to receive an OTP.'}</p>
							{verificationCodeCondition ? 
							<div className="VerificationCodeDiv">
								{[inputRef1,inputRef2,inputRef3,inputRef4,inputRef5,inputRef6].map((textInputRef, index) => 
									<input
									className="inputOTPVerification"
									maxLength={1}
									ref={refCallback(textInputRef)}
									key={`otp_input_${index}`}
									value={inputOTP[index]}
									type="text"
									onKeyUp={event => handleOtpKeyPress(index, event)}
									onChange={(event) => handleEnterOtp(index, event)}
									/>
								)}
							</div>
							: <div className='col-12 padding0 inputDiv'>
								<div className="" style={{display: 'flex'}}>
									{selectCodeActive ?
									<div className="selectCountryCodeDiv">
										<div className="width90"><Async
											isMulti={false}
											styles={customStyles}
											value={countryCode}
											backspaceRemovesValue={false}
											onChange={(value) => handleDropDownChange(value)}
											getOptionLabel={({ name }) => name}
											getOptionValue={({ desc }) => desc}
											name="countryCode"
											loadOptions={handleCountryCodeData}
											placeholder="Search Country" /></div>
											<i className="fas fa-times font20" onClick={() => setSelectCodeActive(false)} />
											</div> 
										: 
										<div className="countryCodeAvailable" onClick={() => setSelectCodeActive(true)}>
											<p className="margin0">{countryCode.desc}  <i className="fas fa-caret-down font18"></i></p>
										</div>
										}
									{selectCodeActive ? null : <input className={`phoneNumberAuthField width65`} id='phoneNumberField' type='text' style={errorMsg.phoneNumber ? { border: '1px solid #e03202' } : {}} placeholder="Phone Number" onChange={(event) => handlePhoneNumberChange(event)} value={phoneNumber} autoComplete="off" />}
									{errorMsg.phoneNumber ? <RenderTooltip text={errorMsg.phoneNumber} customIconStyle={{
										position: 'absolute',
										right: 0,
										top: 5
									}}/> : null}
								</div>
							</div>}
							{verificationCodeCondition ? <p className="textAlignCenter margin0" style={{margin: '10px 0 0'}}>If you didn't receive an OTP! <span className="cursorPointer" onClick={() => handleResendOTP()} style={{color: 'var(--clr-button-orange)'}}>Resend</span></p>: null}
							{errorMsg.verificationCode ? <p style={{margin: '12px 0 0', color: 'red', fontSize: 14}}>{errorMsg.verificationCode}</p>: null}
							{verificationCodeCondition ? <button className={`recoveryBtn ${verifiedOTPLength ? '' : 'disabled'}`} onClick={() => confirmCodeHandler()} disabled={!verifiedOTPLength}>Verify</button> : <button className="recoveryBtn" onClick={handleAuthWithPhone}>Next</button>}
							<div className="returnLoginDiv"><span onClick={() => handleReturnToLogin()}>Login with email</span></div>
							<div id="recaptcha-container"></div>
						</div>
					</div>
				</div> : null}
				{/* Phone Number Auth */}
			</div>
		</ModalComponent>
}

const mapStateToProps = state => {
	const { environment } = state;
	return {
		environment
	};
}


export default connect(mapStateToProps)(LoginPopup);