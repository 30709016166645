import React, { useState, useEffect } from 'react';
import './peerActivity.css';
import { connect } from "react-redux";
import { createPeerCommentUrl, 
         createPeerCommentReviewUrl, 
         getPeerSubmissionUrl,
         constructPeerCommentReviewUrl,
         constructPeerCommentUrl,
         validatePeerStudentUrl,
         createPeerReviewHistoryUrl
         } from "../../../../../utils/ActivityUtils";
import { unauthorizedForbiddenConfig } from '../../../../../utils/CommonUtils';
import { constructAssignmentUrl, constructMyAssignmentFileUrl, generatePresignedUrl } from '../../../../../utils/ResourceUtils';
import Actions from '../../../../../redux/actions';
import ErrorMessageComponent from "../../../components/commonComponent/ErrorMessageComponent";
import axios from 'axios';
import RatingSummary from '../../../components/ratingComponent/ratingSummary';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {useParams} from 'react-router-dom';
import LoaderComponent from '../../../screens/Public/Loader';
import { navigateToAndReplace } from '../../../../../actions/navigator';
import { validateString, validatePositiveNumber } from '../../../validations/validate';
import * as Sentry from '@sentry/browser';


dayjs.extend(LocalizedFormat);

const PeerActivity = (props) => {
  const { currentUser } = props;
  const {id} = useParams();
  const [myReply, setMyReply] = useState('');
	const [showMoreComment, setShowMoreComment] = useState(false);
  const [myComment, setMyComment] = useState('');
  const [peerScore, setPeerScore] = useState(0);
  const [rubericFileId, setRubericFileId] = useState('');
  const [peerData, setPeerData] = useState([]);
  const [isCalled, setIsCalled] = useState(false)
  const [peerComment, setPeerComment] = useState([]);
  const [peerReply, setPeerReply] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [peerIndex, setPeerIndex] = useState(-1);
  const [isActive, setIsActive] = useState(false);
  const [isRedo, setIsRedo] = useState(-1);
  const [errorMessages, setErrorMessages] = useState({});
  const [assignmentData, setAssignmentData] = useState([]);
  const [singleUse, setSingleUse] = useState(false);
  const [allowReEvaluation, setAllowReEvaluation] = useState(false);
  let dateTimeSub = new Date(peerData[currentTab]?.submitted_at);
  var newErrMsg = errorMessages;

  useEffect(() => {
    const { dispatch } = props;
    if (id && currentUser?.profile?.id) {
      let validateObj = { 
          assignment_id: id, 
          profile_id: currentUser.profile.id 
        };

        axios.defaults.headers.common['Authorization'] = localStorage.access_token;
          axios.post(validatePeerStudentUrl(), validateObj)
          .catch(_error => {
            dispatch({ type: "MERGE_IN_COMMON_REDUCER", key: "error_info", data: unauthorizedForbiddenConfig() });
				    dispatch(navigateToAndReplace({ path: ['unauthorized'] }));
          })
      }
  },[id, currentUser?.profile?.id])

  useEffect(() => {
    if (assignmentData.length === 0)
    {
      axios.defaults.headers.common['Authorization'] = localStorage.access_token
      axios.get(constructAssignmentUrl(id), {})
        .then((response) => {
          if(response?.data){
            setAssignmentData(response?.data);
          }
        })
        .catch(() => {
        });
    }
  }, [assignmentData])

  useEffect(() => {
    if (peerData.length === 0 && !isCalled)
    {
      axios.defaults.headers.common['Authorization'] = localStorage.access_token
      axios.get(getPeerSubmissionUrl(id), {})
        .then((response) => {
          if(response?.data){
            if(assignmentData?.data?.assignment?.learning_path === "PEER" && assignmentData?.data?.assignment?.peer_evaluation){
              setPeerData(response?.data?.data.filter(x => x.peer_profile_id !== currentUser?.profile?.id));
              setIsCalled(true)
            }
            else{
              setPeerData(response?.data?.data);
              setIsCalled(true)
            }
            
            if(peerComment.length === 0) {
              axios.defaults.headers.common['Authorization'] = localStorage.access_token
              axios.get(constructPeerCommentReviewUrl(response?.data?.data.filter(x => x.peer_profile_id !== currentUser?.profile?.id)[0].my_assignment_id, "my_activity", id), {})
                .then((response) => {
                  if(response?.data){
                    if(assignmentData?.data && assignmentData?.data?.assignment?.peer_evaluation){
                      setAllowReEvaluation(response?.data?.allow_evaluation)
                      setPeerComment(response?.data?.data.filter(x => x.peer_profile_id === currentUser?.profile?.id));
                    }
                    else {
                      setPeerComment(response?.data);
                    }
                  }
                })
                .catch(() => {
                });
            }
          }
        })
        .catch(() => {
        });
    }
  }, [peerData, assignmentData])

  const handleActive = (id) => {
    setPeerReply([])
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructPeerCommentUrl(id), {}) //peer_activity_assesment_id from peer comment table
			.then((response) => {
				if(response?.data){
					setPeerReply(response?.data);
				}
			})
			.catch(() => {
			});
	}

  const validateReplyContent = () => {
		let isValid = true;
		let isFieldValid = true;
    if (myReply && myReply === '' || myReply === null) {
      isFieldValid = validateString(myReply);
      newErrMsg = isFieldValid ? { ...newErrMsg, reply: null } : { ...newErrMsg, reply: "Please enter a valid data" };
      isValid = isValid && isFieldValid;
    }

    setErrorMessages(newErrMsg);

		return isValid;
  }

  const validateCommentContent = () => {
		let isValid = true;
		let isFieldValid = true;
    if (myComment && myComment === null || myComment === '') {
      isFieldValid = validateString(myComment);
      newErrMsg = isFieldValid ? { ...newErrMsg, comment: null } : { ...newErrMsg, comment: "Please enter a valid data" };
      isValid = isValid && isFieldValid;
    }

    setErrorMessages(newErrMsg);

		return isValid;
  }

  const validateEvalFormContent = () => {
		let isValid = true;
		let isFieldValid = true;

    if (peerScore === null || peerScore === '' || peerScore > assignmentData?.data?.assignment?.mentor_assessment_score) {
      isFieldValid = validatePositiveNumber(peerScore) && peerScore <= assignmentData?.data?.assignment?.mentor_assessment_score;
      newErrMsg = isFieldValid ? { ...newErrMsg, score: null } : { ...newErrMsg, score: "Please enter a score" };
      isValid = isValid && isFieldValid;
    }
    if (myComment === null || myComment === ''){
      isFieldValid = validateString(myComment);
      newErrMsg = isFieldValid ? { ...newErrMsg, comment: null } : { ...newErrMsg, comment: "Please enter a comment" };
      isValid = isValid && isFieldValid;
    }
		if (rubericFileId === null || rubericFileId === '') {
			isFieldValid = rubericFileId === null || rubericFileId === '' ? false : true;
			newErrMsg = isFieldValid ? { ...newErrMsg, rubericFile: null } : { ...newErrMsg, rubericFile: "Please upload a file" };
			isValid = isValid && isFieldValid;
		}

    setErrorMessages(newErrMsg);

		return isValid;
  }

  const handleCreateMyReply = (val) => {
		const { dispatch, currentUser } = props;
    let isValid = validateReplyContent();
		let commentObj = { 
      "peer_comment":
        {peer_activity_assessment_id: val.id, profile_id: currentUser.profile.id, 
          comment: myReply
        } 
    };
    if(dayjs() < dayjs(assignmentData?.data.disable_at)){
      if(isValid) {
        dispatch(Actions.snackBarOn('Posting reply on peer comment ...', 2000, true));

        axios.defaults.headers.common['Authorization'] = localStorage.access_token;
          axios.post(createPeerCommentUrl(), commentObj)
          .then(response => {
            if(response.data){
              setPeerReply(response.data)
              dispatch(Actions.snackBarOn('Reply posted Successfully', 2000))
              setMyReply('')
              setPeerIndex(-1)
            
              axios.defaults.headers.common['Authorization'] = localStorage.access_token
              axios.get(constructPeerCommentReviewUrl(val.my_activity_id, "my_activity", val.assignment_id), {})
                .then((response) => {
                  if(response?.data?.data){
                    setPeerComment(response?.data)
                  }
                })
                .catch(() => {
                });
            }
          })
          .catch(_error => {
            dispatch(Actions.snackBarOn('Reply posting failed', 2000))
          })
      }
    }
    else{
      dispatch(Actions.snackBarOn('Replying is not allowed once deadline crossed', 6000))
    }
	};

  const handleCreateMyComment = (my_activity_id, activity_id) => {
		const { dispatch, currentUser } = props;
		let isValid = validateCommentContent();
		let replyObj = { 
      "peer_activity_assessment":
        {
          type: assignmentData?.data?.assignment?.peer_evaluation ? "PEER EVALUATION" : "PEER LEARNING", 
          comments: myComment,
          peer_profile_id: currentUser.profile.id,
          my_activity_id,
          activity_id,
          peer_assessment_score: assignmentData?.data?.assignment?.peer_evaluation ? parseFloat(peerScore) : 0 ,
          rubric_file: assignmentData?.data?.assignment?.peer_evaluation ? rubericFileId : null
        } 
      };
    if(dayjs() < dayjs(assignmentData?.data.disable_at)){
      if(isValid) {
        setSingleUse(true)
          dispatch(Actions.snackBarOn('Posting Comment ...', 2000, true));
          axios.defaults.headers.common['Authorization'] = localStorage.access_token;
            axios.post(createPeerCommentReviewUrl(), replyObj)
            .then(response => {
              if(response.data){
                setPeerComment(response.data)
                dispatch(Actions.snackBarOn('Comment posted Successfully', 2000))
                setMyComment('')
                setPeerScore('')
                setErrorMessages('')
                setSingleUse(false)

                axios.defaults.headers.common['Authorization'] = localStorage.access_token
                axios.get(constructPeerCommentReviewUrl(my_activity_id, "my_activity", activity_id), {})
                  .then((response) => {
                    if(response?.data){
                      setPeerComment(response?.data);
                    }
                  })
                  .catch(() => {
                  });
              }
            })
            .catch(_error => {
              dispatch(Actions.snackBarOn('Comment posting failed', 2000))
            })
        }
      }
      else{
        dispatch(Actions.snackBarOn('Commenting is not allowed once deadline crossed', 6000))
      }
	};

  const handleCreateEvalMyComment = (my_activity_id, activity_id) => {
		const { dispatch, currentUser } = props;
		const final_peer_score = Number.parseFloat(peerScore).toFixed(1)
		let isValid = validateEvalFormContent();
		let replyObj = { 
      "peer_activity_assessment":
        {
          type: assignmentData?.data?.assignment?.peer_evaluation ? "PEER EVALUATION" : "PEER LEARNING", 
          comments: myComment,
          peer_profile_id: currentUser.profile.id,
          my_activity_id,
          activity_id,
          peer_assessment_score: assignmentData?.data?.assignment?.peer_evaluation ? final_peer_score : 0 ,
          rubric_file: assignmentData?.data?.assignment?.peer_evaluation ? rubericFileId : null
        } 
      };
    if(dayjs() < dayjs(assignmentData?.data.peer_disable_at)){
      if(isValid) {
        setSingleUse(true);
        if(assignmentData?.data?.assignment.peer_evaluation && peerScore <= assignmentData?.data?.assignment?.mentor_assessment_score) {
          dispatch(Actions.snackBarOn('Posting Comment ...', 2000, true));
          axios.defaults.headers.common['Authorization'] = localStorage.access_token;
            axios.post(createPeerCommentReviewUrl(), replyObj)
            .then(response => {
              if(response.data){
                dispatch(Actions.snackBarOn('Comment posted Successfully', 2000))
                setMyComment('')
                setPeerScore('')
                setErrorMessages('')
                setSingleUse(false)

                axios.defaults.headers.common['Authorization'] = localStorage.access_token
                axios.get(constructPeerCommentReviewUrl(my_activity_id, "my_activity", activity_id), {})
                  .then((response) => {
                    if(response?.data){
                      setAllowReEvaluation(response?.data?.allow_evaluation)
                      setPeerComment(response?.data?.data.filter(x => x.peer_profile_id === currentUser?.profile?.id));
                      setRubericFileId(null);
                    }
                  })
                  .catch(() => {
                  });
              }
            })
            .catch(_error => {
              dispatch(Actions.snackBarOn('Comment posting failed', 2000))
            })
          }
        }
      }
      else{
        dispatch(Actions.snackBarOn('Commenting is not allowed once deadline crossed', 6000))
      }
	};

  const handleRedo = (val) => {
    const { dispatch } = props;
    let reviewObj = {
      id: val.id
    }
    
    axios.defaults.headers.common['Authorization'] = localStorage.access_token;
    axios.post(createPeerReviewHistoryUrl(), reviewObj)
    .then(response => {
      if(response.data){
        handleCreateEvalMyComment(val.my_activity_id, val.assignment_id);
      }
    })
    .catch(_error => {
    })
  }

  const handleTabClick = (currTab) => {
    setPeerIndex(-1);
    setCurrentTab(currTab);
    setIsActive(false);
    axios.defaults.headers.common['Authorization'] = localStorage.access_token
    axios.get(constructPeerCommentReviewUrl(peerData[currTab].my_assignment_id, "my_activity", id), {})
      .then((response) => {
        if(response?.data){
          if(assignmentData?.data.assignment.peer_evaluation){
            setAllowReEvaluation(response?.data?.allow_evaluation)
            setPeerComment(response?.data?.data.filter(x => x.peer_profile_id === currentUser?.profile?.id));
          }
          else {
            setPeerComment(response?.data);
          }
        }
      })
      .catch(() => {
      });
  }

  const handleFileUpload = event => {
		const { dispatch, currentUser } = props;
		event.preventDefault();
		let file = event.target.files[0];
		if (!file) {
			return;
		}
		let fileName = file.name ? `${Date.now()}_${file.name.split(' ').join('_')}` : file.name;
		let blob = file.slice(0, file.size, file.type);
		let updatedFile = new File([blob], fileName, { type: file.type })

		let data = {
			file_upload: {
				file_name: updatedFile.name,
				uploader_id: currentUser.profile.id,
				assignment_id: id
			}
		}

		let url = generatePresignedUrl()

    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    axios.post(url, data)
      .then((response) => {
        sendFile(response.data.success, updatedFile)
      })
      .catch((error) => {
        dispatch(Actions.snackBarOn('The file could not be uploaded'))
        Sentry.captureException(`Assignment file upload: ${error.toString()}`)
      });
    setRubericFileId('')
	}

	const sendFile = (presignedurl, file) => {
		const { dispatch } = props;
		const xhr = new XMLHttpRequest();
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					let body = {
						file_upload: {
							content_type: file.type,
							filename: file.name,
							assignment_id: id
						}
					}
					axios.post(constructMyAssignmentFileUrl(), body)
						.then((response) => {
              setRubericFileId(response.data.data?.id)
							dispatch(Actions.snackBarOn('File uploaded successfully', 6000))
						})
						.catch((error) => {
							dispatch(Actions.snackBarOn('The file could not be uploaded'))
							Sentry.captureException(`Assignment file upload: ${error.toString()}`)
						});
				} else {
					dispatch(Actions.snackBarOn('Your file could not be uploaded', 6000))
				}
			}
		};
		xhr.open('PUT', presignedurl);
		xhr.setRequestHeader('Content-Type', file.type);
		xhr.send(file);
	}      
    
  let fileList = [];

  if (peerData[currentTab]?.files && peerData[currentTab]?.files.length) {
        fileList = peerData[currentTab]?.files.map(file => (
            <div key={file.id}>
                <a style={{ fontSize: 14, color: 'black' }} href={file.file} target="_blank" rel="noopener noreferrer">{file.filename}</a>
            </div>
        ))
    }

  let fileActivityList = [];
  if (assignmentData?.data?.assignment.files && assignmentData?.data?.assignment.files.length) {
    fileActivityList = assignmentData?.data?.assignment.files.map(file => (
          <div key={file.id}>
              <a style={{ fontSize: 14, color: 'black' }} href={file.file} target="_blank" rel="noopener noreferrer">{file.filename}</a>
          </div>
      ))
  }

  if(assignmentData?.data && peerData) {
    return (
      <>
        {assignmentData?.data?.assignment && assignmentData?.data?.assignment?.peer_evaluation ?
          <div className="col-12 AccordianClass row margin0 mb0">
          <div className="col-12 padding0">
            <div className="activity">
              <div className="row">
                <div className="col-11">
                    <h3>
                      <span>{assignmentData?.data?.assignment?.title}</span> 
                      <span>
                        <i class="fas fa-info-circle fa-sm" style={{marginLeft: "10px", cursor: "pointer", color: "var(--clr-button-orange)"}}
                          title="Practice offering constructive feedback to your peers in this activity. Submit your response, and then wait to be assigned a peer's submission to evaluate. Use the given rubric to give a fair and well thought out evaluation. Please note that grades are assigned both for your evaluation ability and for how your peers have evaluated you."
                        ></i>
                      </span>
                    </h3>
                </div>
              </div>

              <div className="row">
                <div className="col-7 padding2"><label>Type:</label> {assignmentData?.data?.assignment?.type}</div>
                <div className="col-1 padding0"><label>Total Score:</label> {assignmentData?.data?.assignment?.max_score}</div>
                {assignmentData?.data?.assignment?.mentor_assessment_score ? <div className="col-2 padding0">{assignmentData?.data?.assignment?.peer_evaluation ? <label>Max Score for Activity:</label> : <label>Mentor:</label>} {assignmentData?.data?.assignment?.mentor_assessment_score}</div> :null}
                {assignmentData?.data?.assignment?.peer_assessment_score ? <div className="col-2 padding0">{assignmentData?.data?.assignment?.peer_evaluation ? <label>Max Score for Peer Evaluation:</label> : <label>Peer Engagement:</label>} {assignmentData?.data?.assignment?.peer_assessment_score}</div> :null}
              </div>
              <div className="row">
                <div className="col-7 padding2"><label>Blooms level:</label> </div>
                {assignmentData?.data?.assignment?.final_evaluation && assignmentData?.data?.status === "complete" ?
                <>
                  <div className="col-1 padding0"><label>My Score:</label> {assignmentData?.data?.score + assignmentData?.data?.peer_score}</div>
                  {assignmentData?.data?.assignment?.peer_evaluation ?
                  <>
                    <div className="col-2 padding0"><label>Activity Score:</label> {assignmentData?.data?.score}</div>
                    <div className="col-2 padding0"><label>Peer Evaluation Score:</label> {assignmentData?.data?.peer_score}</div>
                  </>
                  :
                  null
                  }
                </>
                :null}
              </div>
              <div className="col-12 padding0"><label>Learning Type:</label> Peer Engagement</div>
              
              {assignmentData?.data?.assignment?.peer_evaluation ?
              <div className="col-12 padding0"><label className="mr-2">Peer Evaluation:</label> Yes</div>
              : 
              <div className="col-12 padding0"><label className="mr-2">Peer Evaluation:</label> No</div>
              }
              <div className="row">
                <div className="col-7 padding2"><label>Duration: </label>{` ${assignmentData?.data?.assignment?.duration} minutes`}</div>
                <div className="col-5 padding0"><label>Submission Deadline: </label> {dayjs(assignmentData?.data?.disable_at).format('LLLL')}</div>
              </div>
              <div className="row">
                <div className="col-7 padding2"><label>Status: </label> {assignmentData?.data?.status}</div>
                <div className="col-5 padding0"><label>Evaluation Deadline: </label> {dayjs(assignmentData?.data?.peer_disable_at).format('LLLL')}</div>
              </div>
              {/* <RatingSummary mainDivClass="col-12 padding0" mainDivStyles={{ marginBottom: 5 }} 
                ratingContentDiv={{ padding: 0 }} ratingHandler={props.ratingHandler} 
                rating={assignmentData?.data?.assignment ? assignmentData?.data?.assignment.rating : 0}
                reviewTextStyle={{ textAlign: 'left' }} number_of_reviews={assignmentData?.data?.assignment 
                  ? assignmentData?.data?.assignment.number_of_reviews : 0} 
                canRate={currentUser?.profile?.profile_type !== "ADVISOR" && assignmentData?.data?.status && (assignmentData?.data?.status === 'complete' || assignmentData?.data?.status === 'Submitted')} 
              /> */}

              {/* Activity Description */}

              {assignmentData?.data?.active ? <div className="card col-12 padding0">
                <div className="card-header" style={{ cursor: 'default' }}>
                    <h4 className="card-title">Activity Description</h4>
                </div>
                <div className="collapse show">
                    <div className="card-body" dangerouslySetInnerHTML={{ __html: assignmentData?.data?.assignment.description }} />
                </div>
              </div> : null}

              {/* Activity Description */}

              {fileActivityList.length ? <div className="col-12 padding0" style={{ marginBottom: 30 }}>
                <h3 className="col-12 padding0 resourceContentTopicHeading" style={{ fontWeight: 700 }}>Files</h3>
                <div className="col-12 padding0" style={{ marginTop: '18px' }}>
                    {fileActivityList}
                </div>
              </div> : null}

              {/* Questions */}
              {assignmentData?.data?.assignment && assignmentData?.data?.assignment.assignment_que && assignmentData?.data?.assignment.assignment_que.map((ele,index) => {
                  let lastSubmission = assignmentData?.data?.submissions[assignmentData?.data?.submissions.length-1]
                let dateTime = new Date(lastSubmission?.inserted_at)
                return (
                  <>
                    <div className="card col-12 padding0  mt-2">
                      <div className="card-header" style={{ cursor: 'default' }}>
                        <h4 className="card-title">Question - {index + 1} </h4>
                      </div>
                      <div className="collapse show">
                        <div className="card-body" dangerouslySetInnerHTML={{ __html: ele.question }} />
                        {lastSubmission ? 
                          <div className="internal-body card-body">
                            <h6>Current Submission:</h6>
                            <div key="desc" className="resourceFields col-12 padding0">
                              <div dangerouslySetInnerHTML={{ __html: lastSubmission.description }}></div>
                            </div>
                            {lastSubmission.files && lastSubmission.files.length ?
                            <div key="file" className="resourceFields col-12 padding0">
                              <div> <a href={lastSubmission.files[0].file} target="_blank" rel="noopener noreferrer">{lastSubmission.files[0].filename}</a></div>
                            </div> : null}
                            <b>Submitted At:</b> {dateTime.toLocaleString('en-IN', { hour12: true })}
                          </div>
                        
                        :null}
                        
                      </div>
                    </div>
                  </>)
              })}
              {/* Questions */}

              {/* Evaluate your Peers */}
              {peerData && peerData.length > 0 ? 
                <div className="card col-12 padding0  mt-4">
                  <div className="card-header" style={{ cursor: 'default', borderBottom: '2px solid #fa8954' }}>
                    <h4 className="card-title">Evaluate your Peers </h4>
                  </div>
                  <div className="collapse show" style={{backgroundColor: "white", color: "black"}}>

                      <div class="activity-tab">
                        {peerData.map((val, index) => {
                          return(
                          <>
                          <div>
                              <button
                                class="activity-tablinks"
                                key={index}
                                onClick={() => handleTabClick(index)}
                                style={{ textAlign: "center", verticalAlign: "middle", 
                                backgroundColor: currentTab === index ? "#fa8954" : null }}
                              >
                                 Student {index+1}
                              </button>
                          </div>
                          </>
                          );
                          })
                        }
                      </div>

                      <div class="activity-tabcontent">
                        {peerData && peerData.length > 0 
                        && currentTab !== -1 ?
                        <>
                          <div>
                            <p style={{ marginTop: "5px", marginBottom: "20px", fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: peerData[currentTab].submission }} />
                            {fileList.length ? 
                              <div style={{display: "flex"}}>
                                <div className="col-9 padding0">
                                    <div className="col-12 padding0">
                                      <div> <a href={fileList.file} target="_blank" rel="noopener noreferrer">{fileList}</a></div>
                                    </div>
                                  </div>
                                <div style={{ float: "right" }}>
                                  <label>Submitted At: </label>
                                  <span> {dateTimeSub.toLocaleString('en-IN', { hour12: true })}</span>
                                </div>
                              </div>
                            : 
                            <div style={{ float: "right" }}>
                              <label>Submitted At: </label>
                              <span> {dateTimeSub.toLocaleString('en-IN', { hour12: true })}</span>
                            </div>
                            }
                            <hr  />
                          </div>
                          <div class="peerLearningCard" style={{width: "100%"}}>
                            {peerComment && peerComment.length > 0 ?
                              peerComment.map((val, index) => {
                                let dateTime = new Date(val.submitted_at)
                                if(currentUser?.profile.id === val.peer_profile_id) {
                                  if(val && val?.status === "REDO" && isRedo === -1) {
                                    setIsRedo(val.id);
                                  }
                                  return(
                                    <div>
                                      <p style={{fontWeight: "550", fontSize: "16px"}}>{val.peer_name}</p>
                                      <p>{val.comments}</p>
														          <p>Score given: {val.peer_assessment_score}</p>

                                      {val?.file?.data ? <div className="col-9 padding0">
                                        <div className="col-12 padding0">
                                          <div> <a href={val?.file?.data.file} target="_blank" rel="noopener noreferrer">{val?.file?.data.filename.slice(14)}</a></div>
                                        </div>
                                      </div> : null} 
                                      <div className="mb-3">
                                        <label>Posted At: </label>
                                        <span> {dateTime.toLocaleString('en-IN', { hour12: true })}</span>
                                      </div>
                                    </div>
                                  );
                                }
                                })
                              : null
                            }
                          </div>
                          {peerComment && currentUser?.profile.id !== peerComment[0]?.peer_profile_id && dayjs() > dayjs(assignmentData?.data?.peer_enable_at) && dayjs() < dayjs(assignmentData?.data?.peer_disable_at) && allowReEvaluation ?
                            <>
                              <div style={{display: "flex", paddingTop: "2%"}}>
                                <div style={{ paddingRight: "2%" }}>
                                  <p>Evaluated Score *( max: {assignmentData?.data?.assignment?.mentor_assessment_score} )</p>
                                  <input type="text"
                                  value={peerScore}
                                  onChange={(e) => {setPeerScore(e.target.value)}}
                                  placeholder="Type in evaluated score"/>
                                  <ErrorMessageComponent errorMessage={errorMessages['score']} />
                                </div>

                                <div>
                                  <h5 className="col-12 font14" style={{ marginBottom: 10 }}>
                                    <label style={{ marginBottom: 10 }}>Upload Rubric File *</label>
                                    <br />
                                    <input name="resource_group_file_upload" id="resource_group_file_upload" className="resource_group_file_upload"
                                      type="file" 
                                      onChange={event => handleFileUpload(event)}
                                      accept=".mp4,.ogg,.webm,.ogv,.jpg,.xlsx,.csv,.xls,.xml,.doc,.docx,.docm,.ppt,.pptx,.pps,.jpeg,.png,.mp3,.pdf,.txt"
                                    />
                                    <ErrorMessageComponent errorMessage={errorMessages['rubericFile']} />
                                  </h5>
                                </div>

                              </div>
                              <p style={{ paddingTop: "2%" }}>My Comment *</p>
                              <div>
                                <textarea 
                                className="mb-2"
                                value={myComment}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                }}
                                onChange={(e) => {setMyComment(e.target.value)}}
                                />
                                <ErrorMessageComponent errorMessage={errorMessages['comment']} />
                                <button
                                  type="button"
                                  className="peerActivityBtn  row margin0 mb-2"
                                  onClick={() => !singleUse ? handleCreateEvalMyComment(peerData[currentTab].my_assignment_id, peerData[currentTab].assignment_id) : null}
                                >
                                  Post
                                </button>
                              </div>
                            </>
                          :null}
                          
                          {isRedo !== -1 && dayjs() > dayjs(assignmentData?.data?.peer_enable_at) && dayjs() < dayjs(assignmentData?.data?.peer_disable_at) && allowReEvaluation ?
                          peerComment && peerComment.length > 0 ?
                            peerComment.map((val) => {
                              if(val?.status === 'REDO') {
                                return( 
                                <>
                                  <div style={{display: "flex", paddingTop: "2%"}}>
                                    <div style={{ paddingRight: "2%" }}>
                                      <p>Evaluated Score *( max: {assignmentData?.data?.assignment?.mentor_assessment_score} )</p>
                                      <input type="text"
                                      value={peerScore}
                                      onChange={(e) => {setPeerScore(e.target.value)}}
                                      placeholder="Type in evaluated score"/>
                                      <ErrorMessageComponent errorMessage={errorMessages['score']} />
                                    </div>

                                    <div>
                                      <h5 className="col-12 font14" style={{ marginBottom: 10 }}>
                                        <label style={{ marginBottom: 10 }}>Upload Rubric File *</label>
                                        <br />
                                        <input name="resource_group_file_upload" id="resource_group_file_upload" className="resource_group_file_upload"
                                          type="file" 
                                          onChange={event => handleFileUpload(event)}
                                          accept=".mp4,.ogg,.webm,.ogv,.jpg,.xlsx,.csv,.xls,.xml,.doc,.docx,.docm,.ppt,.pptx,.pps,.jpeg,.png,.mp3,.pdf,.txt"
                                        />
                                        <ErrorMessageComponent errorMessage={errorMessages['rubericFile']} />
                                      </h5>
                                    </div>

                                  </div>
                                  <p style={{ paddingTop: "2%" }}>My Comment *</p>
                                  <div>
                                    <textarea 
                                    className="mb-2"
                                    value={myComment}
                                    style={{
                                      width: "100%",
                                      height: "100px",
                                    }}
                                    onChange={(e) => {setMyComment(e.target.value)}}
                                    />
                                    <ErrorMessageComponent errorMessage={errorMessages['comment']} />
                                    <button
                                      type="button"
                                      className="peerActivityBtn  row margin0 mb-2"
                                      onClick={() => !singleUse ? handleRedo(val) : null}
                                    >
                                      Post
                                    </button>
                                  </div>
                                </>)
                              }
                            })
                            :null
                          
                          :null}

                        </>
                        : 
                        <div>
                          <h5>No Peer Submissions</h5>
                        </div>
                        }
                      </div>

                  </div>
                </div>
              : 
              <div className="card col-12 padding0  mt-2">
                <div className="card-header" style={{ cursor: 'default', borderBottom: '2px solid #fa8954' }}>
                  <h4 className="card-title">Evaluate Your Peers </h4>
                </div>
                <div className="collapse show" style={{backgroundColor: "white", color: "black"}}>
                <div className="card-body">
                  <h5>No Peer Submissions</h5>
                </div>
              </div>
            </div>
            }

            {/* Evaluate your Peers */}

            </div>
          </div>
        </div>
        :
        <>
          <div className="col-12 AccordianClass row margin0">
            <div className="col-12 padding0">
              <div className="activity">
                <div className="row">
                  <div className="col-11">
                      <h3>
                        <span>{assignmentData?.data?.assignment?.title}</span> 
                        <span>
                          <i class="fas fa-info-circle fa-sm" style={{marginLeft: "10px", cursor: "pointer", color: "var(--clr-button-orange)"}}
                            title="Submit a response to the activity and engage with your batchmates by commenting on their submissions. If this is a graded activity, marks are assigned for both submissions and comments."
                          ></i>
                        </span>
                      </h3>
                  </div>
                </div>

                <div className="row">
                  <div className="col-8 padding2"><label>Type:</label> {assignmentData?.data?.assignment?.type}</div>
                  <div className="col-1 padding0"><label>Max Score:</label> {assignmentData?.data?.assignment?.max_score}</div>
                  <div className="col-1 padding0"><label>Mentor:</label> {assignmentData?.data?.assignment?.mentor_assessment_score}</div>
                  <div className="col-2 padding0"><label>Peer Engagement:</label> {assignmentData?.data?.assignment?.peer_assessment_score}</div>
                </div>
                <div className="row">
                  <div className="col-8 padding2"><label>Blooms level:</label> </div>
                  {assignmentData?.data?.assignment?.final_evaluation && assignmentData?.data?.status === "complete" ?
                  <div className="col-2 padding0"><label>My Score:</label> {assignmentData?.data?.score + assignmentData?.data?.peer_score}</div>
                  :null}
                </div>
                <div className="col-12 padding0"><label>Learning Type:</label> Peer Engagement</div>
                
                {assignmentData?.data?.assignment?.peer_evaluation ?
                <div className="col-12 padding0"><label className="mr-2">Peer Evaluation:</label> Yes</div>
                : 
                <div className="col-12 padding0"><label className="mr-2">Peer Evaluation:</label> No</div>
                }
                <div className="col-12 padding0"><label>Duration: </label>{` ${assignmentData?.data?.assignment?.duration} minutes`}</div>
                <div className="col-12 padding0"><label>Status: </label> {assignmentData?.data?.status}</div>
                <RatingSummary mainDivClass="col-12 padding0" mainDivStyles={{ marginBottom: 5 }} 
                  ratingContentDiv={{ padding: 0 }} ratingHandler={props.ratingHandler} 
                  rating={assignmentData?.data?.assignment ? assignmentData?.data?.assignment.rating : 0}
                  reviewTextStyle={{ textAlign: 'left' }} number_of_reviews={assignmentData?.data?.assignment 
                    ? assignmentData?.data?.assignment.number_of_reviews : 0} 
                  canRate={currentUser?.profile?.profile_type !== "ADVISOR" && assignmentData?.data?.status && (assignmentData?.data?.status === 'complete' || assignmentData?.data?.status === 'Submitted')} 
                />

                {/* Questions */}
                {assignmentData?.data?.assignment && assignmentData?.data?.assignment.assignment_que && assignmentData?.data?.assignment.assignment_que.map((ele,index) => {
                    let lastSubmission = assignmentData?.data?.submissions[assignmentData?.data?.submissions.length-1]
                  let dateTime = new Date(lastSubmission?.inserted_at)
                  return (
                    <>
                      <div className="card col-12 padding0  mt-2">
                        <div className="card-header" style={{ cursor: 'default' }}>
                          <h4 className="card-title">Question - {index + 1} </h4>
                        </div>
                        <div className="collapse show">
                          <div className="card-body" dangerouslySetInnerHTML={{ __html: ele.question }} />
                          {lastSubmission ? 
                            <div className="internal-body card-body">
                              <h6>Current Submission:</h6>
                              <div key="desc" className="resourceFields col-12 padding0">
                                <div dangerouslySetInnerHTML={{ __html: lastSubmission.description }}></div>
                              </div>
                              {lastSubmission.files && lastSubmission.files.length ?
                              <div key="file" className="resourceFields col-12 padding0">
                                <div> <a href={lastSubmission.files[0].file} target="_blank" rel="noopener noreferrer">{lastSubmission.files[0].filename}</a></div>
                              </div> : null}
                              <b>Submitted At:</b> {dateTime.toLocaleString('en-IN', { hour12: true })}
                            </div>
                          
                          :null}
                          
                        </div>
                      </div>
                    </>)
                })}
                {/* Questions */}

                {/* Submissions */}
                {peerData && peerData.length > 0 ? 
                  <div className="card col-12 padding0  mt-2">
                    <div className="card-header" style={{ cursor: 'default', borderBottom: '2px solid #fa8954' }}>
                      <h4 className="card-title">Submissions </h4>
                    </div>
                    <div className="collapse show" style={{backgroundColor: "white", color: "black"}}>

                        <div class="activity-tab">
                          {peerData.map((val, index) => {
                            return(
                            <>
                            <div>
                                <button
                                  class="activity-tablinks"
                                  key={val.name}
                                  onClick={() => handleTabClick(index)}
                                  style={{ textAlign: "center", verticalAlign: "middle", 
                                  backgroundColor: currentTab === index ? "#fa8954" : null }}
                                >
                                  {val.name}
                                </button>
                            </div>
                            </>
                            );
                            })
                          }
                        </div>

                        <div class="activity-tabcontent">
                          {peerData && peerData.length > 0 
                          && currentTab !== -1 ?
                          <>
                            <div>
                              <p style={{ marginTop: "5px", marginBottom: "20px", fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: peerData[currentTab].submission }} />
                                {fileList.length ? <div className="col-9 padding0">
                                  <div className="col-12 padding0">
                                    <div> <a href={fileList.file} target="_blank" rel="noopener noreferrer">{fileList}</a></div>
                                  </div>
                                </div> : null}
                              <hr />

                              <div style={{ float: "right" }}>
                                <label>Submitted At: </label>
                                <span> {dateTimeSub.toLocaleString('en-IN', { hour12: true })}</span>
                              </div>

                            </div>
                            <div class="" style={{width: "100%"}}>
                              {peerComment?.data && peerComment?.data.length > 0 ?
                                peerComment?.data.map((val, index) => {
                                  let dateTime = new Date(val.submitted_at)
                                  return(
                                    <div>
                                      <p style={{fontWeight: "550", fontSize: "16px"}}>{val.peer_name}</p>
                                      <p>{val.comments}</p>
                                      <div>
                                        <label>Posted At: </label>
                                        <span> {dateTime.toLocaleString('en-IN', { hour12: true })}</span>
                                      </div>

                                      <div className="accordion-item">
                                        {val?.replies ? 
                                          <>
                                          
                                          <div className="accordion-content">
                                            <div style={{padding: "40px", paddingTop: "0px"}}>
                                            {val?.replies?.length > 0 ?
															                val?.replies?.length < 3 || showMoreComment ?
                                                <>
                                                <h6 style={{color: "#F77126"}}>Comments</h6>
                                                {val?.replies?.map((ele) => {
                                                  let dateTime = new Date(ele.submitted_at)
                                                  return(
                                                    <div>
                                                      <p style={{fontWeight: "550", fontSize: "16px"}}>{ele.peer_profile_name}</p>
                                                      <p>{ele.comment}</p>
                                                      <div>
                                                        <label>Posted At: </label>
                                                        <span> {dateTime.toLocaleString('en-IN', { hour12: true })}</span>
                                                      </div>
                                                    </div>
                                                    );
                                                  })}
                                                </>
                                                :
                                                <>
                                                <h6 style={{color: "#F77126"}}>Comments</h6>
                                                {val?.replies?.filter((_item, index) => index < 3).map((ele) => {
                                                  let dateTime = new Date(ele.submitted_at)
                                                  return(
                                                    <div>
                                                      <p style={{fontWeight: "550", fontSize: "16px"}}>{ele.peer_profile_name}</p>
                                                      <p>{ele.comment}</p>
                                                      <div>
                                                        <label>Posted At: </label>
                                                        <span> {dateTime.toLocaleString('en-IN', { hour12: true })}</span>
                                                      </div>
                                                    </div>
                                                    );
                                                  })}
                                                </>
                                                : 
                                                <h6 style={{color: "#F77126"}}>No Comments</h6>
                                              }
															
                                              {val?.replies.length > 3 ? showMoreComment ? <span className="btn btn-secondary btn-sm btn-show-more" onClick={() => setShowMoreComment(false)}>Hide More Comments</span> : <span className="btn btn-secondary btn-sm btn-show-more" onClick={() => setShowMoreComment(true)}>Show More Comments</span> : null}
                                            </div>
                                          </div>
                                          </>

                                          : null}
                                      </div>

                                      {peerIndex !== index ? 
                                        <a onClick={() => {
                                          setPeerIndex(index);
                                          
                                        }}
                                          style={{ float: "right", color: "#F77126", marginRight: "10px", cursor: "pointer" }}
                                        >
                                          Reply
                                        </a> 
                                      : null}
                                      {peerIndex === index ? 
                                        <div style={{margin: "10px"}}>
                                          <textarea type="text" 
                                          value={myReply}
                                          style={{width: "85%", height: "40%"}} 
                                          onChange={(e) => {setMyReply(e.target.value)}} />
                                          <ErrorMessageComponent errorMessage={errorMessages['reply']} />
                                          <button style={{backgroundColor: "#e7e7e7", color: "black", marginTop: "6px"}} className="peerActivityBtn" onClick={() => {
                                            setPeerIndex(-1)
                                          }
                                          }
                                          >Cancel</button>
                                          <button className="peerActivityBtn" 
                                            style={{marginRight: "10px", marginTop: "6px"}} onClick={() => {
                                            handleCreateMyReply(val)
                                          }
                                          }
                                          >Reply</button>
                                        </div>
                                      : null}
                                    </div>
                                  );
                                })
                                : null
                              }
                            </div>
                            
                            {dayjs() > dayjs(assignmentData?.data?.enable_at) && dayjs() < dayjs(assignmentData?.data?.disable_at) && peerData[currentTab].peer_profile_id !== currentUser?.profile?.id ?
                            <>
                              <p style={{ paddingTop: "2%" }}>My Comment</p>
                              <div>
                                <textarea 
                                className="mb-2"
                                value={myComment}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                }}
                                onChange={(e) => {setMyComment(e.target.value)}}
                                />
                                <ErrorMessageComponent errorMessage={errorMessages['comment']} />
                                <button
                                  type="button"
                                  className="peerActivityBtn  row margin0 mb-2"
                                  onClick={() => !singleUse ? handleCreateMyComment(peerData[currentTab].my_assignment_id, peerData[currentTab].assignment_id) : null}
                                >
                                  Post
                                </button>
                              </div>
                            </>
                            :null}
                          </>
                          : 
                          <div>
                            <h5>No Peer Subissions</h5>
                          </div>
                          }
                        </div>

                    </div>
                  </div>
                : 
                <div className="card col-12 padding0  mt-2">
                  <div className="card-header" style={{ cursor: 'default', borderBottom: '2px solid #fa8954' }}>
                    <h4 className="card-title">Submissions </h4>
                  </div>
                  <div className="collapse show" style={{backgroundColor: "white", color: "black"}}>
                  <div className="card-body">
                    <h5>No Peer Subissions</h5>
                  </div>
                  </div>
              </div>
                }
                {/* Submissions */}

              </div>
            </div>
          </div>
  
        </>
        }
      </>
    )
  }
  else {
    return <LoaderComponent />
  }
}
// connect
const mapStateToProps = state => {
  const { currentUser } = state;

	return {
		currentUser,
	}
};
export default connect (mapStateToProps)(PeerActivity);