import React, { lazy, useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, useLocation, Router } from 'react-router-dom';
import LoaderComponent from '../../screens/App/screens/Public/Loader';
import history from './history';
import HelmetComponent from '../../screens/App/components/commonComponent/helmetComponent';
import { metaData } from './metaDataSchema';
import App from '../../screens/App';
import PeerActivity from '../../screens/App/screens/Public/MyAssignmentContainer/peerActivity';

//Route based lazy loading.
const Home = lazy(() => import('../../screens/App/screens/Public/Home'));
const InstituteHome = lazy(() => import('../../screens/App/screens/Public/Home/institute.js'));
const MentorHome = lazy(() => import('../../screens/App/screens/Public/Home/mentor.js'));
const CompanyHome = lazy(() => import('../../screens/App/screens/Public/Home/company.js'));

const Projects = lazy(() => import('../../screens/App/screens/Public/ProjectsContainer'));
const Project = lazy(() => import('../../screens/App/screens/Public/ProjectContainer'));
const MyProject = lazy(() => import('../../screens/App/screens/Public/MyProjectContainer'));
const ProjectPricing = lazy(() => import('../../screens/App/screens/Public/ProjectContainer/projectPricing'));

const Copyright = lazy(() => import('../../screens/App/screens/Public/CopyrightContainer'));
const Mentors = lazy(() => import('../../screens/App/screens/Public/MentorsContainer'));
const CompanyView = lazy(() => import('../../screens/App/screens/Public/CompanyView'));
const RenderPosts = lazy(() => import('../../screens/App/screens/Public/RenderPostsContainer'));
const RenderPostDetail = lazy(() => import('../../screens/App/screens/Public/RenderPostDetailContainer'));
const CompanySpecificPublic = lazy(() =>
	import('../../screens/App/screens/Public/CompanyDashboardContainer/companyACT.js')
);
const CompanyPublic = lazy(() => import('../../screens/App/screens/Public/CompanyDashboardContainer/companyPublic'));
const CompanySpecificPublicDetails = lazy(() =>
	import('../../screens/App/screens/Public/CompanyDashboardContainer/companyACTDetails')
);
const MyProfile = lazy(() => import('../../screens/App/screens/Public/MyProfileContainer'));
const MyPortfolio = lazy(() => import('../../screens/App/screens/Public/MyPortfolioContainer'));
const AboutUs = lazy(() => import('../../screens/App/screens/Public/AboutUs'));
const FAQ = lazy(() => import('../../screens/App/screens/Public/FAQContainer'));
const Privacy = lazy(() => import('../../screens/App/screens/Public/PrivacyContainer'));
const Guidelines = lazy(() => import('../../screens/App/screens/Public/GuidelinesContainer'));
const TermsofUse = lazy(() => import('../../screens/App/screens/Public/TermsofUseContainer'));
const StudentDashBoard = lazy(() => import('../../screens/App/screens/Public/StudentDashBoardContainer'));
const Readings = lazy(() => import('../../screens/App/screens/Public/ReadingsContainer'));
const Articles = lazy(() => import('../../screens/App/screens/Public/ArticlesContainer'));
const Event = lazy(() => import('../../screens/App/screens/Public/EventContainer'));
const Events = lazy(() => import('../../screens/App/screens/Public/EventsContainer'));
const Jobs = lazy(() => import('../../screens/App/screens/Public/JobsContainer'));
const Job = lazy(() => import('../../screens/App/screens/Public/JobContainer'));
const Internships = lazy(() => import('../../screens/App/screens/Public/InternshipsContainer'));

const Default = lazy(() => import('../../screens/App/default.js'));
const Clubs = lazy(() => import('../../screens/App/screens/Public/ClubsContainer'));
const Club = lazy(() => import('../../screens/App/screens/Public/ClubContainer'));
const SkillsDashboard = lazy(() => import('../../screens/App/screens/Public/SkillsDashboardContainer'));
const ShortlistResults = lazy(() =>
	import('../../screens/App/screens/Public/SkillsDashboardContainer/shortlistResults.js')
);
const CreateProfile = lazy(() => import('../../screens/App/screens/Users/UsersSignUp/UserProfile'));
const JobRoles = lazy(() => import('../../screens/App/screens/Public/JobRolesContainer'));
const SelectJobRoles = lazy(() => import('../../screens/App/screens/Public/JobRolesContainer/selectJobRoles'));
const JobDashboard = lazy(() => import('../../screens/App/screens/Public/JobDashboardContainer'));
const JobDashboardMobile = lazy(() =>
	import('../../screens/App/screens/Public/JobDashboardContainer/jobDashboardMobile')
);
const JobDashboardActivityLog = lazy(() =>
	import('../../screens/App/screens/Public/JobDashboardContainer/activityLog.js')
);

const EventDashboard = lazy(() => import('../../screens/App/screens/Public/EventDashboardContainer'));
const ClubDashboard = lazy(() => import('../../screens/App/screens/Public/ClubDashboardContainer'));
const InstituteDashboard = lazy(() => import('../../screens/App/screens/Public/InstituteDashboardContainer'));
const InstitutePublic = lazy(() =>
	import('../../screens/App/screens/Public/InstituteDashboardContainer/institutePublic.js')
);
const CompanyDashboard = lazy(() => import('../../screens/App/screens/Public/CompanyDashboardContainer'));
const CompanyDashboardMobile = lazy(() =>
	import('../../screens/App/screens/Public/CompanyDashboardContainer/companyDashboardMobile')
);
const EnrollmentDashboard = lazy(() => import('../../screens/App/screens/Public/EnrollmentDashboardContainer'));
const TrainingEnrollmentDashboard = lazy(() =>
	import('../../screens/App/screens/Public/TrainingEnrollmentDashboardContainer')
);
const MentorDashboard = lazy(() => import('../../screens/App/screens/Public/MentorDashboardContainer'));
const InstituteMentor = lazy(() => import('../../screens/App/screens/Public/InstituteMentorContainer'));
const WinterInternship = lazy(() => import('../../screens/App/screens/Public/Trending/internship/winter'));
const SummerInternship = lazy(() => import('../../screens/App/screens/Public/Trending/internship/summer'));
const StudyInUsa = lazy(() => import('../../screens/App/screens/Public/Trending/studyInUsa'));
const MachineLearningProgram = lazy(() => import('../../screens/App/screens/Public/Trending/machineLearningProgram'));
const DigitalMarketingProgram = lazy(() => import('../../screens/App/screens/Public/Trending/digitalMarketingProgram'));
const AdvancedPlacementSkillsProgram = lazy(() =>
	import('../../screens/App/screens/Public/Trending/advancedPlacementSkillProgram')
);
const FullStackDevProgram = lazy(() => import('../../screens/App/screens/Public/Trending/fullStackDevProgram'));
const XceleratorCertificationProgram = lazy(() =>
	import('../../screens/App/screens/Public/Trending/xceleratorCertificationProgram')
);
const BITESProject = lazy(() => import('../../screens/App/screens/Public/Trending/bitesProject'));
const CareerReadinessPrograms = lazy(() => import('../../screens/App/screens/Public/Trending/careerReadinessProgram'));
// const XHackathon = lazy(() =>
// 	import("../../screens/App/screens/Public/Trending/xHackathonFIU")
// );
const ProjectDashboard = lazy(() => import('../../screens/App/screens/Public/ProjectDashboardContainer'));
const QuestStatsDashboard = lazy(() => import('../../screens/App/screens/Public/QuestStatsDashboardContainer'));
const AssignmentStatsDashboard = lazy(() =>
	import('../../screens/App/screens/Public/AssignmentStatsDashboardContainer')
);
const QuizStatsDashboard = lazy(() => import('../../screens/App/screens/Public/QuizStatsDashboardContainer'));
const QuestionBankStatsDashboard = lazy(() => import('../../screens/App/screens/Public/QuestionBankStatsDashboardContainer'));
const ResourceCreation = lazy(() => import('../../screens/App/screens/Public/ResourceCreationContainer'));
const Resource = lazy(() => import('../../screens/App/screens/Public/ResourceContainer'));
const ResourceGroup = lazy(() => import('../../screens/App/screens/Public/ResourceGroupContainer'));
const MyQuizBankContainer = lazy(() => import('../../screens/App/screens/Public/MyQuizBankContainer'));
const QuizBank = lazy(() => import('../../screens/App/screens/Public/QuestionBankContainer/questionBankPathView.js'));
const MyResourceGroup = lazy(() => import('../../screens/App/screens/Public/MyResourceGroupContainer'));
const Resources = lazy(() => import('../../screens/App/screens/Public/ResourcesContainer'));
const ResourceGroups = lazy(() => import('../../screens/App/screens/Public/ResourceGroupsContainer'));
const ResourceGroupCreation = lazy(() => import('../../screens/App/screens/Public/ResourceGroupCreationContainer'));
const QuestCreation = lazy(() => import('../../screens/App/screens/Public/QuestCreationContainer'));
const Quest = lazy(() => import('../../screens/App/screens/Public/QuestContainer'));
const MyQuest = lazy(() => import('../../screens/App/screens/Public/MyQuestContainer'));
const Quests = lazy(() => import('../../screens/App/screens/Public/QuestsContainer'));
const ProjectCreation = lazy(() => import('../../screens/App/screens/Public/ProjectCreationContainer'));
const QuizCreation = lazy(() => import('../../screens/App/screens/Public/QuizCreationContainer'));
const QuizBankCreation = lazy(() => import('../../screens/App/screens/Public/QuizBankCreationContainer'));
const Quiz = lazy(() => import('../../screens/App/screens/Public/QuizContainer'));
const QuestionBank = lazy(() => import('../../screens/App/screens/Public/QuestionBankContainer'));
const QuizBankView = lazy(() => import('../../screens/App/screens/Public/QuizBankContainer'));
const QuestDashboard = lazy(() => import('../../screens/App/screens/Public/QuestDashboardContainer'));
const Scorecard = lazy(()=> import('../../screens/App/screens/Public/ScorecardContainer'));
const QuizBankScorecard = lazy(()=> import('../../screens/App/screens/Public/QuizBankScorecardContainer'));
const QuestGraphView = lazy(()=> import('../../screens/App/screens/Public/QuestDashboardGraphContainer'));
const QuizAnswers = lazy(() => import('../../screens/App/screens/Public/QuizAnswersViewContainer'));
const QuizByteAnswers = lazy(() => import('../../screens/App/screens/Public/MyQuizByteAnswersViewContainer'));
const QuestionBankAnswers = lazy(() => import('../../screens/App/screens/Public/QuestionBankAnswersViewContainer'));
const DetailedQuestionBankAnswers = lazy(() => import('../../screens/App/screens/Public/QuestionBankAnswersViewContainer/detailedAnswersView.js'));
const UnapprovedProfile = lazy(() => import('../../screens/App/screens/Public/UnapprovedProfileContainer'));
const AccountDisabled = lazy(() => import('../../screens/App/screens/Public/AccountDisabledContainer'));
const Error = lazy(() => import('../../screens/App/screens/Public/ErrorContainer'));
const PaymentRedirect = lazy(() => import('../../screens/App/screens/Public/PaymentsContainer/paymentRedirect'));
// const DegreeProgram = lazy(() =>
// 	import("../../screens/App/screens/Public/Trending/degreeProgram")
// );
// const GenericInstitute = lazy(() =>
// 	import("../../screens/App/screens/Public/GenericInstituteContainer")
// );

const AssignmentCreate = lazy(() => import('../../screens/App/screens/Public/AssignmentCreationContainer'));
const Assignment = lazy(() => import('../../screens/App/screens/Public/AssignmentContainer'));
const MyAssignment = lazy(() => import('../../screens/App/screens/Public/MyAssignmentContainer'));
// const ExamPrepBootcamp = lazy(() =>
// 	import("../../screens/App/screens/Public/LandingPageContainer/examPrepBootcamp")
// );
const SkillAugmentation = lazy(() => import('../../screens/App/screens/Public/LandingPageContainer/skillAugmentation'));

const SelfPacedProgram = lazy(() => import('../../screens/App/screens/Public/LandingPageContainer/selfPaced'));

const BulkEnrollmentComponent = lazy(() =>
	import('../../screens/App/components/commonComponent/BulkEnrollmentComponent')
);

const BulkProfileUpdationComponent = lazy(() =>
	import('../../screens/App/components/commonComponent/BulkProfileUpdationComponent')
);

const BulkPortfolioUpdationComponent = lazy(() =>
	import('../../screens/App/components/commonComponent/BulkPortfolioUpdation')
);

const MultipleBulkEnrollComponent = lazy(() =>
	import('../../screens/App/components/commonComponent/MultipleBulkEnrollComponent')
);

const MultipleAdvisorBulkEnrollComponent = lazy(() =>
	import('../../screens/App/components/commonComponent/MultipleAdvisorBulkEnrollComponent')
);

const AdvisorEnrollComponent = lazy(() =>
	import('../../screens/App/components/commonComponent/AdvisorEnrollComponent')
);
const AdminDashboard = lazy(() => import('../../screens/App/screens/Public/AdminDashboardContainer'));
const CollegeAdminDashboard = lazy(() => import('../../screens/App/screens/Public/CollegeAdminDashboardContainer'));
const PaymentPage = lazy(() => import('../../screens/App/screens/Public/PaymentsContainer/paymentPage'));
const PaymentCreation = lazy(() => import('../../screens/App/screens/Public/PaymentsContainer/paymentCreation'));

// const XETStudentFormComponent = lazy(() =>
// 	import("../../screens/App/components/commonComponent/XETStudentFormComponent")
// );
const AccountActionsComponent = lazy(() => import('../../screens/App/screens/Users/AccountActions'));

const InstaCatalog = lazy(() => import('../../screens/App/screens/Public/LandingPageContainer/instaCatalog'));

const InstitutePermisssion = lazy(() => import('../../screens/App/screens/Public/InstitutePermissionContainer'));
const InstitutePermisssionCreation = lazy(() =>
	import('../../screens/App/screens/Public/InstitutePermissionCreationContainer')
);

const QuizDashboardContainer = lazy(() =>
	import('../../screens/App/screens/Public/QuizDashboardContainer/index')
);

const ActivityDashboardContainer = lazy(() =>
	import('../../screens/App/screens/Public/ActivityDashboardContainer/index')
);



const AdminPermisssion = lazy(() => import('../../screens/App/screens/Public/adminPermission'));

const WingsProgram = lazy(() => import('../../screens/App/screens/Public/Trending/wingsProgram'));
const DataAnalyticsTermsNdCond = lazy(() => import('../../screens/App/screens/Public/Trending/wingsTC/dataAnalytics'));
const WebApplicationTermsNdCond = lazy(() => import('../../screens/App/screens/Public/Trending/wingsTC/webApplication.js'));
// const QuestionBankCreation = lazy(() => import('../../screens/App/screens/Public/QuestionBankCreationContainer'));

// Scrolls to the top on route change
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

// HOC to add meta data through react helmet to a components
const withMetaData = (WrappedComponent, pageMetaData) => {
	const WrapperComponent = (props) => [
		<HelmetComponent key="helmet" {...pageMetaData} />,
		<WrappedComponent key="component" {...props} />,
	];
	return WrapperComponent;
};

const Root = (props) => {
	const { store } = props;
	return (
		<Provider store={store}>
			<Router history={history}>
				<App>
					<Suspense fallback={<LoaderComponent />}>
						<ScrollToTop />
						<Switch>
							<Route exact path="/" component={Default} />

							{/* Home pages */}
							<Route path="/Home" component={withMetaData(Home, metaData.home)} />
							<Route path="/MentorHome" component={withMetaData(MentorHome, metaData.mentorHome)} />
							<Route
								path="/InstituteHome"
								component={withMetaData(InstituteHome, metaData.instituteHome)}
							/>
							<Route path="/CompanyHome" component={withMetaData(CompanyHome, metaData.companyHome)} />
							{/* <Route
								path="/myGyanVihar"
								component={withMetaData(GenericInstitute, metaData.default)}
							/> */}
							{/* Home pages */}

							{/* Static pages */}
							<Route path="/Aboutus" component={withMetaData(AboutUs, metaData.aboutUs)} />
							<Route path="/FAQ" component={withMetaData(FAQ, metaData.faq)} />
							<Route path="/Privacy" component={withMetaData(Privacy, metaData.privacy)} />
							<Route path="/Guidelines" component={withMetaData(Guidelines, metaData.guidelines)} />
							<Route path="/TermsofUse" component={withMetaData(TermsofUse, metaData.termsOfUse)} />
							<Route path="/Copyright" component={withMetaData(Copyright, metaData.copyright)} />
							{/* Static pages */}

							{/* Landing pages */}
							{/* <Route
								path="/examPrepBootcampVTU"
								component={withMetaData(ExamPrepBootcamp, metaData.examPrep)}
							/> */}
							<Route
								path="/skillAugmentationProgram"
								component={withMetaData(SkillAugmentation, metaData.skillAug)}
							/>
							<Route
								path="/selfPacedPrograms"
								component={withMetaData(SelfPacedProgram, metaData.selfPaced)}
							/>
							<Route path="/instaCatalog" component={withMetaData(InstaCatalog, metaData.default)} />
							<Route
								path="/trending/BITESProject"
								component={withMetaData(BITESProject, metaData.bites)}
							/>
							{/* <Route
								path="/trending/specializedDegreePrograms"
								component={withMetaData(DegreeProgram, metaData.degreeProgram)}
							/> */}
							{/* <Route
								path="/trending/projectChallenge"
								component={withMetaData(XHackathon, metaData.hackathon)}
							/> */}
							<Route
								path="/trending/CareerReadinessPrograms"
								component={withMetaData(CareerReadinessPrograms, metaData.careerReadiness)}
							/>
							<Route path="/trending" component={withMetaData(WinterInternship, metaData.trending)} />
							<Route
								path="/Winter-Internship"
								component={withMetaData(WinterInternship, metaData.winterInternship)}
							/>
							<Route
								path="/Summer-Internship"
								component={withMetaData(SummerInternship, metaData.summerInternship)}
							/>
							{/* <Route
								path="/xetStudentForm"
								component={withMetaData(
									XETStudentFormComponent,
									metaData.default
								)}
							/> */}
							<Route path="/study-in-usa" component={withMetaData(StudyInUsa, metaData.studyInUsa)} />
							<Route
								path="/machine-learning-program"
								component={withMetaData(MachineLearningProgram, metaData.default)}
							/>
							<Route
								path="/digital-marketing-program"
								component={withMetaData(DigitalMarketingProgram, metaData.default)}
							/>
							<Route
								path="/advanced-placement-skills-program"
								component={withMetaData(AdvancedPlacementSkillsProgram, metaData.default)}
							/>
							<Route
								path="/job-assured-program/full-stack-software-development"
								component={withMetaData(FullStackDevProgram, metaData.default)}
							/>
							<Route
								path="/certification-program"
								component={withMetaData(XceleratorCertificationProgram, metaData.default)}
							/>
							{/* Landing pages */}

							{/* Projects */}
							<Route path="/projects/:id" component={withMetaData(Project, metaData.projects)} />
							<Route path="/projects" component={withMetaData(Projects, metaData.projects)} />
							<Route path="/myProjects/:id" component={withMetaData(MyProject, metaData.default)} />
							<Route
								path="/projectPricing/:id"
								component={withMetaData(ProjectPricing, metaData.projects)}
							/>
							<Route
								path="/projectDashboard"
								component={withMetaData(ProjectDashboard, metaData.default)}
							/>
							<Route
								path="/createProject/:id"
								component={withMetaData(ProjectCreation, metaData.default)}
							/>
							<Route path="/createProject" component={withMetaData(ProjectCreation, metaData.default)} />
							{/* Projects */}

							{/* Quests */}
							<Route
								path="/questStatsDashboard"
								component={withMetaData(QuestStatsDashboard, metaData.default)}
							/>
							<Route
								path="/myQuests/:my_quest_id/projects/:id"
								component={withMetaData(Project, metaData.projects)}
							/>
							<Route
								path="/myQuests/:my_quest_id/myProjects/:id"
								component={withMetaData(MyProject, metaData.default)}
							/>
							<Route
								path="/myQuests/:my_quest_id/resourceGroups/:id"
								component={withMetaData(ResourceGroup, metaData.resourceGroups)}
							/>
							<Route
								path="/myQuests/:my_quest_id/myLearning/:my_resource_group_id/resources/:id"
								component={withMetaData(Resource, metaData.resources)}
							/>
							<Route
								path="/myQuests/:my_quest_id/myLearning/:id"
								component={withMetaData(MyResourceGroup, metaData.default)}
							/>
							<Route path="/myQuests/:id" component={withMetaData(MyQuest, metaData.default)} />
							<Route
								path="/questDashboard/:id"
								component={withMetaData(QuestDashboard, metaData.default)}
							/>
							<Route
								path="/scorecard/:id"
								component={withMetaData(Scorecard, metaData.default)}
							/>
							<Route
								path="/quizBankScorecard/:id"
								component={withMetaData(QuizBankScorecard, metaData.default)}
							/>
							<Route
								path="/questGraph/:id"
								component={withMetaData(QuestGraphView, metaData.default)}
							/>
							<Route path="/quests/:id" component={withMetaData(Quest, metaData.quests)} />
							<Route path="/quests" component={withMetaData(Quests, metaData.quests)} />
							<Route path="/createQuest" component={withMetaData(QuestCreation, metaData.default)} />
							<Route path="/editQuest/:id" component={withMetaData(QuestCreation, metaData.default)} />
							{/* Quests */}

							{/* Resources */}
							<Route path="/resources/:id" component={withMetaData(Resource, metaData.resources)} />
							<Route
								path="/resourceGroups/:resource_group_id/resources/:id"
								component={withMetaData(Resource, metaData.resources)}
							/>
							<Route path="/resources" component={withMetaData(Resources, metaData.resources)} />
							<Route
								path="/createResource"
								component={withMetaData(ResourceCreation, metaData.default)}
							/>
							<Route
								path="/editResource/:id"
								component={withMetaData(ResourceCreation, metaData.default)}
							/>
							{/* Resources */}

							{/* Resource Groups */}
							<Route
								path="/myLearning/:my_resource_group_id/resources/:id"
								component={withMetaData(Resource, metaData.resources)}
							/>
							<Route path="/myLearning/:id" component={withMetaData(MyResourceGroup, metaData.default)} />
							<Route
								path="/resourceGroups/:id"
								component={withMetaData(ResourceGroup, metaData.resourceGroups)}
							/>
							<Route
								path="/resourceGroups"
								component={withMetaData(ResourceGroups, metaData.resourceGroups)}
							/>
							<Route
								path="/createResourceGroup"
								component={withMetaData(ResourceGroupCreation, metaData.default)}
							/>
							<Route
								path="/editResourceGroup/:id"
								component={withMetaData(ResourceGroupCreation, metaData.default)}
							/>
							{/* Resource Groups */}

							{/* Quiz */}
							<Route path="/createQuiz/:id" component={withMetaData(QuizCreation, metaData.quiz)} />
							<Route
								path="/quizStatsDashboard"
								component={withMetaData(QuizStatsDashboard, metaData.default)}
							/>
							<Route path="/createQuiz" component={withMetaData(QuizCreation, metaData.quiz)} />
							<Route path="/quiz/:id" component={withMetaData(Quiz, metaData.quiz)} />
							<Route path="/myquiz/:id" component={withMetaData(QuizAnswers, metaData.quiz)} />
							{/* Quiz */}

							{/* Question Bank */}
							<Route path="/createQuizBank/:id" component={withMetaData(QuizBankCreation, metaData.questionBank)} />
							<Route
								path="/questionBankStatsDashboard"
								component={withMetaData(QuestionBankStatsDashboard, metaData.default)}
							/>
							<Route
								path="/questionBankView/:id"
								component={withMetaData(QuizBank, metaData.questionBank)}
							/>
							<Route path="/createQuizBank" component={withMetaData(QuizBankCreation, metaData.questionBank)} />
							<Route path="/questionBank/:id" component={withMetaData(QuestionBank, metaData.questionBank)} />
							<Route path="/quizByte/:id" component={withMetaData(QuizBankView, metaData.questionBank)} />
							<Route path="/myquizbank/:id/" component={withMetaData(MyQuizBankContainer, metaData.questionBank)} />
							<Route path="/myquestionbank/:id" component={withMetaData(QuestionBankAnswers, metaData.questionBank)} />
							<Route path="/myquestionbankdetails/:id" component={withMetaData(DetailedQuestionBankAnswers, metaData.questionBank)} />
							<Route path="/myquizbyte/:id" component={withMetaData(QuizByteAnswers, metaData.questionBank)} />

							{/* Question Bank */}

							{/* Activity */}
							<Route
								path="/createActivity/:id"
								component={withMetaData(AssignmentCreate, metaData.activity)}
							/>
							<Route
								path="/assignmentStatsDashboard"
								component={withMetaData(AssignmentStatsDashboard, metaData.default)}
							/>
							<Route
								path="/createActivity"
								component={withMetaData(AssignmentCreate, metaData.activity)}
							/>
							<Route path="/activity/:id" component={withMetaData(Assignment, metaData.activity)} />
							<Route path="/myActivity/:id" component={withMetaData(MyAssignment, metaData.activity)} />
							<Route
								path="/peerLearning/:id"
								component={withMetaData(PeerActivity, metaData.activity)}
							/>
							{/* Activity */}

							{/* Clubs */}
							<Route path="/clubDashboard/:id" component={withMetaData(ClubDashboard, metaData.clubs)} />
							<Route path="/clubs/:id" component={withMetaData(Club, metaData.clubs)} />
							<Route path="/clubs" component={withMetaData(Clubs, metaData.clubs)} />
							{/* Clubs */}

							{/* Jobs and Internships */}
							<Route path="/jobs/:id" component={withMetaData(Job, metaData.jobs)} />
							<Route
								path="/jobDashboardMobile/:id/activityLog"
								component={withMetaData(JobDashboardActivityLog, metaData.default)}
							/>
							<Route
								path="/jobDashboardMobile/:id"
								component={withMetaData(JobDashboardMobile, metaData.default)}
							/>
							<Route path="/jobDashboard/:id" component={withMetaData(JobDashboard, metaData.default)} />
							<Route path="/jobs" component={withMetaData(Jobs, metaData.jobs)} />
							<Route path="/jobRoles" component={withMetaData(JobRoles, metaData.default)} />
							<Route path="/internships/:id" component={withMetaData(Job, metaData.internships)} />
							<Route path="/internships" component={withMetaData(Internships, metaData.internships)} />
							{/* Jobs and Internships */}

							{/* Events */}
							<Route
								path="/eventDashboard/:id"
								component={withMetaData(EventDashboard, metaData.default)}
							/>
							<Route path="/events/:id" component={withMetaData(Event, metaData.events)} />
							<Route path="/events" component={withMetaData(Events, metaData.events)} />
							{/* Events */}

							{/* Readings */}
							<Route path="/books" component={withMetaData(Readings, metaData.default)} />
							<Route path="/articles" component={withMetaData(Articles, metaData.default)} />
							{/* Readings */}

							{/* Portfolio */}
							<Route
								path="/myPortfolio/:card"
								component={withMetaData(MyPortfolio, metaData.portfolio)}
							/>
							<Route path="/myPortfolio" component={withMetaData(MyPortfolio, metaData.portfolio)} />
							<Route path="/portfolio/:id" component={withMetaData(MyPortfolio, metaData.portfolio)} />
							<Route path="/selectJobRoles" component={withMetaData(SelectJobRoles, metaData.default)} />
							{/* Portfolio */}

							{/* All Users */}
							<Route path="/myprofile" component={withMetaData(MyProfile, metaData.default)} />
							<Route path="/createProfile" component={withMetaData(CreateProfile, metaData.default)} />
							<Route path="/posts/:id" component={withMetaData(RenderPostDetail, metaData.posts)} />
							<Route path="/posts" component={withMetaData(RenderPosts, metaData.posts)} />
							<Route path="/partners/:type" component={withMetaData(CompanyView, metaData.posts)} />
							<Route
								path="/unapprovedProfile"
								component={withMetaData(UnapprovedProfile, metaData.default)}
							/>
							<Route
								path="/disabledProfile"
								component={withMetaData(AccountDisabled, metaData.default)}
							/>
							{/* All Users */}

							{/* Dashboard */}
							<Route path="/dashboard" component={withMetaData(StudentDashBoard, metaData.student)} />
							<Route path="/mentorDashboard" component={withMetaData(MentorDashboard, metaData.mentor)} />
							<Route
								path="/instituteDashboard"
								component={withMetaData(InstituteDashboard, metaData.institute)}
							/>
							<Route
								path="/companyDashboardMobile"
								component={withMetaData(CompanyDashboardMobile, metaData.default)}
							/>
							<Route
								path="/companyDashboard"
								component={withMetaData(CompanyDashboard, metaData.company)}
							/>
							{/* Dashboard */}

							{/* Company */}
							<Route
								path="/companyPublic/:id/:name/Details"
								component={withMetaData(CompanySpecificPublicDetails, metaData.company)}
							/>
							<Route
								path="/companyPublic/:id/:name"
								component={withMetaData(CompanySpecificPublic, metaData.company)}
							/>
							<Route path="/company/:id/jobs" component={withMetaData(Jobs, metaData.jobs)} />
							<Route path="/company/:id/events" component={withMetaData(Events, metaData.events)} />
							<Route path="/company/:id/clubs" component={withMetaData(Clubs, metaData.clubs)} />
							<Route path="/company/:id/mentors" component={withMetaData(Mentors, metaData.default)} />
							<Route path="/company/:id" component={withMetaData(CompanyPublic, metaData.company)} />
							<Route
								path="/skillsDashboard"
								component={withMetaData(SkillsDashboard, metaData.default)}
							/>
							<Route path="/shortlist" component={withMetaData(ShortlistResults, metaData.default)} />
							{/* Company */}

							{/* Institute */}
							<Route
								path="/enrollmentDashboard"
								component={withMetaData(EnrollmentDashboard, metaData.default)}
							/>
							<Route
								path="/instituteMentor"
								component={withMetaData(InstituteMentor, metaData.default)}
							/>
							<Route path="/institute/:id" component={withMetaData(InstitutePublic, metaData.default)} />
							<Route
								path="/trainingEnrollmentDashboard"
								component={withMetaData(TrainingEnrollmentDashboard, metaData.default)}
							/>
							{/* Institute */}

							{/* Mentors */}
							<Route path="/adminDashboard" component={withMetaData(AdminDashboard, metaData.default)} />
							<Route
								path="/college-admin"
								component={withMetaData(CollegeAdminDashboard, metaData.default)}
							/>
							<Route
								path="/bulkEnrollment"
								component={withMetaData(BulkEnrollmentComponent, metaData.default)}
							/>

							<Route
								path="/bulkProfileUpdation"
								component={withMetaData(BulkProfileUpdationComponent, metaData.default)}
							/>

							<Route
								path="/bulkPortfolioUpdation"
								component={withMetaData(BulkPortfolioUpdationComponent, metaData.default)}
							/>

							<Route
								path="/multipleBulkEnroll"
								component={withMetaData(MultipleBulkEnrollComponent, metaData.default)}
							/>

							<Route
								path="/multipleAdvisorBulkEnroll"
								component={withMetaData(MultipleAdvisorBulkEnrollComponent, metaData.default)}
							/>

							<Route
								path="/advisorBulkEnrollment"
								component={withMetaData(AdvisorEnrollComponent, metaData.default)}
							/>
							{/* Mentors */}

							{/* Payment */}
							<Route
								path="/paymentRedirect"
								component={withMetaData(PaymentRedirect, metaData.default)}
							/>
							<Route path="/payments/:id" component={withMetaData(PaymentPage, metaData.default)} />
							<Route path="/createPayment" component={withMetaData(PaymentCreation, metaData.default)} />
							<Route
								path="/editPayment/:id"
								component={withMetaData(PaymentCreation, metaData.default)}
							/>
							{/* Payment */}

							{/* Error */}
							<Route path="/unauthorized" component={withMetaData(Error, metaData.default)} />
							<Route path="/error" component={withMetaData(Error, metaData.default)} />
							<Route
								path="/accounts"
								component={withMetaData(AccountActionsComponent, metaData.default)}
							/>
							<Route
								path="/institutePermission"
								component={withMetaData(InstitutePermisssion, metaData.default)}
							/>
							<Route
								path="/createInstitutePermission"
								component={withMetaData(InstitutePermisssionCreation, metaData.default)}
							/>
							<Route
								path="/updateInstitutePermission/:id"
								component={withMetaData(InstitutePermisssionCreation, metaData.default)}
							/>

							<Route path="/adminAccess" component={withMetaData(AdminPermisssion, metaData.default)} />
							<Route
								path="/interventionEditAccess"
								component={withMetaData(AdminPermisssion, metaData.default)}
							/>
							<Route
								path="/quizDashboard/:id"
								component={withMetaData(QuizDashboardContainer, metaData.default)}
							/>
							<Route
								path="/activityDashboard/:id"
								component={withMetaData(ActivityDashboardContainer, metaData.default)}
							/>
							<Route
								path="/wings-program"
								component={withMetaData(WingsProgram, metaData.default)}
							/>

							<Route
								path="/wings-data-analytics-tc"
								component={withMetaData(DataAnalyticsTermsNdCond, metaData.default)}
							/>

							<Route
								path="/wings-web-app-tc"
								component={withMetaData(WebApplicationTermsNdCond, metaData.default)}
							/>
							<Route component={withMetaData(Error, metaData.default)} />

							{/* Error */}
						</Switch>
					</Suspense>
				</App>
			</Router>
		</Provider>
	);
};

export default Root;
