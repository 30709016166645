/* eslint-disable */
import dayjs from 'dayjs';

function getRegexForUrl() {
	return new RegExp(
		"^" +
		// protocol identifier
		"(?:(?:https?|ftp)://)" +
		// user:pass authentication
		"(?:\\S+(?::\\S*)?@)?" +
		"(?:" +
		// IP address exclusion
		// private & local networks
		"(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
		"(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
		"(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
		// IP address dotted notation octets
		// excludes loopback network 0.0.0.0
		// excludes reserved space >= 224.0.0.0
		// excludes network & broacast addresses
		// (first & last IP address of each class)
		"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
		"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
		"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
		"|" +
		// host name
		"(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
		// domain name
		"(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
		// TLD identifier
		"(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
		// TLD may end with dot
		"\\.?" +
		")" +
		// port number
		"(?::\\d{2,5})?" +
		// resource path
		"(?:[/?#]\\S*)?" +
		"$",
		"i"
	);
}

export function getUrls() {
	return new RegExp(
		"(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))",
		"g"
	);
}

export function emptyValidator(target, fieldName) {
	if (target === null || target === undefined || (typeof target === "string" && target.trim() === "")) {
		throw new Error(fieldName + " cannot be empty");
	} else {
		return true;
	}
}

export function minMaxValidator(target, min, max, fieldName) {
	if (!target) {
		throw new Error(fieldName + " cannot be empty");
	}
	target = parseFloat(target, 10);
	if (target < min || target > max) {
		throw new Error(fieldName + " should be between " + min + " and " + max);
	}
	return true;
}

export function dateValidator(target, min, max, fieldName) {
	let dateFormat = "YYYY-MM-DD";
	if (!dayjs(target, dateFormat).isValid()) {
		throw new Error(fieldName + " cannot be empty");
	}
	let minDate = dayjs(min).format("YYYY-MM-DD");
	let maxDate = dayjs(max).format("YYYY-MM-DD");
	let targetDate = dayjs(target).format("YYYY-MM-DD");
	if (targetDate < minDate) {
		throw new Error(
			"Invalid date used for " +
			fieldName +
			". Should be after '" +
			minDate +
			"'"
		);
	}
	if (targetDate > maxDate) {
		throw new Error(
			"Invalid date used for " +
			fieldName +
			". Should not exceed '" +
			maxDate +
			"'"
		);
	}
	return true;
}

export function emailValidator(target, fieldName, isOptional) {
	if (!target && isOptional) {
		return true;
	}
	let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	let emailPattern = target.match(regex);
	if (emailPattern) {
		return true;
	}
	throw new Error("Enter a valid email id");
}

export function phoneNumberValidator(target, fieldName, isOptional) {
	if (!target && isOptional) {
		return true;
	}
	let regex = /^[0-9]{10}$/;
	let phonePattern = target.match(regex);
	if (phonePattern) {
		return true;
	}
	throw new Error("Enter a valid, 10 digit ", fieldName);
}

export function urlValidator(target) {
	let regex = getRegexForUrl();
	if (!target.trim()) {
		return true;
	}
	let urlPattern = target.match(regex);
	return urlPattern;
}

export function ValidURL(str) {
	var regex = /http(s)?:\/\/.?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
	return regex.test(str);
}

//Function checks for the null values of the required inputs
export let checkVal = target => {
	if (target === null || target === undefined || target === "") {
		return false;
	} else {
		return true;
	}
};

export function runValidators(target, validators) {
	let val = null;
	for (var i = 0; i < validators.length; i++) {
		try {
			let result = validators[i](target);
		} catch (e) {
			return e.message;
		}
	}
	return val;
}

export function nameValidator(target, fieldName) {
	if (!target) {
		throw new Error(fieldName + " cannot be empty");
	}
	let regex = /^[A-Za-z .'-]{1,25}$/;
	let fieldNamePattern = target.match(regex);
	if (fieldNamePattern) {
		return true;
	}
	throw new Error(fieldName + " cannot exceed 25 characters");
}

export function mobileValidator(target, fieldName) {
	if (!target) {
		throw new Error(fieldName + " cannot be empty");
	}
	let regex = /^[0-9]{10}$/;
	let mobilePattern = target.match(regex);
	if (mobilePattern) {
		return true;
	}
	throw new Error("Enter your 10 digit mobile number");
}

export function minValidators(target, fieldName) {
	target = parseInt(target);
	if (
		target === null ||
		target === undefined ||
		target === "" ||
		target === " " ||
		isNaN(target)
	) {
		throw new Error(fieldName + " cannot be empty");
	} else if (target < 1) {
		throw new Error(fieldName + " cannot be less than 1");
	} else return true;
}

export function salaryValidators(target, fieldName) {
	target = parseInt(target);
	if (
		target === null ||
		target === undefined ||
		isNaN(target) ||
		target === " " ||
		target === ""
	) {
		throw new Error(fieldName + " cannot be empty");
	} else if (target < 1) {
		throw new Error(fieldName + " cannot be less than 1");
	} else return true;
}

export function resolvePrevRoute(prevRoute, pathToCheck) {
	var path = prevRoute ? prevRoute.split("/") : [];
	if (path.length === 3) {
		return path[1] === pathToCheck && !isNaN(parseInt(path[2]));
	} else return false;
}

export function validateString(value) {
	if (value === null || value === undefined || (typeof value === "string" && value.trim() === ""))
		return false;
	else return true;
}

export function validateDropDownContent(value, multi) {
	if (multi) return !value || value.length === 0 ? false : true;
	else return !value ? false : true;
}

export function validatePositiveNumber(value) {
	return !value || value <= 0 ? false : true;
}

export function validateNonDecimalNumber(value) {
	let regex = /^[0-9\b]+$/;
	return regex.test(value);
}

export function validateNumber(value) {
	return !value || value < 0 ? false : true;
}

// USE THIS
export function emailAddressValidator(value) {
	if (!value) return false;
	let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return value.match(regex);
}

// USE THIS
export function phoneNumberMobileValidator(target) {
	if (!target) return false;
	let regex = /^[0-9]{10}$/;
	return target.match(regex);
}
export function internationalPhoneNumberMobileValidator(target) {
  if (!target) return false;
  let regex = /^[0-9]{3,14}$/;
  return target.match(regex);
}

export function internationalPhoneNumberMobileCommonValidator(target, fieldName, isOptional, isAlreadyValid) {
  if (!target && isOptional) {
    return true;
  }
  if (isAlreadyValid)
    return true;
  let regex = /^[0-9]{3,14}$/;
  let phonePattern = target.match(regex);
  if (phonePattern) {
    return true;
  }
  throw new Error("Enter a valid phone number ", fieldName);
}

export function bulkEnrollmentPhoneNumberValidator(target) {
	if (!target) return false;
	let regex = /\+\d{3,14}$/;
	let phonePattern = target.match(regex);
	if (phonePattern)
		return true;
	else
		return false;
}

export function bulkEnrollmentIndianPhoneNumberValidator(target) {
	if (!target) return false;
	let regex = /^(\+91)[6789]\d{9}$/;
	let phonePattern = target.match(regex);
	if (phonePattern)
		return true;
	else
		return false;
}
export function validateWorkbenchIdNumber(value) {
	let regex = /^[0-9]+$/;
	return regex.test(value);
}
