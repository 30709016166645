export const CHANGE_IS_MOBILE = "CHANGE_IS_MOBILE";
export const CHANGE_PATH = "CHANGE_PATH";
export const CHANGE_WIDTH_AND_HEIGHT = "CHANGE_WIDTH_AND_HEIGHT";
export const SET_ERROR = "SET_ERROR";

export const REQUEST_PROFILES = "REQUEST_PROFILES";
export const RECEIVE_PROFILES = "RECEIVE_PROFILES";

export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS";
export const RECEIVE_MORE_NOTIFICATIONS = "RECEIVE_MORE_NOTIFICATIONS";
export const REQUEST_MORE_NOTIFICATIONS = "REQUEST_MORE_NOTIFICATIONS";

export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";

export const REQUEST_TAGGED_NOTIFICATIONS = "REQUEST_TAGGED_NOTIFICATIONS";
export const RECEIVE_TAGGED_NOTIFICATIONS = "RECEIVE_TAGGED_NOTIFICATIONS";

export const REQUEST_MORE_TAGGED_NOTIFICATIONS =
  "REQUEST_MORE_TAGGED_NOTIFICATIONS";
export const RECEIVE_MORE_TAGGED_NOTIFICATIONS =
  "RECEIVE_MORE_TAGGED_NOTIFICATIONS";
export const RECEIVE_MORE_TAGGED_NOTIFICATIONS_ERROR =
  "RECEIVE_NOTIFICATIONS_ERROR";

export const FETCH_PORTFOLIO = "FETCH_PORTFOLIO";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const FAILED_TO_FETCH_PORTFOLIO = "FAILED_TO_FETCH_PORTFOLIO";

export const RECEIVE_RECOMMENDED_EVENTS = "RECEIVE_RECOMMENDED_EVENTS";
export const RECEIVE_RECOMMENDED_JOBS = "RECEIVE_RECOMMENDED_JOBS";
export const RECEIVE_RECOMMENDED_READINGS = "RECEIVE_RECOMMENDED_READINGS";
export const RECEIVE_RECOMMENDED_CLUBS = "RECEIVE_RECOMMENDED_CLUBS";
export const RECEIVE_RECOMMENDED_ARTICLES = "RECEIVE_RECOMMENDED_ARTICLES";

export const REQUEST_RECOMMENDED_EVENTS = "REQUEST_RECOMMENDED_EVENTS";
export const REQUEST_RECOMMENDED_JOBS = "REQUEST_RECOMMENDED_JOBS";
export const REQUEST_RECOMMENDED_READINGS = "REQUEST_RECOMMENDED_READINGS";
export const REQUEST_RECOMMENDED_ARTICLES = "REQUEST_RECOMMENDED_ARTICLES";
export const REQUEST_RECOMMENDED_CLUBS = "REQUEST_RECOMMENDED_CLUBS";

export const UPDATE_SELECTED_ADD_CARD_STATUS =
  "UPDATE_SELECTED_ADD_CARD_STATUS";
export const RECEIVE_ADD_MODAL_DATA = "RECEIVE_ADD_MODAL_DATA";
export const RESET_ADD_CARD_MODAL_DATA = "RESET_ADD_CARD_MODAL_DATA";

export const FETCH_BOOKS = "FETCH_BOOKS";
export const FETCH_ARTICLES = "FETCH_ARTICLES";

export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const PROFILE_NOT_UPDATED = "PROFILE_NOT_UPDATED";

export const SET_LOGIN_ERROR = "SET__LOGIN_ERROR";

// Skills Dashboard
export const FETCH_FIELD_COUNT = "FETCH_FIELD_COUNT";
export const OVERALL_GRAPH_DATA = "OVERALL_GRAPH_DATA";
export const EDUCATION_GRAPH_DATA = "EDUCATION_GRAPH_DATA";
export const EXPERIENCE_GRAPH_DATA = "EXPERIENCE_GRAPH_DATA";
export const EXPOSURE_GRAPH_DATA = "EXPOSURE_GRAPH_DATA";
export const ACADEMIC_GRAPH_DATA = "ACADEMIC_GRAPH_DATA";
export const BRANCH_GRAPH_DATA = "BRANCH_GRAPH_DATA";

export const ACADEMIC_GRAPH_FILTERS = "ACADEMIC_GRAPH_FILTERS";
export const FETCHED_SHORTLISTED_DATA = "FETCHED_SHORTLISTED_DATA";

export const FETCH_FILTER_SET = "FETCH_FILTER_SET";
export const MAX_GRAPH_RANGE = "MAX_GRAPH_RANGE";

export const FETCH_OVERALL_SKILLS = "FETCH_OVERALL_SKILLS";
export const FETCH_EDUCATION_SKILLS = "FETCH_EDUCATION_SKILLS";
export const FETCH_EXPERIENCE_SKILLS = "FETCH_EXPERIENCE_SKILLS";
export const FETCH_EXPOSURE_SKILLS = "FETCH_EXPOSURE_SKILLS";
export const FETCH_INSTITUTE_SPLIT = "FETCH_INSTITUTE_SPLIT";
export const FETCH_DEGREE_SPLIT = "FETCH_DEGREE_SPLIT";
export const FETCH_EMAIL_AVAILABILITY = "FETCH_EMAIL_AVAILABILITY";
export const FETCH_PHONE_AVAILABILITY = "FETCH_PHONE_AVAILABILITY";
// Enrollment Dashboard
export const FETCHED_POSTS_TIMELINE = "FETCHED_POSTS_TIMELINE";
export const YEAR_GRAPH_DATA = "YEAR_GRAPH_DATA";
export const ENROLLMENT_BRANCH_DATA = "ENROLLMENT_BRANCH_DATA";
export const FETCH_ENROLLMENT_DASHBOARD_DATA =
  "FETCH_ENROLLMENT_DASHBOARD_DATA";
export const FETCH_INSTITUTE_PROJECT_COUNT = "FETCH_INSTITUTE_PROJECT_COUNT";
export const FETCH_INSTITUTE_PROJECT_SPLIT = "FETCH_INSTITUTE_PROJECT_SPLIT";
export const FETCH_INSTITUTE_PROJECT_TABLE = "FETCH_INSTITUTE_PROJECT_TABLE";
export const FETCH_INSTITUTE_PROJECTS = "FETCH_INSTITUTE_PROJECTS";
export const ACTIVE_PAYMENT_MODAL = "ACTIVE_PAYMENT_MODAL";
export const PROJECT_TYPE = "PROJECT";
export const QUEST_TYPE = "QUEST";
export const RESOURCE_GROUP_TYPE = "RESOURCE_GROUP";
export const PAYMENT_URL_LOADING = "PAYMENT_URL_LOADING";
export const ADD_PAYMENT_RESPONSE = "ADD_PAYMENT_RESPONSE";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CLOSE_PAYMENT_MODAL = "CLOSE_PAYMENT_MODAL";
export const RESET_PAYMENT_MODAL = "RESET_PAYMENT_MODAL";

export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const UNPROCESSABLE_ENTITY = 422;
export const INTERNAL_SERVER_ERROR = 500;
