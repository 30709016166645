import axios from 'axios';
import * as types from '../constants/ActionTypes';
import { navigateTo, navigateToAndReplace } from '../actions/navigator';
import { displayError, loginError } from '../actions/error';
import { constructCurrentUserUrl, constructUpdateProfileUrl, constructOauthVerificationUrl } from '../utils/AuthUtils.js';
import { constructOrgUrl, constructInstituteOrgUrl } from '../utils/CommonUtils.js';
import { constructFetchPortfolioUrl, constructFetchPublicPortfolioUrl, createProfileUrl, createPortfolioUrl, constructSkillsUrl, constructFetchClubStudentProfile } from '../utils/ProfileUtils.js';
import { constructJobRoleListUrl, constructAddJobRolesUrl } from '../utils/PortfolioUtils.js'
import { constructRecommendedClubsUrl, constructRecommendedEventsUrl, constructRecommendedJobsUrl, constructRecommendedReadingsUrl, constructRecommendedArticlesUrl } from '../utils/RecommendationUtils.js'
import { constructTestimonialUrl, constructJobRoleUrl } from '../utils/CommonUtils.js';
import { constructJobApplicantsDetailsUrl, constructEventApplicantsDetailsUrl, constructJobEnrollmentByCollegeUrl, constructProjectTeamStatsUrl, constructProjectEnrollmentCountUrl, constructProjectEnrollmentSplitUrl, constructEventEnrollmentCountUrl, constructEventTotalCountUrl, constructFetchNewClubUrl, constructJobInvitedApplicantsUrl } from '../utils/CompanyUtils.js'
import { constructFetchNewJobUrl } from '../utils/JobUtils.js'
import { constructJobProjectsUrl } from '../utils/ProjectUtils.js'
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import { errorHandler } from '../actions/errorHandler';
import firebase from '../firebase'; 
import * as Sentry from '@sentry/browser';

const Actions = {};

Actions.logout = function logout() {
	return dispatch => {
		firebase.auth().signOut()
		.then(() => {
			dispatch(Actions.logoutActions());
		}).catch((error) => {
			Sentry.captureException(error.toString())
		})
	}
};

Actions.logoutActions = function logoutActions() {
	return dispatch => {
		localStorage.clear();
		dispatch(navigateTo({ path: ['Home'] }))
		dispatch({ type: 'DELETE_CURRENT_USER' })
		dispatch({ type: "DELETE_PORTFOLIO" })
		dispatch({type: 'TOKEN_EXPIRED_CONDITION', data: {tokenExpired: true, callFetchRefresh: false}});
		dispatch({ type: "NOT_LOADING" });
	}
}

Actions.getCurrentUser = function getCurrentUser() {
	return dispatch => {
		const jwtToken = localStorage.access_token;
		if (jwtToken) {
			dispatch({ type: 'IS_LOADING' });
			axios
				.get(constructCurrentUserUrl(), {
					headers: { 'Authorization': localStorage.access_token }
				})
				.then(response => {
					dispatch({type: 'MERGE_IN_COMMON_REDUCER', key: 'loginPopupConfig', data: {showModal: false, isLogin: false}})
					if (response.status === 201) {
						dispatch({
							type: 'CURRENT_USER',
							user: response.data.user
						});
						if (response.data.user.profile === null) {
							dispatch(navigateTo({ path: ['createProfile'] }));
						}
						else {
							if (response.data.user.profile.profile_type === 'STUDENT') {
								if (!response.data.user.profile.active) {
									dispatch(navigateToAndReplace({ path: ['disabledProfile'] }))
								}
								else if (response.data.user.portfolio_job_roles.length === 0) {
									dispatch(navigateTo({ path: ['jobRoles'] }))
									dispatch(Actions.openAddJobRolesModal())
								} else if (!response.data.user.portfolio_job_roles[0].portfolio_id) {
									dispatch(navigateTo({ path: ['jobRoles'] }))
								}
							}
							else if (response.data.user.profile.profile_type === 'COMPANY' || response.data.user.profile.profile_type === 'INSTITUTION') {
								if (!response.data.user.profile.active) {
									dispatch(navigateToAndReplace({ path: ['unapprovedProfile'] }))
								}
							} else if(response.data.user.profile.profile_type === 'ADVISOR') {
								if (!response.data.user.profile.active) {
									dispatch(navigateToAndReplace({ path: ['disabledProfile'] }))
								}
							}
						}
					}
					else if (response.status === 404) {
						dispatch(navigateTo({ path: ['Home'] }))
					}
					else {
						dispatch({
							type: 'NOT_LOGGED_IN'
						})
					}
					dispatch({ type: 'NOT_LOADING' });
				})
				.catch(error => {
					dispatch({type: 'MERGE_IN_COMMON_REDUCER', key: 'loginPopupConfig', data: {showModal: false, isLogin: false}})
					if (error.status === 400) {
						dispatch(navigateTo({ path: ['Home'] }))
					}
					dispatch({
						type: 'NOT_LOGGED_IN'
					})
					dispatch({ type: 'NOT_LOADING' });
					dispatch(Actions.logoutActions());
				});
		}
	}
};

Actions.createProfile = function createProfile(profile, isMobile = false) {
	return dispatch => {
		dispatch({ type: 'COMPONENT_IS_LOADING' });
		dispatch({ type: 'APPLY_IS_LOADING' });
		axios.defaults.headers.common['Authorization'] = localStorage.access_token;
		axios
			.post(createProfileUrl(),
				{ profile: profile })
			.then(response => {
				if (response.status === 201) {
					if(window.dataLayer && typeof window.fbq === 'function') {
						window.dataLayer.push({
							registrationComplete: 'success',
							event: 'new_registration'
						})
					}
					dispatch(Actions.getCurrentUser(isMobile));
					dispatch({ type: 'APPLY_NOT_LOADING' });
					if (response.data.profile_type === 'STUDENT') {
						if (response.data.portfolio) {
							dispatch(navigateTo({ path: ['dashboard'] }))
						}
						else {
							dispatch(navigateTo({ path: ['jobRoles'] }))
							if (response.data.user.portfolio_job_roles.length === 0)
								dispatch(Actions.openAddJobRolesModal())
						}
					}
					else if (sessionStorage.getItem('redirectPath') !== null) {
						var path = sessionStorage.getItem('redirectPath')
						sessionStorage.removeItem('redirectPath')
						path = path.slice(1);
						dispatch(navigateTo({ path: [path] }))
					}
					else if (response.data.profile_type === 'COMPANY') {
						if (response.data.active) {
							if (!isMobile)
								dispatch(navigateTo({ path: ['companyDashboard'] }));
							else
								dispatch(navigateTo({ path: ['posts'] }));
						} else {
							dispatch(navigateTo({ path: ['unapprovedProfile'] }))
						}
					}
					else if (response.data.profile_type === 'INSTITUTION') {
						if (response.data.active) {
							if (!isMobile)
								dispatch(navigateTo({ path: ['instituteDashboard'] }));
							else
								dispatch(navigateTo({ path: ['posts'] }));
						} else {
							dispatch(navigateTo({ path: ['unapprovedProfile'] }))
						}
					}
					else if (response.data.profile_type === 'ADVISOR') {
						dispatch(navigateTo({ path: ['mentorDashboard'] }));
					}
					else {
						dispatch(navigateTo({ path: ['posts'] }));
					}
				}
				else
					dispatch(displayError("Profile Creation Failed. Please check input parameters."));

				dispatch({ type: 'COMPONENT_NOT_LOADING' });
			})
			.catch((_error) => {
				dispatch(loginError("Profile Creation Failed. Please check input parameters."));
				dispatch({ type: 'COMPONENT_NOT_LOADING' });
			});
	}
}

Actions.login = function login(isMobile) {
	return dispatch => {
		dispatch({ type: 'IS_LOADING' });
		axios.get(constructOauthVerificationUrl(), {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 201) {
					dispatch(loginError(""));
					dispatch(Actions.getCurrentUser(isMobile));
					if (response.data.user.profile !== null) {
						if (sessionStorage.getItem('redirectPath') !== null) {
							var path = sessionStorage.getItem('redirectPath')
							sessionStorage.removeItem('redirectPath')
							path = path.slice(1);
							dispatch(navigateToAndReplace({ path: [path] }))
						}
						else if (response.data.user.profile.profile_type === 'STUDENT') {
							if (response.data.user.profile.portfolio) {
								if (response.data.user.profile.active)
									dispatch(navigateToAndReplace({ path: ['dashboard'] }))
								else
									dispatch(navigateToAndReplace({ path: ['disabledProfile'] }))
							}
							else {
								if (!response.data.user.profile.active)
									dispatch(navigateToAndReplace({ path: ['disabledProfile'] }))
								else if (response.data.user.portfolio_job_roles.length === 0) {
									dispatch(navigateToAndReplace({ path: ['jobRoles'] }))
									dispatch(Actions.openAddJobRolesModal())
								} else if (!response.data.user.portfolio_job_roles[0].portfolio_id) {
									dispatch(navigateToAndReplace({ path: ['jobRoles'] }))
								} else {
									dispatch(navigateToAndReplace({ path: ['dashboard'] }))
								}
							}
						}
						else if (response.data.user.profile.profile_type === 'COMPANY') {
							if (response.data.user.profile.active) {
								if (!isMobile)
									dispatch(navigateToAndReplace({ path: ['companyDashboard'] }))
								else
									dispatch(navigateToAndReplace({ path: ['companyDashboardMobile'] }));
							} else {
								dispatch(navigateToAndReplace({ path: ['unapprovedProfile'] }))
							}
						}
						else if (response.data.user.profile.profile_type === 'INSTITUTION') {
							if (response.data.user.profile.active) {
								if (!isMobile)
									dispatch(navigateToAndReplace({ path: ['instituteDashboard'] }))
								else
									dispatch(navigateToAndReplace({ path: ['posts'] }));
							}
							else {
								dispatch(navigateToAndReplace({ path: ['unapprovedProfile'] }))
							}
						}
						else if (response.data.user.profile.profile_type === 'ADVISOR') {
							if (response.data.user.profile.active)
								dispatch(navigateToAndReplace({ path: ['mentorDashboard'] }))
							else
								dispatch(navigateToAndReplace({ path: ['disabledProfile'] }))
						}
					}
					else
						dispatch(navigateToAndReplace({ path: ['createProfile'] }));
				}
				else {
					dispatch(loginError("Please enter correct username and password"));
				}
			})
			.catch(_error => {
				dispatch(loginError("Please enter correct username and password"));
			})
	}
}

Actions.editProfile = function editProfile(profile) {
	return dispatch => {
		dispatch({ type: 'APPLY_IS_LOADING' });
		axios.defaults.headers.common['Authorization'] = localStorage.access_token;
		axios.post(constructUpdateProfileUrl(), { profile: profile })
			.then(response => {
				if (response.status === 201) {
					dispatch({ type: 'APPLY_NOT_LOADING' });
					dispatch({
						type: types.PROFILE_UPDATED
					})
					dispatch({ type: 'UPDATE_CURRENT_USER_PROFILE', data: response.data })
				}
				else {
					dispatch({ type: 'APPLY_NOT_LOADING' });
					dispatch({
						type: types.PROFILE_NOT_UPDATED
					})
				}
			})
			.catch(() => {
				dispatch({
					type: types.PROFILE_NOT_UPDATED
				})
				dispatch({ type: 'APPLY_NOT_LOADING' });
			})
	}
}

Actions.hideMyPlansModal = function hideMyPlansModal() {
	return {
		type: 'HIDE_MY_PLANS'
	}
}

Actions.openAddJobRolesModal = function openAddJobRolesModal() {
	return {
		type: 'SHOW_ADD_JOBS_MODAL'
	}
}

Actions.fetchPortfolio = function fetchPortfolio() {
	return dispatch => {
		dispatch({ type: 'COMPONENT_IS_LOADING' });
		axios.get(constructFetchPortfolioUrl(), {
			headers: { 'Authorization': localStorage.access_token }
		}).then(response => {
			if (response.status === 200) {
				dispatch({
					type: types.FETCH_PORTFOLIO,
					portfolio: response.data.data
				});
				dispatch({ type: 'COMPONENT_NOT_LOADING' });
			}
			else {
				dispatch({
					type: types.FAILED_TO_FETCH_PORTFOLIO,
					portfolio: false
				});
				dispatch({ type: 'COMPONENT_NOT_LOADING' });
				dispatch(Actions.logout());
			}
		})
			.catch(() => {
				dispatch({
					type: types.FAILED_TO_FETCH_PORTFOLIO,
					portfolio: false
				});
				dispatch({ type: 'COMPONENT_NOT_LOADING' });
				dispatch(Actions.logout());
			});
	}
};

Actions.fetchPublicPortfolio = function fetchPublicPortfolio(id) {
	return dispatch => {
		dispatch({ type: 'COMPONENT_IS_LOADING' });
		axios.get(constructFetchPublicPortfolioUrl(id))
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCH_PUBLIC_PORTFOLIO',
						portfolio: response.data.data
					});
					dispatch({ type: 'COMPONENT_NOT_LOADING' });
				}
				else {
					dispatch({
						type: 'FAILED_FETCH_PUBLIC_PORTFOLIO',
						portfolio: false
					});
					dispatch({ type: 'COMPONENT_NOT_LOADING' });
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
				dispatch({ type: 'COMPONENT_NOT_LOADING' });
			});
	}
}

// fetch portfolio reading action

Actions.fetchPortfolioCardContent = function fetchPortfolioCardContent(url, data, task, toast = false) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		return axios({
			url: url,
			timeout: 20000,
			method: task,
			responseType: 'json',
			data: data

		}).then(response => {
			if (response.data.error) {
				dispatch(Actions.toastActive('This item is already added to your portfolio.', 'info', 'We know you like it!'))
			}
			else if (response.status === 200) {
				var data = response.data.data ? response.data.data : response.data;
				if (toast) {
					dispatch(Actions.toastActive(toast.toastMsg, toast.toastType, toast.toastHeading))
				}
				dispatch({
					type: types.UPDATE_PORTFOLIO,
					portfolio: data
				});
			}
			else {
				dispatch({
					type: types.SET_ERROR,
					error_msg: "Already inside Portfolio"
				});
				dispatch({
					type: types.FAILED_TO_FETCH_PORTFOLIO,
					portfolio: false
				});
			}
		}).catch(() => {
			dispatch({
				type: types.SET_ERROR,
				error_msg: "Already inside Portfolio"
			});
		})
	}
};

Actions.fetchRecommendations = function fetchRecommendations(type, params = {}) {
	let url, response_type;
	switch (type) {
		case types.REQUEST_RECOMMENDED_EVENTS:
			url = constructRecommendedEventsUrl();
			response_type = types.RECEIVE_RECOMMENDED_EVENTS
			break;
		case types.REQUEST_RECOMMENDED_JOBS:
			url = constructRecommendedJobsUrl();
			response_type = types.RECEIVE_RECOMMENDED_JOBS
			break;
		case types.REQUEST_RECOMMENDED_READINGS:
			url = constructRecommendedReadingsUrl();
			response_type = types.RECEIVE_RECOMMENDED_READINGS
			break;
		case types.REQUEST_RECOMMENDED_CLUBS:
			url = constructRecommendedClubsUrl();
			response_type = types.RECEIVE_RECOMMENDED_CLUBS;
			break;
		case types.REQUEST_RECOMMENDED_ARTICLES:
			url = constructRecommendedArticlesUrl();
			response_type = types.RECEIVE_RECOMMENDED_ARTICLES;
			break;
		default:
			break;
	}
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token },
			params: params
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: response_type,
						data: response.data.data
					});
				}
			})
			.catch(() => {
				dispatch(navigateTo({ path: [] }))
			});
	}
};

Actions.fetchEvents = function fetchEvents(url, type) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200)
					dispatch(eventsResult(response.data, type));
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

function eventsResult(data, type) {
	return {
		type: type,
		data: data
	}
}

Actions.fetchJobsInternship = function fetchJobsInternship(url, type) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		dispatch({ type: 'DETAIL_PAGE_LOADING' });
		axios.get(url)
			.then(response => {
				dispatch({
					type: type,
					data: response.data
				})
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
			})
			.catch(error => {
				errorHandler(error, dispatch);
			})
	}
}

Actions.sendProjectTaskCompleted = function sendProjectTaskCompleted(url, projectTask) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		axios.put(url, {
			my_project: projectTask
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: "PROJECT_TASK_COMPLETED",
						data: response.data
					});
					dispatch(Actions.snackBarOn('Successfully submitted', 3000));
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchBooks = function fetchBooks(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200)
					dispatch(booksResult(response.data));
			})
			.catch(err => {
				errorHandler(err, dispatch)
			})
	}
}

function booksResult(data) {
	return {
		type: 'FETCH_BOOKS',
		books: data
	}
}

Actions.fetchArticles = function fetchArticles(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200)
					dispatch(articlesResult(response.data));
			})
			.catch(err => {
				errorHandler(err, dispatch)
			})
	}
}

function articlesResult(data) {
	return {
		type: 'FETCH_ARTICLES',
		articles: data
	}
}

Actions.fetchClubs = function fetchClubs(url) {
	return dispatch => {
		dispatch({ type: 'CLUBS_LOADING' })
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch(clubsFetchSuccess(response.data.data))
				}
			})
			.catch(err => {
				errorHandler(err, dispatch)
			}
			)
	}
}

function clubsFetchSuccess(data) {
	return {
		type: 'FETCH_CLUBS',
		data
	}
}

//Action to check which add modal to be opened
Actions.OpenAddAction = function OpenAddAction(type, status, data, cardName) {
	let response_type;
	switch (type) {
		case types.RECEIVE_ADD_MODAL_DATA:
			response_type = types.RECEIVE_ADD_MODAL_DATA
			break;
		default:
			break;
	}
	return {
		type: response_type,
		status,
		data,
		cardName,
		openAddCard: true
	}
}

Actions.fetchTestimonials = function fetchTestimonials(typeOfUser) {
	return dispatch => {
		axios.get(constructTestimonialUrl(typeOfUser))
			.then(response => {
				if (response.status === 200)
					dispatch({
						type: 'FETCHED_TESTIMONIALS',
						data: response.data.data
					})
			})
			.catch(err => {
				errorHandler(err, dispatch)
			})
	}
}

Actions.fetchJobRoles = function fetchJobRoles() {
	return dispatch => {
		axios.get(constructJobRoleUrl())
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_JOB_ROLES',
						data: response.data.data
					})
				}
			})
			.catch(err => {
				errorHandler(err, dispatch)
			})
	}
}

Actions.getJobRoles = function getJobRoles() {
	return dispatch => {
		axios.get(constructJobRoleListUrl(), {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				dispatch({
					type: 'FETCHED_JOB_LIST',
					data: response.data.data
				})
			})
			.catch(err => {
				errorHandler(err, dispatch)
			})
	}
}

Actions.addJobRoles = function addJobRoles(job_ids, createProfile, isMobile = false) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token;
		if (createProfile) {
			dispatch({ type: 'COMPONENT_IS_LOADING' });
			axios.post(createPortfolioUrl(), { portfolio: { job_roles: job_ids } })
				.then((response) => {
					dispatch({type: 'PORTFOLIO_JOB_ROLE_UPDATE', data: response.data.data.job_roles, portfolio_id: response.data.data.id})
					dispatch({
						type: 'HIDE_ADD_JOBS_MODAL'
					})
					if (sessionStorage.getItem('redirectPath') !== null) {
						var path = sessionStorage.getItem('redirectPath')
						sessionStorage.removeItem('redirectPath')
						path = path.slice(1);
						dispatch(navigateTo({ path: [path] }))
					}
					else if (!isMobile)
						dispatch(navigateTo({ path: ['dashboard'] }));
					else
						dispatch(navigateTo({ path: ['posts'] }));
					dispatch({ type: 'COMPONENT_NOT_LOADING' });
				})
				.catch(() => {
					dispatch({ type: 'COMPONENT_NOT_LOADING' });
				})
		}
		else {
			axios.post(constructAddJobRolesUrl(), { job_role_ids: job_ids })
				.then(() => {
					dispatch({
						type: 'HIDE_ADD_JOBS_MODAL'
					})
				})
				.catch(() => {
					dispatch({
						type: 'JOB_ROLE_NOT_ADDED'
					})
				})
		}
	}
}

Actions.applyNow = function applyNow(url, data, errorMsg = false) {
	return dispatch => {
		dispatch({ type: 'APPLY_IS_LOADING' });
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		return axios({
			url: url,
			timeout: 20000,
			method: 'POST',
			responseType: 'json',
			data: data
		})
			.then(response => {
				if (response.data.error) {
					dispatch({ type: 'APPLY_NOT_LOADING' });
					dispatch({ type: 'ERROR_KUDOS_MODAL' });
				}
				else {
					var data = response.data.data ? response.data.data : response.data;
					dispatch({ type: 'APPLY_NOT_LOADING' });
					dispatch({ type: 'SHOW_KUDOS_MODAL' });
					dispatch({
						type: types.UPDATE_PORTFOLIO,
						portfolio: data
					});
				}
			})
			.catch(error => {
				switch (error.response.data.error) {
					case 'Invalid data':
						dispatch({ type: 'APPLY_NOT_LOADING' });
						dispatch({ type: 'ERROR_KUDOS_MODAL' });
						break;
					case 'Ineligible':
						dispatch({ type: 'APPLY_NOT_LOADING' });
						dispatch({ type: 'INELIGIBLE_KUDOS_MODAL' });
						break;
					default:
						break;
				}
				if (error.response.status === 422 && errorMsg) {
					dispatch(Actions.popUpOn(errorMsg, ""));
					dispatch({
						type: 'APPLY_NOT_LOADING'
					})
				}
			})
	}
}

Actions.getJobApplicants = function getJobApplicants(id, org_ids, page, statusFilter = false, orgId, profileType, profileSubType = false) {
	if (profileType === 'company' || profileSubType)
		var data = { job_id: id, org_ids: org_ids, page: page }
	else
		data = { job_id: id, org_ids: orgId, specialization_ids: org_ids, page: page }
	if (statusFilter)
		data = Object.assign({}, data, { status: statusFilter })
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructJobApplicantsDetailsUrl(), {
			params: data
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_JOB_ENROLLED_DETAILS',
						data: response.data.data,
						pagination: response.data.pagination
					})
				}
				if (page < 2)
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
				}
			})
	}
}

Actions.getJobInvitedApplicants = function getJobInvitedApplicants(id, org_ids, page, statusFilter = false, orgId, profileType) {
	if (profileType === 'company')
		var data = { job_id: id, org_ids: org_ids, page: page }
	else
		data = { job_id: id, org_ids: orgId, specialization_ids: org_ids, page: page }

	if (statusFilter)
		data = Object.assign({}, data, { status: statusFilter })

	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructJobInvitedApplicantsUrl(), {
			params: data
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_JOB_INVITED_STUDENT_DETAILS',
						data: response.data.data,
						pagination: response.data.pagination,
						count: response.data.count
					})
				}
				if (page < 2)
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
				}
			})
	}
};


Actions.getJobsFilteredByInstituteStats = function getJobsFilteredByInstituteStats(job_id, org_ids, orgId, profileType, profileSubType) {
	return dispatch => {
		if (profileType === 'company' || profileSubType) {
			var params = { job_ids: job_id, org_ids: org_ids, org_grouping: true }
		}
		else {
			params = { job_ids: job_id, org_ids: orgId, specialization_ids: org_ids, specialization_grouping: true }
		}
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructJobEnrollmentByCollegeUrl(), {
			params: params
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_JOBS_APPLIED_BY_INSTITUTE',
						data: response.data.data
					})
				}
				dispatch({ type: 'REDUCE_FILTER_LOAD' });
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(navigateTo({ path: ['/'] }));
					dispatch(Actions.popUpOn(error.response.data.error));
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
				}
			})
	}
}

Actions.getProjectTeamStats = function getProjectTeamStats(job_id, org_ids, project_ids, page, _orgId, profileType, profileSubType) {
	return dispatch => {
		if (profileType === 'company' || profileSubType) {
			var params = { job_ids: job_id, org_ids: org_ids, project_ids: project_ids, page: page }
		}
		else {
			params = { job_ids: job_id, specialization_ids: org_ids, project_ids: project_ids, page: page }
		}
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructProjectTeamStatsUrl(), {
			params: params
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_PROJECT_TEAM_DATA',
						data: response.data.data,
						pagination: response.data.pagination
					})
					if (page < 2)
						dispatch({ type: 'REDUCE_FILTER_LOAD' });

				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyJobs = function fetchCompanyJobs(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch(Actions.fetchCompanyJobsDataSuccess(response))
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyJobsDataSuccess = function fetchCompanyJobsDataSuccess(data) {
	return {
		type: 'RECEIVE_COMPANY_JOBS',
		data: data.data.data
	}
}

Actions.fetchCompanyPublicJobData = function fetchCompanyPublicJobData(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_COMPANY_PUBLIC_JOB_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyPublicEventsData = function fetchCompanyPublicEventsData(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_COMPANY_PUBLIC_EVENT_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyPublicMentorsData = function fetchCompanyPublicMentorsData(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_COMPANY_PUBLIC_MENTOR_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyEvents = function fetchCompanyEvents(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch(Actions.fetchCompanyEventsDataSuccess(response))
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyEventsDataSuccess = function fetchCompanyEventsDataSuccess(data) {
	return {
		type: 'RECEIVE_COMPANY_EVENTS',
		data: data.data.data
	}
}

Actions.fetchCompanyMentors = function fetchCompanyMentors(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({ type: 'REDUCE_LOAD' })
					dispatch(Actions.fetchCompanyMentorsDataSuccess(response))
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyMentorsDataSuccess = function fetchCompanyMentorsDataSuccess(data) {
	return {
		type: 'RECEIVE_COMPANY_MENTORS',
		data: data
	}
}

Actions.getProjectsEnrollmentCount = function getProjectsEnrollmentCount(job_ids, project_ids, org_ids, _orgId, profileType, profileSubType) {
	return dispatch => {
		if (profileType === 'company' || profileSubType) {
			var params = { job_ids: job_ids, org_ids: org_ids, project_ids: project_ids }
		}
		else {
			params = { job_ids: job_ids, specialization_ids: org_ids, project_ids: project_ids }
		}
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructProjectEnrollmentCountUrl(), {
			params: params
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_PROJECT_ENROLLMENT_COUNT',
						data: response.data
					})
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.getProjectsEnrollmentSplit = function getProjectsEnrollmentSplit(job_ids, project_ids, org_ids, _orgId, profileType, profileSubType) {
	return dispatch => {
		if (profileType === 'company' || profileSubType) {
			var params = { job_ids: job_ids, org_ids: org_ids, project_ids: project_ids }
		}
		else {
			params = { job_ids: job_ids, specialization_ids: org_ids, project_ids: project_ids }
		}
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructProjectEnrollmentSplitUrl(), {
			params: params
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_PROJECT_ENROLLMENT_SPLIT',
						data: response.data
					})
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.setCurrentJobInstituteId = function setCurrentJobInstituteId(org_id) {
	return {
		type: 'SET_SELECTED_INSTITUTE_ID',
		data: org_id
	}
}

Actions.deleteCurrentJobInstituteId = function deleteCurrentJobInstituteId() {
	return {
		type: 'DELETE_SELECTED_INSTITUTE_ID',
		data: false
	}
}

Actions.getEventEnrollmentCount = function getEventEnrollmentCount(event_ids, org_ids) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructEventEnrollmentCountUrl(), {
			params: { event_ids: event_ids, org_ids: org_ids }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_EVENT_ENROLLMENT_BY_INSTITUTE',
						data: response.data.data
					})

				}
				dispatch({ type: 'REDUCE_FILTER_LOAD' });
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
				}
				else
					errorHandler(error, dispatch)
			})
	}
}

Actions.getEventApplicants = function getEventApplicants(event_ids, page, org_ids) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructEventApplicantsDetailsUrl(), {
			params: { event_ids: event_ids, page: page, org_ids: org_ids }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_EVENT_ENROLLED_DETAILS',
						data: response.data.data,
						pagination: response.data.pagination
					})
				}
				if (page < 2)
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_FILTER_LOAD' });
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
				}
				else
					errorHandler(error, dispatch)
			})
	}
}

Actions.getEventTotalCount = function getEventTotalCount(id, org_ids) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.access_token
		axios.get(constructEventTotalCountUrl(), {
			params: { event_id: id, org_ids: org_ids }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_TOTAL_EVENT_COUNT',
						data: response.data
					})
				}
				dispatch({ type: 'REDUCE_FILTER_LOAD' });
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchProjectGroups = function fetchProjectGroups() {
	return dispatch => {
		axios.get(constructJobProjectsUrl())
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCHED_ALL_PROJECT_GROUPS',
						data: response.data.data
					})
					dispatch({ type: 'REDUCE_LOAD' })
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchRequiredSkills = function fetchRequiredSkills() {
	return dispatch => {
		axios.get(constructSkillsUrl())
			.then(response => {
				dispatch({
					type: 'FETCHED_JOB_REQUIRED_SKILLS',
					data: response.data.data
				})
				dispatch({ type: 'REDUCE_LOAD' })
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.updateEventDetails = function updateEventDetails(url, event) {
	return dispatch => {
		dispatch({ type: 'DETAIL_PAGE_LOADING' });
		axios.put(url, {
			event: event
		})
			.then(() => {
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
				dispatch({
					type: 'CREATE_EVENT_FORM_CLOSE',
					data: false
				})
				dispatch(Actions.fetchJobsInternship(url, 'RECIEVE_EVENT_DETAIL'))
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
			})
	}
}

Actions.createNewEvent = function createNewEvent(url, org_id, org_name, club_id = false) {
	var event = club_id ? { org_id: org_id, host: org_name, club_id: club_id } : { org_id: org_id, host: org_name }
	return dispatch => {
		axios.post(url, {
			event: event
		})
			.then(response => {
				if (response.status === 201) {
					dispatch({
						type: 'CREATE_EVENT_FORM_OPEN',
						data: true
					})
					dispatch(navigateTo({ path: ['events', response.data.id] }))
				}
				dispatch({ type: 'REDUCE_LOAD' });
				dispatch(Actions.snackBarOff());
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
				else
					errorHandler(error, dispatch)
			})
	}
}

Actions.discardCurrentEvent = function discardCurrentEvent(url, navigate, profileType) {
	return dispatch => {
		return axios({
			url: url,
			method: 'DELETE',
			responseType: 'json',
		})
			.then(() => {
				dispatch({
					type: 'CREATE_EVENT_FORM_CLOSE',
					data: false
				})
				dispatch({
					type: 'DELETE_EVENT_DETAIL',
					data: false
				})
				if (navigate) {
					if (profileType === 'company')
						dispatch(navigateTo({ path: ['companyDashboard'] }))
					else
						dispatch(navigateTo({ path: ['instituteDashboard'] }))
				}
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.deleteCompanyJobsData = function deleteCompanyJobsData() {
	return {
		type: 'DELETE_COMPANY_JOBS',
		data: false
	}
}

Actions.deleteCompanyEventsData = function deleteCompanyEventsData() {
	return {
		type: 'DELETE_COMPANY_EVENTS',
		data: false
	}
}

Actions.deleteCompanyMentorsData = function deleteCompanyMentorsData() {
	return {
		type: 'DELETE_COMPANY_MENTORS',
		data: false
	}
}

Actions.fetchOrg = function fetchOrg(id) {
	return dispatch => {
		axios.get(constructOrgUrl(id))
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'ORG_FETCHED',
						data: response.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchCompanyPublicEventsData = function fetchCompanyPublicEventsData(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_COMPANY_PUBLIC_EVENT_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchUpdatedJobDetail = function fetchUpdatedJobDetail(url, data, task) {
	return dispatch => {
		dispatch({ type: 'DETAIL_PAGE_LOADING' });
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		return axios({
			url: url,
			timeout: 20000,
			method: task,
			responseType: 'json',
			data: data
		})
			.then(() => {
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
				dispatch({
					type: 'CREATE_JOB_FORM_CLOSE',
					data: false
				})
				dispatch(Actions.fetchJobsInternship(url, 'RECIEVE_JOB_DETAIL'))
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
			})
	}
}

Actions.fetchUpdatedClubDetail = function fetchUpdatedClubDetail(url, data, task) {
	return dispatch => {
		dispatch({ type: 'DETAIL_PAGE_LOADING' });
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		return axios({
			url: url,
			timeout: 20000,
			method: task,
			responseType: 'json',
			data: data
		})
			.then(() => {
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
				dispatch({
					type: 'CREATE_CLUB_FORM_CLOSE',
					data: false
				})
				dispatch(Actions.fetchJobsInternship(url, 'RECIEVE_CLUB_DETAIL'))
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
				dispatch({ type: 'DETAIL_PAGE_NOT_LOADING' });
			}
			)
	}
}

Actions.fetchNewJobAdd = function fetchNewJobAdd(data) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		return axios({
			url: constructFetchNewJobUrl(),
			timeout: 20000,
			method: 'POST',
			responseType: 'json',
			data: data
		}).then(response => {
			if (response.status === 201) {
				dispatch({
					type: 'CREATE_JOB_FORM_OPEN',
					data: true
				})
				dispatch(navigateTo({ path: ['jobs', response.data.id] }))
			}
			dispatch({ type: 'REDUCE_LOAD' });
		})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			}
			)
	}
}

Actions.fetchNewClubAdd = function fetchNewClubAdd(data) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		return axios({
			url: constructFetchNewClubUrl(),
			timeout: 20000,
			method: 'POST',
			responseType: 'json',
			data: data
		})
			.then(response => {
				if (response.status === 201) {
					dispatch({
						type: 'CREATE_CLUB_FORM_OPEN',
						data: true
					})
					dispatch(navigateTo({ path: ['clubs', response.data.data.id] }))
				}
				dispatch({ type: 'REDUCE_LOAD' });
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			}
			)
	}
}

Actions.discardCurrentJob = function discardCurrentJob(url, navigate, dashboard) {
	return dispatch => {
		return axios({
			url: url,
			timeout: 20000,
			method: 'DELETE',
			responseType: 'json',
		})
			.then(() => {
				dispatch({
					type: 'CREATE_JOB_FORM_CLOSE',
					data: false
				})
				dispatch({
					type: 'DELETE_JOB_DETAIL',
					data: false
				})
				if (navigate)
					dispatch(navigateTo({ path: [dashboard] }))
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.discardCurrentClub = function discardCurrentClub(url, navigate, path = '') {
	return dispatch => {
		return axios({
			url: url,
			timeout: 20000,
			method: 'DELETE',
			responseType: 'json',
		})
			.then(() => {
				dispatch({
					type: 'CREATE_CLUB_FORM_CLOSE',
					data: false
				})
				dispatch({
					type: 'DELETE_CLUB_DETAIL',
					data: false
				})
				if (navigate)
					dispatch(navigateTo({ path: [path] }))
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.fetchCompanyPublicMentorsData = function fetchCompanyPublicMentorsData(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_COMPANY_PUBLIC_MENTOR_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.updateClubJoinRequest = function updateClubJoinRequest(url, dataObj) {
	return dispatch => {
		axios.put(url, {
			my_club: dataObj
		})
			.then(() => {
				dispatch({
					type: 'UPDATE_CLUB_JOIN_RESPONSE',
					data: true
				})
				dispatch({
					type: "REDUCE_LOAD"
				})
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.updateClubAdminData = function updateClubAdminData(url, dataObj) {
	return dispatch => {
		axios.put(url, {
			club: dataObj
		})
			.then(response => {
				dispatch({
					type: 'RECIEVE_CLUB_DETAIL',
					data: response.data
				})
				dispatch({
					type: "REDUCE_LOAD"
				})
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.fetchClubStudentProfile = function fetchClubStudentProfile(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_CLUB_STUDENTS_MEMBER_DATA',
						data: response.data.members
					})
					dispatch({
						type: 'RECEIVE_CLUB_STUDENTS_COMMITTEE_DATA',
						data: response.data.committee
					})
					var membersList = response.data.members
					var committeeList = response.data.committee
					var totalMemberList = membersList.concat(committeeList)
					dispatch({
						type: 'RECEIVE_CLUB_ALL_MEMBER_DATA',
						data: totalMemberList
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.deleteClubStudentProfile = function deleteClubStudentProfile() {
	return {
		type: 'DELETE_CLUB_STUDENTS_MEMBER_DATA',
		data: false
	}
}

Actions.updateClubCommittee = function updateClubCommittee(url, dataList, clubId) {
	return dispatch => {
		axios.put(url, {
			my_club: dataList,
			club_id: clubId
		})
			.then(() => {

				dispatch(Actions.fetchClubStudentProfile(constructFetchClubStudentProfile(clubId)))
				dispatch({
					type: "REDUCE_LOAD"
				})
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch({ type: 'REDUCE_LOAD' });
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.deleteClubCommitteeStudentProfile = function deleteClubCommitteeStudentProfile() {
	return {
		type: 'DELETE_CLUB_STUDENTS_COMMITTEE_DATA',
		data: false
	}
}

Actions.fetchClubJoinRequest = function fetchClubJoinRequest(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_CLUB_JOIN_REQUEST_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.fetchClubInvitedList = function fetchClubInvitedList(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_CLUB_INVITE_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.fetchEventInvitedList = function fetchEventInvitedList(url) {
	return dispatch => {
		axios.get(url)
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'RECEIVE_EVENT_INVITE_DATA',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(navigateTo({ path: [''] }));
					dispatch(Actions.popUpOn(error.response.data.error));
				}
			})
	}
}

Actions.deleteClubJoinRequest = function deleteClubJoinRequest() {
	return {
		type: 'DELETE_CLUB_JOIN_REQUEST_DATA',
		data: false
	}
}

Actions.getFilteredData = function getFilteredData(data, type) {
	if (type === 'FILTERED_JOBS')
		return {
			type: type,
			name: data.name,
			org_ids: data.org_ids,
			loadCount: data.loadCount
		}
	else
		return {
			type: type,
			name: data.name,
			loadCount: data.loadCount,
			project_ids: data.project_ids
		}
}

Actions.snackBarOn = function snackBarOn(message, timeout = 15000, save = false, publish = false, discard = false) {
	return {
		type: 'SNACK_BAR_ON',
		data: {
			on: true,
			message,
			save: save,
			publish: publish,
			discard: discard,
			timeout
		}
	}
}

Actions.snackBarOff = function snackBarOff() {
	return {
		type: 'SNACK_BAR_OFF'
	}
}

Actions.toastActive = function toastActive(toastMsg, toastType, toastHeading = 'Voila!', timeout = 3000) {
	return {
		type: 'TOAST_ACTIVE',
		data: {
			active: true,
			toastMsg,
			toastHeading,
			toastType,
			timeout
		}
	}
}

Actions.popUpOn = function popUpOn(message = "Something went wrong.", heading = "Uh-Oh!", showClose = true) {
	return {
		type: 'SET_POPUP_MODAL',
		data: {
			show: true,
			showClose,
			message,
			heading
		}
	}
}

Actions.fetchStudentProfileOrg = function fetchStudentProfileOrg(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCH_INSTITUTE_STUDENTS',
						data: response.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchInstituteMentors = function fetchInstituteMentors(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCH_INSTITUTE_MENTORS',
						data: response.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}
Actions.fetchInstituteJobs = function fetchInstituteJobs(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCH_INSTITUTE_JOBS',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchInstituteEvents = function fetchInstituteEvents(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCH_INSTITUTE_EVENTS',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.fetchInstituteClubs = function fetchInstituteClubs(url) {
	return dispatch => {
		axios.get(url, {
			headers: { 'Authorization': localStorage.access_token }
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'FETCH_INSTITUTE_CLUBS',
						data: response.data.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch)
			})
	}
}

Actions.deleteInstituteEventProp = function deleteInstituteEventProp() {
	return {
		type: 'DELETE_INSTITUTE_EVENT_PROP',
		data: false
	}
}

Actions.deleteInstituteJobProp = function deleteInstituteJobProp() {
	return {
		type: 'DELETE_INSTITUTE_JOB_PROP',
		data: false
	}
}

Actions.deleteInstituteClubProp = function deleteInstituteClubProp() {
	return {
		type: 'DELETE_INSTITUTE_CLUB_PROP',
		data: false
	}
}


Actions.fetchInstitute = function fetchInstitute(id) {
	return dispatch => {
		axios.get(constructInstituteOrgUrl(id))
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: 'INSTITUTE_FETCHED',
						data: response.data
					})
				}
			})
			.catch(error => {
				errorHandler(error, dispatch);
			})
	}
}

Actions.generatePDF = function generatePDF(url) {
	return dispatch => {
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob'
		})
			.then(response => {
				const file = new Blob(
					[response.data],
					{ type: 'application/pdf' });
				var date = dayjs().format('YYYY-MM-DD_h:mm:ss');
				fileDownload(file, `portfolio_${date}.pdf`)
				dispatch({ type: 'REDUCE_LOAD' })
			})
			.catch(error => {
				errorHandler(error, dispatch);
			})
	}
}

export default Actions;