// Warning
// Maintain the same order of fields in CardFieldNameAdd and CardFieldNameEdit
// Never change the keys field anywhere

export const PortfolioCards = [
  "external_projects",
  "external_courses",
  "readings",
  "articles",
  "external_events",
  "jobs",
  "internships",
  "social_clubs",
  "cocurriculars",
  "educations",
];

export const articles = {
  title: "My Articles",
  CardFieldNameAdd: [
    { key: "title", name: "Title", type: "text" },
    { key: "desc", name: "Description", type: "text" },
    { key: "skills", name: "Skills", type: "text" },
    { key: "date", name: "Date of completion", type: "text" },
    {
      key: "type",
      name: "Type",
      type: "text",
      options: [
        { value: "Textbooks", label: "Textbooks" },
        { value: "Technical", label: "Technical" },
        { value: "Fiction", label: "Fiction" },
        { value: "History", label: "History" },
        { value: "Comic", label: "Comic" },
        { value: "Autobiography", label: "Autobiography" },
        { value: "Science", label: "Science" },
        { value: "Math", label: "Math" },
        { value: "Non Fiction", label: "Non Fiction" },
        { value: "Others", label: "Others" },
      ],
    },
    { key: "author", name: "Author", type: "text" },
    {
      key: "status",
      name: "Status",
      type: "text",
      options: [{ value: "complete", label: "Completed" }],
    },
    { key: "url", name: "URL", type: "text" },
    { key: "isbn", name: "ISBN", type: "number" },
  ],
  CardFieldNameEdit: [
    "title",
    "desc",
    "skills",
    "date",
    "genre",
    "author",
    "status",
    "url",
    "isbn",
  ],
  tabs: ["RECOMMENDED", "MY ARTICLES"],
  viewAll: "articles",
  borderColor: "2px solid #f26f84",
  LikeText: ["This article has been bookmarked."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The article has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "You just earned some exposure points. This article has been added to your portfolio.",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: ["This article has already been bookmarked."],
  InfoTextBold: ["We know you like it!"],
  InfoOngoingTextBold: ["Seems like you are on it!"],
  InfoOngoingText: ["This article is already added to your portfolio."],
};

export const readings = {
  title: "Books",
  key: "books",
  CardFieldNameAdd: [
    {
      title: "",
      fields: [
        {
          key: "title",
          name: "Name of the book",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "author",
          name: "Author",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "status",
          name: "Have you read the book or currently reading it?",
          type: "radio",
          options: [
            { value: "complete", label: "I have read the book" },
            { value: "ongoing", label: "I am currently reading the book" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "date",
          name: "When did you read the book?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
    {
      title: "",
      fields: [
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/life skills is this book related to?",
          type: "text",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What did you learn from reading the book?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: [
    "Add books you have read or currently reading to your portfolio",
  ],
  EditModalText: [
    "Earn exposure points. Edit books you have read to your portfolio.",
  ],
  CardFieldNameEdit: ["isbn", "title", "skills", "author", "status", "date"],
  tabs: ["RECOMMENDED", "MY BOOKS"],
  viewAll: "readings",
  borderColor: "2px solid #f26f84",
  LikeText: ["This book has been bookmarked."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The book has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "You just earned some exposure points. This book has been added to your portfolio.",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: ["This book is already bookmarked."],
  InfoTextBold: ["We know you like it!"],
  ongoingText: [
    "This book has been added to your active list. Tell us once you are done reading it and we will add it to your portfolio",
  ],
  ongoingTextBold: ["Good choice!"],
  InfoOngoingTextBold: ["Seems like you are on it!"],
  InfoOngoingText: ["This book is already added to your portfolio."],
};

export const social_clubs = {
  title: "Clubs",
  key: "clubs",
  CardFieldNameAdd: [
    {
      title: "Club Details",
      fields: [
        {
          key: "title",
          name: "Name of the Club",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "type",
          name: "Type of the club?",
          type: "radio",
          options: [
            { value: "Educational", label: "Education" },
            { value: "Non Educational", label: "Non Education" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "role",
          name: "What is your role in the club?",
          type: "radio",
          options: [
            { value: "Member", label: "I am a member of the club" },
            { value: "Board Member", label: "I am a board/ committee member" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "date",
          name: "When did you join the club?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "source",
          name:
            "Who hosts the club? (Name of the parent organization/institute)",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
    {
      title: "Learnings from the Club",
      fields: [
        {
          key: "desc",
          name: "Describe the club and your contribution",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/life skills is this club related to?",
          type: "text",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name:
            "What Life Skills did you pick up from being a part of this club?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What skills did you pick up from being a part of this club?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: ["Add clubs you are a part of to your portfolio"],
  EditModalText: [
    "Earn exposure points. Edit clubs you are a part of to the portfolio.",
  ],
  CardFieldNameEdit: ["title", "desc", "skills", "source", "role", "date"],
  tabs: ["RECOMMENDED", "MEMBER"],
  viewAll: "clubs",
  borderColor: "2px solid #a883a4",
  LikeText: ["You are now a member of this club."],
  LikeTextBold: ["Congratulations!"],
  AddText: [
    "You just earned some exposure points. This club has been added to your portfolio.",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: ["You are already a member of this club."],
  InfoTextBold: ["Hey!"],
};

export const education = {
  title: "Degrees",
  key: "education",
  CardFieldNameAdd: [
    {
      title: "",
      fields: [
        {
          key: "degree",
          name: "Degree",
          type: "text",
          mandatory: true,
          col: "col-12",
          toolTipMsg: "Value",
        },
        {
          key: "institute",
          name: "Institute",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "specialization",
          name: "Specialization",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "startdate",
          name: "Degree Start Date",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "finishdate",
          name: "Degree End Date",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "format",
          name: "Scoring Scheme",
          options: [
            { value: "GPA", label: "CGPA" },
            { value: "Numeric", label: "Percentage" },
          ],
          type: "radio",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "score",
          name: "Choose one first",
          type: "number",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "backlogsStatus",
          name: "Current Backlogs",
          options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ],
          type: "radio",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "backlogs",
          name: "Backlogs count",
          type: "number",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "placed",
          name: "Already Placed",
          options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ],
          type: "radio",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: ["Add your degree(s) and the marks scored to your portfolio"],
  EditModalText: [
    "Earn exposure points. Edit your degree(s) and the marks scored to your portfolio",
  ],
  CardFieldNameEdit: [
    "institute",
    "degree",
    "specialization",
    "gen_id",
    "backlogs",
    "score",
    "format",
    "startdate",
    "finishdate",
  ],
  tabs: ["NoTab"],
  viewAll: "education",
  borderColor: "2px solid #de9d37",
  AddText: [
    " You just earned some education points. Your academic details have been added to your portfolio.",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
};

// export const education_tooltip = {
//   fields: [
//     { key: "degree", value: "degree" },
//     { key: "institute", value: "" },
//     { key: "placed", value: "Do you have any company offer?" },
//   ],
// };

export const projects = {
  title: "Projects",
  key: "projects",
  CardFieldNameAdd: [
    {
      title: "Project Details",
      fields: [
        {
          key: "title",
          name: "Name of the project",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "status",
          name: "Have you implemented the project or currently working on it?",
          type: "radio",
          options: [
            { value: "complete", label: "I have implemented it" },
            { value: "Enrolled", label: "I am currently working on it" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "date",
          name: "When did you complete the project ",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "duration",
          name: "How long did project implementation take?(Weeks)",
          type: "number",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "source",
          name: "Where did you do the project from?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
    {
      title: "Project Learnings",
      fields: [
        {
          key: "desc",
          name: "Describe the project",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/life skills is the project related to?",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name: "What Life Skills did you pick up from the project?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What skills did you pick up on implementing the project?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: [
    "Add projects you have implemented or currently working on to your portfolio",
  ],
  EditModalText: [
    "Earn experience points.Edit projects you have done to your portfolio",
  ],
  CardFieldNameEdit: [
    "title",
    "desc",
    "skills",
    "source",
    "duration",
    "date",
    "status",
  ],
  tabs: ["RECOMMENDED", "INTERESTED", "IN PROGRESS", "COMPLETED"],
  viewAll: "projects",
  borderColor: "2px solid #439ed6 ",
  LikeText: ["This project has been added to your interested list."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The project has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "You just earned some experience points. This project has been added to your portfolio.",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: ["This project is already added to your interested list."],
  InfoTextBold: ["We know you like it!"],
  ongoingText: [
    "This project has been added to your in-progress list. Tell us once you have completed it and we will add it to your portfolio.",
  ],
  ongoingTextBold: ["Good choice!"],
};

export const events = {
  title: "Events",
  key: "events",
  CardFieldNameAdd: [
    {
      title: "Event Details",
      fields: [
        {
          key: "title",
          name: "Name of the Event",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "role",
          name: "How were you involved in the event?",
          type: "radio",
          options: [
            { value: "Participate", label: "I participated in the event" },
            { value: "Deliver", label: "I delivered the event" },
            { value: "Organize", label: "I organized the event" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "date",
          name: "Date of completion",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "duration",
          name: "What was the duration of the event (in hours)?",
          type: "number",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "source",
          name: "Who hosted the event? (Name of the organization/company/club)",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
    {
      title: "Event Learnings",
      fields: [
        {
          key: "desc",
          name: "Describe the event and your role in it",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/life skills is this event related to?",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name:
            "What Life Skills did you pick up from being a part of this event?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What skills did you pick up from this event?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: ["Add events you have delivered to your portfolio"],
  EditModalText: [
    "Earn exposure and experience points.Edit events you have attended or organized to your portfolio.",
  ],
  CardFieldNameEdit: [
    "title",
    "desc",
    "role",
    "date",
    "job_role",
    "duration",
    "source",
    "skills",
  ],
  tabs: ["RECOMMENDED", "MY EVENTS"],
  viewAll: "events",
  borderColor: "2px solid #7cced1 ",
  LikeText: ["This event has been added to your interested list."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The event has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "This event has been added to your portfolio. You just earned some exposure points",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: ["You can find this event in your interested or in-progress list."],
  InfoTextBold: ["We know you like it!"],
  ongoingText: [
    "This event has been added to your in-progress list. Tell us once you have attended it and we will add it to your portfolio.",
  ],
  ongoingTextBold: ["Good choice!"],
  InfoOngoingTextBold: ["Seems like you are on it!"],
  InfoOngoingText: [
    "You can look for this event in your interested or in-progress list",
  ],
};

export const internships = {
  title: "Internships",
  key: "internships",
  CardFieldNameAdd: [
    {
      title: "Internship Details",
      fields: [
        {
          key: "title",
          name: "Designation",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "host",
          name: "Name of the Organization",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "status",
          name: "Have you completed the internship or currently pursuing it?",
          type: "radio",
          options: [
            { value: "complete", label: "I have completed the internship" },
            { value: "ongoing", label: "My internship is ongoing" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "startdate",
          name: "Duration",
          type: "text",
          mandatory: true,
          col: "col-5",
        },
        {
          key: "finishdate",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-5",
        },
      ],
    },
    {
      title: "Learnings from the Internship",
      fields: [
        {
          key: "desc",
          name:
            "Describe what your roles and responsibilities were as part of the internship?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/ life skills was the internship related to?",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name: "What Life Skills did you pick up from the internship?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What did you learn from the internship?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: ["Add completed and on going internships to your portfolio"],
  EditModalText: [
    "Earn experience points.Edit internships you have completed to your portfolio.",
  ],
  CardFieldNameEdit: [
    "title",
    "desc",
    "skills",
    "date",
    "status",
    "duration",
    "host",
  ],
  tabs: ["MY INTERNSHIPS"],
  viewAll: "internships",
  borderColor: "2px solid #f7984d ",
  LikeText: ["This internship has been added to your interested list."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The internship has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "This internship has been added to your portfolio. You just earned some experience points",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: [
    "You can find this internship in your interested or in-applied list.",
  ],
  InfoTextBold: ["We know you like it!"],
  ongoingText: [
    "This internship has been added to your applied list. Tell us once you have completed it and we will add it to your portfolio.",
  ],
  ongoingTextBold: ["Good choice!"],
  InfoOngoingTextBold: ["Seems like you are on it!"],
  InfoOngoingText: [
    "You can look for this internship in your interested or in-applied list",
  ],
};

export const learning = {
  title: "Courses",
  key: "courses",
  CardFieldNameAdd: [
    {
      title: "Course Details",
      fields: [
        {
          key: "title",
          name: "Name of the Course",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "status",
          name: "Have you completed the course or currently pursuing it",
          type: "radio",
          options: [
            { value: "complete", label: "Completed" },
            { value: "ongoing", label: "In Progress" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "date",
          name: "When did you complete the course",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "duration",
          name: "Duration (In Hours)",
          type: "number",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "type",
          name: "What was the mode of delivery",
          type: "radio",
          options: [
            { value: "Online course", label: "Online" },
            { value: "Offline course", label: "Offline" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "certificates",
          name: "Link to certificate",
          type: "text",
          mandatory: false,
          col: "col-6",
        },
        {
          key: "source",
          name: "Where did you do the course? ",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
      ],
    },
    {
      title: "Course Learnings",
      fields: [
        {
          key: "desc",
          name: "What did you learn from the course?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/life skills was the course related to?",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name: "What Life Skills did you pick up from the course?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What skills did the course help you learn?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: [
    "Add courses you have completed or currently pursuing to your portfolio",
  ],
  EditModalText: [
    "Earn education points.Edit courses you have completed to your portfolio.",
  ],
  CardFieldNameEdit: [
    "title",
    "desc",
    "status",
    "date",
    "duration",
    "type",
    "certificates",
    "source",
    "roles",
    "skills",
  ],
  tabs: ["RECOMMENDED", "MY COURSES"],
  viewAll: "courses",
  borderColor: "2px solid #80ca9c ",
  LikeText: ["This course has been added to your interested list."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The course has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "This course has been added to your portfolio. You just earned some education points",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: [
    "You can find this course in your interested or in-progress list.",
  ],
  InfoTextBold: ["We know you like it!"],
  ongoingText: [
    "This course has been added to your in-progress list. Tell us once you have completed it and we will add it to your portfolio.",
  ],
  ongoingTextBold: ["Good choice!"],
  InfoOngoingTextBold: ["Seems like you are on it!"],
  InfoOngoingText: [
    "You can look for this course in your interested or in-progress list.",
  ],
};

export const educationSemAdd = {
  title: "Subject Details",
  key: "semester",
  CardFieldNameAdd: [
    {
      title: "",
      fields: [
        {
          key: "degree",
          name: "Degree",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "institute",
          name: "Institute",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "name",
          name: "Subject Name",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "semester_number",
          name: "Semester",
          type: "number",
          mandatory: true,
          col: "col-4",
        },
        {
          key: "subjectType",
          name: "Type of subject",
          type: "radio",
          options: [
            { value: "Theory", label: "Theory" },
            { value: "Lab", label: "Lab" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "format",
          name: "Scoring Scheme",
          options: [
            { value: "GPA", label: "CGPA" },
            { value: "Numeric", label: "Percentage" },
          ],
          type: "radio",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "score",
          name: "Grade Point/Marks",
          type: "number",
          mandatory: true,
          col: "col-6",
        },
        {
          key: "job_roles",
          name: "What career paths did you pick up from this subject?",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "skills",
          name: "What skills did the course help you learn?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  CardFieldNameEdit: ["subject", "skills"],
  tabs: ["COMPLETED"],
  AddText: [
    "This subject has been added to your portfolio. You just earned some education points",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  viewAll: "education",
  borderColor: "2px solid #de9d37",
};

export const certificate = {
  title: "Certification",
  key: "certification",
  CardFieldNameAdd: [
    {
      title: "",
      fields: [
        {
          key: "title",
          name: "Certificate Title",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "role",
          name: "Did you do the certification Online or Offline?",
          type: "radio",
          options: [
            { value: "Online course", label: "Online" },
            { value: "Offline course", label: "Offline" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "source",
          name: "Who did you get the certification from? ",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "date",
          name: "When did you get the certification?",
          type: "text",
          mandatory: true,
          col: "col-6",
        },
      ],
    },
    {
      title: "Course Learnings",
      fields: [
        {
          key: "desc",
          name: " Describe the certification and what you learnt from it",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name:
            "Which of the career paths/life skills was the course related to?",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name: "What Life Skills did you pick up from the course?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "What skills did the course help you learn?",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddText: [
    "This certificate has been added to your portfolio. You just earned some education points",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
};

export const jobs = {
  title: "Jobs",
  key: "jobs",
  CardFieldNameAdd: [
    {
      title: "Job Details",
      fields: [
        {
          key: "title",
          name: "Designation",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "host",
          name: "Name of the organization",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "status",
          name: "Have you completed the job or currently working?",
          type: "radio",
          options: [
            { value: "complete", label: "Completed" },
            { value: "ongoing", label: "In Progress" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "type",
          name: "Type of Job",
          type: "radio",
          options: [
            { value: "Job_Fulltime", label: "Job - Full time" },
            { value: "Job_Parttime", label: "Job - Part Time" },
          ],
          mandatory: true,
          col: "col-12",
        },
        {
          key: "startdate",
          name: "Duration",
          type: "text",
          mandatory: true,
          col: "col-5",
        },
        {
          key: "finishdate",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-5",
        },
      ],
    },
    {
      title: "Learnings from the Job",
      fields: [
        {
          key: "desc",
          name: "Describe your role",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
        {
          key: "job_roles_option",
          name: "Which of the Career Paths/life skills was the job related to?",
          options: [
            { label: "Career Paths", value: "job_roles" },
            { label: "Life Skills", value: "life_skills" },
          ],
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "job_roles",
          name: "",
          type: "text",
          mandatory: true,
          col: "col-8",
        },
        {
          key: "job_roles_toggle",
          name: "",
          type: "text",
          mandatory: false,
          col: "col-4",
        },
        {
          key: "life_skills",
          name: "What Life Skills did you pick up from the job?",
          type: "text",
          mandatory: false,
          col: "col-12",
        },
        {
          key: "skills",
          name: "Skills",
          type: "text",
          mandatory: true,
          col: "col-12",
        },
      ],
    },
  ],
  AddModalText: [
    "Earn experience points. Add jobs you have completed to your portfolio.",
  ],
  EditModalText: [
    "Earn experience points.Edit jobs you have completed to your portfolio.",
  ],
  CardFieldNameEdit: [
    "title",
    "desc",
    "skills",
    "date",
    "type",
    "duration",
    "host",
    "status",
  ],
  tabs: ["MY JOBS"],
  viewAll: "jobs",
  borderColor: "2px solid #f7984d ",
  LikeText: ["This job has been added to your interested list."],
  LikeTextBold: ["We will keep it safe for you."],
  DisLikeText: ["No worries. The job has been removed from your list."],
  DisLikeTextBold: ["Not interested in it anymore?"],
  AddText: [
    "This job has been added to your portfolio. You just earned some experience points",
  ],
  AddTextBold: ["Congratulations, Ninja!"],
  InfoText: ["You can find this job in your interested or in-applied list."],
  InfoTextBold: ["We know you like it!"],
  ongoingText: [
    "This job has been added to your applied list. Tell us once you have completed it and we will add it to your portfolio.",
  ],
  ongoingTextBold: ["Good choice!"],
  InfoOngoingTextBold: ["Seems like you are on it!"],
  InfoOngoingText: [
    "You can look for this job in your interested or in-applied list.",
  ],
};

export const cardMapping = {
  education: "education",
  semesterAdd: "semester",
  semesterEdit: "semester",
  courses: "external_courses",
  Deliver: "external_events",
  books: "readings",
  clubs: "social_clubs",
  Participate: "external_events",
  projects: "external_projects",
  internships: "internships",
  jobs: "jobs",
  Organize: "external_events",
  certificate: "certificate",
};

export const tooltip = {
  education: {
    degree: { value: "" },
    institute: { value: "" },
    specialization: { value: "" },
    startdate: { value: "" },
    finishdate: { value: "" },
    format: { value: "" },
    score: { value: "" },
    backlogsStatus: { value: "" },
    placed: { value: "Do you have any company offer?" },
  },
  courses: {
    title: { value: "" },
    status: { value: "" },
    date: { value: "" },
    duration: { value: "" },
    type: { value: "" },
    certificates: { value: "" },
    source: { value: "" },
    desc: { value: "" },
    job_roles_option: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  certification: {
    title: { value: "" },
    role: { value: "" },
    source: { value: "" },
    date: { value: "" },
    desc: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  events: {
    title: { value: "" },
    role: { value: "" },
    date: { value: "" },
    duration: { value: "" },
    source: { value: "" },
    desc: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  projects: {
    title: { value: "" },
    status: { value: "" },
    date: { value: "" },
    duration: { value: "" },
    source: { value: "" },
    desc: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  internships: {
    title: { value: "" },
    host: { value: "" },
    status: { value: "" },
    startdate: { value: "" },
    finishdate: { value: "" },
    desc: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  jobs: {
    title: { value: "" },
    host: { value: "" },
    status: { value: "" },
    type: { value: "" },
    startdate: { value: "" },
    finishdate: { value: "" },
    desc: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  books: {
    title: { value: "" },
    author: { value: "" },
    status: { value: "" },
    date: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
  clubs: {
    title: { value: "" },
    type: { value: "" },
    role: { value: "" },
    date: { value: "" },
    source: { value: "" },
    desc: { value: "" },
    life_skills: { value: "" },
    skills: { value: "" },
  },
};
