import axios from "axios";
import Actions from "../redux/actions";
import { navigateTo } from "./navigator";
import { errorHandler } from "../actions/errorHandler";

/*
it is a dynamic fetch action which fetches the data and assigns it to the reducer 
based on the given message. 
It can also be used to assign data to the common reducer based on the given key and message. 

url: api url (required).
message: message for assigning reducer. (required)
snack: contains the message to be shown in snack bar after response. 
key: name for common reducer.
params: fetch params.
*/
export function fetchData(
  url,
  message,
  snack = false,
  key = false,
  params = {},
  errorModal = false,
  errorMessage = false
) {
  return (dispatch) => {
    axios
      .get(url, {
        params: params,
      })
      .then(function (response) {
        dispatch({ type: "REDUCE_LOAD" });
        if (response.status === 200) {
          if (response.data.data)
            dispatch({
              type: message,
              data: response.data.data,
              key,
            });
          else
            dispatch({
              type: message,
              data: response.data,
              key,
            });
        }
      })
      .catch((err) => {
        if (errorModal) {
          dispatch(Actions.popUpOn(errorMessage, "Oops!"));
          dispatch({ type: "SNACK_BAR_OFF" });
        } else {
          errorHandler(err, dispatch);
        }
      });
  };
}

/*
it is a dynamic fetch action which fetches the data using auth token and assigns it to the reducer 
based on the given message. 
It can also be used to assign data to the common reducer based on the given key and message. 

url: api url (required)
message: message for assigning reducer. (required)
snack: contains message to be shown in snack bar after response. 
key: name for common reducer
params: Params passed with the url
path: contains the path to be redirected after the response.
errorModal: boolean value to show error modal or error page.
errorMessage: contains the message to be shown if there is an error.
query: Additional query parameters in the url
pathWithoutId: TO navigate to a common page or catalog
*/
export function fetchDataAuth(
  url,
  message,
  snack = false,
  key = false,
  params = {},
  path = false,
  errorModal = false,
  errorMessage = false,
  query = {},
  pathWithoutId = false
) {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = localStorage.access_token;
    axios
      .get(url, {
        params: params,
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          if (message) {
            if (response.data.data && !response.data.pagination)
              dispatch({
                type: message,
                data: response.data.data,
                key,
              });
            else
              dispatch({
                type: message,
                data: response.data,
                key,
              });
          }
          if (snack) {
            dispatch(Actions.snackBarOn(snack, 3000));
          } else {
            dispatch(Actions.snackBarOff());
          }
          if (pathWithoutId)
            dispatch(navigateTo({ path: [pathWithoutId], query: query }));
          else if (path) {
            var id = response.data.data
              ? response.data.data.id
              : response.data.id;
            dispatch(navigateTo({ path: [path, id], query: query }));
          }
        }
      })
      .catch((err) => {
        if (errorModal) {
          dispatch(Actions.popUpOn(errorMessage, "Oops!"));
          dispatch({ type: "SNACK_BAR_OFF" });
        } else {
          errorHandler(err, dispatch);
        }
      });
  };
}

/*
it is a dynamic post action which posts' params and assigns the response to the reducer 
based on the given message. 

url: api url (required)
message: message for assigning reducer. (required)
data: post params (required)
snack: contains message to be shown in snack bar after response. 
*/
export function postData(url, message, data, snack = false) {
  return (dispatch) => {
    axios
      .post(url, data)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          if (response.data.data)
            dispatch({
              type: message,
              data: response.data.data,
            });
          else
            dispatch({
              type: message,
              data: response.data,
            });
        }
      })
      .catch((err) => {
        errorHandler(err, dispatch);
      });
  };
}

/*
it is a dynamic put action which makes the update request and assigns the response to the reducer 
based on the given message. 

url: api url (required)
message: message for assigning reducer. (required)
data: update params (required)
snack: contains message to be shown in snack bar after response. 
*/
export function putData(url, message, data, snack = false) {
  return (dispatch) => {
    axios
      .put(url, data)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          if (response.data.data)
            dispatch({
              type: message,
              data: response.data.data,
            });
          else
            dispatch({
              type: message,
              data: response.data,
            });
        }
        if (snack) {
          dispatch(Actions.snackBarOn(snack, 3000));
        } else {
          dispatch(Actions.snackBarOff());
        }
      })
      .catch((err) => {
        errorHandler(err, dispatch);
      });
  };
}

/*
it is a dynamic post action which posts' params using auth token and assigns the response to the reducer 
based on the given message. 
It can also be used to assign data to the common reducer based on the given key and message. 

url: api url (required)
message: message for assigning reducer. (required)
data: post params (required)
snack: contains message to be shown in snack bar after response. 
path: contains the path to be redirected after the response. 
key: name for common reducer
errorMessage: contains the message to be shown if there is an error. 
*/
export function postAuth(
  url,
  message,
  data,
  snack = false,
  key = false,
  path = false,
  errorModal = false,
  errorMessage = false,
  publish = false,
  query = false,
  pathWithoutId = false,
  errorSnack = false,
  showResponseMsg = false
) {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = localStorage.access_token;
    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (message) {
            if (response.data.data && !response.data.pagination)
              dispatch({
                type: message,
                data: response.data.data,
                key,
              });
            else
              dispatch({
                type: message,
                data: response.data,
                key,
              });
          }
          if (showResponseMsg && response.data.success)
            dispatch(Actions.snackBarOn(response.data.success, 5000));
          else if (snack) {
            dispatch(Actions.snackBarOn(snack, 3000));
          } else {
            dispatch(Actions.snackBarOff());
          }
          if (pathWithoutId) dispatch(navigateTo({ path: [pathWithoutId] }));
          else if (path) {
            var id = response.data.data
              ? response.data.data.id
              : response.data.id;
            if (query) {
              dispatch(navigateTo({ path: [path, id], query: query }));
            } else {
              dispatch(navigateTo({ path: [path, id] }));
            }
          }
          if (publish) dispatch({ type: "SNACK_BAR_PUBLISH_OFF" });
          else dispatch({ type: "SNACK_BAR_SAVE_OFF" });
        }
      })
      .catch((err) => {
        if (errorModal) {
          dispatch(Actions.popUpOn(errorMessage, "Oops!"));
          dispatch({ type: "SNACK_BAR_OFF" });
        } else if (errorSnack) {
          dispatch(Actions.snackBarOn(errorSnack, 3000));
        } else {
          errorHandler(err, dispatch);
        }
      });
  };
}

/*
it is a dynamic put action which makes the update request using auth token and assigns the response to the reducer 
based on the given message. 
It can also be used to assign data to the common reducer based on the given key and message. 

url: api url (required)
message: message for assigning reducer. (required)
params: update params (required)
snack: contains message to be shown in snack bar after response. 
path: contains the path to be redirected after the response. 
key: name for common reducer
errorMessage: contains the message to be shown if there is an error. 
*/
export function putAuth(
  url,
  message,
  params,
  snack = false,
  key = false,
  path = false,
  errorModal = false,
  errorMessage = false,
  publish = false,
  query = false,
  pathWithoutId = false
) {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = localStorage.access_token;
    axios
      .put(url, params)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          if (message) {
            if (response.data.data)
              dispatch({
                type: message,
                data: response.data.data,
                key,
              });
            else
              dispatch({
                type: message,
                data: response.data,
                key,
              });
          }
          if (snack) {
            dispatch(Actions.snackBarOn(snack, 3000));
          } else {
            dispatch(Actions.snackBarOff());
          }
          if (pathWithoutId) dispatch(navigateTo({ path: [pathWithoutId] }));
          else if (path) {
            var id = response.data.data
              ? response.data.data.id
              : response.data.id;
            if (query) {
              dispatch(navigateTo({ path: [path, id], query: query }));
            } else {
              dispatch(navigateTo({ path: [path, id] }));
            }
          }
          if (publish) dispatch({ type: "SNACK_BAR_PUBLISH_OFF" });
          else dispatch({ type: "SNACK_BAR_SAVE_OFF" });
        }
      })
      .catch((err) => {
        if (errorModal) {
          dispatch(Actions.popUpOn(errorMessage, "Oops!"));
          dispatch({ type: "SNACK_BAR_OFF" });
        } else {
          errorHandler(err, dispatch);
        }
      });
  };
}

/*
it is a dynamic delete action which makes the delete request using auth token and assigns the response to the reducer 
based on the given message. 

url: api url (required)
snack: contains message to be shown in snack bar after response. 
path: contains the path to be redirected after the response. 
*/
export function deleteAuth(
  url,
  message,
  snack = false,
  path = false,
  item_id = false
) {
  return (dispatch) => {
    axios.defaults.headers.common["Authorization"] = localStorage.access_token;
    axios
      .delete(url)
      .then(function (response) {
        if (
          response.status === 204 ||
          response.status === 200 ||
          response.status === 201
        ) {
          if (message) {
            if (response.data.data)
              dispatch({
                type: message,
                data: response.data.data,
                item_id: item_id,
              });
            else
              dispatch({
                type: message,
                data: response.data,
                item_id: item_id,
              });
          }
          if (snack) {
            dispatch(Actions.snackBarOn(snack, 3000));
          } else {
            dispatch(Actions.snackBarOff());
          }
          if (path !== false) {
            dispatch(navigateTo({ path: [path] }));
          }
          dispatch({ type: "SNACK_BAR_DISCARD_OFF" });
        }
      })
      .catch(function (error) {
        errorHandler(error, dispatch);
      });
  };
}
