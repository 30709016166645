import myPortfolioIcon from "../assets/DrawerIcons/Portfolio.png";
import clubIcon from "../assets/DrawerIcons/Clubs.png";
import communityIcon from "../assets/DrawerIcons/Community.png";
import coursesIcon from "../assets/DrawerIcons/Courses.png";
import learningpathIcon from "../assets/DrawerIcons/Learning-Path.png";
import dashboardIcon from "../assets/DrawerIcons/Dashboard.png";
import eventIcon from "../assets/DrawerIcons/Events.png";
import partnerIcon from "../assets/DrawerIcons/Partners.png";
import projectIcon from "../assets/DrawerIcons/Projects.png";
import companyIcon from "../assets/DrawerIcons/Companies.png";
import institutionIcon from "../assets/DrawerIcons/Institution.png";
import readingIcon from "../assets/DrawerIcons/Reading.png";
import questIcon from "../assets/DrawerIcons/Quests.png";
import jobIcon from "../assets/DrawerIcons/Jobs.png";
import internshipIcon from "../assets/DrawerIcons/Internships.png";
import bookIcon from "../assets/DrawerIcons/Books.png";
import articleIcon from "../assets/DrawerIcons/Articles.png";
import ninjaIcon from "../assets/DrawerIcons/Ninja.png";
import mentorIcon from "../assets/DrawerIcons/Mentors.png";
import collegeAdminIcon from "../assets/DrawerIcons/College-Admin.png";

export const drawerStructure = (
  type,
  isMobile,
  college_admin,
  dept_admin_access
) => {
  let drawer = null;
  switch (type) {
    case "STUDENT_LOGIN":
      drawer = [
        {
          name: "myportfolio",
          display_name: "My Portfolio",
          drawerIcon: myPortfolioIcon,
          navigateTo: ["myPortfolio"],
          path: "myPortfolio",
        },
        {
          name: "dashboard",
          display_name: "Dashboard",
          drawerIcon: dashboardIcon,
          navigateTo: ["dashboard"],
          path: "dashboard",
        },
        {
          name: "project",
          display_name: "Projects",
          drawerIcon: projectIcon,
          navigateTo: ["projects"],
          path: "projects",
        },
        {
          name: "event",
          display_name: "Events",
          drawerIcon: eventIcon,
          navigateTo: ["events"],
          path: "events",
        },
        {
          name: "course",
          display_name: "Courses",
          drawerIcon: coursesIcon,
          navigateTo: null,
          child: [
            {
              name: "quest",
              display_name: "Quests",
              drawerIcon: questIcon,
              navigateTo: ["quests"],
              path: "quests",
            },
            {
              name: "resource_group",
              display_name: "Learning Paths",
              drawerIcon: learningpathIcon,
              navigateTo: ["resourceGroups"],
              path: "resourceGroups",
            },
          ],
          childPaths: ["resourceGroups", "quests"],
        },
        // {
        //   name: "work",
        //   display_name: "Jobs",
        //   drawerIcon: jobIcon,
        //   navigateTo: null,
        //   child: [
        //     {
        //       name: "job",
        //       display_name: "Jobs",
        //       drawerIcon: jobIcon,
        //       navigateTo: ["jobs"],
        //       path: "jobs",
        //     },
        //     {
        //       name: "internship",
        //       display_name: "Internships",
        //       drawerIcon: internshipIcon,
        //       navigateTo: ["internships"],
        //       path: "internships",
        //     },
        //   ],
        //   childPaths: ["jobs", "internships"],
        // },
          {
              name: "job",
              display_name: "Jobs",
              drawerIcon: jobIcon,
              navigateTo: ["jobs"],
              path: "jobs",
            },
            {
              name: "internship",
              display_name: "Internships",
              drawerIcon: internshipIcon,
              navigateTo: ["internships"],
              path: "internships",
            }, 
        {
          name: "reading",
          display_name: "Readings",
          drawerIcon: readingIcon,
          navigateTo: null,
          child: [
            {
              name: "book",
              display_name: "Books",
              drawerIcon: bookIcon,
              navigateTo: ["books"],
              path: "books",
            },
            {
              name: "article",
              display_name: "Articles",
              drawerIcon: articleIcon,
              navigateTo: ["articles"],
              path: "articles",
            },
          ],
          childPaths: ["books", "articles"],
        },
        {
          name: "club",
          display_name: "Clubs",
          drawerIcon: clubIcon,
          navigateTo: ["clubs"],
          path: "clubs",
        },
        {
          name: "community",
          display_name: "Community",
          drawerIcon: communityIcon,
          navigateTo: ["posts"],
          path: "posts",
        },
        {
          name: "partner",
          display_name: "Partners",
          drawerIcon: partnerIcon,
          navigateTo: null,
          child: [
            {
              name: "company",
              display_name: "Companies",
              drawerIcon: companyIcon,
              navigateTo: ["partners", "companies"],
              path: "partners/companies",
            },
            {
              name: "institute",
              display_name: "Institutes",
              drawerIcon: institutionIcon,
              navigateTo: ["partners", "institutes"],
              path: "partners/institutes",
            },
          ],
          childPaths: ["partners/companies", "partners/institutes"],
        },
      ];
      break;
    case "ADVISOR_LOGIN":
      drawer = [
        {
          name: "dashboard",
          display_name: "Dashboard",
          drawerIcon: dashboardIcon,
          navigateTo: ["mentorDashboard"],
          path: "mentorDashboard",
        },
        {
          name: "project",
          display_name: "Projects",
          drawerIcon: projectIcon,
          navigateTo: ["projects"],
          path: "projects",
        },
        {
          name: "event",
          display_name: "Events",
          drawerIcon: eventIcon,
          navigateTo: ["events"],
          path: "events",
        },
        {
          name: "course",
          display_name: "Courses",
          drawerIcon: coursesIcon,
          navigateTo: null,
          child: [
            {
              name: "quest",
              display_name: "Quests",
              drawerIcon: questIcon,
              navigateTo: ["quests"],
              path: "quests",
            },
            {
              name: "resource_group",
              display_name: "Learning Paths",
              drawerIcon: learningpathIcon,
              navigateTo: ["resourceGroups"],
              path: "resourceGroups",
            },
          ],
          childPaths: ["resourceGroups", "quests"],
        },
        // {
        //   name: "work",
        //   display_name: "Jobs",
        //   drawerIcon: jobIcon,
        //   navigateTo: null,
        //   child: [
        //     {
        //       name: "job",
        //       display_name: "Jobs",
        //       drawerIcon: jobIcon,
        //       navigateTo: ["jobs"],
        //       path: "jobs",
        //     },
        //     {
        //       name: "internship",
        //       display_name: "Internships",
        //       drawerIcon: internshipIcon,
        //       navigateTo: ["internships"],
        //       path: "internships",
        //     },
        //   ],
        //   childPaths: ["jobs", "internships"],
        // },
          {
              name: "job",
              display_name: "Jobs",
              drawerIcon: jobIcon,
              navigateTo: ["jobs"],
              path: "jobs",
            },
            {
              name: "internship",
              display_name: "Internships",
              drawerIcon: internshipIcon,
              navigateTo: ["internships"],
              path: "internships",
            },  
        {
          name: "club",
          display_name: "Clubs",
          drawerIcon: clubIcon,
          navigateTo: ["clubs"],
          path: "clubs",
        },
        {
          name: "community",
          display_name: "Community",
          drawerIcon: communityIcon,
          navigateTo: ["posts"],
          path: "posts",
        },
        {
          name: "partner",
          display_name: "Partners",
          drawerIcon: partnerIcon,
          navigateTo: null,
          child: [
            {
              name: "company",
              display_name: "Companies",
              drawerIcon: companyIcon,
              navigateTo: ["partners", "companies"],
              path: "partners/companies",
            },
            {
              name: "institute",
              display_name: "Institutes",
              drawerIcon: institutionIcon,
              navigateTo: ["partners", "institutes"],
              path: "partners/institutes",
            },
          ],
          childPaths: ["partners/companies", "partners/institutes"],
        },
      ];
      break;
    case "COMPANY_LOGIN":
      drawer = [
        {
          name: "dashboard",
          display_name: "Dashboard",
          drawerIcon: dashboardIcon,
          navigateTo: [
            isMobile ? "companyDashboardMobile" : "companyDashboard",
          ],
          path: "companyDashboard",
        },
        {
          name: "project",
          display_name: "Projects",
          drawerIcon: projectIcon,
          navigateTo: ["projects"],
          path: "projects",
        },
        {
          name: "event",
          display_name: "Events",
          drawerIcon: eventIcon,
          navigateTo: ["events"],
          path: "events",
        },
        {
          name: "course",
          display_name: "Courses",
          drawerIcon: coursesIcon,
          navigateTo: null,
          child: [
            {
              name: "quest",
              display_name: "Quests",
              drawerIcon: questIcon,
              navigateTo: ["quests"],
              path: "quests",
            },
            {
              name: "resource_group",
              display_name: "Learning Paths",
              drawerIcon: learningpathIcon,
              navigateTo: ["resourceGroups"],
              path: "resourceGroups",
            },
          ],
          childPaths: ["resourceGroups", "quests"],
        },
        // {
        //   name: "work",
        //   display_name: "Jobs",
        //   drawerIcon: jobIcon,
        //   navigateTo: null,
        //   child: [
        //     {
        //       name: "job",
        //       display_name: "Jobs",
        //       drawerIcon: jobIcon,
        //       navigateTo: ["jobs"],
        //       path: "jobs",
        //     },
        //     {
        //       name: "internship",
        //       display_name: "Internships",
        //       drawerIcon: internshipIcon,
        //       navigateTo: ["internships"],
        //       path: "internships",
        //     },
        //   ],
        //   childPaths: ["jobs", "internships"],
        // },
        {
              name: "job",
              display_name: "Jobs",
              drawerIcon: jobIcon,
              navigateTo: ["jobs"],
              path: "jobs",
            },
            {
              name: "internship",
              display_name: "Internships",
              drawerIcon: internshipIcon,
              navigateTo: ["internships"],
              path: "internships",
            },  
        {
          name: "community",
          display_name: "Community",
          drawerIcon: communityIcon,
          navigateTo: ["posts"],
          path: "posts",
        },
        {
          name: "partner",
          display_name: "Partners",
          drawerIcon: partnerIcon,
          navigateTo: null,
          child: [
            {
              name: "company",
              display_name: "Companies",
              drawerIcon: companyIcon,
              navigateTo: ["partners", "companies"],
              path: "partners/companies",
            },
            {
              name: "institute",
              display_name: "Institutes",
              drawerIcon: institutionIcon,
              navigateTo: ["partners", "institutes"],
              path: "partners/institutes",
            },
          ],
          childPaths: ["partners/companies", "partners/institutes"],
        },
      ];
      break;
    case "INSTITUTION_LOGIN":
      drawer = [
        {
          name: "dashboard",
          display_name: "Dashboard",
          drawerIcon: dashboardIcon,
          navigateTo: ["instituteDashboard"],
          path: "instituteDashboard",
        },
        {
          name: "project",
          display_name: "Projects",
          drawerIcon: projectIcon,
          navigateTo: ["projects"],
          path: "projects",
        },
        {
          name: "event",
          display_name: "Events",
          drawerIcon: eventIcon,
          navigateTo: ["events"],
          path: "events",
        },
        {
          name: "course",
          display_name: "Courses",
          drawerIcon: coursesIcon,
          navigateTo: null,
          child: [
            {
              name: "quest",
              display_name: "Quests",
              drawerIcon: questIcon,
              navigateTo: ["quests"],
              path: "quests",
            },
            {
              name: "resource_group",
              display_name: "Learning Paths",
              drawerIcon: learningpathIcon,
              navigateTo: ["resourceGroups"],
              path: "resourceGroups",
            },
          ],
          childPaths: ["resourceGroups", "quests"],
        },
        // {
        //   name: "work",
        //   display_name: "Jobs",
        //   drawerIcon: jobIcon,
        //   navigateTo: null,
        //   child: [
        //     {
        //       name: "job",
        //       display_name: "Jobs",
        //       drawerIcon: jobIcon,
        //       navigateTo: ["jobs"],
        //       path: "jobs",
        //     },
        //     {
        //       name: "internship",
        //       display_name: "Internships",
        //       drawerIcon: internshipIcon,
        //       navigateTo: ["internships"],
        //       path: "internships",
        //     },
        //   ],
        //   childPaths: ["jobs", "internships"],
        // },
          {
              name: "job",
              display_name: "Jobs",
              drawerIcon: jobIcon,
              navigateTo: ["jobs"],
              path: "jobs",
            },
            {
              name: "internship",
              display_name: "Internships",
              drawerIcon: internshipIcon,
              navigateTo: ["internships"],
              path: "internships",
            }, 
        {
          name: "community",
          display_name: "Community",
          drawerIcon: communityIcon,
          navigateTo: ["posts"],
          path: "posts",
        },
        {
          name: "partner",
          display_name: "Partners",
          drawerIcon: partnerIcon,
          navigateTo: null,
          child: [
            {
              name: "company",
              display_name: "Companies",
              drawerIcon: companyIcon,
              navigateTo: ["partners", "companies"],
              path: "partners/companies",
            },
            {
              name: "institute",
              display_name: "Institutes",
              drawerIcon: institutionIcon,
              navigateTo: ["partners", "institutes"],
              path: "partners/institutes",
            },
          ],
          childPaths: ["partners/companies", "partners/institutes"],
        },
      ];
      break;
    default:
      if (isMobile)
        drawer = [
          {
            name: "ninja",
            display_name: "Ninja",
            drawerIcon: ninjaIcon,
            navigateTo: ["Home"],
            path: "Home",
          },
          {
            name: "mentor",
            display_name: "Mentor",
            drawerIcon: mentorIcon,
            navigateTo: ["MentorHome"],
            path: "MentorHome",
          },
          {
            name: "institute",
            display_name: "Institute",
            drawerIcon: institutionIcon,
            navigateTo: ["InstituteHome"],
            path: "InstituteHome",
          },
          {
            name: "company",
            display_name: "Company",
            drawerIcon: companyIcon,
            navigateTo: ["CompanyHome"],
            path: "CompanyHome",
          },
          {
            name: "project",
            display_name: "Projects",
            drawerIcon: projectIcon,
            navigateTo: ["projects"],
            path: "projects",
          },
          {
            name: "event",
            display_name: "Events",
            drawerIcon: eventIcon,
            navigateTo: ["events"],
            path: "events",
          },
          {
            name: "course",
            display_name: "Courses",
            drawerIcon: coursesIcon,
            navigateTo: null,
            child: [
              {
                name: "quest",
                display_name: "Quests",
                drawerIcon: questIcon,
                navigateTo: ["quests"],
                path: "quests",
              },
              {
                name: "resource_group",
                display_name: "Learning Paths",
                drawerIcon: learningpathIcon,
                navigateTo: ["resourceGroups"],
                path: "resourceGroups",
              },
            ],
            childPaths: ["resourceGroups", "quests"],
          },
          {
            name: "work",
            display_name: "Jobs",
            drawerIcon: jobIcon,
            navigateTo: null,
            child: [
              {
                name: "job",
                display_name: "Jobs",
                drawerIcon: jobIcon,
                navigateTo: ["jobs"],
                path: "jobs",
              },
              {
                name: "internship",
                display_name: "Internships",
                drawerIcon: internshipIcon,
                navigateTo: ["internships"],
                path: "internships",
              },
            ],
            childPaths: ["jobs", "internships"],
          },
          {
            name: "reading",
            display_name: "Readings",
            drawerIcon: readingIcon,
            navigateTo: null,
            child: [
              {
                name: "book",
                display_name: "Books",
                drawerIcon: bookIcon,
                navigateTo: ["books"],
                path: "books",
              },
              {
                name: "article",
                display_name: "Articles",
                drawerIcon: articleIcon,
                navigateTo: ["articles"],
                path: "articles",
              },
            ],
            childPaths: ["books", "articles"],
          },
          {
            name: "club",
            display_name: "Clubs",
            drawerIcon: clubIcon,
            navigateTo: ["clubs"],
            path: "clubs",
          },
          {
            name: "community",
            display_name: "Community",
            drawerIcon: communityIcon,
            navigateTo: ["posts"],
            path: "posts",
          },
          {
            name: "partner",
            display_name: "Partners",
            drawerIcon: partnerIcon,
            navigateTo: null,
            child: [
              {
                name: "company",
                display_name: "Companies",
                drawerIcon: companyIcon,
                navigateTo: ["partners", "companies"],
                path: "partners/companies",
              },
              {
                name: "institute",
                display_name: "Institutes",
                drawerIcon: institutionIcon,
                navigateTo: ["partners", "institutes"],
                path: "partners/institutes",
              },
            ],
            childPaths: ["partners/companies", "partners/institutes"],
          },
        ];
      else
        drawer = [
          {
            name: "ninja",
            display_name: "Ninja",
            drawerIcon: ninjaIcon,
            navigateTo: ["Home"],
            path: "Home",
          },
          {
            name: "project",
            display_name: "Projects",
            drawerIcon: projectIcon,
            navigateTo: ["projects"],
            path: "projects",
          },
          {
            name: "event",
            display_name: "Events",
            drawerIcon: eventIcon,
            navigateTo: ["events"],
            path: "events",
          },
          {
            name: "course",
            display_name: "Courses",
            drawerIcon: coursesIcon,
            navigateTo: null,
            child: [
              {
                name: "quest",
                display_name: "Quests",
                drawerIcon: questIcon,
                navigateTo: ["quests"],
                path: "quests",
              },
              {
                name: "resource_group",
                display_name: "Learning Paths",
                drawerIcon: learningpathIcon,
                navigateTo: ["resourceGroups"],
                path: "resourceGroups",
              },
            ],
            childPaths: ["resourceGroups", "quests"],
          },
          // {
          //   name: "work",
          //   display_name: "Jobs",
          //   drawerIcon: jobIcon,
          //   navigateTo: null,
          //   child: [
          //     {
          //       name: "job",
          //       display_name: "Jobs",
          //       drawerIcon: jobIcon,
          //       navigateTo: ["jobs"],
          //       path: "jobs",
          //     },
          //     {
          //       name: "internship",
          //       display_name: "Internships",
          //       drawerIcon: internshipIcon,
          //       navigateTo: ["internships"],
          //       path: "internships",
          //     },
          //   ],
          //   childPaths: ["jobs", "internships"],
          // },
          
          {
                name: "job",
                display_name: "Jobs",
                drawerIcon: jobIcon,
                navigateTo: ["jobs"],
                path: "jobs",
              },
              {
                name: "internship",
                display_name: "Internships",
                drawerIcon: internshipIcon,
                navigateTo: ["internships"],
                path: "internships",
              },
          {
            name: "reading",
            display_name: "Readings",
            drawerIcon: readingIcon,
            navigateTo: null,
            child: [
              {
                name: "book",
                display_name: "Books",
                drawerIcon: bookIcon,
                navigateTo: ["books"],
                path: "books",
              },
              {
                name: "article",
                display_name: "Articles",
                drawerIcon: articleIcon,
                navigateTo: ["articles"],
                path: "articles",
              },
            ],
            childPaths: ["books", "articles"],
          },
          {
            name: "club",
            display_name: "Clubs",
            drawerIcon: clubIcon,
            navigateTo: ["clubs"],
            path: "clubs",
          },
          {
            name: "community",
            display_name: "Community",
            drawerIcon: communityIcon,
            navigateTo: ["posts"],
            path: "posts",
          },
          {
            name: "partner",
            display_name: "Partners",
            drawerIcon: partnerIcon,
            navigateTo: null,
            child: [
              {
                name: "company",
                display_name: "Companies",
                drawerIcon: companyIcon,
                navigateTo: ["partners", "companies"],
                path: "partners/companies",
              },
              {
                name: "institute",
                display_name: "Institutes",
                drawerIcon: institutionIcon,
                navigateTo: ["partners", "institutes"],
                path: "partners/institutes",
              },
            ],
            childPaths: ["partners/companies", "partners/institutes"],
          },
        ];
      break;
  }
  let college_admin_drawer =
    ( dept_admin_access && dept_admin_access.tpo_admin_dashboard)|| (college_admin ||
      (dept_admin_access && dept_admin_access.dept_admin_dashboard)) &&
    !isMobile
      ? [
          {
            name: "admin_dashboard",
            display_name: "Admin",
            drawerIcon: collegeAdminIcon,
            navigateTo: ["college-admin"],
            path: "college-admin",
          },
        ]
      : [];
  return [...college_admin_drawer, ...drawer];
};
