import React, {Component} from 'react';
import { connect } from 'react-redux';
import Toast from 'react-bootstrap/Toast';

class ToasterComponent extends Component{
    render(){
        const {dispatch, toastMsg, toastHeading, toastType, active} = this.props;
        return (
            <Toast className="toastMain" onClose={() => dispatch({type:'TOAST_INACTIVE'})} style={active?{}:{zIndex: -1}} show={active} delay={3000} autohide>
                <Toast.Header className="toastHeader" style={toastType==='delete'?{backgroundColor: 'var(--clr-toast-delete)'}:toastType==='info'?{backgroundColor: 'var(--clr-dark-blue)'}:{backgroundColor: 'var(--clr-toast-success)'}}>
                    <strong className="mr-auto">{toastHeading}</strong>
                </Toast.Header>
                <Toast.Body className="toastBody" style={toastType==='delete'?{backgroundColor: 'var(--clr-toast-delete)'}:toastType==='info'?{backgroundColor: 'var(--clr-dark-blue)'}:{backgroundColor: 'var(--clr-toast-success)'}}>{toastMsg}</Toast.Body>
            </Toast>
        )
    }
}

const mapStateToProps = state => {
    const {toastState} = state;
    const {toastMsg, toastHeading, toastType, active, timeout} = toastState;
    return {
        toastMsg, toastHeading, toastType, active, timeout
    }
}

export default connect(mapStateToProps)(ToasterComponent);