import React from 'react';
import PropTypes from 'prop-types';
import { navigateTo } from '../../../actions/navigator';
import { constructUrl } from '../../../utils/RouteUtils';
import { initNavigator } from "../../../actions/navigator";


const propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	dispatch: PropTypes.func.isRequired,
	route: PropTypes.object.isRequired,
	title: PropTypes.string,
};

const Link = props => {

	const handleClick = e => {
		e.preventDefault();
		const { dispatch, route } = props;
		var { query } = route;
		var queryData = query ? query : {};
		var pathnameStr = "/" + constructUrl(route);
		var locationObj = { pathname: pathnameStr, query: queryData };
		dispatch(navigateTo(route));
		dispatch(initNavigator(locationObj));
		// TODO: Check alternative
		window.scroll(0, 0);
	};

	const { children, className, title, style } = props;
	return (
		/* eslint-disable-next-line */
		<a
			className={className}
			style={style}
			onClick={handleClick}
			title={title ? String(title) : ''}
		>
			{children}
		</a>
	);
}

Link.propTypes = propTypes;


export default Link;
