import React from "react";
import "./index.css";

const ModalComponent = props => {
  return (
    <>
      <div className="modal-background"
        style={
          props.show ?
            props.modalDarkBackground ?
              { opacity: 0.5, display: "block", backgroundColor: '#333333' } :
              props.companyContainer ?
                { opacity: 0.95, display: "block" } :
                { opacity: 0.5, display: "block" } :
            { opacity: 0, display: "none" }
        }></div>
      <div role="dialog" aria-modal="true" className="modal-box" tabIndex="-1" aria-hidden="true" style={{ display: props.show ? "flex" : "none" }}>
        <div role="document" className={`modal-class ${props.modalClass}`} style={props.modalStyle}>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
