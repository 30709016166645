import React from 'react';
import logoLoader from "../../../../../assets/xceler_logo1.gif";

const LoaderComponent = () => {
	return (
		<div className="logoLoader">
			<img src={logoLoader} alt="xcelerator_loader" />
		</div>
	)
}

export default LoaderComponent;