export const metaData = {
	aboutUs: {
		title: "About xcelerator",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	activity: {
		title: "Activities",
		description:
			"Take up activities to test your knowledge and prac…nce, Computer Fundamentals, Environment and more!",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	bites: {
		title: "BITES Project",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	careerReadiness: {
		title: "Career Readiness",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	clubs: {
		title: "Clubs",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/default_images/club_default.jpeg",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	company: {
		title: "Companies",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	companyHome: {
		title: "xcelerator - Learn By Doing",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	copyright: {
		title: "Copyright Policy",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	default: {
		title: "xcelerator - Learn By Doing",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	degreeProgram: {
		title: "Specialized Degree Program",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	events: {
		title: "Events",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://xceler-platform-assets.s3-ap-southeast-1.amazonaws.com/uploads/featured_event.png",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	examPrep: {
		title: "VTU Exam Prep",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	faq: {
		title: "FAQs",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	guidelines: {
		title: "Community Guidelines",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	hackathon: {
		title: "xHackathon",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	home: {
		title: "xcelerator - Learn By Doing",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	institute: {
		title: "Institutes",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	instituteHome: {
		title: "xcelerator - Learn By Doing",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	internships: {
		title: "Internships",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://xceler-platform-assets.s3-ap-southeast-1.amazonaws.com/uploads/featured_job.png",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	jobs: {
		title: "Jobs",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://xceler-platform-assets.s3-ap-southeast-1.amazonaws.com/uploads/featured_job.png",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	mentor: {
		title: "Mentor Dashboard",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	mentorHome: {
		title: "xcelerator - Learn By Doing",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	partners: {
		title: "Partners",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	portfolio: {
		title: "Portfolio",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	posts: {
		title: "Community",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	privacy: {
		title: "Privacy Policy",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	projects: {
		title: "Projects",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/default_images/promoted_projects.jpg",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	quests: {
		title: "Quests",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://xceler-platform-assets.s3-ap-southeast-1.amazonaws.com/uploads/featured_quest.jpg",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	quiz: {
		title: "Quizzes",
		description:
			"Take quizzes to test your knowledge on a variety o…nce, Computer Fundamentals, Environment and more!",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	questionBank: {
		title: "Quiz Bank",
		description:
			"Take quiz bank to test your knowledge on a variety o…nce, Computer Fundamentals, Environment and more!",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	resourceGroups: {
		title: "Learning Path",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://xceler-platform-assets.s3-ap-southeast-1.amazonaws.com/uploads/featured_resource_group.jpg",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	resources: {
		title: "Resources",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations.",
		image:
			"https://xceler-platform-assets.s3-ap-southeast-1.amazonaws.com/uploads/featured_resource.jpg",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	skillAug: {
		title: "Skill Augmentation",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	selfPaced: {
		title: "Self Paced Programs",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	student: {
		title: "Student Dashboard",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	termsOfUse: {
		title: "Terms of Use",
		description:
			"Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them.",
		keywords:
			"Education,EdTech,Mentoring,Community,Projects,xceler,xcelerator,xcelerator.ninja"
	},

	winterInternship: {
		title: "XCELERATOR | Winter Internship Program for Karnataka students",
		description: "Looking for internships during winter break? Join Xcelerator's Winter Internship Program. Work on real world projects in IoT, Machine Learning, Data analysis, Supply Chain & more",
		keywords: "internships, internship bangalore, winter internship, internship programme, winter intership 2019, winter internship in India, winter internship in bangalore"
	},

	summerInternship: {
		title: "XCELERATOR | Summer Internship Program 2020 now ONLINE.",
		description: "Looking for internships during summer break 2020? Join Xcelerator's Virtual Simulated Summer Internship Program. Work on real world projects in IoT & Machine Learning",
		keywords: "internships, internship bangalore, summer internship, internship programme, summer internship 2020, summer internship in India, summer internship in bangalore, summer internship online"
	},

	studyInUsa: {
		title:
			"Xcelerator International Entrance Test (XIET): Your gateway to universities in the US",
		description:
			"Want to study in the US? Take the XIET & build your portfolio on Xcelerator. Xcelerator has partnered with top universities in US & offers you an opportunity to get scholarships & fee waivers",
		keywords:
			"study in us, study abroad, study in United States, US universities ranking, US universities admissions, entrance test for US admissions"
	},

	trending: { canonical_url: `${window.location.origin}/Winter-Internship` }
};
