import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { initEnvironment } from "../../actions/environment";
import { initNavigator, navigateToAndReplace } from "../../actions/navigator";
import { initError } from "../../actions/error";
import LoggedOutMainMenu from "./menus/MainMenu/loggedOut";
import MainMenu from "./menus/MainMenu";
import PopUpModal from './components/PopUpModal';
import LoaderComponent from "./screens/Public/Loader";
import SnackBar from './menus/SnackBar';
import AddCardComponent from './components/portfolio/AddCard.js';
import DrawerMenu from './menus/MainMenu/drawer'
import Actions from '../../redux/actions.js';
import { changeDrawerState } from '../../actions/drawer';
import ToasterComponent from './components/toasterComponent';
import { withRouter } from 'react-router-dom'
import { parseQueryString, handleAuthClick } from '../../utils/CommonUtils';
import HelmetComponent from './components/commonComponent/helmetComponent';
import firebase from '../../firebase';
import worker_script from './token_expiry_worker';
import LoginPopup from './screens/Users/LoginPopup';

let worker;

const propTypes = {
	path: PropTypes.array.isRequired,
	error_msg: PropTypes.string,
};

class App extends Component {
	constructor(props) {
		super(props);
		const { dispatch } = props;
		this.state = {
			isPageFocused: true
		}
		dispatch(initNavigator(this.props.location));
	}
	componentDidMount() {
		window.addEventListener('focus', this.onFocus)
		window.addEventListener('blur', this.onBlur)
		const { currentUser, dispatch } = this.props;
		if (localStorage.access_token && localStorage.access_expiry) {
			let access_expiry = localStorage.access_expiry;
			if (parseInt(access_expiry) - Math.round(new Date().getTime() / 1000) < 120) {
				this.fetchRefresh().then((response) => {
					if (!response.tokenUpdated) {
						dispatch(Actions.logout())
					}
					else if (!currentUser && response.tokenUpdated) {
						dispatch(Actions.getCurrentUser());
					}
				});
			} else {
				dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: access_expiry })
				if (!currentUser) {
					dispatch(Actions.getCurrentUser());
				}
			}
		} else {
			localStorage.removeItem('access_token')
			localStorage.removeItem('access_expiry')
		}
		dispatch(initEnvironment());
		dispatch(initError());
	}

	async componentDidUpdate(prevProps, prevState) {
		const { tokenExpiryTime, tokenExpiredCondition, dispatch, currentUser } = this.props;
		if (this.props.location !== prevProps.location) {
			if(currentUser && currentUser.profile && !currentUser.profile.active) {
				if (currentUser.profile.profile_type === 'COMPANY' || currentUser.profile.profile_type === 'INSTITUTION')
					this.props.dispatch(navigateToAndReplace({path: ['unapprovedProfile']}))
				else
					this.props.dispatch(navigateToAndReplace({path: ['disabledProfile']}))
			}
			this.props.dispatch(initNavigator(this.props.location, prevProps.location));
		}
		if (tokenExpiryTime && prevProps.tokenExpiryTime !== tokenExpiryTime) {
			if (typeof (Worker) !== 'undefined') {
				if (typeof (worker) !== 'undefined') {
					worker.terminate();
					worker = undefined;
				}
				if (typeof (worker) === 'undefined') {
					worker = new Worker(worker_script);
					worker.postMessage(tokenExpiryTime)
				}
			}
		}
		if (tokenExpiredCondition.tokenExpired && prevProps.tokenExpiredCondition.tokenExpired !== tokenExpiredCondition.tokenExpired) {
			if (typeof (Worker) !== 'undefined') {
				if (typeof (worker) !== 'undefined') {
					worker.terminate();
					worker = undefined;
				}
			}
			if (tokenExpiredCondition.callFetchRefresh) {
				this.fetchRefresh();
			}
			else {
				dispatch({ type: 'TOKEN_EXPIRED_CONDITION', data: { tokenExpired: false, callFetchRefresh: false } });
			}
		}
		if (this.state.isPageFocused && !prevState.isPageFocused) {
			if (localStorage.access_token && localStorage.access_expiry) {
				if (parseInt(localStorage.access_expiry) - Math.round(new Date().getTime() / 1000) < 120) {
					dispatch(Actions.snackBarOn('Please wait while we refresh your session'))
					const response = await this.fetchRefresh();
					if (!response.tokenUpdated) {
						dispatch(Actions.logout())
					}
					else if (!currentUser && response.tokenUpdated) {
						dispatch(Actions.getCurrentUser());
					}
					document.removeEventListener("click", this.clickHandler, true);
					dispatch(Actions.snackBarOff());
				}
				else {
					document.removeEventListener("click", this.clickHandler, true);
				}
			}
			else {
				document.removeEventListener("click", this.clickHandler, true);
			}
		}
	}

	onFocus = () => this.setState({ isPageFocused: true })

	onBlur = () => {
		document.addEventListener("click", this.clickHandler, true);
		this.setState({ isPageFocused: false })
	}

	clickHandler = (event) => {
		event.stopPropagation();
		event.preventDefault();
	}

	componentWillUnmount() {
		window.removeEventListener('focus', this.onFocus)
		window.removeEventListener('blur', this.onBlur)
		clearInterval(this.refreshToken)
	}

	fetchRefresh = () => {
		const { dispatch } = this.props;
		return new Promise((resolve) => {
			firebase.auth().onAuthStateChanged(user => {
				if (user) {
					user.getIdTokenResult(true).then((idTokenResult) => {
						let access_expiry_time = idTokenResult.claims.exp;
						localStorage.setItem('access_token', idTokenResult.token);
						localStorage.setItem('access_expiry', access_expiry_time);
						dispatch({ type: 'TOKEN_EXPIRY_TIME', access_expiry: access_expiry_time })
						dispatch({ type: 'TOKEN_EXPIRED_CONDITION', data: { tokenExpired: false, callFetchRefresh: false } });
						resolve({ tokenUpdated: true });
					});
				} else {
					localStorage.removeItem('access_token')
					localStorage.removeItem('access_expiry')
					dispatch({ type: 'TOKEN_EXPIRED_CONDITION', data: { tokenExpired: true, callFetchRefresh: false } });
					resolve({ tokenUpdated: false });
				}
			})
		})
	}

	handleDrawer = () => {
		const { dispatch } = this.props
		var value = !this.props.drawerState;
		dispatch(changeDrawerState(value));
	}

	changePopupState = (isLogin = null, isOpen = null) => {
		const { dispatch } = this.props;
		if (isOpen === false) {
			handleAuthClick();
		}
		dispatch({ type: 'MERGE_IN_COMMON_REDUCER', key: 'loginPopupConfig', data: { showModal: isOpen, isLogin: isLogin } })
	}

	render() {
		const { dispatch, path, drawerState, error, isMobile, height, currentUser, isLoading, openAddCard, hideMenuBar, loginPopupConfig, hideMenuBarInsta } = this.props;
		if (typeof Worker !== "undefined") {
			if (typeof worker !== "undefined") {
				worker.onmessage = _event => {
					dispatch({ type: "TOKEN_EXPIRED_CONDITION", data: true });
				};
			}
		}
		let currPath = path[0];
		let query = parseQueryString(this.props.location.search);
		let is_app = query.is_app;
		let hideHeader = query.hideHeader;
		let renderData = isLoading.currentUser ? (
			<LoaderComponent key="component" />
		) : (
				<div key="component">
					{!(is_app && isMobile) && !hideMenuBar ? (
						!currentUser ? (
							isMobile ?
								<DrawerMenu />
								: <DrawerMenu />
						) : path[0] === "studentProfile" ||
							path[0] === "selectJobRoles" ||
							path[0] === "jobRoles" ||
							path[0] === "mentorProfile" ||
							path[0] === "instituteProfile" ||
							path[0] === "companyProfile" ||
							path[0] === "unapprovedProfile" ||
							path[0] === "disabledProfile" ||
							!currentUser.profile ? null : isMobile ? (
								<DrawerMenu />
							) :
									<DrawerMenu />
					) : null}
					<div
						style={
							drawerState && isMobile
								? { position: "fixed", transform: "translate(202.11px)" }
								: null
						}
						className="rootContainer col-12"
					>
						{!(is_app && isMobile) && !(hideMenuBarInsta || hideHeader) ? (
							<div
								style={{ height: currentUser ? 55 : 70 }}
							>
								{currentUser ? (
									<MainMenu location={currPath} />
								) : (
										<LoggedOutMainMenu location={currPath} />
									)}
							</div>
						) : null}
						{error.error_msg ? (
							<div
								className="alert alert-danger page-notification"
								style={{ position: "fixed", zIndex: "1000", display: "none" }}
							>
								{error.error_msg}
							</div>
						) : null}
						<div
							onClick={this.props.drawerState ? () => this.handleDrawer() : null}
							style={is_app && isMobile ? { top: 0 } : null}
							className={
								hideMenuBarInsta
									? "content-container-loggedout grayBgColor"
									: !currentUser
										? "content-container-loggedout"
										: "content-container-loggedin"
							}
						>
							{this.props.children}
						</div>
						<div
							onClick={() => this.handleDrawer()}
							className={drawerState && isMobile ? "overlayWhole" : null}
							style={drawerState && isMobile ? { height: height } : null}
						></div>
						<SnackBar />
						<ToasterComponent />
						<PopUpModal />
						{loginPopupConfig ? (
							<LoginPopup
								showModal={loginPopupConfig.showModal}
								isLogin={loginPopupConfig.isLogin}
								changePopupState={this.changePopupState}
							/>
						) : null}
						{currentUser && openAddCard ? <AddCardComponent /> : null}
					</div>
				</div>
			);
		return [
			<HelmetComponent
				key="helmet"
				title="xcelerator - Learn By Doing"
				description="Xcelerator helps college students build a portfolio of skills aligned to their career aspirations. The platform creates a gamification layer to integrate and align everything a college student does (education, exposure, experience) to specific job roles chosen by the students - all the while creating personal paths to success for them."
			/>,
			renderData
		];
	}
}

App.propTypes = propTypes;
const mapStateToProps = (state) => {
	const { environment, navigator, error, isLoading, drawerState, portfolio, tokenExpiryTime, tokenExpiredCondition, addModal, commonReducer } = state;
	const { height, isMobile, width } = environment;
	const { path } = navigator.route;
	const { openAddCard } = addModal;
	const { hideMenuBar, updateAccessToken, loginPopupConfig, hideMenuBarInsta } = commonReducer
	return {
		currentUser: state.currentUser,
		height,
		drawerState,
		isMobile,
		path,
		isLoading,
		width,
		error,
		portfolio,
		tokenExpiryTime,
		tokenExpiredCondition,
		openAddCard,
		hideMenuBar,
		updateAccessToken,
		loginPopupConfig,
		hideMenuBarInsta
	};
}

export default withRouter(connect(mapStateToProps)(App));