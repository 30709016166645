import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = props => {
	let description = props.description ? props.description.replace(/<[^>]*>/g, '') : null
	return (
		<Helmet>
			<title>{props.title}</title>
			{props.canonical_url ? <link rel="canonical" href={props.canonical_url} /> : null}
			<meta property="og:title" content={props.title} />
			{description ? <meta property="og:description" content={description} /> : null}
			{props.image ? <meta property="og:image" itemprop="image" content={props.image} /> : null}
			{props.keywords ? <meta name="keywords" content={props.keywords} /> : null}
		</Helmet>
	)
}

export default HelmetComponent;