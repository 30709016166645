/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../../redux/actions.js";
import Select from "react-select";
import Creatable from "react-select/creatable";
import dayjs from "dayjs";
import {
  constructFetchPortfolioReadingUrl,
  constructFetchPortfolioSocialClubUrl,
  constructFetchPortfolioInternshipUrl,
  constructFetchPortfolioExtProjectUrl,
  constructFetchPortfolioExtEventUrl,
  constructFetchPortfolioExtCoursesUrl,
  constructFetchPortfolioEducationUrl,
  constructAddPortfolioSubjectsUrl,
  constructEditPortfolioSemesterUrl,
  constructEditPortfolioEducationUrl,
  constructEditPortfolioExtProjectUrl,
  constructEditPortfolioInternshipUrl,
  constructEditPortfolioReadingUrl,
  constructEditPortfolioSocialClubUrl,
  constructEditPortfolioExtEventUrl,
  constructEditPortfolioExtCoursesUrl,
  constructFetchLifeSkillsRole,
} from "../../../../utils/PortfolioUtils.js";
import {
  constructSkillsUrl,
  constructInstituteUrl,
  getSpecializationsUrl,
  getDegreesUrl,
} from "../../../../utils/ProfileUtils.js";
import {
  social_clubs,
  projects,
  education,
  learning,
  internships,
  jobs,
  events,
  readings,
  educationSemAdd,
  certificate,
  tooltip,
} from "../../../../constants/PortfolioSchema";
import { _validateField } from "./PortfolioCardHelperFunction";
import {
  getDateDropdown,
  createMomentDate,
} from "../../../../utils/CommonUtils";
import { fetchData } from "../../../../actions/commonActions.js";
import ModalComponent from "../commonComponent/modalComponent.js";
import Tooltip from "react-tooltip-lite";

// Add forms and Edit forms. All fields are being mapped from PortfolioSchema file
class AddCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      selected: {
        status: null,
        type: null,
        format: null,
      },
      selectedJobRoles: [],
      FormValues: {},
      errorMessages: {},
      Datevalue: null,
      startDateState: null,
      formattedStartDate: null,
      finishDateState: null,
      formattedFinishDate: null,
      cardToRender: null,
      cards: null,
      data: null,
      cardName: null,
      disableScoringRadioBtn: false,
      tipOpen: this.props.tipOpen,
    };
  }

  // Here skills, specializations, institutes and degrees are fetched from the backend after elements are mounted into the DOM
  componentDidMount() {
    const {
      dispatch,
      specializations,
      degrees,
      skills,
      institutesList,
      lifeSkills,
      addModal,
    } = this.props;
    var fetchingData = false;
    if (!specializations) {
      dispatch(
        fetchData(
          getSpecializationsUrl(),
          "MERGE_IN_COMMON_REDUCER",
          false,
          "specializations"
        )
      );
      fetchingData = true;
    }
    if (!degrees) {
      dispatch(
        fetchData(getDegreesUrl(), "MERGE_IN_COMMON_REDUCER", false, "degrees")
      );
      fetchingData = true;
    }
    if (!skills) {
      dispatch(
        fetchData(
          constructSkillsUrl(),
          "MERGE_IN_COMMON_REDUCER",
          false,
          "skills"
        )
      );
      fetchingData = true;
    }
    if (!institutesList) {
      dispatch(
        fetchData(
          constructInstituteUrl(),
          "MERGE_IN_COMMON_REDUCER",
          false,
          "institutesList"
        )
      );
      fetchingData = true;
    }
    if (!lifeSkills) {
      dispatch(
        fetchData(
          constructFetchLifeSkillsRole(),
          "MERGE_IN_COMMON_REDUCER",
          false,
          "lifeSkills"
        )
      );
      fetchingData = true;
    }

    if (fetchingData) dispatch(Actions.snackBarOn("Loading..."));

    if (addModal) {
      dispatch({
        type: "UPDATE_SELECTED_ADD_CARD_STATUS",
        data: { status: true, cardName: addModal.selectedCard },
      });
    }

    var d = new Date();
    var year = d.getFullYear();
    var start = 1970,
      end = year + 15;
    var options = getDateDropdown(start, end, true);
    this.setState({
      dateOptions: options.dateOptions,
      monthsOptions: options.monthsOptions,
      yearOptions: options.yearOptions,
      Datevalue: "01",
      Monthvalue: "01",
      Yearvalue: year,
      startDateState: "01",
      startMonthState: "01",
      startYearState: year,
      finishDateState: "31",
      finishMonthState: "12",
      finishYearState: year,
    });
  }

  // Here we check whether we need to prepopulate the form values( Add/Edit ) and also which card to be opened
  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    let dataList = [
      "specializations",
      "degrees",
      "skills",
      "institutesList",
      "lifeSkills",
    ];
    var dataFetched = true;
    for (let key of dataList) {
      dataFetched = dataFetched && this.props[key];
    }
    if (dataFetched) {
      dispatch(Actions.snackBarOff());
    }

    var toRender = null,
      data = null,
      cards = null,
      addModal = this.props.addModal,
      card;
    // Check whether any form should be rendered
    for (var field in addModal) {
      if (addModal[field].status) {
        if (prevProps.addModal[field].status !== addModal[field].status) {
          toRender = field;
          data = addModal[field].data;
          card = addModal[field].cardName;
          this.setState({
            Datevalue: "01",
            Monthvalue: "01",
            Yearvalue: dayjs().format("YYYY"),
            startDateState: "01",
            startMonthState: "01",
            startYearState: dayjs().format("YYYY"),
            finishDateState: "",
            finishMonthState: "",
            finishYearState: "",
          });
        }
      }
    }
    if (toRender) {
      cards = this.findCard(toRender); // Find which form to be rendered
      var temp = this.state.FormValues;
      var errorM = this.state.errorMessages;
      let disableScoringRadioBtn = this.state.disableScoringRadioBtn;
      if (
        cards.key === "education" &&
        addModal.education.data !== null &&
        addModal.education.data.format !== null
      ) {
        disableScoringRadioBtn = true;
      }
      if (
        cards.key !== "education" ||
        cards.key !== "semester" ||
        cards.key !== "clubs"
      ) {
        temp["jobLife"] = "job_roles";
        temp["showJobRoles"] = "job_roles";
      }
      if (cards.key === "clubs" && data && data["type"] !== null) {
        if (data["type"] === "Educational") {
          temp["jobLife"] = "job_roles";
          cards["CardFieldNameAdd"][1]["fields"][2].mandatory = true;
          cards["CardFieldNameAdd"][1]["fields"][4].mandatory = false;
        } else {
          temp["jobLife"] = "life_skills";
          cards["CardFieldNameAdd"][1]["fields"][2].mandatory = false;
          cards["CardFieldNameAdd"][1]["fields"][4].mandatory = true;
        }
        temp["showJobRoles"] = "job_roles";
      }
      this.setState({
        FormValues: temp,
        selectedJobRoles: prevProps.portfolio.job_roles,
        disableScoringRadioBtn: disableScoringRadioBtn,
      });
      // Check whether its an Add/Edit form by seeing whether any data is coming or not
      if (data && card !== "semesterAdd") {
        // Prepopulate all the fields with respective incoming data
        cards.CardFieldNameAdd.forEach((field) => {
          field.fields.forEach((f) => {
            if (f.key === "date") {
              if (data["date"] !== null) {
                this.setState({
                  Datevalue: dayjs(data["date"]).format("DD"),
                  Monthvalue: dayjs(data["date"]).format("MM"),
                  Yearvalue: dayjs(data["date"]).format("YYYY"),
                });
                temp[f.key] = createMomentDate(
                  this.state.Datevalue,
                  this.state.Monthvalue,
                  this.state.Yearvalue
                );
              } else {
                this.setState({
                  Datevalue: dayjs().format("DD"),
                  Monthvalue: dayjs().format("MM"),
                  Yearvalue: dayjs().format("YYYY"),
                });
                temp[f.key] = dayjs().format("YYYY-MM-DD");
              }
              errorM[f.key] = "";
            } else if (f.key === "startdate" || f.key === "finishdate") {
              if (data["tenure"]) {
                this.setState({
                  startDateState: dayjs(data["tenure"][0]).format("DD"),
                  startMonthState: dayjs(data["tenure"][0]).format("MM"),
                  startYearState: dayjs(data["tenure"][0]).format("YYYY"),
                  finishDateState: dayjs(data["tenure"][1]).format("DD"),
                  finishMonthState: dayjs(data["tenure"][1]).format("MM"),
                  finishYearState: dayjs(data["tenure"][1]).format("YYYY"),
                });
                temp["startdate"] = createMomentDate(
                  this.state.startDateState,
                  this.state.startMonthState,
                  this.state.startYearState
                );
                temp["finishdate"] = createMomentDate(
                  this.state.finishDateState,
                  this.state.finishMonthState,
                  this.state.finishYearState
                );
                errorM["startdate"] = "";
                errorM["finishdate"] = "";
              } else if (data["startdate"] && data["finishdate"]) {
                this.setState({
                  startDateState: dayjs(data["startdate"]).format("DD"),
                  startMonthState: dayjs(data["startdate"]).format("MM"),
                  startYearState: dayjs(data["startdate"]).format("YYYY"),
                  finishDateState: dayjs(data["finishdate"]).format("DD"),
                  finishMonthState: dayjs(data["finishdate"]).format("MM"),
                  finishYearState: dayjs(data["finishdate"]).format("YYYY"),
                });
                temp["startdate"] = createMomentDate(
                  this.state.startDateState,
                  this.state.startMonthState,
                  this.state.startYearState
                );
                temp["finishdate"] = createMomentDate(
                  this.state.finishDateState,
                  this.state.finishMonthState,
                  this.state.finishYearState
                );
                errorM["startdate"] = "";
                errorM["finishdate"] = "";
              } else if (data["startdate"]) {
                this.setState({
                  startDateState: dayjs(data["startdate"]).format("DD"),
                  startMonthState: dayjs(data["startdate"]).format("MM"),
                  startYearState: dayjs(data["startdate"]).format("YYYY"),
                });
                temp["startdate"] = createMomentDate(
                  this.state.startDateState,
                  this.state.startMonthState,
                  this.state.startYearState
                );
                errorM["startdate"] = "";
                errorM["finishdate"] = "";
              } else {
                errorM["startdate"] = "This field cant be empty";
                errorM["finishdate"] = "This field cant be empty";
                this.setState({
                  startDateState: dayjs().format("DD"),
                  startMonthState: dayjs().format("MM"),
                  startYearState: dayjs().format("YYYY"),
                  finishDateState: dayjs().format("DD"),
                  finishMonthState: dayjs().format("MM"),
                  finishYearState: dayjs().format("YYYY"),
                  Datevalue: dayjs().format("DD"),
                  Monthvalue: dayjs().format("MM"),
                  Yearvalue: dayjs().format("YYYY"),
                });
              }
            } else if (f.key === "skills") {
              var skillsAdded = [];
              for (let i = 0; i < data.score_log.skills.length; i++) {
                var skillIndex = prevProps.skills
                  ? prevProps.skills.findIndex(
                      (item) => item.display_name === data.score_log.skills[i]
                    )
                  : -1;
                if (skillIndex >= 0)
                  skillsAdded.push(prevProps.skills[skillIndex]);
              }
              temp[f.key] = skillsAdded;
              if (data.score_log.skills.length < 1)
                errorM[f.key] = "This field cant be empty";
              else errorM[f.key] = "";
            } else if (f.key === "job_roles" || f.key === "life_skills") {
              if (f.key === "job_roles") {
                var jobRolesAdded = [];
                for (let i = 0; i < data.score_log.job_roles.length; i++) {
                  var jobRolesIndex = prevProps.lifeSkills
                    ? prevProps.lifeSkills.job_roles.findIndex(
                        (item) => item.name === data.score_log.job_roles[i]
                      )
                    : -1;
                  if (jobRolesIndex >= 0)
                    jobRolesAdded.push(
                      prevProps.lifeSkills.job_roles[jobRolesIndex]
                    );
                }
                temp[f.key] = jobRolesAdded;
                if (
                  data.score_log.job_roles.length < 1 &&
                  (data["type"] === "Educational" || data["type"] === null)
                )
                  errorM[f.key] = "Career Paths cant be empty";
                else errorM[f.key] = "";
              } else {
                var lifeSkillsAdded = [];
                for (let i = 0; i < data.score_log.life_skills.length; i++) {
                  var lifeSkillsIndex = prevProps.lifeSkills
                    ? prevProps.lifeSkills.life_skills.findIndex(
                        (item) => item.name === data.score_log.life_skills[i]
                      )
                    : -1;
                  if (lifeSkillsIndex >= 0)
                    lifeSkillsAdded.push(
                      prevProps.lifeSkills.life_skills[lifeSkillsIndex]
                    );
                }
                temp[f.key] = lifeSkillsAdded;
                if (
                  data.score_log.life_skills.length < 1 &&
                  (data["type"] === "Non Educational" || data["type"] === null)
                )
                  errorM[f.key] = "Life Skills cant be empty";
                else errorM[f.key] = "";
              }
            } else if (f.key === "backlogsStatus") {
              if (data["backlogs"] > 0) {
                temp["backlogsStatus"] = "yes";
              } else {
                temp["backlogsStatus"] = "no";
              }
              errorM["backlogs"] = "";
              errorM["backlogsStatus"] = "";
            } else if (f.key === "subjectType") {
              temp["subjectType"] = data["type"];
              errorM["subjectType"] = "";
            } else if (f.key === "score") {
              if (card === "education" || card === "semesterEdit") {
                if (data["format"] === "GPA") {
                  this.setState(
                    _validateField("cgpa", data["score"], this.state)
                  );
                } else {
                  this.setState(
                    _validateField("percentage", data["score"], this.state)
                  );
                }
              }
              temp[f.key] = data[f.key];
              errorM[f.key] = "";
            } else {
              if (f.key === "status" && data["status"] === "applied") {
                errorM["status"] = "This field cant be empty";
              } else {
                if (data[f.key] !== null) {
                  if (f.key === "duration") {
                    this.setState(
                      _validateField("duration", data[f.key], this.state)
                    );
                  } else {
                    errorM[f.key] = "";
                  }
                  temp[f.key] = data[f.key];
                } else if (data[f.key] === null) {
                  if (f.mandatory) errorM[f.key] = "This field cant be empty";
                  else errorM[f.key] = "";
                }
              }
            }
          });
        });
      } else if (data && card === "semesterAdd") {
        this.state.FormValues["format"] = data.format;
        this.state.FormValues["education_id"] = data.id;
        this.state.FormValues["institute"] = data.institute;
        this.state.FormValues["degree"] = data.degree;
        if (this.state.FormValues["format"] === null)
          errorM["format"] = "Please select one by editing degree";
        else errorM["format"] = "";
        errorM["institute"] = "";
        errorM["degree"] = "";
      }
      if (card === "semesterEdit") {
        this.state.FormValues["format"] = data.format;
        this.state.FormValues["education_id"] = data.education_id;
        this.state.FormValues["institute"] = data.institute;
        this.state.FormValues["degree"] = data.degree;
      }
      // Dynamically change texts based on which events button was clicked
      switch (card) {
        case "Deliver":
          cards.title = "Events Delivered";
          cards.AddModalText =
            "Add events you have delivered to your portfolio";
          temp["role"] = card;
          errorM["role"] = "";
          break;
        case "Organize":
          cards.title = "Events Organized";
          cards.AddModalText =
            "Add events you have organized to your portfolio";
          temp["role"] = card;
          errorM["role"] = "";
          break;
        case "Participate":
          cards.title = "Events Attended";
          cards.AddModalText =
            "Add events you have participated to your portfolio";
          temp["role"] = card;
          errorM["role"] = "";
          break;
      }
      this.setState({
        errorMessages: errorM,
        FormValues: temp,
        cardToRender: toRender,
        cards,
        data,
        cardName: card,
      });
    }
  }

  toggleJobRoles(field, option) {
    var temp = this.state.FormValues;
    if (field === "job_roles_option") {
      temp["jobLife"] = option;
    } else {
      if (option === "job_roles") {
        this.setState({
          selectedJobRoles: this.props.portfolio.job_roles,
        });
      } else {
        this.setState({
          selectedJobRoles: this.props.lifeSkills.job_roles,
        });
      }
      temp["showJobRoles"] = option;
    }
    this.setState({
      FormValues: temp,
    });
  }

  // This function finds which card fields to render
  findCard(card) {
    switch (card) {
      case "external_courses":
        return learning;
      case "external_projects":
        return projects;
      case "internships":
        return internships;
      case "jobs":
        return jobs;
      case "external_events":
        return events;
      case "readings":
        return readings;
      case "social_clubs":
        return social_clubs;
      case "education":
        return education;
      case "semester":
        return educationSemAdd;
      case "certificate":
        return certificate;
    }
  }

  // Func passed to selectTag to show the new user created skills
  newSkillsAdd(label) {
    return "Add " + label + "as a new skill";
  }

  selectTag(
    fieldName,
    fieldKey,
    valueHere,
    opt,
    handleChange,
    fargs,
    valueKey,
    labelKey,
    txtCreator,
    multi,
    creatable = false,
    col,
    placeholder = "",
    disable
  ) {
    if (
      disable &&
      (fieldKey === "institute" || fieldKey === "degree") &&
      valueHere
    ) {
      return (
        <div className={`form-group ${col}`} style={{ marginBottom: "20px" }}>
          <label
            style={{
              fontWeight: "400",
              color: "rgb(104, 104, 104)",
              cursor: "pointer",
            }}
          >
            {fieldName}
            <span className="asterisk">*</span>
          </label>
          <p className="AddCard-DisabledField">{valueHere}</p>
        </div>
      );
    } else {
      if (creatable) {
        return (
          <div className={`form-group ${col}`} style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "400",
                color: "rgb(104, 104, 104)",
                cursor: "pointer",
              }}
            >
              {fieldName}
              <span className="asterisk">*</span>
            </label>
            <Creatable
              className="float"
              name={fieldName}
              isMulti={multi}
              getOptionValue={(data) => data[valueKey]}
              getOptionLabel={(data) => data[labelKey]}
              isClearable={false}
              formatCreateLabel={(inputValue) => `Create New "${inputValue}"`}
              getNewOptionData={(inputValue, optionLabel) => ({
                [valueKey]: inputValue,
                [labelKey]: optionLabel,
                __isNew__: true,
              })}
              value={valueHere}
              menuShouldScrollIntoView={false}
              options={opt}
              onChange={handleChange.bind(...fargs)}
            />
            <span className="error-message">
              {this.state.errorMessages[fieldKey]}
            </span>
          </div>
        );
      } else {
        if (fieldKey === "specialization")
          valueHere = opt.find((item) => item.name === valueHere);
        return (
          <div className={`form-group ${col}`} style={{ marginBottom: "20px" }}>
            <label
              style={{
                fontWeight: "400",
                color: "rgb(104, 104, 104)",
                display:
                  fieldKey === "job_roles" || fieldKey === "life_skills"
                    ? "none"
                    : "",
                cursor: "pointer",
              }}
            >
              {fieldName}
              <span className="asterisk">*</span>
            </label>

            <Tooltip
              content={
                tooltip[this.state.cards.key] &&
                tooltip[this.state.cards.key][fieldKey] &&
                tooltip[this.state.cards.key][fieldKey].value
                  ? tooltip[this.state.cards.key][fieldKey].value
                  : fieldName
              }
              zIndex={999999}
              direction="up"
              className="custom-tool customTip"
              eventOff="onBlur"
              useHover={
                this.state.tipOpen &&
                tooltip[this.state.cards.key] &&
                tooltip[this.state.cards.key][fieldKey] &&
                tooltip[this.state.cards.key][fieldKey].value
                  ? true
                  : false
              }
              forceDirection
              tipContentHover
            >
              <Select
                className="float"
                name={fieldName}
                isMulti={multi}
                getOptionValue={(data) => data[valueKey]}
                getOptionLabel={(data) => data[labelKey]}
                isClearable={false}
                value={valueHere}
                placeholder={placeholder}
                menuShouldScrollIntoView={false}
                options={opt}
                isDisabled={disable}
                onChange={handleChange.bind(...fargs)}
              />
            </Tooltip>
            <span className="error-message">
              {fieldKey === "job_roles" || fieldKey === "life_skills"
                ? this.state.FormValues["type"] === "Non Educational"
                  ? this.state.errorMessages["life_skills"]
                  : this.state.errorMessages["job_roles"]
                : this.state.errorMessages[fieldKey]}
            </span>
          </div>
        );
      }
    }
  }

  inputTag(
    fieldName,
    fieldKey,
    type,
    value,
    handleChange,
    args,
    labelNone,
    col,
    disp = null
  ) {
    return (
      <div
        className={`form-group ${col}`}
        style={{ display: disp ? disp : "" }}
      >
        <label
          style={{
            fontWeight: "400",
            color: "rgb(104, 104, 104)",
            marginBottom: "0px",
            cursor: "pointer",
          }}
        >
          <Tooltip
            content={
              tooltip[this.state.cards.key] &&
              tooltip[this.state.cards.key][fieldKey] &&
              tooltip[this.state.cards.key][fieldKey].value
                ? tooltip[this.state.cards.key][fieldKey].value
                : fieldName
            }
            zIndex={999999}
            direction="up"
            className="custom-tool customTip "
            eventOff="onBlur"
            useHover={
              this.state.tipOpen &&
              tooltip[this.state.cards.key] &&
              tooltip[this.state.cards.key][fieldKey] &&
              tooltip[this.state.cards.key][fieldKey].value
                ? true
                : false
            }
            forceDirection
            tipContentHover
          >
            {fieldName}
          </Tooltip>
          <span className={`asterisk ${labelNone}`}>*</span>
        </label>
        <input
          type={type}
          value={value}
          onChange={handleChange.bind(...args)}
          onBlur={handleChange.bind(...args)}
          style={{ padding: "0px 2px", height: "24px" }}
          className="form-control float"
        />
        <span className="error-message">
          {this.state.errorMessages[fieldKey]}
        </span>
      </div>
    );
  }

  dateSelect(
    date,
    month,
    year,
    dateChange,
    monthChange,
    yearChange,
    fargs,
    fieldName,
    error,
    col
  ) {
    return (
      <div className={col} style={{ padding: "0px", paddingTop: "10px" }}>
        <Tooltip
          content={
            tooltip[this.state.cards.key] &&
            tooltip[this.state.cards.key][fargs[2]] &&
            tooltip[this.state.cards.key][fargs[2]].value
              ? tooltip[this.state.cards.key][fargs[2]].value
              : fieldName
          }
          zIndex={999999}
          direction="up"
          className="custom-tool customTip width"
          eventOff="onBlur"
          useHover={
            this.state.tipOpen &&
            tooltip[this.state.cards.key] &&
            tooltip[this.state.cards.key][fargs[2]] &&
            tooltip[this.state.cards.key][fargs[2]].value
              ? true
              : false
          }
          forceDirection
          tipContentHover
        >
          <p
            className={`datepick col-12`}
            style={{
              padding: 0,
              fontWeight: 400,
              color: "rgb(104, 104, 104)",
              display: fieldName === "" ? "none" : "",
            }}
          >
            {fieldName}

            <span className="asterisk">*</span>
          </p>
        </Tooltip>
        <div className={`form-group ${error} col-12`} style={{ padding: 0 }}>
          <div style={{ marginTop: "10px" }}>
            <select
              className="dateDropDownOptions"
              name="date"
              onChange={dateChange.bind(this)}
              value={parseInt(date)}
            >
              {this.state.dateOptions}
            </select>
            <select
              className="dateDropDownOptions"
              name="month"
              onChange={monthChange.bind(this)}
              value={parseInt(month)}
            >
              {this.state.monthsOptions}
            </select>
            <select
              className="dateDropDownOptions"
              name="year"
              onChange={yearChange.bind(this)}
              value={parseInt(year)}
            >
              {this.state.yearOptions}
            </select>
          </div>
          <span className="error-message col-12">{error}</span>
        </div>
      </div>
    );
  }

  // Close the respective form
  closeModal = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        FormValues: {},
        errorMessages: {},
        Datevalue: null,
        Monthvalue: null,
        Yearvalue: null,
        startDateState: null,
        startMonthState: null,
        startYearState: null,
        finishDateState: null,
        finishMonthState: null,
        finishYearState: null,
        disableScoringRadioBtn: false,
      },
      () => {
        dispatch({ type: "RESET_ADD_CARD_MODAL_DATA" });
      }
    );
  };

  // Func called to handle selectTag dropdown values select
  handleSelectChange(key, mandatory, value) {
    var details = this.state.FormValues;
    var error = this.state.errorMessages;
    // Used for select tag with multiselect option
    if (value instanceof Array && mandatory) {
      if (value.length > 0) {
        error[key] = "";
      } else {
        if (key === "job_roles" || key === "life_skills") {
          if (this.state.FormValues["type"] === "Educational") {
            error["job_roles"] = "Career Paths cant be empty";
            error["life_skills"] = "";
          } else if (this.state.FormValues["type"] === "Non Educational") {
            error["life_skills"] = "Life Skills cant be empty";
            error["job_roles"] = "";
          } else {
            error["job_roles"] = "Career Paths cant be empty";
          }
        } else error[key] = "This cant be empty";
      }
    }
    // Used for select tag without multiselect option
    else {
      error[key] = "";
    }
    details[key] = value;
    this.setState({
      errorMessages: error,
      FormValues: details,
    });
  }

  // Function to check whether selected date is valid
  checkDate(date) {
    var error = this.state.errorMessages;
    var today = dayjs().format("YYYY-MM-DD");
    if (date !== undefined) {
      if (date === "Invalid date") {
        error["date"] = "Please enter a valid date";
      } else if (this.state.FormValues["status"] === "complete") {
        if (date > today) {
          error["date"] = "Cannot select a future date";
        } else {
          error["date"] = "";
        }
      } else if (
        this.state.FormValues["status"] === "ongoing" ||
        this.state.FormValues["status"] === "Enrolled"
      ) {
        if (date < today) {
          error["date"] = "Please select a future date";
        } else {
          error["date"] = "";
        }
      } else {
        if (date > today) {
          error["date"] = "Cannot select a future date";
        } else {
          error["date"] = "";
        }
      }
    } else {
      error["date"] = "This field cant be empty";
    }
    this.setState({
      errorMessages: error,
    });
  }

  checkStartDate(date) {
    var error = this.state.errorMessages;
    if (date === undefined) {
      error["startdate"] = "This field cant be empty";
    } else if (date === "Invalid date") {
      error["startdate"] = "Please enter a valid date";
    } else {
      var today = dayjs().format("YYYY-MM-DD");
      if (date > today) {
        error["startdate"] = "Cannot select a future date";
      } else {
        error["startdate"] = "";
      }
    }
    this.setState({
      errorMessages: error,
    });
  }

  checkFinishDate(date) {
    var error = this.state.errorMessages;
    if (date === undefined) {
      error["finishdate"] = "This field cant be empty";
    } else if (date === "Invalid date") {
      error["finishdate"] = "Please enter a valid date";
    } else {
      var today = dayjs().format("YYYY-MM-DD");
      if (this.state.FormValues["status"] === "complete") {
        if (date > today) {
          error["finishdate"] = "Cannot select a future date";
        } else {
          error["finishdate"] = "";
        }
      } else {
        if (date < today) {
          error["finishdate"] = "Please select a future date";
        } else {
          error["finishdate"] = "";
        }
      }
      if (date < this.state.FormValues["startdate"]) {
        error["finishdate"] = "End date should be greater than start date";
      }
    }
    this.setState({
      errorMessages: error,
    });
  }

  //Func called when date is selected
  handleDateChange(event) {
    this.setState({
      Datevalue: event.target.value,
    });
  }
  handleMonthChange(event) {
    this.setState({
      Monthvalue: event.target.value,
    });
  }
  handleYearChange(event) {
    this.setState({
      Yearvalue: event.target.value,
    });
  }
  handleValidateDate(field) {
    var DateVal = createMomentDate(
      this.state.Datevalue,
      this.state.Monthvalue,
      this.state.Yearvalue
    );
    this.checkDate(DateVal);

    var val = this.state.FormValues;
    val[field] = DateVal;
    this.setState({
      FormValues: val,
    });
  }
  handleStartDateChange(event) {
    this.setState({
      startDateState: event.target.value,
    });
  }
  handleStartMonthChange(event) {
    this.setState({
      startMonthState: event.target.value,
    });
  }
  handleStartYearChange(event) {
    this.setState({
      startYearState: event.target.value,
    });
  }
  handleFinishDateChange(event) {
    this.setState({
      finishDateState: event.target.value,
    });
  }
  handleFinishMonthChange(event) {
    this.setState({
      finishMonthState: event.target.value,
    });
  }
  handleFinishYearChange(event) {
    this.setState({
      finishYearState: event.target.value,
    });
  }
  //Func called to handle start date selected
  handleValidateStartDate(cards, field) {
    var DateVal = createMomentDate(
      this.state.startDateState,
      this.state.startMonthState,
      this.state.startYearState
    );

    if (cards === "jobs" || cards === "internships") {
      this.checkStartDate(DateVal);
    } else {
      if (DateVal === undefined) {
        this.state.errorMessages[field] = "Date cannot be empty";
      } else {
        var todayDate = dayjs().format("YYYY-MM-DD");
        if (DateVal > todayDate) {
          this.state.errorMessages[field] = "Cannot select a future date";
        } else {
          this.state.errorMessages[field] = "";
        }
      }
    }
    this.setState({ errorMessages: this.state.errorMessages });
    this.state.FormValues[field] = DateVal;
  }
  handleValidateFinishDate(cards, field) {
    var DateVal = createMomentDate(
      this.state.finishDateState,
      this.state.finishMonthState,
      this.state.finishYearState
    );
    if (cards === "jobs" || cards === "internships") {
      this.checkFinishDate(DateVal);
    } else {
      if (DateVal === undefined || DateVal === "Invalid Date") {
        this.state.errorMessages[field] = "Date cannot be empty";
      } else {
        var todayDate = dayjs().format("YYYY-MM-DD");
        if (DateVal > todayDate) {
          if (
            cards === "education" &&
            this.props.addModal.education.data &&
            this.props.addModal.education.data.latest_edu
          )
            this.state.errorMessages[field] = "";
          else this.state.errorMessages[field] = "Cannot select a future date";
        } else if (DateVal < this.state.FormValues["startdate"]) {
          this.state.errorMessages["finishdate"] =
            "End date should be greater than start date";
        } else {
          this.state.errorMessages[field] = "";
        }
      }
    }
    this.setState({ errorMessages: this.state.errorMessages });
    this.state.FormValues[field] = DateVal;
  }

  // Func called to cardshandle input in inputTag
  handleAddChange(fieldKey, fieldType, fieldMandatory, event) {
    // All input field values except score and isbn values should not accept decimal
    if (
      fieldKey !== "score" &&
      fieldKey !== "isbn" &&
      fieldType === "number" &&
      event.target.value !== ""
    )
      event.target.value = Math.floor(event.target.value);
    if (fieldMandatory && fieldKey !== "score") {
      this.setState(_validateField(fieldKey, event.target.value, this.state));
    } else if (fieldMandatory && fieldKey === "score") {
      if (this.state.FormValues["format"] === "GPA") {
        this.setState(_validateField("cgpa", event.target.value, this.state));
      } else {
        this.setState(
          _validateField("percentage", event.target.value, this.state)
        );
      }
    }
    var temp = this.state.FormValues;
    if (
      fieldType === "number" &&
      fieldKey !== "isbn" &&
      event.target.value !== ""
    ) {
      temp[fieldKey] = Number(event.target.value);
    } else {
      temp[fieldKey] = event.target.value;
    }
    this.setState({
      FormValues: temp,
    });
  }

  //Func called to handle radio button click
  handleOptionChange(fieldKey, event) {
    let inter;
    inter = this.state.selected;
    inter[fieldKey] = event.target.value;
    this.setState({
      selected: inter,
    });
    inter = this.state.errorMessages;
    if (!this.state.selected) {
      inter[fieldKey] = "This cant be empty";
      this.setState({
        errorMessages: inter,
      });
    } else {
      inter[fieldKey] = "";
      this.setState({
        errorMessages: inter,
      });
      inter = this.state.FormValues;
      inter[fieldKey] = event.target.value;
      this.setState({
        FormValues: inter,
      });
    }
    var temp = this.state.cards;
    if (fieldKey === "type" && event.target.value === "Educational") {
      temp["CardFieldNameAdd"][1]["fields"][2].mandatory = true;
      temp["CardFieldNameAdd"][1]["fields"][4].mandatory = false;
    } else if (
      fieldKey === "type" &&
      event.target.value === "Non Educational"
    ) {
      temp["CardFieldNameAdd"][1]["fields"][2].mandatory = false;
      temp["CardFieldNameAdd"][1]["fields"][4].mandatory = true;
    }
    this.setState({
      cards: temp,
    });
  }

  // Fields are fetched using this func
  fetchFields(cards) {
    var fields = cards.CardFieldNameAdd;
    var basicFields = [];
    // JSON storing all the dropdown options fetched from the backend in the componentDidMount method
    var options = {
      job_roles: {
        value: this.state.selectedJobRoles,
        id: "id",
        name: "name",
        multi: true,
      },
      life_skills: {
        value: this.props.lifeSkills.life_skills,
        id: "id",
        name: "name",
        multi: true,
      },
      skills: {
        value: this.props.skills,
        id: "id",
        name: "display_name",
        multi: true,
      },
      specialization: {
        value: this.props.specializations,
        id: "id",
        name: "name",
        multi: false,
      },
      institute: {
        value: this.props.institutesList,
        id: "full_name",
        name: "full_name",
        multi: false,
      },
      degree: {
        value: this.props.degrees,
        id: "name",
        name: "name",
        multi: false,
      },
    };

    fields.map((f, index) => {
      basicFields.push(
        <div style={{ marginBottom: "20px" }} className="col-12" key={index}>
          <h4 className="font18">{f.title}</h4>
        </div>
      );
      basicFields.push(
        f.fields.map((field) => {
          switch (field.key) {
            case "desc":
              return (
                <div
                  className={`form-group ${field.col}`}
                  style={{ marginBottom: "20px" }}
                >
                  <label
                    style={{ fontWeight: "400", color: "rgb(104, 104, 104)" }}
                  >
                    {" "}
                    {field.name}
                    <span className={`asterisk`}>*</span>
                  </label>

                  <textarea
                    value={this.state.FormValues[field.key]}
                    rows="4"
                    onChange={this.handleAddChange.bind(
                      this,
                      field.key,
                      field.type,
                      field.mandatory
                    )}
                    onBlur={this.handleAddChange.bind(
                      this,
                      field.key,
                      field.type,
                      field.mandatory
                    )}
                    style={{ padding: "0px 2px" }}
                    className="form-control float"
                  />
                  <span className="error-message">
                    {this.state.errorMessages[field.key]}
                  </span>
                </div>
              );
            case "job_roles":
            case "skills":
            case "specialization":
            case "institute":
            case "degree":
            case "life_skills":
              var creatable = false,
                txtCreator = "",
                placeholder,
                disable = false;
              if (field.key === "skills") {
                creatable = true;
                txtCreator = this.newSkillsAdd;
              }
              if (field.key === "job_roles")
                placeholder = "Select Career Paths";
              else if (field.key === "life_skills")
                placeholder = "Select Life Skills";
              else placeholder = "Select ...";
              if (
                (cards.key === "education" || cards.key === "semester") &&
                (field.key === "degree" || field.key === "institute") &&
                this.state.data
              )
                disable = true;
              if (field.key === "job_roles" && cards.key !== "semester") {
                if (this.state.FormValues["jobLife"] === "job_roles") {
                  return this.selectTag(
                    field.name,
                    field.key,
                    this.state.FormValues[field.key],
                    options[field.key].value,
                    this.handleSelectChange,
                    [this, field.key, field.mandatory],
                    options[field.key].id,
                    options[field.key].name,
                    txtCreator,
                    options[field.key].multi,
                    creatable,
                    field.col,
                    placeholder,
                    disable,
                    field
                  );
                }
              } else if (field.key === "life_skills") {
                if (this.state.FormValues["jobLife"] === "life_skills") {
                  return this.selectTag(
                    field.name,
                    field.key,
                    this.state.FormValues[field.key],
                    options[field.key].value,
                    this.handleSelectChange,
                    [this, field.key, field.mandatory],
                    options[field.key].id,
                    options[field.key].name,
                    txtCreator,
                    options[field.key].multi,
                    creatable,
                    field.col,
                    placeholder,
                    disable
                  );
                }
              } else {
                return this.selectTag(
                  field.name,
                  field.key,
                  this.state.FormValues[field.key],
                  options[field.key].value,
                  this.handleSelectChange,
                  [this, field.key, field.mandatory],
                  options[field.key].id,
                  options[field.key].name,
                  txtCreator,
                  options[field.key].multi,
                  creatable,
                  field.col,
                  placeholder,
                  disable
                );
              }
              break;
            case "job_roles_option":
              return (
                <div
                  className={`form-group ${field.col} row margin0`}
                  style={{ marginBottom: "20px", fontSize: "14px", padding: 0 }}
                >
                  <label
                    className="col-12"
                    style={{
                      fontWeight: "400",
                      color: "rgba(104, 104, 104)",
                      padding: 0,
                    }}
                  >
                    {" "}
                    {field.name}
                    <span className={`asterisk`}>*</span>
                  </label>
                  <div
                    className="col-6"
                    style={{
                      textAlign: "center",
                      color:
                        this.state.FormValues["jobLife"] ===
                        field.options[0].value
                          ? "var(--clr-black)"
                          : "rgba(104, 104, 104, 0.3)",
                    }}
                    onClick={this.toggleJobRoles.bind(
                      this,
                      field.key,
                      field.options[0].value
                    )}
                  >
                    {field.options[0].label}
                  </div>
                  <div
                    className="col-6"
                    style={{
                      textAlign: "center",
                      color:
                        this.state.FormValues["jobLife"] ===
                        field.options[1].value
                          ? "var(--clr-black)"
                          : "rgba(104, 104, 104, 0.3)",
                    }}
                    onClick={this.toggleJobRoles.bind(
                      this,
                      field.key,
                      field.options[1].value
                    )}
                  >
                    <Tooltip
                      content={"Click to update your softskills"}
                      zIndex={999999}
                      direction="up"
                      className="custom-tool customTip width"
                      eventOff="onBlur"
                      useHover={this.state.tipOpen ? true : false}
                      forceDirection
                      tipContentHover
                    >
                      {field.options[1].label}
                    </Tooltip>
                  </div>
                  <span className="col-12 error-message">
                    {this.state.errorMessages[field.key]}
                  </span>
                </div>
              );
            case "job_roles_toggle":
              if (this.state.FormValues["jobLife"] === "job_roles") {
                return (
                  <div
                    className={`form-group ${field.col} row margin0`}
                    style={{
                      marginBottom: "20px",
                      fontSize: "12px",
                      lineHeight: "28px",
                      marginTop: "7px",
                    }}
                  >
                    <div
                      className="col-6"
                      style={{
                        backgroundColor:
                          this.state.FormValues["showJobRoles"] === "job_roles"
                            ? "var(--clr-button-orange)"
                            : "var(--clr-white)",
                        color:
                          this.state.FormValues["showJobRoles"] === "job_roles"
                            ? "var(--clr-white)"
                            : "var(--clr-black)",
                        textAlign: "center",
                        height: "fit-content",
                      }}
                      onClick={this.toggleJobRoles.bind(
                        this,
                        field.key,
                        "job_roles"
                      )}
                    >
                      <Tooltip
                        content={
                          "Click on 'Selected' to choose from the career paths you have already selected under Career Tracker"
                        }
                        zIndex={999999}
                        direction="up"
                        className="custom-tool customTip"
                        eventOff="onBlur"
                        useHover={this.state.tipOpen ? true : false}
                        forceDirection
                        tipContentHover
                      >
                        Selected
                      </Tooltip>
                    </div>
                    <div
                      className="col-6"
                      style={{
                        backgroundColor:
                          this.state.FormValues["showJobRoles"] === "all_roles"
                            ? "var(--clr-button-orange)"
                            : "var(--clr-white)",
                        color:
                          this.state.FormValues["showJobRoles"] === "all_roles"
                            ? "var(--clr-white)"
                            : "var(--clr-black)",
                        textAlign: "center",
                        height: "fit-content",
                      }}
                      onClick={this.toggleJobRoles.bind(
                        this,
                        field.key,
                        "all_roles"
                      )}
                    >
                      <Tooltip
                        content={
                          "Click on 'All Roles' to choose from all career paths"
                        }
                        zIndex={999999}
                        direction="up"
                        className="custom-tool customTip width"
                        eventOff="onBlur"
                        useHover={this.state.tipOpen ? true : false}
                        forceDirection
                        tipContentHover
                      >
                        All Roles
                      </Tooltip>
                    </div>
                  </div>
                );
              }
              break;
            case "status":
            case "type":
            case "role":
            case "format":
            case "backlogsStatus":
            case "placed":
            case "subjectType":
              var display =
                (field.key === "format" ||
                  field.key === "backlogsStatus" ||
                  field.key === "placed" ||
                  field.key === "subjectType") &&
                "inline";
              var margin =
                (field.key === "format" ||
                  field.key === "backlogsStatus" ||
                  field.key === "placed" ||
                  field.key === "subjectType") &&
                "60px";
              var disabled = false;
              if (
                cards.key === "education" &&
                field.key === "format" &&
                this.state.disableScoringRadioBtn
              ) {
                disabled = true;
              } else {
                disabled = false;
              }
              if (cards.key === "semester" && field.key === "format") {
                disabled = true;
              }

              return (
                <div
                className={`form-group ${field.key === "format" ? "col-12 col-md-10 col-lg-6" : field.col}`}
                  style={{ fontSize: "13px", marginBottom: "4px" }}
                >
                  <label style={{ color: "#686868", fontWeight: 400 }}>
                    {" "}
                    <Tooltip
                      content={
                        tooltip[this.state.cards.key] &&
                        tooltip[this.state.cards.key][field.key] &&
                        tooltip[this.state.cards.key][field.key].value
                          ? tooltip[this.state.cards.key][field.key].value
                          : null
                      }
                      zIndex={999999}
                      direction="up"
                      className="custom-tool customTip width"
                      eventOff="onBlur"
                      useHover={
                        this.state.tipOpen &&
                        tooltip[this.state.cards.key] &&
                        tooltip[this.state.cards.key][field.key] &&
                        tooltip[this.state.cards.key][field.key].value
                          ? true
                          : false
                      }
                      forceDirection
                      tipContentHover
                    >
                      {field.name}
                    </Tooltip>
                    <span className="asterisk">*</span>
                  </label>

                  <div>
                    {field.options.map((option, index) => {
                      return (
                        <p
                          key={index}
                          style={{
                            display: display,
                            marginLeft: index > 0 && margin,
                            marginBottom: "4px",
                          }}
                        >
                          <input
                            type={field.type}
                            name={field.name}
                            value={option.value}
                            onChange={this.handleOptionChange.bind(
                              this,
                              field.key
                            )}
                            checked={
                              this.state.FormValues[field.key] === option.value
                            }
                            disabled={disabled}
                          />{" "}
                          {option.label}
                        </p>
                      );
                    })}
                  </div>
                  <span className="error-message">
                    {this.state.errorMessages[field.key]}
                  </span>
                </div>
              );
            case "date":
            case "startdate":
            case "finishdate":
              var placeholder = null,
                label;
              if (cards.key === "jobs" || cards.key === "internships") {
                if (field.key === "startdate") placeholder = "From";
                else placeholder = "To";
              }
              if (field.key === "startdate") {
                if (
                  cards.key === "jobs" &&
                  this.state.FormValues["status"] === "ongoing"
                )
                  label = "When did you start?";
                else label = field.name;

                return this.dateSelect(
                  this.state.startDateState,
                  this.state.startMonthState,
                  this.state.startYearState,
                  this.handleStartDateChange,
                  this.handleStartMonthChange,
                  this.handleStartYearChange,
                  [this, cards.key, field.key],
                  label,
                  this.state.errorMessages[field.key],
                  field.col
                );
              } else if (field.key === "finishdate") {
                if (cards.key === "internships" || cards.key === "jobs") {
                  if (cards.key === "internships") {
                    return [
                      <div
                        className="col-1"
                        style={{
                          paddingTop: "5px",
                          textAlign: "center",
                          fontSize: "30px",
                          color: "rgb(104, 104, 104)",
                        }}
                      >
                        -
                      </div>,
                      this.dateSelect(
                        this.state.finishDateState,
                        this.state.finishMonthState,
                        this.state.finishYearState,
                        this.handleFinishDateChange,
                        this.handleFinishMonthChange,
                        this.handleFinishYearChange,
                        [this, cards.key, field.key],
                        field.name,
                        this.state.errorMessages[field.key],
                        field.col
                      ),
                    ];
                  } else {
                    if (this.state.FormValues["status"] !== "ongoing") {
                      return [
                        <div
                          className="col-1"
                          style={{
                            paddingTop: "5px",
                            textAlign: "center",
                            fontSize: "30px",
                            color: "rgb(104, 104, 104)",
                          }}
                        >
                          -
                        </div>,
                        this.dateSelect(
                          this.state.finishDateState,
                          this.state.finishMonthState,
                          this.state.finishYearState,
                          this.handleFinishDateChange,
                          this.handleFinishMonthChange,
                          this.handleFinishYearChange,
                          [this, cards.key, field.key],
                          field.name,
                          this.state.errorMessages[field.key],
                          field.col
                        ),
                      ];
                    } else return;
                  }
                } else {
                  return this.dateSelect(
                    this.state.finishDateState,
                    this.state.finishMonthState,
                    this.state.finishYearState,
                    this.handleFinishDateChange,
                    this.handleFinishMonthChange,
                    this.handleFinishYearChange,
                    [this, cards.key, field.key],
                    field.name,
                    this.state.errorMessages[field.key],
                    field.col
                  );
                }
              }
              var label = field.name;
              if (
                this.state.FormValues["status"] === "ongoing" ||
                this.state.FormValues["status"] === "Enrolled"
              ) {
                label = "Expected Date of completion";
              }
              return this.dateSelect(
                this.state.Datevalue,
                this.state.Monthvalue,
                this.state.Yearvalue,
                this.handleDateChange,
                this.handleMonthChange,
                this.handleYearChange,
                [this, field.key],
                label,
                this.state.errorMessages[field.key],
                field.col
              );
            default:
              var labelNone = !field.mandatory ? "labelNone" : "";
              if (
                cards.key === "courses" &&
                (field.key === "source" || field.key === "certificate")
              ) {
                var display =
                  this.state.FormValues["type"] === "Online course"
                    ? "block"
                    : "none";
                return this.inputTag(
                  field.name,
                  field.key,
                  field.type,
                  this.state.FormValues[field.key],
                  this.handleAddChange,
                  [this, field.key, field.type, field.mandatory],
                  labelNone,
                  field.col,
                  display
                );
              } else if (
                (cards.key === "education" || cards.key === "semester") &&
                (field.key === "backlogs" || field.key === "score")
              ) {
                if (field.key === "backlogs") {
                  var display =
                    this.state.FormValues["backlogsStatus"] === "yes"
                      ? "block"
                      : "none";
                  return this.inputTag(
                    field.name,
                    field.key,
                    field.type,
                    this.state.FormValues[field.key],
                    this.handleAddChange,
                    [this, field.key, field.type, field.mandatory],
                    labelNone,
                    field.col,
                    display
                  );
                } else {
                  var label;
                  if (this.state.FormValues["format"] === "Numeric") {
                    if (this.state.cards.key === "semester") label = "Marks";
                    else label = "Percentage";
                  } else if (this.state.FormValues["format"] === "GPA") {
                    if (this.state.cards.key === "semester")
                      label = "Grade Point";
                    else label = "CGPA";
                  } else {
                    if (this.state.cards.key === "semester")
                      label = "Grade Point/Marks";
                    else label = "CGPA/Percentage";
                  }
                  return this.inputTag(
                    label,
                    field.key,
                    field.type,
                    this.state.FormValues[field.key],
                    this.handleAddChange,
                    [this, field.key, field.type, field.mandatory],
                    labelNone,
                    field.col,
                    display
                  );
                }
              } else {
                return this.inputTag(
                  field.name,
                  field.key,
                  field.type,
                  this.state.FormValues[field.key],
                  this.handleAddChange,
                  [this, field.key, field.type, field.mandatory],
                  labelNone,
                  field.col
                );
              }
          }
        })
      );
    });
    return basicFields;
  }

  // Func called to handle submit buttin click
  handleSubmit(cards, e) {
    const { dispatch, portfolio } = this.props;
    e.preventDefault();
    var isValid = true,
      isFieldValid = true,
      error;
    cards.CardFieldNameAdd.map((field) => {
      field.fields.map((f) => {
        error = this.state.errorMessages;
        if (f.mandatory) {
          if (
            this.state.FormValues[f.key] === undefined ||
            this.state.FormValues[f.key] === null ||
            this.state.errorMessages[f.key] !== "" ||
            this.state.FormValues[f.key].length < 1
          ) {
            if (cards.key === "courses" && f.key === "source") {
              if (this.state.FormValues["type"] === "Online course") {
                isFieldValid = false;
                if (
                  this.state.FormValues[f.key] === undefined ||
                  this.state.FormValues[f.key] === null
                )
                  error[f.key] = "This field cant be empty";
              } else {
                isFieldValid = true;
              }
              isValid = isValid && isFieldValid;
            } else if (cards.key === "education" && f.key === "backlogs") {
              if (this.state.FormValues["backlogsStatus"] === "yes") {
                isFieldValid = false;
                if (
                  this.state.FormValues[f.key] === undefined ||
                  this.state.FormValues[f.key] === null
                )
                  error[f.key] = "This field cant be empty";
              } else {
                isFieldValid = true;
              }
            } else if (
              (cards.key === "education" || cards.key === "semester") &&
              f.key === "score"
            ) {
              if (this.state.FormValues["format"] === "GPA") {
                this.setState(
                  _validateField(
                    "cgpa",
                    this.state.FormValues["score"],
                    this.state
                  )
                );
              } else {
                this.setState(
                  _validateField(
                    "percentage",
                    this.state.FormValues["score"],
                    this.state
                  )
                );
              }
              if (this.state.errorMessages["score"] !== "") {
                isFieldValid = false;
              } else isFieldValid = true;
              isValid = isValid && isFieldValid;
            } else if (f.key === "startdate" || f.key === "finishdate") {
              if (f.key === "startdate") {
                this.handleValidateStartDate(cards.key, f.key);
                if (this.state.errorMessages["startdate"] !== "")
                  isFieldValid = false;
              } else {
                if (cards.key !== "education") {
                  if (cards.key !== "jobs") {
                    this.handleValidateFinishDate(cards.key, f.key);
                  } else {
                    if (this.state.FormValues["status"] === "complete")
                      this.handleValidateFinishDate(cards.key, f.key);
                    else {
                      this.state.errorMessages["finishdate"] = "";
                      delete this.state.FormValues["finishdate"];
                    }
                  }
                } else {
                  this.handleValidateFinishDate(cards.key, f.key);
                  if (this.state.FormValues["finishdate"] === undefined) {
                    var message = this.state.errorMessages;
                    message["finishdate"] = "This field cant be empty";
                    this.setState({
                      errorMessages: message,
                    });
                    isFieldValid = false;
                  }
                  isValid = isValid && isFieldValid;
                }
                if (this.state.errorMessages["finishdate"] !== "")
                  isFieldValid = false;
              }
              isValid = isValid && isFieldValid;
            } else if (f.key === "date") {
              this.handleValidateDate(f.key);
              if (this.state.errorMessages["date"] !== "") {
                isFieldValid = false;
                isValid = isValid && isFieldValid;
              }
            } else if (f.key === "job_roles" || f.key === "life_skills") {
              isFieldValid = false;
              if (
                this.state.FormValues[f.key] === undefined &&
                cards.key !== "clubs"
              )
                error[f.key] = "Career Paths cant be empty";
              else {
                if (this.state.FormValues["type"] === "Educational") {
                  error["job_roles"] = "Career Paths cant be empty";
                  error["life_skills"] = "";
                } else if (
                  this.state.FormValues["type"] === "Non Educational"
                ) {
                  error["life_skills"] = "Life Skills cant be empty";
                  error["job_roles"] = "";
                } else {
                  error["job_roles"] = "Career Paths cant be empty";
                  error["life_skills"] = "";
                }
              }
              this.setState({
                errorMessages: error,
              });
              isValid = isValid && isFieldValid;
            } else {
              isFieldValid = false;
              if (this.state.FormValues[f.key] === undefined)
                error[f.key] = "This field cant be empty";
            }
            this.setState({
              errorMessages: error,
            });
            isValid = isValid && isFieldValid;
          } else {
            if (f.key === "date") {
              this.handleValidateDate(f.key);
              if (this.state.errorMessages["date"] !== "") {
                isFieldValid = false;
                isValid = isValid && isFieldValid;
              }
            } else if (f.key === "startdate" || f.key === "finishdate") {
              if (f.key === "startdate") {
                this.handleValidateStartDate(cards.key, f.key);
                if (this.state.errorMessages["startdate"] !== "")
                  isFieldValid = false;
              } else {
                if (
                  cards.key === "education" ||
                  cards.key === "jobs" ||
                  cards.key === "internships"
                ) {
                  this.handleValidateFinishDate(cards.key, f.key);
                } else {
                  this.state.FormValues[f.key] = dayjs().format("YYYY-MM-DD");
                }
                if (this.state.errorMessages["finishdate"] !== "")
                  isFieldValid = false;
              }
              isValid = isValid && isFieldValid;
            } else if (
              (cards.key === "education" || cards.key === "semester") &&
              f.key === "score"
            ) {
              if (this.state.FormValues["format"] === "GPA") {
                this.setState(
                  _validateField(
                    "cgpa",
                    this.state.FormValues["score"],
                    this.state
                  )
                );
              } else {
                this.setState(
                  _validateField(
                    "percentage",
                    this.state.FormValues["score"],
                    this.state
                  )
                );
              }
              if (this.state.errorMessages["score"] !== "") {
                isFieldValid = false;
              } else isFieldValid = true;
              isValid = isValid && isFieldValid;
            }
          }
        }
      });
    });
    if (!isValid) return;
    var skillsAdded = [],
      jobRolesAdded = [],
      lifeSkillsAdded = [];
    if (this.state.FormValues["skills"] !== undefined)
      for (let i = 0; i < this.state.FormValues["skills"].length; i++) {
        skillsAdded.push(this.state.FormValues["skills"][i].display_name);
      }

    if (this.state.FormValues["job_roles"] !== undefined)
      for (let i = 0; i < this.state.FormValues["job_roles"].length; i++) {
        jobRolesAdded.push(this.state.FormValues["job_roles"][i].name);
      }
    if (this.state.FormValues["life_skills"] !== undefined)
      for (let i = 0; i < this.state.FormValues["life_skills"].length; i++) {
        lifeSkillsAdded.push(this.state.FormValues["life_skills"][i].name);
      }
    this.state.FormValues["skills"] = skillsAdded;
    this.state.FormValues["job_roles"] = jobRolesAdded;
    this.state.FormValues["life_skills"] = lifeSkillsAdded;

    var url,
      task,
      toaster,
      snackBarMsg = "";
    if (this.state.data) {
      if (cards.key === "semester") {
        if (this.state.cardName === "semesterAdd") task = "POST";
        else task = "PUT";
      } else task = "PUT";
    } else task = "POST";
    if (this.state.FormValues["showJobRoles"])
      delete this.state.FormValues["showJobRoles"];
    if (this.state.FormValues["jobLife"])
      delete this.state.FormValues["jobLife"];
    switch (cards) {
      case learning:
        if (task === "PUT") {
          url = constructEditPortfolioExtCoursesUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Course Details";
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
          this.state.FormValues["external_id"] = this.state.data.external_id;
        } else {
          url = constructFetchPortfolioExtCoursesUrl();
          toaster = {
            toastType: "add",
            toastMsg: learning.AddText,
            toastHeading: learning.AddTextBold,
          };
          snackBarMsg = "Adding Course Details";
        }
        var dataObj = { external_course: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case readings:
        if (task === "PUT") {
          url = constructEditPortfolioReadingUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Book Details";
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
        } else {
          url = constructFetchPortfolioReadingUrl();
          toaster = {
            toastType: "add",
            toastMsg: readings.AddText,
            toastHeading: readings.AddTextBold,
          };
          snackBarMsg = "Adding Book Details";
        }
        var dataObj = { reading: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case social_clubs:
        if (task === "PUT") {
          url = constructEditPortfolioSocialClubUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
          snackBarMsg = "Updating Club Details";
        } else {
          url = constructFetchPortfolioSocialClubUrl();
          toaster = {
            toastType: "add",
            toastMsg: social_clubs.AddText,
            toastHeading: social_clubs.AddTextBold,
          };
          snackBarMsg = "Adding Club Details";
        }
        this.state.FormValues["status"] = "complete";
        var dataObj = { social_club: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case projects:
        if (task === "PUT") {
          url = constructEditPortfolioExtProjectUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
          toaster = "SHOW_TOASTER_EDIT";
        } else {
          url = constructFetchPortfolioExtProjectUrl();
          toaster = {
            toastType: "add",
            toastMsg: projects.AddText,
            toastHeading: projects.AddTextBold,
          };
          snackBarMsg = "Adding Project Details";
        }
        delete this.state.FormValues["job_roles_option"];
        delete this.state.FormValues["job_roles_toggle"];
        this.state.FormValues["duration"] = Number(
          this.state.FormValues["duration"]
        );
        var dataObj = { external_project: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case education:
        if (task === "PUT") {
          url = constructEditPortfolioEducationUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Degree Details";
          this.state.FormValues["my_org_id"] = this.state.data.my_org_id;
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
        } else {
          url = constructFetchPortfolioEducationUrl();
          toaster = {
            toastType: "add",
            toastMsg: education.AddText,
            toastHeading: education.AddTextBold,
          };
          snackBarMsg = "Adding Degree Details";
        }
        var tenureDate = [];
        tenureDate.push(this.state.FormValues["startdate"]);
        tenureDate.push(this.state.FormValues["finishdate"]);
        this.state.FormValues["tenure"] = tenureDate;
        if (
          this.state.FormValues["backlogs"] === undefined ||
          this.state.FormValues["backlogs"] === null
        )
          this.state.FormValues["backlogs"] = 0;
        if (this.state.FormValues["backlogsStatus"] === "no")
          this.state.FormValues["backlogs"] = 0;
        delete this.state.FormValues["backlogsStatus"];
        delete this.state.FormValues["startdate"];
        delete this.state.FormValues["finishdate"];
        var dataObj = { education: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case internships:
        if (task === "PUT") {
          url = constructEditPortfolioInternshipUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Update Internship Details";
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
        } else {
          url = constructFetchPortfolioInternshipUrl();
          toaster = {
            toastType: "add",
            toastMsg: internships.AddText,
            toastHeading: internships.AddTextBold,
          };
          snackBarMsg = "Adding Internship Details";
        }
        this.state.FormValues["duration"] =
          dayjs(this.state.FormValues["finishdate"]).diff(
            dayjs(this.state.FormValues["startdate"]),
            "weeks"
          ) + 1;
        this.state.FormValues["type"] = "Internship";
        var dataObj = { internship: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case events:
        if (task === "PUT") {
          url = constructEditPortfolioExtEventUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Event Details";
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
          this.state.FormValues["external_id"] = this.state.data.external_id;
        } else {
          url = constructFetchPortfolioExtEventUrl();
          toaster = {
            toastType: "add",
            toastMsg: events.AddText,
            toastHeading: events.AddTextBold,
          };
          snackBarMsg = "Adding Event Details";
        }
        this.state.FormValues["status"] = "complete";
        var dataObj = { external_event: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case jobs:
        if (task === "PUT") {
          url = constructEditPortfolioInternshipUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Job Details";
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
        } else {
          url = constructFetchPortfolioInternshipUrl();
          toaster = {
            toastType: "add",
            toastMsg: jobs.AddText,
            toastHeading: jobs.AddTextBold,
          };
          snackBarMsg = "Adding Job Details";
        }
        this.state.FormValues["duration"] =
          dayjs(this.state.FormValues["finishdate"]).diff(
            dayjs(this.state.FormValues["startdate"]),
            "months"
          ) + 1;
        var dataObj = { internship: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;

      case educationSemAdd:
        if (task === "PUT") {
          url = constructEditPortfolioSemesterUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Subject Details";
        } else {
          url = constructAddPortfolioSubjectsUrl();
          toaster = {
            toastType: "add",
            toastMsg: educationSemAdd.AddText,
            toastHeading: educationSemAdd.AddTextBold,
          };
          snackBarMsg = "Adding Subject Details";
        }

        var dataObj = {
          education_id: this.state.FormValues["education_id"],
          subject: {
            name: this.state.FormValues["name"],
            semester_number: this.state.FormValues["semester_number"],
            date: dayjs(new Date()).format("YYYY-MM-DD"),
            score: this.state.FormValues["score"],
            type: this.state.FormValues["subjectType"],
            skills: skillsAdded,
            job_roles: this.state.FormValues["job_roles"],
          },
        };
        if (task === "PUT") {
          dataObj["subject"]["id"] = this.state.data.id;
          dataObj["subject"]["uuid"] = this.state.data.uuid;
        }
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;
      case certificate:
        if (task === "PUT") {
          url = constructEditPortfolioExtCoursesUrl(portfolio.id);
          toaster = {
            toastType: "edit",
            toastMsg: "Your changes have been saved.",
          };
          snackBarMsg = "Updating Certificate Details";
          this.state.FormValues["id"] = this.state.data.id;
          this.state.FormValues["uuid"] = this.state.data.uuid;
        } else {
          url = constructFetchPortfolioExtCoursesUrl();
          toaster = {
            toastType: "add",
            toastMsg: certificate.AddText,
            toastHeading: certificate.AddTextBold,
          };
          snackBarMsg = "Adding Certificate Details";
        }
        this.state.FormValues["type"] = "Industry Certification";
        this.state.FormValues["status"] = "complete";
        var dataObj = { external_course: this.state.FormValues };
        dispatch(
          Actions.fetchPortfolioCardContent(url, dataObj, task, toaster)
        );
        break;
    }
    dispatch(Actions.snackBarOn(snackBarMsg, 3000));
    this.closeModal();
  }

  render() {
    const {
      specializations,
      degrees,
      skills,
      institutesList,
      lifeSkills,
    } = this.props;
    if (
      this.state.cardToRender &&
      specializations &&
      degrees &&
      skills &&
      institutesList &&
      lifeSkills
    ) {
      var fields;
      // For filling the modal with respective card forms
      fields = this.fetchFields(this.state.cards);
      return (
        <div>
          <ModalComponent
            show={this.state.modalIsOpen}
            onHide={this.closeModal}
            modalClass="AddFormModal"
          >
            <div className="box-content">
              <h3 className="text-center ModalHeading">
                <span>{this.state.cards.title}</span>
                <i
                  className="fa fa-times AddFormClose float-right"
                  onClick={this.closeModal.bind(this)}
                />
              </h3>
              <h5 className="text-center ModalText font14">
                {this.state.cards.AddModalText}{" "}
              </h5>
              <form className="Addform row margin0">
                {fields}
                <div className="form-group col-12">
                  <center className="col-12">
                    <button
                      className="AddformBtn btn btn-warning"
                      onClick={this.handleSubmit.bind(this, this.state.cards)}
                    >
                      ADD THIS TO PORTFOLIO
                    </button>
                  </center>
                </div>
              </form>
            </div>
          </ModalComponent>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  const { dispatch, portfolio, addModal, environment, commonReducer } = state;
  const {
    specializations,
    degrees,
    skills,
    institutesList,
    lifeSkills,
    tipOpen,
  } = commonReducer;
  return {
    portfolio,
    addModal,
    dispatch,
    environment,
    specializations,
    degrees,
    skills,
    institutesList,
    lifeSkills,
    tipOpen,
  };
};

export default connect(mapStateToProps)(AddCardComponent);
