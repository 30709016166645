import {base_url} from "./ConstantUrl"

//  V2 currentUser
export function constructCurrentUserUrl(){
  // return base_url+'/api/v2/current_user';
  return base_url+'/api/v1/current_user_data';
}

export function constructMyTopicInterestUrl(){
  return base_url+'/api/myprofile/topic_interests';
}

export function destructMyTopicInterestUrl(){
  return base_url+'/api/myprofile/delete_topic_interests';
}

export function constructNotificationsUrl() {
  return base_url+'/api/myprofile/list/notifications';
}

export function resetUnviewed(){
  return base_url+'/api/myprofile/reset_unviewed';
}

export function fetchJobNotificationsUrl(page){
  return base_url+`/api/job_notifications${page ? `?page=${page}` : ""}`;
}

export function updateJobNotificationUrl(){
  return base_url+'/api/update_my_notification';
}

export function constructTaggedNotificationsUrl(type) {
  return base_url+ '/api/myprofile/list/notifications?activity_key=' + type;
}

export function constructUpdateProfileUrl() {
  return base_url+'/api/myprofile/update_settings'
}

export function constructOauthVerificationUrl() {
  return base_url+'/api/v1/oauth'
}

export function constructRefreshTokenUrl() {
  return base_url+'/api/v1/refresh_token'
}

export function constructLogoutUrl() {
  return base_url+'/api/v1/logout';
}
