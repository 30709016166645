import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Reducers from './reducers.js';
import { composeWithDevTools } from 'redux-devtools-extension';

const reducer = combineReducers({
  currentUser: Reducers.currentUser,
  environment: Reducers.environment,
  navigator: Reducers.navigator,
  error: Reducers.error,
  profileMaster: Reducers.profileMaster,
  ntfMaster: Reducers.ntfMaster,
  entities: Reducers.entities,
  portfolio: Reducers.portfolio,
  recommendations: Reducers.recommendations,
  events: Reducers.events,
  books: Reducers.books,
  articles: Reducers.articles,
  drawerState: Reducers.drawerState,
  currentActive: Reducers.currentActive,
  isLoading: Reducers.isLoading,
  loginModal: Reducers.loginModal,
  jobs: Reducers.jobs,
  internships: Reducers.internships,
  updateProfile: Reducers.updateProfile,
  testimonials: Reducers.testimonials,
  myPlansModal: Reducers.myPlansModal,
  clubs: Reducers.clubs,
  jobRoles: Reducers.jobRoles,
  getJobList: Reducers.getJobList,
  addModal: Reducers.addModal,
  countStudentData: Reducers.countStudentData,
  shortlistedStudents: Reducers.shortlistedStudents,
  jobsAppliedByInstitute: Reducers.jobsAppliedByInstitute,
  projectTeamData: Reducers.projectTeamData,
  companyJobs: Reducers.companyJobs,
  companyEvents: Reducers.companyEvents,
  companyMentors: Reducers.companyMentors,
  projectEnrollmentData: Reducers.projectEnrollmentData,
  eventEnrolledByInstitute: Reducers.eventEnrolledByInstitute,
  totalEventCount: Reducers.totalEventCount,
  projectGroups: Reducers.projectGroups,
  requiredSkills: Reducers.requiredSkills,
  isCreateJob: Reducers.isCreateJob,
  isCreateEvent: Reducers.isCreateEvent,
  companyPublicJobs: Reducers.companyPublicJobs,
  companyPublicEventsData: Reducers.companyPublicEventsData,
  companyPublicMentorsData: Reducers.companyPublicMentorsData,
  org: Reducers.org,
  postCategoryId: Reducers.postCategoryId,
  filteredData: Reducers.filteredData,
  graphData: Reducers.graphData,
  graphFilters: Reducers.graphFilters,
  topSkillsList: Reducers.topSkillsList,
  academicFilters: Reducers.academicFilters,
  publicPortfolio: Reducers.publicPortfolio,
  snackBarState: Reducers.snackBarState,
  instituteSplitData: Reducers.instituteSplitData,
  degreeSplitData: Reducers.degreeSplitData,
  instituteDegreeFilters: Reducers.instituteDegreeFilters,
  maxGraphRange: Reducers.maxGraphRange,
  totalStudentCount: Reducers.totalStudentCount,
  orgStudent: Reducers.orgStudent,
  instituteMentors: Reducers.instituteMentors,
  instituteJobs: Reducers.instituteJobs,
  instituteEvents: Reducers.instituteEvents,
  instituteClubs: Reducers.instituteClubs,
  instituteOrg: Reducers.instituteOrg,
  jobApplicants: Reducers.jobApplicants,
  eventApplicants: Reducers.eventApplicants,
  finalStudentCount: Reducers.finalStudentCount,
  isSavingShortlist: Reducers.isSavingShortlist,
  platformUpdates: Reducers.platformUpdates,
  actionItems: Reducers.actionItems,
  postsTimeline: Reducers.postsTimeline,
  yearGraphData: Reducers.yearGraphData,
  enrollmentBranchData: Reducers.enrollmentBranchData,
  enrollmentDashboardData: Reducers.enrollmentDashboardData,
  enrollmentFilters: Reducers.enrollmentFilters,
  instituteProjectCount: Reducers.instituteProjectCount,
  instituteProjectSplit: Reducers.instituteProjectSplit,
  instituteProjectTable: Reducers.instituteProjectTable,
  instituteProjects: Reducers.instituteProjects,
  instituteProjectFilter: Reducers.instituteProjectFilter,
  isCreateClub: Reducers.isCreateClub,
  clubStudent: Reducers.clubStudent,
  clubStudentCommittee: Reducers.clubStudentCommittee,
  clubJoinReq: Reducers.clubJoinReq,
  clubInvite: Reducers.clubInvite,
  eventInvite: Reducers.eventInvite,
  clubAllStudents: Reducers.clubAllStudents,
  clubJoinResponse: Reducers.clubJoinResponse,
  tokenExpiryTime: Reducers.tokenExpiryTime,
  tokenExpiredCondition: Reducers.tokenExpiredCondition,
  profileCreationData: Reducers.profileCreationData,
  recommendedJobRoles: Reducers.recommendedJobRoles,
  jobRoleLifeSkillsList: Reducers.jobRoleLifeSkillsList,
  eventSummaryData: Reducers.eventSummaryData,
  jobSummaryData: Reducers.jobSummaryData,
  cityList: Reducers.cityList,
  skillsDashboardFilterProp: Reducers.skillsDashboardFilterProp,
  skillsXcelerFilters: Reducers.skillsXcelerFilters,
  filterStudentCount: Reducers.filterStudentCount,
  jobInvitedApplicants: Reducers.jobInvitedApplicants,
  commonReducer: Reducers.commonReducer,
  popUpConfig: Reducers.popUpConfig,
  postsData: Reducers.postsData,
  postLoadState: Reducers.postLoadState,
  paymentData: Reducers.paymentData,
  postDetailData: Reducers.postDetailData,
  quizDetail: Reducers.quizDetail,
  quizBankDetail: Reducers.quizBankDetail,
  projectCreate: Reducers.projectCreate,
  myAssignment: Reducers.myAssignment,
  ratingIntervention: Reducers.ratingIntervention,
  toastState: Reducers.toastState,
  emailAvailability: Reducers.emailAvailability,
  phoneAvailability: Reducers.phoneAvailability,
  instaReducer: Reducers.instaReducer,
  accountActionUserUpdate: Reducers.accountActionUserUpdate,
  storeCurrentPath: Reducers.storeCurrentPath,
  getSearchProps: Reducers.getSearchProps,
  getCoAuthor: Reducers.getCoAuthor,
  getBatchScreen: Reducers.getBatchScreen,

});

export default function configureStore(initialState) {
	return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
}
