import { base_url } from "./ConstantUrl"

export function constructJobDetailUrl(id) {
	return base_url + `/api/jobs/${id}`;
}

export function fetchJobDetailsUrl(id) {
	return base_url + `/api/admin/fetch_job/${id}`;
}

export function constructJobDashboardDetailUrl(id) {
	return base_url + `/api/show_job_dashboard/${id}`;
}

export function constructFetchNewJobUrl() {
	return base_url + `/api/jobs`;
}

export function constructJobApplyUrl() {
	return base_url + "/api/my_profile/my_jobs";
}
export function calculateEligibilityPercentageUrl(job_id) {
	return base_url + "/api/calculate_eligibility_percentage?job_id="+job_id;
}


export function constructJobsListSearchUrl(input) {
	return base_url + "/api/search/jobs?query=" + input;
}

export function jobRecomBasedOnJobRoleUrl() {
	return base_url + "/api/recommendation_based_on_job_roles";
}