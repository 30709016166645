import * as types from '../constants/ActionTypes';
import { constructUrl } from '../utils/RouteUtils';
import history from '../containers/Root/history';
import { parseQueryString } from '../utils/CommonUtils';

export function changePath(route) {
	return {
		type: types.CHANGE_PATH,
		route
	};
}

/*
This function is used to initialise the navigator reducer. 
It is called from the main index.js when the project is mounted.  
*/
export function initNavigator(location, prevLocation = false) {
	return dispatch => {
		dispatch(changePath({ path: location.pathname.split('/').slice(1), query: parseQueryString(location.search), prevPath: prevLocation ? prevLocation.pathname : null }));
	};
}

/*
  This function is used to navigate to the given path.
*/
export function navigateTo(route) {
	return (dispatch, getState) => {
		const { navigator } = getState();
		if (constructUrl(route) === constructUrl(navigator.route)) {
			return null;
		}
		pushState(route, dispatch);
	};
}

function pushState(route) {
	history.push(`/${constructUrl(route)}`);
}

export function navigateToAndReplace(route) {
	return (dispatch, getState) => {
		const { navigator } = getState();
		if (constructUrl(route) === constructUrl(navigator.route)) {
			return null;
		}
		replaceState(route, dispatch);
	};
}

function replaceState(route) {
	history.replace(`/${constructUrl(route)}`);
}