import { base_url } from './ConstantUrl';

export function createPeerCommentReviewUrl() {
	return base_url + '/api/peer_review'
}

export function constructPeerCommentReviewUrl(id, type, aid) {
	return base_url + `/api/get_peer_review/${id}?type=${type}&aid=${aid}`
}

export function constructPeerReviewStatusUrl() {
	return base_url + `/api/peer_review_update_status`
}

export function createPeerCommentUrl() {
	return base_url + `/api/peer_comment`
}

export function constructPeerCommentUrl(id) {
	return base_url + `/api/peer_comment/${id}`
}

export function updatePeerReviewStatusUrl() {
	return base_url + `/api/peer_review_update_status`
}

export function getPeerSubmissionUrl(id) {
	return base_url + `/api/get_peer_submission/${id}`
}

export function constructPeerLearningUrl() {
	return base_url + `/api/get_peer_learning`
}

export function validatePeerStudentUrl() {
	return base_url + `/api/validate_peer_student`
}

export function updateFinalEvaluationUrl() {
	return base_url + `/api/update_final_evaluation`
}

export function updateEnableReviewsUrl() {
	return base_url + `/api/update_enable_reviews`
}

export function getStudentsEnrolledUrl(id, type, data_group_id) {
	return base_url + `/api/get_all_students/${id}?type=${type}&data_group_id=${data_group_id}`
}

export function createPeerEvaluationUrl() {
	return base_url + `/api/peer_evaluation`
}

export function getSelectedStudentsUrl(id) {
	return base_url + `/api/insert_check/${id}`
}

export function deletePeerEvaluationUrl(id) {
	return base_url + `/api/peer_evaluation/${id}`
}

export function checkPeerEvaluationUrl(id, data_group_id, resource_group_id) {
	return base_url + `/api/check_peer_evaluated/${id}?data_group_id=${data_group_id}&resource_group_id=${resource_group_id}`
}

export function autoEnrollPeerEvaluatorUrl() {
	return base_url + `/api/admin/auto_enroll_peer_evaluators`
}

export function manualEnrollPeerEvaluatorUrl() {
    return base_url + `/api/admin/manual_enroll_peer_evaluators`
}

export function techopsAllocation() {
	return base_url + `/api/admin/techops_allocation`
}

export function getMyEvaluationDetails(id) {
	return base_url + `/api/get_my_evaluation_details/${id}`
}

export function createPeerReviewHistoryUrl() {
	return base_url + `/api/peer_review_history`
}

export function createSendScoreCardUrl() {
	return base_url + `/api/admin/send_score_card`
}

export function downloadScoreCardUrl(profile_id, quest_id) {
	return base_url + `/api/admin/download_score_card?profile_id=${profile_id}&quest_id=${quest_id}`
}

export function skipResourceGroupUnitUrl() {
	return base_url + `/api/skip_resource_group_unit`
}

export function sendRequestExtensionUrl() {
	return base_url + `/api/send_request_extension`
}