import * as types from '../constants/ActionTypes';


function changeIsMobile(isMobile) {
	return {
		type: types.CHANGE_IS_MOBILE,
		isMobile,
	};
}

export function changeWidthAndHeight(height, width) {
	return {
		type: types.CHANGE_WIDTH_AND_HEIGHT,
		height,
		width,
	};
}

function detectBrowser(browserObject) {
	return {
		type: 'DETECT_USER_BROWSER',
		browserObject
	}
}
/* 
It is called from the main index.js when the project is mounted.  
It is used for setting up the environment params. 

1. width,height of the screen
2. Identifying the brower. 
3. Setting up event listner for the screen size change. 
 */
export function initEnvironment() {
	return dispatch => {
		var isMobile = window.innerWidth;
		if (isMobile < 768) {
			isMobile = true;
		}
		else {
			isMobile = false;
		}
		if (isMobile) {
			document.body.style.overflow = 'scroll'; //hidden
		}

		var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [], browserName = null, version = null;
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			browserName = "IE"
			version = tem[1]
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\bOPR|Edge\/(\d+)/)
			if (tem != null) {
				browserName = "Opera"
				version = tem[1]
			}
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) {
			M.splice(1, 1, tem[1]);
		}
		if (browserName === null) browserName = M[0]
		if (version === null) version = M[1]

		var browserObject = {
			browserName
		}

		dispatch(detectBrowser(browserObject))

		dispatch(changeIsMobile(isMobile));
		dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));

		window.onresize = () => {
			var isMobile = window.innerWidth;

			if (isMobile < 768) {
				isMobile = true;
			}
			else {
				isMobile = false;
			}
			if (isMobile) {
				document.body.style.overflow = 'scroll'; //hidden
			}
			dispatch(changeIsMobile(isMobile));
			dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));
		};
	};
}
