import React from 'react';

const skillComponent = props => {
	let temp = null;
	if (Array.isArray(props.skills)) {
		temp = props.skills.map((item, index) => {
			return <div className={props.type ? "skillTabBlack" : "skillTab"} style={props.nameStyles ? props.nameStyles : {}} key={index}>{item.display_name ? item.display_name : item.name}</div>
		})
	}
	return (
		<div className={`skills ${props.customClass ? props.customClass: 'col-12'}`} style={props.styles ? props.styles : {}}>
			{temp}
		</div>
	)
}

export default skillComponent;