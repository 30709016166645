import { UNAUTHORIZED, FORBIDDEN, NOT_FOUND, UNPROCESSABLE_ENTITY, INTERNAL_SERVER_ERROR } from '../constants/ActionTypes';
import { unauthorizedForbiddenConfig, pageNotFoundConfig, unprocessableEntityConfig, internalServerErrorConfig } from '../utils/CommonUtils';
import { navigateToAndReplace } from './navigator';

const dispatchAndNavigate = (dispatch, path, data) => {
	dispatch({ type: "MERGE_IN_COMMON_REDUCER", key: "error_info", data });
	dispatch(navigateToAndReplace({ path }));
}

export function errorHandler(error, dispatch) {
	dispatch({ type: 'RESET_SNACK_BAR' })
	if (error.response !== undefined) {
		if (error.response.status === UNAUTHORIZED || error.response.status === FORBIDDEN) {
			dispatchAndNavigate(dispatch, ['unauthorized'], unauthorizedForbiddenConfig());
		} else if (error.response.status === NOT_FOUND) {
			dispatchAndNavigate(dispatch, ['invalidRoute'], pageNotFoundConfig());
		} else if (error.response.status === UNPROCESSABLE_ENTITY) {
			dispatchAndNavigate(dispatch, ['error'], unprocessableEntityConfig());
		} else if (error.response.status === INTERNAL_SERVER_ERROR) {
			dispatchAndNavigate(dispatch, ['error'], internalServerErrorConfig());
		}
	}
	else {
		dispatchAndNavigate(dispatch, ['error'], unprocessableEntityConfig());
	}
}