import { base_url } from './ConstantUrl';
export function constructProfileUrl(profileId) {
	return base_url + '/api/profiles/' + profileId;
}

export function createProfileUrl() {
	return base_url + '/api/profiles';
}

export function createPortfolioUrl() {
	return base_url + '/api/my_portfolio/portfolios';
}

export function constructProfilesUrlCompany(profile_type) {
	return base_url + `/api/profiles?profile_type=${profile_type}`;
}

export function constructProfilesJobCompanyList(profile_type) {
	return base_url + `/api/profiles?profile_type=${profile_type}&status=CompanyList`;
}

export function constructProfilesUrlMentor() {
	return base_url + '/api/list/profiles/?profile_type=ADVISOR';
}

export function constructProfilesUrlStudent() {
	return base_url + '/api/list/profiles/?profile_type=STUDENT';
}

export function constructOrgUrl() {
	return base_url + '/api/orgs';
}

export function constructInstituteUrl() {
	return base_url + '/api/orgs?org_type=INSTITUTION';
}

export function constructBulkProfileUpload() {
	return base_url + '/api/admin/bulk_student_profile_upload';
}

export function constructBulkProfileUpdate() {
	return base_url + '/api/admin/bulk_user_update';
}

export function constructBulkPortfolioUpdate() {
	return base_url + '/api/admin/bulk_update_student_portfolio';
}

export function constructBulkAdvisorUpload() {
	return base_url + '/api/admin/bulk_advisor_profile_upload';
}

export function getDegreesUrl() {
	return base_url + '/api/degrees';
}

export function getSpecializationsUrl() {
	return base_url + '/api/specializations';
}

export function getOrganizationUrl(id, name) {
	return (
		base_url +
		`/api/get_org_by_id_and_name?${id && id.toString().length ? 'id=' + id : ''}${
			name && name.length ? 'name=' + name : ''
		}`
	);
}

export function getDesignationsUrl() {
	return base_url + '/api/designations';
}

export function constructSkillsUrl() {
	return base_url + '/api/skills';
}

export function constructFeedUrl() {
	return base_url + '/api/myprofile/feed';
}

export function constructMyProjectUrl() {
	return base_url + '/api/my_profile/my_projects';
}

export function checkTeamNameUrl(projectId) {
	return base_url + '/api/projects/' + projectId + '/check_team_name';
}

export function constructUserList(input) {
	return base_url + `/api/lov/profiles?profile_type[]=STUDENT&profile_type[]=ADVISOR&query=${input}`;
}

export function constructTrimmedUserList(input) {
	return base_url + `/api/lov/profiles_trimmed?profile_type[]=STUDENT&profile_type[]=ADVISOR&query=${input}`;
}

export function constructSearchUserTrimmed(profileType, input) {
	return base_url + `/api/lov/profiles_trimmed?profile_type[]=${profileType}&query=${input}`;
}

export function constructSearchUserTrimmedRespectToProject(profileType, projectId, input) {
	return base_url + `/api/lov/profiles_trimmed?profile_type[]=${profileType}&project_id=${projectId}&query=${input}`;
}

export function constructSearchAdvisorTrimmed(input) {
	return base_url + `/api/lov/profiles_trimmed?profile_type[]=ADVISOR&query=${input}`;
}

export function constructFetchPortfolioUrl() {
	return base_url + '/api/my_portfolio/profile_portfolios';
}

export function constructFetchPublicPortfolioUrl(id) {
	return base_url + `/api/public_portfolio?profile_id=${id}`;
}

export function constructFetchStudentProfileOrg() {
	return base_url + '/api/show_org_students';
}

export function constructFetchClubStudentProfile(id) {
	return base_url + `/api/club_org_students?club_id=${id}`;
}

export function constructFetchAllClubMemberProfile(id) {
	return base_url + `/api/club_all_members?club_id=${id}`;
}

export function constructUpdateClubJoinRequest(id) {
	return base_url + `/api/my_profile/my_clubs/${id}`;
}

export function constructUpdateClubAdmin(id) {
	return base_url + `/api/update_club_admin/${id}`;
}

export function constructUpdateClubCommitteeUrl(id) {
	return base_url + `/api/update_club_committee`;
}

export function constructFetchClubCommitteeStudentProfile(id) {
	return base_url + `/api/club_org_students_committee?club_id=${id}`;
}

export function constructFetchClubJoinRequestProfile(id) {
	return base_url + `/api/show_club_join_requests?club_id=${id}`;
}

export function constructFetchClubInviteList(id, status) {
	return base_url + `/api/admin/inivited_students_list?id=${id}&type=Club_Invited&status=${status}`;
}
export function constructFetchEventInviteList(id, status) {
	return base_url + `/api/admin/inivited_students_list?id=${id}&type=Event_Invited&status=${status}`;
}
export function constructFetchStudentProfileOrgPublic(orgId) {
	return base_url + '/api/show_org_students?org_id=' + orgId;
}

export function constructCompanyUrl() {
	return base_url + '/api/orgs?org_type=COMPANY';
}

export function jobRolesRecommendationUrl() {
	return base_url + '/api/get_recommended/job_roles';
}

export function constructEnrollmentStudentsUrl() {
	return base_url + '/api/non_grad_student';
}

export function getUserByEmail(user) {
	return `${base_url}/api/admin/get_profile_by_email?email_id=${user.email}&profile_type=${user.profileType}`;
}

export function getUserByPhone(user) {
	return `${base_url}/api/admin/get_profile_by_phone?phone=${user.email}&profile_type=${user.profileType}`;
}

export function getUserProfileCompletion() {
	return `${base_url}/api/admin/get_student_incomplete_account`;
}

export function checkUserAvailability(param) {
	return `${base_url}/api/check_identifier_availability?field=${param.name}&${param.name}=${param.value}`;
}

export function checkBulkEnrollAvailability(param) {
	return `${base_url}/api/bulk_enroll_identifier_availability?field=${param.name}&${param.name}=${param.value}`;
}

export function constructUserUpdateUrl() {
	return base_url + '/api/admin/update_profile_linked_entities';
}

export function getInstaPosts(type, per_page, page) {
	return base_url + `/api/get_insta_catalog?type=${type}&sub_type=ALL&per_page=${per_page}&page=${page}`;
}

export function getInstaImageUrl() {
	return base_url + '/api/upload_insta_file';
}

export function constructInstaUploadUrl() {
	return base_url + '/api/add_insta_entry';
}

export function removeInstaEntry() {
	return base_url + '/api/remove_insta_entry';
}

export function getAllInstaUrl(per_page, page) {
	return base_url + `/api/get_insta_uploads?type=INSTA_CATALOG&sub_type=ALL&per_page=${per_page}&page=${page}`;
}

export function createZoomPostUrl() {
	return base_url + '/api/create_zoom_post';
}

export function listZoomPostsUrl() {
	return base_url + '/api/list_zoom_posts';
}

export function listScheduledPostsUrl() {
	return base_url + '/api/list_my_scheduled_posts';
}
export function listMentorQuizUrl() {
	return base_url + '/api/fetch_mentor_quiz_activity_data_groups?type=QUIZ';
}
export function listMentorQuestionBankUrl() {
	return base_url + '/api/fetch_mentor_quiz_activity_data_groups?type=QUESTION_BANK';
}
export function listMentorActivityUrl() {
	return base_url + '/api/fetch_mentor_quiz_activity_data_groups?type=ACTIVITY';
}

export function deleteScheduledPostUrl() {
	return base_url + '/api/delete_scheduled_post';
}

export function updateZoomPostUrl(postId) {
	return base_url + `/api/update_zoom_post/${postId}`;
}

export function updateOnlyProfileUrl() {
	return base_url + '/api/v2/update_only_profile';
}

export function eguruProfileCreateUrl() {
	return 'https://www.mysmarttraining.net/api/user/create';
}

export function constructOrgProfileTrimmed(input) {
	return base_url + `/api/lov/org_profile_trimmed?profile_type=ADVISOR&query=${input}`;
}
