import { base_url } from "./ConstantUrl"

export function constructProjectUrl(projectId) {
  return base_url + `/api/projects/${projectId}`;
}

export function constructProjectsUrl() {
  return base_url + '/api/lists/project';
}

export function constructJobProjectsUrl() {
  return base_url + '/api/list/project';
}

export function constructProjectTaskCompletedUrl(projectId) {
  return base_url + `/api/my_profile/my_projects/${projectId}`;
}

export function constructMentorProjects() {
  return base_url + '/api/my_profile/my_mentored_projects'
}

export function constructMentorOwnedProjectsListUrl() {
  return base_url + '/api/list/owned_projects';
}


export function constructMentorLPEnrollUrl() {
  return base_url + "/api/my_profile/my_resource_groups";
}

export function constructMentorQuestEnrollUrl() {
  return base_url + "/api/my_profile/my_quests";
}

export function constructProjectSubmissionUpdateUrl(projectId) {
  return base_url + `/api/my_profile/update_submission_status/${projectId}`
}

export function constructProjectQueryUrl() {
  return base_url + "/api/my_profile/my_projects/project_query";
}

export function constructAddNewTaskUrl() {
  return base_url + "/api/my_profile/my_project_activities"
}

export function constructEditTaskUrl(id) {
  return base_url + "/api/my_profile/my_project_activities/" + id
}

export function constructDisplayGroupUrl() {
  return base_url + "/api/admin/projectgroups"
}

export function createProjectUrl() {
  return base_url + "/api/admin/projects"
}

export function createProjectDetailsUrl() {
  return base_url + `/api/admin/project/details`
}

export function createProjectActivitiesUrl() {
  return base_url + `/api/admin/project/create_project_activities`
}

export function updateProjectActivitiesUrl(id) {
  return base_url + `/api/admin/project/update_project_activities/${id}`
}

export function deleteProjectActivitiesUrl(id) {
  return base_url + `/api/admin/project/delete_project_activities/${id}`
}

export function constructBasicProjectUrl(id) {
  return base_url + `/api/show_project_basic/${id}`
}

export function constructProjectListUrl() {
  return base_url + `/api/fetch_filtered_projects`;
}

export function constructPublishProjectUrl() {
  return base_url + `/api/admin/project/publish_project_update`
}

export function constructAcceptAllProjectTasksUrl() {
  return base_url + `/api/admin/project_task_updation`;
}
export function constructProjectActivityFileUrl() {
  return base_url + '/api/my_profile/project_activity_files'
}

export function generatePresignedUrl() {
  return base_url + '/api/my_profile/create_presigned_url';
}

export function constructProjectActivityFileUploadUrl() {
  return base_url + '/api/create_project_activity_upload'
}

export function constructProjectActivityFileDeleteUrl() {
  return base_url + '/api/delete_project_activity_file'
}

export function constructProjectBImageUrl() {
  return base_url + '/api/my_profile/create_project_upload';
}

export function deleteProjectBImageUrl(id) {
  return base_url + '/api/delete_project_file/' + id;
}