import { runValidators, minValidators, emptyValidator, dateValidator, minMaxValidator } from "../../validations/validate";

export function createLabelTag(tag, label) {
  return tag + '"' + label + '"';
}

//Validators for forms
export function getValidators(minDate, maxDate) {
  return {

    'empty': [(v) => emptyValidator(v, 'This ')],
    'date': [(v) => dateValidator(v, minDate, maxDate, "Date")],
    'cgpa': [(v) => minMaxValidator(v, 0, 10, "This")],
    'duration': [(v) => minValidators(v, 'Duration ')],
    'backlogs': [(v) => minValidators(v, 'Backlogs ')],
    'percentage': [(v) => minMaxValidator(v, 0, 100, "This")],
    'semester_number': [(v) => minMaxValidator(v, 1, 10, "Semester")],
  }
}

//_validateField is called to set error messages in form fields
export function _validateField(fieldKey, target, state) {
    const validators = getValidators(state.minDate, state.maxDate);
    let { errorMessages } = state;
    var result;
    var toCheck = /date/g;
    var toCheckDuration = /duration/g;
    var toCheckCgpa = /cgpa/g;
    var toCheckPercentage = /percentage/g;
    var toCheckBacklogs = /backlogs/g;
    var toCheckSemester = /sem/g;

    var istrue = toCheck.test(fieldKey);
    var istrueCgpa = toCheckCgpa.test(fieldKey);
    var istrueDuration = toCheckDuration.test(fieldKey);
    var isTrueBacklogs = toCheckBacklogs.test(fieldKey);
    var isTruePercentage = toCheckPercentage.test(fieldKey);
    var isTrueSemester = toCheckSemester.test(fieldKey);

    if (istrue)
      result = runValidators(target, validators["date"]);
    else if (istrueDuration)
      result = runValidators(target, validators[fieldKey]);
    else if (istrueCgpa)
      result = runValidators(target, validators["cgpa"]);
    else if(isTrueBacklogs)
      result = runValidators(target, validators[fieldKey]);
    else if(isTruePercentage)
      result = runValidators(target, validators["percentage"]);
    else if(isTrueSemester)
      result = runValidators(target, validators[fieldKey]);
    else
      result = runValidators(target, validators["empty"]);

    if(fieldKey === "percentage" || fieldKey === "cgpa") fieldKey = "score"
    if (result !== null) {
      errorMessages[fieldKey] = result;
    } else {
      errorMessages[fieldKey] = "";
    }

    return {
      errorMessages
    };
}

// clubs -> source
// events -> source
// Jobs/Internships -> Organisation === host
