import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoggedInLinks from './screens/LoggedInLinks';
import imglogo from '../../../../assets/main-logo.png';
import imglogoMobile from '../../../../assets/xceler_logo.jpeg';
import { changeDrawerState } from '../../../../actions/drawer';
import { navigateTo } from '../../../../actions/navigator';
import ModalComponent from '../../components/commonComponent/modalComponent';
import TermsofUse from "./termsOfUse"
import {acceptTermsUrl} from "../../../../utils/CommonUtils"
import { constructCurrentUserUrl} from '../../../../utils/AuthUtils.js';
import Actions from '../../../../../src/redux/actions';
import axios from 'axios';

const imageDynamic = 'https://s3-ap-southeast-1.amazonaws.com/xceler-platform-assets/site-images/partner_logos/';
const navStyle = {
	position: 'fixed',
	zIndex: '1200',
	backgroundColor: 'var(--clr-white)',
	margin: '0px',
	border: '1px solid #ddd',
	width: '100%',
	height: '55px',
	top: 0
};



const MainMenu = props => {
	const handleDrawer = () => {
		const { dispatch } = props
		dispatch(changeDrawerState(!props.drawerState));
	}

	const logout=()=> {
		const { dispatch } = props; 
      	dispatch({
        	type: "DELETE_KEY_COMMON_REDUCER",
        	key: "adminAccessLevel",
      	});
    	props.dispatch(changeDrawerState(false));
		props.dispatch(Actions.logout());
		props.dispatch({ type: "IS_LOADING" });
		props.dispatch({ type: 'HIDE_ADD_JOBS_MODAL' });
	}

	const agreeTerms=()=>{
		axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
			axios.post(acceptTermsUrl(),{terms_nd_cond: true})
				.then((response) => {
					updateCurrentuserData()


				})
				.catch((error) => {
					
				});
	}


	const updateCurrentuserData=()=>{
	axios
		.get(constructCurrentUserUrl(), {
						headers: { 'Authorization': localStorage.access_token }
			})
	   .then(response => {
			dispatch({type: 'MERGE_IN_COMMON_REDUCER', key: 'loginPopupConfig', data: {showModal: false, isLogin: false}})
			if (response.status === 201) {
				dispatch({
					type: 'CURRENT_USER',
					user: response.data.user
				});
				setshowTermsModal(false)
			}
			})
		.catch(error=>{
			console.log("error", error)
		})
	}


	const { dispatch, currentUser, isMobile, drawerState, signUpFlowActive, hideMenuBar } = props;
	const [showTermsModal, setshowTermsModal] = useState(false)

	useEffect(()=>{
		if (currentUser && currentUser.profile && !currentUser.profile.terms_nd_cond){
			
			setshowTermsModal(true)
			}
	})
	return (
		<>
	 {showTermsModal?<ModalComponent show={true} modalClass="commonModalStyle" modalDarkBackground={true} modalStyle={{ width: '85vw', overflowY:"scroll" }}>

			<TermsofUse dispatch= {props.dispatch}/>
					<div className="col-12 text-center">
						<button
							className="enrollmentProjectFilterBtn font14 "
							onClick={()=>{
								agreeTerms()
							}}
						>
							Agree
						</button>
						<button
							className="enrollmentProjectFilterBtn font14 "
							style={{marginLeft: "20px"}}
							onClick={()=>{
								logout()
							}}
							
						>
							Cancel
					    </button>
									</div>
                </ModalComponent>:null}
		<div style={navStyle} className="col-12" >
			{hideMenuBar ?
				<img src={imglogo} alt="" style={isMobile ? { width: '87%', position: 'relative', left: '50%', transform: 'translate(-50%, 35%)' } : { height: 18, verticalAlign: 'inherit', position: 'relative', top: 17 }} />
				: <div className="row">
					<div className="col-5 col-sm-3" style={{ height: 53 }}>
						{!isMobile ?
							<div
								className="navbarLogoCustom hidden-xs"
								dispatch={dispatch}
								route={(currentUser.profile)
									?
									(currentUser.profile.profile_type === "STUDENT")
										?
										currentUser.profile.active ? 
											{ path: ['dashboard'] }
											: { path: ['disabledAccount']}
										:
										(currentUser.profile.profile_type === 'ADVISOR')
											?
											currentUser.profile.active ? 
												{ path: ['posts'] }
												: { path: ['disabledAccount']}
											:
											(currentUser.profile.profile_type === 'COMPANY')
												?
												(currentUser.profile.active) ?
													{ path: ['companyDashboard'] }
													:
													{ path: ['unapprovedProfile'] }
												:
												(currentUser.profile.active) ?
													{ path: ['instituteDashboard'] }
													:
													{ path: ['unapprovedProfile'] }
									:
									(currentUser.profile_type === "STUDENT") ?
										{ path: ['studentProfile'] }
										:
										(currentUser.profile_type === "ADVISOR") ?
											{ path: ['mentorProfile'] }
											:
											(currentUser.profile_type === "COMPANY") ?
												{ path: ['companyProfile'] }
												:
												{ path: ['instituteProfile'] }
								}
								title="Home">
								{currentUser && currentUser.profile && currentUser.profile.my_org_logo ? <img src={imageDynamic + currentUser.profile.my_org_logo} alt="" style={{ height: 50, position: 'absolute', top: 2 }} /> :
									<img src={imglogo} alt="" style={{ height: 18, verticalAlign: 'inherit' }} />}
							</div>
							:
							<div className="mobileLoggedInLeft">
								{(signUpFlowActive) || (currentUser && currentUser.profile && !currentUser.profile.active) ?
									null :
									<span className="hamBurger">
										<i className="fa fa-bars" style={drawerState ? { color: 'var(--clr-button-orange)' } : null} onClick={handleDrawer} aria-hidden="true"></i>
									</span>
								}
								<span
									onClick={currentUser && currentUser.profile && !currentUser.profile.active ? () => { } : handleDrawer}
									className="navbarLogoCustomMobile">
									{currentUser && currentUser.profile && currentUser.profile.my_org_logo ? <img src={imageDynamic + 'thumb_' + currentUser.profile.my_org_logo} alt="" style={{ height: '100%', width: '100%' }} /> :
										<img src={imglogoMobile} alt="" style={{ height: '100%', width: '100%' }} />}
								</span></div>}
					</div>
					<div className={"col-md-3 col-sm-3 col-7 offset-sm-6 float-right"} style={drawerState && isMobile ? { display: 'none' } : null}>
						<div className="navbar-collapse" id="bs-example-navbar-collapse-1" style={{ paddingRight: 0 }}>
							<LoggedInLinks />
						</div>
					</div>
				</div>}
		</div>
		</>
	)
}

const mapStateToProps = (state) => {
	const { environment, navigator, drawerState, commonReducer } = state;
	const { isMobile, signUpFlowActive } = environment;
	const { hideMenuBar } = commonReducer;
	const { path } = navigator.route;
	return {
		currentUser: state.currentUser,
		path,
		signUpFlowActive,
		isMobile,
		drawerState,
		hideMenuBar
	}
}

export default connect(mapStateToProps)(MainMenu);


