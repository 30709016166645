
import * as types from '../constants/ActionTypes';

export function initError() {
   return dispatch => {
    dispatch(setError(null));
  };
}

export function setError(error_msg) {
   return {
    type: types.SET_ERROR,
    error_msg,
  };
}

export function displayError (error_msg) {
   return dispatch => {
    dispatch(setError(error_msg));
  };
}
export function loginError (error_msg) {
   return dispatch => {
    dispatch(setLoginError(error_msg));
  };
}
export function setLoginError(error_msg) {
   return {
    type: types.SET_LOGIN_ERROR,
    error_msg,
  };
}


